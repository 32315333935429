/* External modules */
import React, { FC } from "react";

/* MUI Components */
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

/* Material Icons */
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

/* Local modules */
import ObjectTemplateRow from "./ObjectTemplateRow";
import { ObjectTemplate } from "gather-common/dist/src/public/resources/objectTemplates";

interface Props {
  objTemplates: ObjectTemplate[];
  canEdit: boolean;
  currentSortAsc: boolean;
  onToggleSort: () => void;
  onEdit?: (template: ObjectTemplate) => void;
  onDelete?: (template: ObjectTemplate) => void;
}

const ObjectTemplatesTable: FC<Props> = ({
  objTemplates,
  canEdit,
  currentSortAsc,
  onToggleSort,
  onEdit,
  onDelete,
}) => (
  <Box sx={{ minWidth: "100%" }}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell
            onClick={onToggleSort}
            sx={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            Name
            <Box
              sx={{
                mb: -1,
                ml: -1,
              }}
            >
              {currentSortAsc ? (
                <ArrowDropDownIcon color="primary" sx={{ ml: 2 }} fontSize="small" />
              ) : (
                <ArrowDropUpIcon color="primary" sx={{ ml: 2 }} fontSize="small" />
              )}
            </Box>
          </TableCell>
          <TableCell>Tags</TableCell>
          <TableCell>Interaction Type</TableCell>
          {canEdit && (onEdit || onDelete) && <TableCell>Actions</TableCell>}
        </TableRow>
      </TableHead>

      <TableBody>
        {objTemplates.map((item, index: number) => (
          <ObjectTemplateRow
            key={`obj-${index}`}
            objTemplate={item}
            canEdit={canEdit}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        ))}
      </TableBody>
    </Table>
  </Box>
);

export default ObjectTemplatesTable;
