import React, { useEffect, FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useRoles from "features/roles/useRoles";
import { toast } from "react-hot-toast";
import { AdminPermission } from "gather-admin-common/dist/src/public/roles/types";
import { checkIfPermitted } from "gather-admin-common/dist/src/public/roles/permissions";

interface Props {
  required: AdminPermission[];
  requiresAll?: boolean;
  children: string | React.ReactNode;
}

const PermittedRouteGuard: FC<Props> = ({ children, required, requiresAll = false }) => {
  const { permissions: currentPermissions } = useRoles();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const isPermitted = checkIfPermitted(required, currentPermissions, requiresAll);

    if (!isPermitted) {
      navigate("/dashboard");
      toast.error(
        "You do not have permission to access this page. If you require access, contact the Platform Tools team.",
      );
    }
  }, [location, navigate, required, currentPermissions, requiresAll]);

  return <>{children}</>;
};

export default PermittedRouteGuard;
