import { useSearchParams } from "react-router-dom";

const useDeepLinking = () => {
  const [params, setParams] = useSearchParams();
  return {
    params,
    setParams,
  };
};

export default useDeepLinking;
