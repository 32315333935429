import type { PayloadAction } from "@reduxjs/toolkit";
import { createDraftSafeSelector, createSelector, createSlice } from "@reduxjs/toolkit";
import { BrowserVideoClientStateSession } from "gather-prisma-types/dist/src/public/client";
import { isArray, orderBy } from "lodash";
import {
  getAllParticipants,
  getParticipantsFromSnapshots,
} from "pages/dashboard/clientState/clientStateTool/participants.logic";
import {
  getSnapshots,
  isValidSession,
  addUniqueMessageId,
} from "pages/dashboard/clientState/clientStateTool/utils";
import { ViewerMessage } from "pages/dashboard/clientState/clientStateTool/types";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import type { RootState } from "./store";

export interface AvClientStateViewerState {
  isPlaying: boolean;
  currentStep: number;
  currentSession?: BrowserVideoClientStateSession;
  pointsOfInterest: string[];
}

const initialState: AvClientStateViewerState = {
  isPlaying: false,
  currentStep: 0,
  pointsOfInterest: [
    "CONSOLE_MESSAGE/warn",
    "CONSOLE_MESSAGE/error",
    "WEBRTC_ISSUE/",
    "NETWORK_TEST_RESULTS/",
  ],
};

export const avClientStateViewerSlice = createSlice({
  name: "avClientStateViewer",
  initialState,
  reducers: {
    playing: (state) => {
      state.isPlaying = true;
    },
    paused: (state) => {
      state.isPlaying = false;
    },
    sessionChanged: (state, action: PayloadAction<BrowserVideoClientStateSession | undefined>) => {
      state.currentSession = action.payload;
      state.isPlaying = false;
      state.currentStep = 0;
    },
    timelineChanged: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
      state.isPlaying = false;
    },
    clickedToTimestamp: (state, action: PayloadAction<number>) => {
      const value = findStepByTimestamp(action.payload, selectLocalTimestamps(state));
      if (value !== undefined) {
        state.currentStep = value;
        state.isPlaying = false;
      }
    },
    stepIncrement: (state) => {
      state.currentStep += 1;
    },
  },
});

export const {
  clickedToTimestamp,
  timelineChanged,
  stepIncrement,
  sessionChanged,
  playing,
  paused,
} = avClientStateViewerSlice.actions;

export const selectIsPlaying = (state: RootState) => state.avClientStateViewer.isPlaying;
export const selectCurrentStep = (state: RootState) => state.avClientStateViewer.currentStep;
export const selectCurrentSession = (state: RootState) => state.avClientStateViewer.currentSession;
export const selectPointsOfInterest = (state: RootState) =>
  state.avClientStateViewer.pointsOfInterest;

export const selectRawMessages = (
  state: RootState,
): BrowserVideoClientStateSession["session"] | undefined =>
  state.avClientStateViewer.currentSession?.session;

export const transformWithId = (messages?: unknown): ViewerMessage[] => {
  if (isArray(messages) && isValidSession(messages))
    return addUniqueMessageId(orderBy(messages, ["timestamp"], ["asc"]));

  return [];
};

export const selectMessages: (root: RootState) => ViewerMessage[] = createSelector(
  selectRawMessages,
  transformWithId,
);
export const transformTimestamps = (messages: ViewerMessage[] = []) =>
  Array.from(new Set(messages.map((i) => i.timestamp)));

export const selectLocalRawMessages: (state: AvClientStateViewerState) => ViewerMessage[] =
  createDraftSafeSelector(
    (state: AvClientStateViewerState) => state.currentSession?.session,
    transformWithId,
  );

export const selectTimestamps = createSelector(selectMessages, transformTimestamps);
export const selectLocalTimestamps = createSelector(selectLocalRawMessages, transformTimestamps);
export const selectCurrentTimestamp = createSelector(
  selectCurrentStep,
  selectTimestamps,
  (currentStep, timestamps) => timestamps[currentStep] ?? 0,
);

export const useIsPlaying = () => useSelector(selectIsPlaying);
export const useCurrentStep = () => useSelector(selectCurrentStep);
export const useCurrentSession = () => useSelector(selectCurrentSession);
export const useSessionMessages = () => useSelector(selectMessages);
export const useTimestamps = () => useSelector(selectTimestamps);
export const useCurrentTimestamp = () => useSelector(selectCurrentTimestamp);
export const useAVClientStateViewerDispatchers = () => {
  const dispatch = useDispatch();

  const dispatchStepIncrement = useCallback(() => dispatch(stepIncrement()), []);
  const dispatchPlaying = useCallback(() => dispatch(playing()), []);
  const dispatchPaused = useCallback(() => dispatch(paused()), []);

  const dispatchTimelineChanged = useCallback(
    (currentStep: number) => dispatch(timelineChanged(currentStep)),
    [],
  );
  const dispatchClickedToTimestamp = useCallback(
    (timestamp: number) => dispatch(clickedToTimestamp(timestamp)),
    [],
  );

  const dispatchSessionChanged = useCallback(
    (currentSession: BrowserVideoClientStateSession | undefined) =>
      dispatch(sessionChanged(currentSession)),
    [],
  );
  return {
    dispatchPlaying,
    dispatchPaused,
    dispatchStepIncrement,
    dispatchTimelineChanged,
    dispatchSessionChanged,
    dispatchClickedToTimestamp,
  };
};

export const selectParticipantsByTimestamp = createSelector(selectMessages, (messages) =>
  getParticipantsFromSnapshots(getSnapshots(messages)),
);

export const selectParticipants = createSelector(selectMessages, (messages) =>
  getAllParticipants(messages),
);
export const selectCurrentParticipants = createSelector(
  selectParticipantsByTimestamp,
  selectCurrentTimestamp,
  (participantsByTimestamp, currentTimestamp) => participantsByTimestamp[currentTimestamp],
);
export const useParticipantsByTimestamp = () => useSelector(selectParticipantsByTimestamp);
export const useSelectParticipants = () => useSelector(selectParticipants);
export const useCurrentParticipants = () => useSelector(selectCurrentParticipants);
export const usePointsOfInterest = () => useSelector(selectPointsOfInterest);

export function useParticipants(selfParticipant = "") {
  const participantsByTimestamp = useParticipantsByTimestamp();

  const remoteParticipants = useSelectParticipants();
  const currentParticipants = useCurrentParticipants();

  const participants = useMemo(
    () => (selfParticipant ? [selfParticipant, ...remoteParticipants] : remoteParticipants),
    [selfParticipant, remoteParticipants],
  );

  const currentParticipantsWithSelf = useMemo(
    () => [...(currentParticipants ?? []), selfParticipant],
    [selfParticipant, currentParticipants],
  );

  return {
    participantsByTimestamp,
    participants,
    currentParticipants: currentParticipantsWithSelf,
  };
}

export const findStepByTimestamp = (timestamp: number, timestamps: number[]) =>
  timestamps.lastIndexOf(timestamp);

export default avClientStateViewerSlice.reducer;
