/* External modules */
import React, { FC, useState } from "react";
import { toast } from "react-hot-toast";
import { Formik } from "formik";

/* MUI Components */
import Box from "@mui/material/Box";

/* Local modules */
import WearablesForm, { WearableFields } from "./WearablesForm";
import * as WearablesAPI from "../../../../features/wearables/api";
import Typography from "@mui/material/Typography";
import {
  Wearable,
  WearableColors,
  WearableLayerLegacy,
} from "gather-common/dist/src/public/resources/users";
import { getInEnum } from "gather-common/dist/src/public/ts-utils";

const INITIAL_VALUES: WearableFields = {
  type: "",
  subType: "",
  isDefault: true,
  startDate: null,
  endDate: null,
};

const NewWearablesForm: FC = () => {
  // TODO: [OA-249] Nice-to-have: convert to using Formik built-in input type that we could
  // add to WearableFields
  const [files, setFiles] = useState<FileList | null>(null);
  const [log, setLog] = useState<JSX.Element[]>([]);

  // TODO: [OA-248] Nice-to-have: add Formik form validation, specifying some schema

  function addLog(header: string, msg: string, color = "red") {
    setLog((log) => [
      ...log,
      <Typography variant="body1" key={header}>
        <b style={{ color }}>{header}</b>
        {msg ? `: ${msg}` : ""}
      </Typography>,
    ]);
  }

  function validWearable(wear: Partial<Wearable>) {
    if (!wear.color || !wear.type || !wear.name) {
      addLog(`${wear.name}/${wear.color}`, `Invalid wearable: missing required field`);
      return false;
    }
    if (!wear.parts || wear.parts.length === 0) {
      addLog(`${wear.name}/${wear.color}`, `Invalid wearable: missing parts`);
      return false;
    }
    if (!wear.previewUrl) {
      addLog(`${wear.name}/${wear.color}`, `Invalid wearable: missing preview image url`);
      return false;
    }
    if (wear.parts.some((part) => !part.spritesheetUrl || part.layerId === undefined)) {
      addLog(
        `${wear.name}/${wear.color}`,
        `Invalid wearable: one or more parts is missing a field`,
      );
      return false;
    }
    return true;
  }

  const serializeWear = (name: string, color: string) => `${name}/${color}`;

  const upload = async (src?: string) => {
    if (!src) return;

    return await WearablesAPI.uploadWearableImageFromRelativePath(src);
  };

  // TODO: [OA-245] Improve old code: https://linear.app/gather-town/issue/OA-245/[wearables]-[admin-dashboard]-improve-old-code.
  // If you're touching this, please look at the Linear ticket and address first!
  const onSubmitWearables = async (values: WearableFields) => {
    const { type, subType, isDefault, startDate, endDate, style } = values;
    setLog([]);

    if (!files || !files[0]) {
      toast.error("No files were uploaded");
      return;
    }

    if (!type) {
      toast.error("No type selected");
      return;
    }

    const wearables: Record<string, Partial<Wearable>> = {};
    for (const file of files) {
      if (file.webkitRelativePath?.split("/")?.splice(-1)?.[0]?.[0] === ".") {
        continue;
      }

      const [_, wearName, color, fileName] = file.webkitRelativePath.split("/");

      if (!wearName || !color || !fileName) {
        addLog(file.webkitRelativePath, `Incorrect file path`);
        continue;
      }

      if (
        (!subType && WearableColors[type][color] === undefined) ||
        (subType && WearableColors[subType][color] === undefined)
      ) {
        addLog(
          `${wearName}/${color}/${fileName}`,
          `Unrecognized color '${color}' for type ${type}. Valid colors: ${Object.keys(
            subType ? WearableColors[subType] : WearableColors[type],
          )}`,
        );
        continue;
      }

      const wearId = serializeWear(wearName, color);

      if (!wearables[wearId]) {
        wearables[wearId] = {
          name: wearName,
          color,
          type,
          style,
          isDefault,
          parts: [],
          ...(type === "costume"
            ? {
                startDate: startDate ? startDate.toISODate() : null,
                endDate: endDate ? endDate.toISODate() : null,
                ...(subType ? { subType } : {}),
              }
            : {}),
        };
      }

      const fileUrl = URL.createObjectURL(file);

      const wearable = wearables[wearId];
      if (fileName.split(".")[0] === "preview" && wearable) {
        wearable.previewUrl = fileUrl;
      } else {
        const templayerId = `${type} ${fileName.split(".")[0]}`;
        const layerId = getInEnum(templayerId, WearableLayerLegacy);
        if (!layerId) {
          addLog(`${wearName}/${color}/${fileName}`, `unrecognized layer id: ${layerId}`);
          continue;
        }
        if (wearable) {
          wearable.parts?.push({
            spritesheetUrl: fileUrl,
            spritesheetId: "",
            layerId,
          });
        }
      }
    }

    const dbWearables = Object.values(wearables).filter((wear) => {
      if (!validWearable(wear)) return false;

      return true;
    });

    if (dbWearables.length === 0) {
      toast.error("Upload failed, no valid wearables");
      return;
    }

    addLog("uploading images...", "", "green");

    const success = new Set();
    for (const wear of dbWearables) {
      const previewUrl = await upload(wear.previewUrl);
      if (!previewUrl) {
        addLog(`${wear.name}/${wear.color}/preview.png`, `failed to upload preview image`);
        continue;
      }
      addLog(`${wear.name}/${wear.color}/preview.png`, `uploaded image`, "green");
      wear.previewUrl = previewUrl;
      try {
        if (!wear?.parts) return;

        for (const part of wear?.parts) {
          const partUrl = await upload(part.spritesheetUrl);
          if (!partUrl) {
            addLog(
              `${wear.name}/${wear.color}/${part.layerId.split(" ")[1]}.png`,
              `failed to upload layer`,
            );
            throw new Error();
          }
          addLog(
            `${wear.name}/${wear.color}/${part.layerId.split(" ")[1]}.png`,
            `uploaded image`,
            "green",
          );
          part.spritesheetUrl = partUrl;
        }
      } catch {
        continue;
      }
      success.add(wear.id);
    }

    addLog("", "");

    dbWearables
      .filter((wear) => !success.has(wear.id))
      .forEach((wear) => {
        addLog(`${wear.name}/${wear.color}`, "failed, one or more images could not upload");
      });

    const successfulWearables = dbWearables.filter((wear) => success.has(wear.id));

    try {
      const data = await WearablesAPI.submitWearables(successfulWearables);
      const editEntries = new Set(
        data.map((wear: Wearable) => serializeWear(wear.name, wear.color)),
      );

      successfulWearables.forEach((wear) => {
        if (!wear.name || !wear.color) return;

        const isEdit = editEntries.has(serializeWear(wear.name, wear.color));
        addLog(
          `${wear.name}/${wear.color} (${wear.parts?.length} parts)`,
          isEdit ? "found existing entry, edited successfully" : "uploaded successfully",
          "green",
        );
      });
      toast.success("Upload success!");
    } catch (e) {
      toast.error("Failed to upload wearables.");
      successfulWearables.forEach((wear) => {
        addLog(
          `${wear.name}/${wear.color} (${wear.parts?.length} parts)`,
          "unknown error! upload failed",
        );
      });
    }
  };

  return (
    <Box sx={{ minWidth: 1100 }}>
      <Formik
        initialValues={INITIAL_VALUES}
        onSubmit={async (values, { setStatus, setSubmitting }) => {
          try {
            await onSubmitWearables(values);
            setStatus({ success: true });
            setSubmitting(false);
          } catch (error) {
            if (error instanceof Error) {
              const msg = error.message;
              toast.error(msg);
            }
            setStatus({ success: false });
            setSubmitting(false);
          }
        }}
      >
        {(formik) => <WearablesForm log={log} setLog={setLog} setFiles={setFiles} {...formik} />}
      </Formik>
    </Box>
  );
};

export default NewWearablesForm;
