import React, { FC, useEffect } from "react";
import { useRoutes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { QueryClientProvider, QueryClient } from "react-query";

import useSettings from "features/settings/useSettings";
import useAuth from "features/authentication/useAuth";
import { createCustomTheme } from "theme/index";
import routes from "routes/index";
import SplashScreen from "components/SplashScreen";
import { Provider } from "react-redux";
import { store } from "state/store";
import {
  AxiosClientEvent,
  axiosClientEventEmitter,
} from "gather-http-v2-client/dist/src/public/axiosClientEventEmitter";

const queryClient = new QueryClient();

const App: FC = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const auth = useAuth();

  useEffect(
    () =>
      axiosClientEventEmitter.addEventListener(
        AxiosClientEvent.SSOAuthenticationFailed,
        async () => {
          await auth.logout();
          window.location.href = "/login?expired=1";
        },
      ),
    [auth],
  );

  const theme = createCustomTheme({
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Toaster position="top-center" />
          {auth.isInitialized ? content : <SplashScreen />}
        </ThemeProvider>
      </QueryClientProvider>
    </Provider>
  );
};

export default App;
