import { axios } from "gather-common-including-video/dist/src/public/axios";
import { Buffer } from "buffer";
import { authTokenManager } from "gather-auth-client/dist/src/public/auth";
// eslint-disable-next-line @gathertown/cross-module-import-paths -- Error autoignored while enabling eslint for gather-admin. If you're already touching this code, please clean this up while you're at it.
import { NewObjectVariant } from "gather-admin-common/dist/src/objectTemplates/types";
import { UpdatedVariantFields } from "./mutations/types";
import { buildSpritesheet, buildVariantId } from "./utils";
import { Orientation } from "gather-common/dist/src/public/constants";
import {
  ObjectTemplate,
  ObjectVariant,
} from "gather-common/dist/src/public/resources/objectTemplates";
import { BulkUploadResponse } from "gather-http-common/dist/src/public/resources/admin";
import {
  NewObjectTemplateFields,
  UpdateObjectTemplateFields,
} from "gather-admin-common/dist/src/public/objectTemplates/types";

const ORIGIN = process.env.REACT_APP_API_BASE_PATH;

/**
 * API request to fetch all object templates in the database
 * @returns list of ObjectTemplates
 */
export const fetchObjectTemplates = async (): Promise<ObjectTemplate[]> => {
  const authToken = await authTokenManager.waitForToken();

  try {
    const response = await axios.get(`${ORIGIN}/api/v2/admin/object-templates`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    return response.data;
  } catch (e) {
    let message = "Failed to retrieve object templates.";

    if (e instanceof Error) {
      message = e.message;
    }

    return Promise.reject(message);
  }
};

/**
 * API request to fetch a single object templates in the database
 * @param templateId - ID of the object template to retrieve
 * @returns ObjectTemplate with variants
 */
export const fetchObjectTemplate = async (templateId: string): Promise<ObjectTemplate> => {
  const authToken = await authTokenManager.waitForToken();

  try {
    const response = await axios.get(`${ORIGIN}/api/v2/admin/object-templates/${templateId}`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    return response.data;
  } catch (e) {
    let message = "Failed to retrieve object template.";

    if (e instanceof Error) {
      message = e.message;
    }

    return Promise.reject(message);
  }
};

/**
 * API request to create a new object template
 * @param fields - form body of object template being created
 * @returns void promise
 */
export const submitNewObjectTemplate = async (fields: NewObjectTemplateFields): Promise<void> => {
  const authToken = await authTokenManager.waitForToken();

  try {
    return await axios.post(`${ORIGIN}/api/v2/admin/object-templates`, fields, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
  } catch (e) {
    let message = "Failed to create new object template.";

    if (e instanceof Error) {
      message = e.message;
    }

    return Promise.reject(message);
  }
};

/**
 * API request to create a new object variant
 * @param templateId - ID of the object template to add the variant to
 * @param fields - form body of object variant being created
 * @returns void promise
 */
export const submitVariant = async (
  templateId: string,
  fields: NewObjectVariant,
): Promise<void> => {
  const authToken = await authTokenManager.waitForToken();

  try {
    return await axios.post(
      `${ORIGIN}/api/v2/admin/object-templates/${templateId}/variants`,
      fields,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
  } catch (e) {
    let message = "Failed to create new variant.";

    if (e instanceof Error) {
      message = e.message;
    }

    return Promise.reject(message);
  }
};

/**
 * API Request to update a template's raw data fields
 * @param templateId - ID of the object template to be updated
 * @param fields - form fields that were submitted
 * @returns Promise
 */
export const updateTemplate: (
  templateId: string,
  fields: UpdateObjectTemplateFields,
) => Promise<void> = async (templateId, fields) => {
  const authToken = await authTokenManager.waitForToken();
  const origin = process.env.REACT_APP_API_BASE_PATH;

  try {
    return axios.patch(`${origin}/api/v2/admin/object-templates/${templateId}`, fields, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
  } catch (e) {
    let message = "Failed to update object template.";

    if (e instanceof Error) {
      message = e.message;
    }

    return Promise.reject(message);
  }
};

/**
 * API request to update an object variant
 * @param templateId - ID of the template that the variant belongs to
 * @param variantId - ID of the variant to be updated
 * @param fields - form body of object variant being created
 * @returns void promise
 */
export const updateVariant = async (
  templateId: string,
  variantId: string,
  fields: UpdatedVariantFields,
): Promise<void> => {
  const authToken = await authTokenManager.waitForToken();
  const spritesheet = buildSpritesheet(fields);
  const fieldsToSend = {
    spritesheet: spritesheet,
    color: fields.color,
    orientation: fields.orientation,
    default: fields.default,
    highlighted: fields.highlighted instanceof Buffer ? fields.highlighted : undefined,
    normal: fields.normal instanceof Buffer ? fields.normal : undefined,
  };

  try {
    return await axios.patch(
      `${ORIGIN}/api/v2/admin/object-templates/${templateId}/variants/${variantId}`,
      fieldsToSend,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
  } catch (e) {
    let message = "Failed to update variant.";

    if (e instanceof Error) {
      message = e.message;
    }

    return Promise.reject(message);
  }
};

/**
 * Endpoint that does a switcheroo to assign a new default variant via two API requests
 * @param templateId - ID of the template that the variants belong to
 * @param currentDefault - the current default variant
 * @param newDefault - the new default variant
 * @returns Promise
 */
export const updateDefaultVariant = async (
  templateId: string,
  currentDefault: ObjectVariant | undefined,
  newDefault: ObjectVariant,
): Promise<void> => {
  const authToken = await authTokenManager.waitForToken();
  const headers = {
    Authorization: `Bearer ${authToken}`,
  };

  try {
    // Update old default (if one exists) to be false
    if (currentDefault) {
      const currentId = buildVariantId(currentDefault);
      const currentDefaultFields = {
        ...currentDefault,
        spritesheet: undefined,
        highlighted: undefined,
        normal: undefined,
        default: false,
      };
      await axios.patch(
        `${ORIGIN}/api/v2/admin/object-templates/${templateId}/variants/${currentId}`,
        currentDefaultFields,
        { headers },
      );
    }

    // Set new default to be true
    const newId = buildVariantId(newDefault);
    const newDefaultFields = {
      ...newDefault,
      spritesheet: undefined,
      highlighted: undefined,
      normal: undefined,
      default: true,
    };
    return await axios.patch(
      `${ORIGIN}/api/v2/admin/object-templates/${templateId}/variants/${newId}`,
      newDefaultFields,
      { headers },
    );
  } catch (e) {
    let message = "Failed to update default variant.";

    if (e instanceof Error) {
      message = e.message;
    }

    return Promise.reject(message);
  }
};

/**
 * API request to delete an object template
 * @param templateId - ID of object template being deleted
 * @returns void Promise
 */
export const deleteObjectTemplate = async (templateId: string): Promise<void> => {
  const authToken = await authTokenManager.waitForToken();

  try {
    return axios.delete(`${ORIGIN}/api/v2/admin/object-templates/${templateId}`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
  } catch (e) {
    let message = "Failed to delete object template.";

    if (e instanceof Error) {
      message = e.message;
    }

    return Promise.reject(message);
  }
};

/**
 * API request to delete a variant belonging to a specific object template
 * @param templateId - ID of object template the variant belongs to
 * @param variantId - ID of object variant to be deleted
 * @returns void Promise
 */
export const deleteVariant = async (templateId: string, variantId: string): Promise<void> => {
  const authToken = await authTokenManager.waitForToken();

  try {
    return axios.delete(
      `${ORIGIN}/api/v2/admin/object-templates/${templateId}/variants/${variantId}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
  } catch (e) {
    let message = "Failed to delete variant.";

    if (e instanceof Error) {
      message = e.message;
    }

    return Promise.reject(message);
  }
};

export type BulkUploadObject = {
  name: string;
  variants: { orientation: Orientation; color: string; normal: Buffer; normalFileType: string }[];
};

/**
 * API request to delete a variant belonging to a specific object template
 * @param templateId - ID of object template the variant belongs to
 * @param variantId - ID of object variant to be deleted
 * @returns void Promise
 */
export const bulkUploadObjects = async (objects: BulkUploadObject[]) => {
  const authToken = await authTokenManager.waitForToken();

  const { data } = await axios.post<BulkUploadResponse>(
    `${ORIGIN}/api/v2/admin/object-templates/bulk`,
    objects,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    },
  );

  return data;
};
