/* External modules */
import React, { FC } from "react";
import { Formik } from "formik";
import { Buffer } from "buffer";
import { Orientation } from "gather-common/dist/src/public/constants";

/* MUI Components */
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

/* Material Icons */
import ArrowDownIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpIcon from "@mui/icons-material/ArrowUpward";
import ArrowLeftIcon from "@mui/icons-material/ArrowBack";
import ArrowRightIcon from "@mui/icons-material/ArrowForward";

/* Local modules */
import { OBJECT_VARIANT_SCHEMA } from "features/objectTemplates/constants";
import ImageUploadField from "components/inputs/FileUploadField";
import ColorPicker from "components/dashboard/ColorPicker";
import { NewObjectVariant } from "gather-admin-common/dist/src/public/objectTemplates/types";

interface Props {
  onClose: () => void;
  onSubmit: (variant: NewObjectVariant) => void;
}

const NewObjectVariantForm: FC<Props> = ({ onSubmit, onClose }) => (
  <Formik
    initialValues={{
      normal: undefined,
      normalFileType: undefined,
      highlighted: undefined,
      highlightedFileType: undefined,
      default: false,
      color: "",
      orientation: 0,
    }}
    validationSchema={OBJECT_VARIANT_SCHEMA}
    onSubmit={async (values, { setStatus, setSubmitting, resetForm }) => {
      onSubmit(values);
      resetForm();
      setStatus({ success: true });
      setSubmitting(false);
    }}
  >
    {({
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      setFieldValue,
    }) => (
      <form onSubmit={handleSubmit} autoComplete="off">
        <Box sx={{ p: 3 }}>
          <Typography align="center" color="textPrimary" gutterBottom variant="h5">
            Add Variant
          </Typography>

          <Box sx={{ mt: 2, display: "flex", gap: 2, flexWrap: "wrap", flexDirection: "row" }}>
            <Box sx={{ width: "100%", display: "flex", gap: 2, mb: 2 }}>
              <ColorPicker
                error={Boolean(touched.color && errors.color)}
                helperText={touched.color && errors.color}
                label="Color"
                name="color"
                onBlur={handleBlur}
                onChange={(color: string) => setFieldValue("color", color)}
                value={values.color}
                variant="outlined"
              />

              <Box sx={{ maxWidth: 300 }}>
                <TextField
                  fullWidth
                  required
                  select
                  label="Orientation"
                  name="orientation"
                  value={values.orientation}
                  onChange={handleChange}
                  error={Boolean(touched.orientation && errors.orientation)}
                  helperText={touched.orientation && errors.orientation}
                >
                  <MenuItem value={Orientation.Up}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <ArrowUpIcon fontSize="small" />
                      <Typography>Up</Typography>
                    </Box>
                  </MenuItem>

                  <MenuItem value={Orientation.Down}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <ArrowDownIcon fontSize="small" />
                      <Typography>Down</Typography>
                    </Box>
                  </MenuItem>

                  <MenuItem value={Orientation.Left}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <ArrowLeftIcon fontSize="small" />
                      <Typography>Left</Typography>
                    </Box>
                  </MenuItem>

                  <MenuItem value={Orientation.Right}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <ArrowRightIcon fontSize="small" />
                      <Typography>Right</Typography>
                    </Box>
                  </MenuItem>
                </TextField>
              </Box>
            </Box>

            <ImageUploadField
              label="Normal"
              name="normal"
              value={values.normal}
              error={Boolean(touched.normal && (errors.normal || errors.normalFileType))}
              helperText={touched.normal && (errors.normal || errors.normalFileType)}
              onBlur={handleBlur}
              onChange={(value?: Buffer, fileType?: string) => {
                setFieldValue("normal", value);
                setFieldValue("normalFileType", fileType);
              }}
            />

            <ImageUploadField
              label="Highlighted"
              name="highlighted"
              value={values.highlighted}
              error={Boolean(touched.highlighted && (errors.highlighted || errors.highlighted))}
              helperText={touched.highlighted && (errors.highlighted || errors.highlighted)}
              onBlur={handleBlur}
              onChange={(value?: Buffer, fileType?: string) => {
                setFieldValue("highlighted", value);
                setFieldValue("highlightedFileType", fileType);
              }}
            />
          </Box>
        </Box>

        <Divider />

        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            p: 2,
          }}
        >
          <Box sx={{ flexGrow: 1 }} />
          <Button color="primary" onClick={onClose} variant="text">
            Cancel
          </Button>

          <Button
            color="primary"
            disabled={isSubmitting}
            sx={{ ml: 1 }}
            type="submit"
            variant="contained"
          >
            Create
          </Button>
        </Box>
      </form>
    )}
  </Formik>
);

export default NewObjectVariantForm;
