import { useQueryClient, useMutation } from "react-query";

// eslint-disable-next-line @gathertown/cross-module-import-paths -- Error autoignored while enabling eslint for gather-admin. If you're already touching this code, please clean this up while you're at it.
import { AdminSpace } from "gather-admin-common/dist/src/spaces/types";
import * as SpacesAPI from "features/spaces/api";
import QueryKeys from "features/queryKeys";
import { SpaceMutationCallbacks, SpaceMutationContext, SpaceUpdateVariables } from "./types";

/**
 * Mutation hook that updates a space
 * TODO @alecia - actually make this accept fields to update entire space
 * @returns ReactQuery useMutation hook
 */
const useToggleStaffAccess = (callbacks?: SpaceMutationCallbacks) => {
  const queryClient = useQueryClient();

  return useMutation(
    (variables: SpaceUpdateVariables) => SpacesAPI.toggleStaffAccess(variables.spaceId),
    {
      onMutate: async (variables) => {
        const { spaceId } = variables;
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        queryClient.cancelQueries([QueryKeys.Space, spaceId]);

        // Snapshot the previous values
        const previousSpace = queryClient.getQueryData<AdminSpace>([QueryKeys.Space, spaceId]);

        if (previousSpace) {
          const updatedSpace: AdminSpace = {
            ...previousSpace,
            settings: {
              ...previousSpace.settings,
              allowStaffAccess: !previousSpace.settings.allowStaffAccess,
            },
          };
          queryClient.setQueryData<AdminSpace>([QueryKeys.Space, spaceId], updatedSpace);
        }

        return { previousSpace };
      },

      onError: (_err, variables, context?: SpaceMutationContext) => {
        const { previousSpace } = context ?? {};
        const { spaceId } = variables;

        if (previousSpace) {
          queryClient.setQueryData<AdminSpace>([QueryKeys.Space, spaceId], previousSpace);
        }

        if (callbacks?.onError) {
          callbacks?.onError();
        }
      },

      onSuccess: () => {
        if (callbacks?.onSuccess) {
          callbacks?.onSuccess();
        }
      },

      onSettled: (_data, _err, variables) => {
        const { spaceId } = variables;
        queryClient.invalidateQueries([QueryKeys.Space, spaceId]);
      },
    },
  );
};

export default useToggleStaffAccess;
