import React, { FC, useState, useCallback, SyntheticEvent } from "react";
import { useParams } from "react-router-dom";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Fab from "@mui/material/Fab";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import CodeIcon from "@mui/icons-material/Code";

import { Tab as TabType } from "components/types";
import { BreadcrumbTrail } from "components/layout/dashboard/Breadcrumbs";
import TabWrapper from "components/dashboard/TabWrapper";
import PageContainer from "components/layout/dashboard/PageContainer";
import GeneralTab from "./partials/tabs/GeneralTab";
import ObjectsTab from "./partials/tabs/ObjectsTab";
import MapJsonModal from "./partials/MapJsonModal";
import { useSpaceMap } from "features/spaces/hooks";
import TutorialAreasTab from "./partials/tabs/TutorialAreasTab";

const PAGE_TITLE = "Map Details";

const DetailsPage: FC = () => {
  const { spaceId, mapId } = useParams();
  const { data, isLoading } = useSpaceMap(
    decodeURIComponent(mapId ?? ""),
    decodeURIComponent(spaceId ?? ""),
  );
  const [currentTab, setCurrentTab] = useState("general");
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const tabs: TabType[] = [
    {
      label: "General",
      value: "general",
    },
    {
      label: `Objects (${Object.keys(data?.objects ?? {}).length})`,
      value: "objects",
    },
    {
      label: "Tutorial Areas",
      value: "tutorialAreas",
    },
  ];

  const breadcrumbs: BreadcrumbTrail[] = [
    {
      to: `/dashboard/mapmaker/inspector?space=${encodeURIComponent(spaceId || "")}`,
      title: "Map Inspector",
    },
  ];

  const getCurrentTabContents = () => {
    switch (currentTab) {
      case "general":
        return <GeneralTab map={data} />;
      case "objects":
        return <ObjectsTab map={data} />;
      case "tutorialAreas":
        return <TutorialAreasTab map={data} spaceId={spaceId} mapId={mapId} />;
      default:
        return (
          <TabWrapper>Please let the Platform tools team know how you ended up here...</TabWrapper>
        );
    }
  };

  const handleModalOpen = useCallback(() => {
    setModalOpen(true);
  }, [setModalOpen]);

  const handleModalClose = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  const handleTabsChange = useCallback(
    (_event: SyntheticEvent<Element>, value: string) => {
      setCurrentTab(value);
    },
    [setCurrentTab],
  );

  return (
    <PageContainer pageTitle={PAGE_TITLE} breadcrumbs={breadcrumbs}>
      {isLoading && <Box sx={{ p: 2 }}>Loading...</Box>}

      {!isLoading && data && (
        <Box sx={{ width: "100%", pb: 8 }}>
          <Tabs
            indicatorColor="primary"
            onChange={handleTabsChange}
            scrollButtons="auto"
            textColor="primary"
            value={currentTab}
            variant="scrollable"
          >
            {tabs.map((tab) => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>

          <Divider />

          {getCurrentTabContents()}

          <Fab
            variant="extended"
            color="primary"
            aria-label="open-json"
            sx={{
              position: "fixed",
              margin: 0,
              top: "auto",
              right: "20px",
              bottom: "20px",
              left: "auto",
            }}
            onClick={handleModalOpen}
          >
            <CodeIcon sx={{ mr: 1 }} />
            View Map JSON
          </Fab>

          <MapJsonModal open={modalOpen} map={data} onClose={handleModalClose} />
        </Box>
      )}
    </PageContainer>
  );
};

export default DetailsPage;
