import React, { FC, memo } from "react";
import { formatTimestamp, isSFUMessage } from "./utils";
import { RenderRowProps, WithRestProps } from "./types";
import { Box } from "@mui/material";

const withoutPrefix = (p: string) => p.split("/").slice(1).join("");

export const RenderSFURow: FC<WithRestProps<RenderRowProps>> = memo(
  ({ message, className = "", ...props }) => {
    if (!isSFUMessage(message)) return null;
    const { serverURL, direction, type, payload, timestamp } = message;

    return (
      <div className={`row-message sfu-message sfu-message-${direction} ${className}`} {...props}>
        <Box flexShrink={0}>{formatTimestamp(timestamp)}</Box>
        <div>
          [{serverURL}:{withoutPrefix(type)}] {JSON.stringify(payload)}
        </div>
      </div>
    );
  },
);
