import React, { FC, useState, useCallback } from "react";
import { Outlet } from "react-router-dom";
import { experimentalStyled } from "@mui/material";

import DashboardNavbar from "./dashboard/NavBar";
import DashboardSidebar from "./dashboard/Sidebar";
import { RoleProvider } from "features/roles/RoleContext";

const DashboardLayoutRoot = experimentalStyled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%",
}));

const DashboardLayoutWrapper = experimentalStyled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  paddingTop: "64px",
  [theme.breakpoints.up("lg")]: {
    paddingLeft: "280px",
  },
}));

const DashboardLayoutContainer = experimentalStyled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

const DashboardLayoutContent = experimentalStyled("div")({
  flex: "1 1 auto",
  height: "100%",
  overflow: "auto",
  position: "relative",
  WebkitOverflowScrolling: "touch",
});

const DashboardLayout: FC = () => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState(false);

  const toggleMobileNav = useCallback(() => {
    setIsSidebarMobileOpen((prev) => !prev);
  }, [setIsSidebarMobileOpen]);

  const handleMobileNavClosure = useCallback(() => {
    setIsSidebarMobileOpen(false);
  }, [setIsSidebarMobileOpen]);

  return (
    <RoleProvider>
      <DashboardLayoutRoot>
        <DashboardNavbar onToggleMobileNav={toggleMobileNav} />
        <DashboardSidebar onMobileClose={handleMobileNavClosure} openMobile={isSidebarMobileOpen} />
        <DashboardLayoutWrapper>
          <DashboardLayoutContainer>
            <DashboardLayoutContent>
              <Outlet />
            </DashboardLayoutContent>
          </DashboardLayoutContainer>
        </DashboardLayoutWrapper>
      </DashboardLayoutRoot>
    </RoleProvider>
  );
};

export default DashboardLayout;
