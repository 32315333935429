import React, { FC, useRef, useState } from "react";
import { AdminRoleTypePrisma as AdminRole } from "gather-prisma-types/dist/src/public/client";

import { SelectChangeEvent } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DotsIcon from "@mui/icons-material/MoreHoriz";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import ChipList from "components/dashboard/ChipList";
import MultipleSelectChip from "components/inputs/MultipleSelectChip";
import { AdminUser } from "gather-admin-common/dist/src/public/auth/types";

interface Props {
  user: AdminUser;
  index: number;
  onDelete?: (userId: string) => void;
  onUpdate?: (userId: string, roles: AdminRole[]) => void;
}

const UserRow: FC<Props> = ({ user, onDelete, onUpdate }) => {
  const moreRef = useRef(null);
  const initialRoles: AdminRole[] = user.roles || [];
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [selectedRoles, setSelectedRoles] = useState<AdminRole[]>(initialRoles);

  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  const handleDelete = () => {
    setOpenMenu(false);

    if (onDelete) {
      onDelete(user.id);
    }
  };

  const handleEdit = () => {
    setOpenMenu(false);
    setIsEditing(true);
  };

  const handleSave = () => {
    setIsEditing(false);

    if (onUpdate) {
      onUpdate(user.id, selectedRoles);
    }
  };

  const handleSelectedRolesChange = (event: SelectChangeEvent<AdminRole[]>) => {
    const {
      target: { value },
    } = event;
    const result =
      typeof value === "string"
        ? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          (value.split(",") as AdminRole[]) // autofill returns a string but we know the string array only has AdminRole or CoreRole list in it
        : value;
    setSelectedRoles(result);
  };

  return (
    <TableRow hover key={user.id}>
      <TableCell>
        <Typography color="textSecondary" variant="body2">
          {user.id}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography color="textPrimary" variant="body2">
          {user.email}
        </Typography>
      </TableCell>

      <TableCell>
        {isEditing ? (
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              gap: 2,
            }}
          >
            <MultipleSelectChip
              options={Object.keys(AdminRole).sort()}
              value={selectedRoles}
              onChange={handleSelectedRolesChange}
              label="Filter by Roles"
            />

            <Button variant="contained" color="primary" onClick={handleSave}>
              Save
            </Button>
          </Box>
        ) : (
          <ChipList list={user.roles || []} />
        )}
      </TableCell>

      <TableCell>
        <IconButton color="inherit" edge="end" onClick={handleMenuOpen} ref={moreRef}>
          <DotsIcon fontSize="small" />
        </IconButton>

        {(onUpdate || onDelete) && (
          <Menu
            anchorEl={moreRef.current}
            anchorOrigin={{
              horizontal: "center",
              vertical: "bottom",
            }}
            keepMounted
            onClose={handleMenuClose}
            open={openMenu}
          >
            {onUpdate && <MenuItem onClick={handleEdit}>Edit Roles</MenuItem>}
            {onDelete && <MenuItem onClick={handleDelete}>Delete</MenuItem>}
          </Menu>
        )}
      </TableCell>
    </TableRow>
  );
};

export default UserRow;
