import * as Comlink from "comlink";
import { Logger } from "../Logger";
import { isUnitTest } from "gather-env-config/dist/src/public/envShared";

// Webpack natively supports loading webworkers through the following syntax:
// new Worker(
//   /* webpackChunkName: "foo-worker" */ new URL('./worker.js', import.meta.url)
// );
//
// Using a variable in the Worker constructor is not supported by webpack.
// For example, the following code will not work:
// const url = new URL('./path/to/worker.ts', import.meta.url);
// const worker = new Worker(url);.
// This is because webpack cannot analyse the syntax statically.
//
// As a result, we expect the callers of createWorker to explicitly pass the worker instance.
//
// We have to pass in the comlink library, which is weird.
// If we use the Comlink library from this module, rather than passing it in, then we'll get an
// error when creating workers outside the current module (e.g. in gather-browser).
//
//   comlink.mjs:290 Uncaught (in promise) DOMException: Failed to execute 'postMessage' on 'Worker': (data) => {
//         var _a, _b;
//         if ("error" in data) {
//           (_a = callback[comlink__WEBPACK_IMPORTED_MOD...<omitted>... } could not be cloned.
//       at http://localhost:8080/bundle.561b7be7a26ae1b24ee6.js:192343:12
//
export function createWorker<T>(comlink: typeof Comlink, getRawWorker: () => Worker) {
  let worker: Comlink.Remote<T> | Record<string, never>;
  if (process.env.BROWSER_EXTENSION) {
    worker = {};

    // In unit tests, workers should always be mocked out. Because integ and E2E use a test env.
    // It's not my fave to write test-specific code in implementation files, but this was simple
    // enough and would be easy to miss in unit tests.
  } else if (isUnitTest) {
    Logger.error(`
Do not import real web workers in unit tests. Instead, mock out the worker client file that wraps
the web worker in a usable interface, e.g.

    jest.mock('path/to/myWorker.client')

`);
    throw new Error("Unmocked web worker detected in unit tests");
  } else {
    worker = comlink.wrap<T>(getRawWorker());
  }

  return worker;
}
