import { QueryClient } from "react-query";

import QueryKeys from "features/queryKeys";
import { ObjectTemplateCategoryGroup } from "gather-common/dist/src/public/resources/objectTemplates";

export const objectTemplateCategoryGroupMutation = (queryClient: QueryClient) => ({
  // When mutate is called:
  onMutate: async (categoryGroup: ObjectTemplateCategoryGroup) => {
    // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
    queryClient.cancelQueries([QueryKeys.ObjectTemplateCategories]);

    // Snapshot the previous value
    const previousCategoryGroups = queryClient.getQueryData<ObjectTemplateCategoryGroup[]>([
      QueryKeys.ObjectTemplateCategories,
    ]);

    // Optimistically update to the new value
    if (previousCategoryGroups) {
      const updatedArray = previousCategoryGroups.map((prevGroup) =>
        prevGroup.id === categoryGroup.id ? categoryGroup : prevGroup,
      );

      queryClient.setQueryData<ObjectTemplateCategoryGroup[]>(
        [QueryKeys.ObjectTemplateCategories],
        updatedArray,
      );

      return updatedArray;
    }

    return { previousCategoryGroups };
  },
  // Lint warning auto-ignored when enabling the no-explicit-any rule. Fix this the next time this code is edited! TODO: @ENG-4294 Clean these up! See the linear task for guidance on how to do so.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onError: (_err: any, _variables: any, context: any) => {
    if (context?.previousCategoryGroups) {
      queryClient.setQueryData<ObjectTemplateCategoryGroup>(
        QueryKeys.ObjectTemplateCategories,
        context.previousCategoryGroups,
      );
    }
  },
  // Always refetch after error or success:
  onSettled: () => {
    queryClient.invalidateQueries([QueryKeys.ObjectTemplateCategories]);
  },
});
