import { all, is, isEmpty, isNil } from "ramda";
import isPlainObject from "lodash.isplainobject";
import { NonEmptyArray } from "gather-common-including-video/dist/src/public/tsUtils";

/**
 * A collection of TS utilities.
 *
 * We disable no-explicit-any for this entire file because it's often perfectly
 * valid or even necessary to use the `any` type in these utils.
 */

/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/consistent-type-assertions */

/**
 * Array.prototype.map(), but for NonEmptyArrays. Use this to ensure that your output array is
 * also correctly typed as a NonEmptyArray.
 */
export function mapNonEmptyArray<T, R>(a: NonEmptyArray<T>, f: (x: T) => R): NonEmptyArray<R> {
  return a.map(f) as NonEmptyArray<R>;
}

/**
 * Casts the given array to a NonEmptyArray. Only use when you're sure the array cannot be empty!
 */
export function castArrayToNonEmpty<T>(a: T[]): NonEmptyArray<T> {
  if (isEmpty(a)) throw new Error("Casting an empty array to NonEmptyArray!");

  return a as NonEmptyArray<T>;
}

/**
 * Simple null check and assertion. Example usage vs `just` (in fpHelpers):
 *
 *    // just
 *    const definitelyUser: User = just(maybeGetUser())
 *
 *    // vs assertNotNil
 *    function doSomethingWithUser(maybeUser: User | undefined) {
 *      assertNotNil(maybeUser)
 *      // maybeUser now is definitely User, and TS knows it
 *      ...
 *    }
 *
 */
export function assertNotNil<T>(
  x?: T | null,
  message = "value is nullish",
): asserts x is NonNullable<T> {
  if (isNil(x)) throw new Error(message);
}

export function assertNonEmpty<T>(xs: T[]): asserts xs is NonEmptyArray<T> {
  if (isEmpty(xs)) throw new Error("Expected array to be non-empty");
}

// There's no generic enum type (https://github.com/microsoft/TypeScript/issues/30611) so this
// is the best we can do. Current inspiration:
// https://github.com/microsoft/TypeScript/issues/30611#issuecomment-1295497089
type EnumValueType = string | number | symbol;
type EnumLikeType = { [key in EnumValueType]: EnumValueType };

// Adapted from https://stackoverflow.com/a/62812933/2672869
export const enumFromValue = <T extends EnumLikeType>(val: string | number, enumVar: T) => {
  // We should only ever use this util with an enum passed for enumVar, in which case Object.keys
  // will always be the exact set of keys for that enum (impossible to be a superset).
  // Basically as long as enumVar is actually an enum we will never run into problems here.
  const enumName = enumKeys(enumVar).find((k) => enumVar[k] === val);
  if (!enumName)
    throw new Error(`Value "${val}" not found on string enum values ${JSON.stringify(enumVar)}`);

  return enumVar[enumName];
};

export const enumFromNullableValue = <T extends EnumLikeType>(
  val: string | number | null,
  enumVar: T,
) => {
  if (val === null) return null;
  return enumFromValue(val, enumVar);
};

export const enumFromValueWithDefault = <T extends EnumLikeType>(
  val: string | number,
  enumVar: T,
  defaultVal: T[Extract<keyof T, string>],
): T[Extract<keyof T, string>] => {
  try {
    return enumFromValue(val, enumVar);
  } catch {
    return defaultVal;
  }
};

/**
 * Used for migrating a TS string enum to a "plain enum". A string enum is something like:
 *
 *     enum Foo { somekey = "some string with spaces and arbitrary characters" }
 *
 * A "plain enum" is like:
 *
 *     enum Bar { somekey = "somekey" }
 *
 * To translate an enum from a string enum to a plain enum, we need to create a "migration enum".
 *   - The keys of the migration enum are the **values** of the old enum.
 *   - The values of the migration enum are the **keys** of the new enum.
 *
 * Here's a little visualization: https://i.imgur.com/AFrqiBd.png
 * See tests for an example.
 */
// The multiple type signatures allow for passing in undefined values.
export function migrateEnum<T extends EnumLikeType, N extends EnumLikeType>(
  val: undefined,
  migrationEnum: T,
  newEnum: N,
): undefined;
export function migrateEnum<T extends EnumLikeType, N extends EnumLikeType>(
  val: string,
  migrationEnum: T,
  newEnum: N,
): N[keyof N];
export function migrateEnum<T extends EnumLikeType, N extends EnumLikeType>(
  val: string | undefined,
  migrationEnum: T,
  newEnum: N,
): N[keyof N] | undefined;
export function migrateEnum<T extends EnumLikeType, N extends EnumLikeType>(
  val: string | undefined,
  migrationEnum: T,
  newEnum: N,
): N[keyof N] | undefined {
  if (isNil(val)) return undefined;
  const newEnumKeys = enumKeys(newEnum) as Array<keyof N>;
  if (Object.values(migrationEnum).some((x) => !newEnumKeys.includes(x)))
    throw new Error("Not all values of migration enum mapped to keys of new enum");

  // Check the new enum first; if we find the value, we're set.
  const enumKey = newEnumKeys.find((k) => newEnum[k] === val);
  if (enumKey) return newEnum[enumKey];

  // Otherwise, plug this value into the migration enum. Its keys are the old enum values, and its
  // values correspond to keys on the new enum.
  const newEnumKey = migrationEnum[val] as keyof N;
  if (!newEnumKey) {
    throw new Error(
      `Value "${val}" not found on string enum values ${JSON.stringify(migrationEnum)}`,
    );
  }
  return newEnum[newEnumKey];
}

export const enumToHuman =
  <T extends EnumLikeType>(
    // we don't actually need this enum val, but it's the only way to _force_ the user of this
    // function to provide the enum type. Otherwise, you could do this, and wind up with unsafe
    // types:
    //
    //     enumToHuman({ foo: 'this is foo' })  // <-- arbitrary record type
    //
    _enumVal: T,
    translations: Record<keyof T, string>,
  ) =>
  (key: keyof T) =>
    translations[key];

// This chunk of code is lifted from parts https://github.com/UselessPickles/ts-enum-util, just
// pulling out the bit we need for now, we could fully adopt the library later.
export type StringKeyOf<T> = Extract<keyof T, string>;
const { getOwnEnumerableNonNumericKeysES6 } = (() => {
  function isNonNumericKey(key: string): boolean {
    return key !== String(parseFloat(key));
  }

  function getOwnEnumerableNonNumericKeysES6<T extends Record<string, any>>(
    obj: T,
  ): StringKeyOf<T>[] {
    return Object.getOwnPropertyNames(obj).filter(
      (key) => obj.propertyIsEnumerable(key) && isNonNumericKey(key),
    ) as StringKeyOf<T>[];
  }

  return { getOwnEnumerableNonNumericKeysES6 };
})();

// back to code we own...
export const enumKeys = <T extends EnumLikeType>(enumVal: T): StringKeyOf<T>[] =>
  getOwnEnumerableNonNumericKeysES6(enumVal);

export const getInEnum = <T extends EnumLikeType>(val: string | number, enumVar: T) => {
  try {
    return enumFromValue(val, enumVar);
  } catch (e) {
    return null;
  }
};

/**
 * Used to migrate data from Prisma to be transferred by Protobuf and its converters
 *
 * Optional (NULL) cells are converted to undefined as Protobuf uses undefined for optional props
 *
 * { type: 1, description: null } is converted to { type: 1, description: undefined }
 */
type RecursivelyReplaceNullWithUndefined<T> = T extends null
  ? undefined
  : T extends (infer U)[]
  ? RecursivelyReplaceNullWithUndefined<U>[]
  : T extends Record<string, unknown>
  ? { [K in keyof T]: RecursivelyReplaceNullWithUndefined<T[K]> }
  : T;

export const nullsToUndefined = <T>(obj: T): RecursivelyReplaceNullWithUndefined<T> => {
  // disabling type-assertions check to allow recursive call of this fn
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  if (isNil(obj)) return undefined as any;

  if (isPlainObject(obj) || Array.isArray(obj)) {
    for (const key in obj) {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      obj[key] = nullsToUndefined(obj[key]) as any;
    }
  }
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return obj as any;
};

/**
 * A strongly typed BUT NOT TYPESAFE replacement for Object.keys.
 *
 * If you want to be typesafe, you might be able to use this approach using generics:
 * https://i.imgur.com/WeiU5Fi.png (excerpt from
 * https://fettblog.eu/typescript-iterating-over-objects/).
 *
 * But, that approach doesn't meet all use cases, and sometimes we want strong typing.
 *
 * How is it not type-safe? There's technically no way for `Object.keys` to be typesafe at runtime.
 * Runtime objects can have more keys than their type would suggest. More discussion here:
 * - https://effectivetypescript.com/2020/05/26/iterate-objects/
 * - https://github.com/microsoft/TypeScript/pull/12253#issuecomment-263132208
 *
 * Use this helper if you're confident that:
 *   a) You're not passing in a subtype
 *   b) Your object doesn't have extra keys added at runtime
 *
 * If you're not confident in these two cases, you probably shouldn't be using this helper
 * or `Object.keys` at all.
 *
 * Lifted from ts-extras, we can install the whole package later if we find others useful:
 * https://github.com/sindresorhus/ts-extras/blob/main/source/object-keys.ts
 */
export type ObjectKeys<T extends object> = `${Exclude<keyof T, symbol>}`;
export const objectKeys = Object.keys as <Type extends object>(
  value: Type,
) => Array<ObjectKeys<Type>>;

// Similar notes as above, pulled from:
// https://github.com/sindresorhus/ts-extras/blob/main/source/object-entries.ts
export const objectEntries = Object.entries as <Type extends Record<PropertyKey, unknown>>(
  value: Type,
) => Array<[ObjectKeys<Type>, Type[ObjectKeys<Type>]]>;

/**
 * Checks if the value passed in is an object (and not null). Uses Lodash's `isPlainObject`.
 */
export const isObject = (val: unknown): val is Record<PropertyKey, unknown> =>
  val !== null && isPlainObject(val);

export const isArrayOfStrings = (arr: unknown[]): arr is string[] =>
  all((item: unknown): item is string => is(String, item), arr);
