/* External modules */
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { head, prop, toPairs, values } from "ramda";
import { Formik } from "formik";

/* MUI Components */
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";

/* Local modules */
import useUpdateWearables from "../../../../features/wearables/mutations/useUpdateWearables";
import { EditableWearableFields } from "../../../../features/wearables/api";
import DetailsCard from "../../../../components/dashboard/DetailsCard";
import TableCellTitle from "../../../../components/dashboard/TableCellTitle";
import Label from "../../../../components/dashboard/Label";
import {
  Wearable,
  WearableSubType,
  WearableType,
} from "gather-common/dist/src/public/resources/users";

export const WearableEditForm = ({ wearables }: { wearables: Wearable[] }) => {
  const navigate = useNavigate();

  const firstWearable = wearables[0];

  const onSuccessfulUpdate = (updatedWearables: Record<string, Wearable>) => {
    toast.success("Variant was successfully updated.");
    const firstWearable = head(wearables);
    const firstUpdatedWearable = head(values(updatedWearables));

    if (firstWearable && firstUpdatedWearable && firstWearable.name !== firstUpdatedWearable.name) {
      navigate(`/dashboard/wearables/${firstUpdatedWearable.name}`);
    }
  };

  const onUpdateError = () => {
    toast.error("Error: Variant could not be updated.");
  };

  const { mutate: updateWearables } = useUpdateWearables({
    onSuccess: onSuccessfulUpdate,
    onError: onUpdateError,
  });

  const handleUpdate = useCallback((values: EditableWearableFields) => {
    const idsToUpdate = wearables.map(prop("id"));

    const wearablesToUpdateRecord = idsToUpdate.reduce<Record<string, EditableWearableFields>>(
      (acc, id) => ({ ...acc, [id]: values }),
      {},
    );
    updateWearables(wearablesToUpdateRecord);
  }, []);

  if (!firstWearable) return null;

  const { id, name, type, subType, startDate, endDate, parts, style, isDefault } = firstWearable;

  return (
    <Formik
      initialValues={{
        name,
        type,
        subType,
        style,
        isDefault,
      }}
      onSubmit={(values) => handleUpdate(values)}
    >
      {({ touched, errors, handleChange, values, handleBlur, setFieldValue, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Box>
            <DetailsCard header="General Details" sx={{ maxWidth: 500, mb: 4 }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCellTitle>ID</TableCellTitle>
                    <TableCell>
                      <Typography color="textPrimary" variant="body2">
                        {id}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCellTitle>Name</TableCellTitle>
                    <TableCell>
                      <TextField
                        fullWidth
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                        name="name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name}
                        variant="outlined"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCellTitle>Layer ID(s)</TableCellTitle>
                    <TableCell>
                      {parts.map(({ layerId }, index: number) => (
                        <Box component="span" sx={{ mr: 1, mt: 2 }} key={`${id}-${layerId}`}>
                          <Label key={`part-${index}`} color="primary">
                            {layerId}
                          </Label>
                        </Box>
                      ))}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCellTitle>Type</TableCellTitle>
                    <TableCell sx={{ maxWidth: 250 }}>
                      <Box component="span" sx={{ mr: 1, mt: 2 }} key={`${name}-${type}`}>
                        <TextField
                          select
                          fullWidth
                          name="type"
                          value={values.type}
                          onChange={handleChange}
                          error={Boolean(touched.type && errors.type)}
                          helperText={touched.type && errors.type}
                        >
                          {toPairs(WearableType).map(([label, value]) => (
                            <MenuItem key={value} value={value}>
                              {label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCellTitle>Sub Type</TableCellTitle>
                    <TableCell sx={{ maxWidth: 250 }}>
                      <Box component="span" sx={{ mr: 1, mt: 2 }} key={`${name}-${type}`}>
                        <TextField
                          select
                          fullWidth
                          name="subType"
                          value={values.subType ?? "None"}
                          onChange={(e) =>
                            setFieldValue(
                              "subType",
                              e.target.value === "None" ? null : e.target.value,
                            )
                          }
                          error={Boolean(touched.subType && errors.subType)}
                          helperText={touched.subType && errors.subType}
                        >
                          {toPairs(WearableSubType).map(([label, value]) => (
                            <MenuItem key={value} value={value}>
                              {label}
                            </MenuItem>
                          ))}
                          <MenuItem value="None">None</MenuItem>
                        </TextField>
                      </Box>
                    </TableCell>
                  </TableRow>
                  {startDate && (
                    <TableRow>
                      <TableCellTitle>Start Date</TableCellTitle>
                      <TableCell>
                        <Typography color="textPrimary" variant="body2">
                          {new Date(startDate).toLocaleString()}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  {endDate && (
                    <TableRow>
                      <TableCellTitle>End Date</TableCellTitle>
                      <TableCell>
                        <Typography color="textPrimary" variant="body2">
                          {new Date(endDate).toLocaleString()}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCellTitle>Style</TableCellTitle>
                    <TableCell>
                      <TextField
                        fullWidth
                        error={Boolean(touched.style && errors.style)}
                        helperText={touched.style && errors.style}
                        name="style"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.style}
                        variant="outlined"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCellTitle>Visible (default)</TableCellTitle>
                    <TableCell>
                      <Switch
                        name="isDefault"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        checked={values.isDefault}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </DetailsCard>
            <Box textAlign="right">
              <Button color="primary" type="submit" variant="contained" size="large">
                Save
              </Button>
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
};
