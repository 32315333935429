/* External modules */
import React, { FC } from "react";

/* MUI Components */
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

/* Local modules */
import ObjectVariantRow from "./ObjectVariantRow";
import { groupBy } from "utils/objFromArray";
import { ObjectVariant } from "gather-common/dist/src/public/resources/objectTemplates";
import { NewObjectVariant } from "gather-admin-common/dist/src/public/objectTemplates/types";

interface Props {
  variants: ObjectVariant[] | NewObjectVariant[];
  onEdit?: (variant: ObjectVariant | NewObjectVariant) => void;
  onRemove?: (variant: ObjectVariant | NewObjectVariant) => void;
  onUpdateDefault?: (variant: ObjectVariant | NewObjectVariant) => void;
}

const ObjectVariantsTable: FC<Props> = ({ variants, onEdit, onRemove, onUpdateDefault }) => {
  const groups = groupBy<ObjectVariant | NewObjectVariant, string>(variants, (i) => i.color);

  return (
    <Box>
      <Card>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Color</TableCell>
              <TableCell>Orientation</TableCell>
              <TableCell>Default</TableCell>
              {(onEdit || onRemove) && <TableCell>Actions</TableCell>}
            </TableRow>
          </TableHead>

          <TableBody>
            {Object.keys(groups)
              .sort()
              .map((group: string) =>
                groups[group]
                  ?.sort((a, b) => a.orientation - b.orientation)
                  .map((variant, index) => (
                    <ObjectVariantRow
                      variant={variant}
                      index={index}
                      key={`var-${group}-${index}`}
                      onEdit={onEdit}
                      onRemove={onRemove}
                      onUpdateDefault={onUpdateDefault}
                    />
                  )),
              )}
          </TableBody>
        </Table>
      </Card>
    </Box>
  );
};

export default ObjectVariantsTable;
