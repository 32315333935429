// I cannot seem to export this from gather-game-common within gather-admin-common
// without causing a compilation error that says "you may need an additional loader"
// so, for now, I've copied over the exact code for InteractionEnum_ENUM and
// everything works.
export enum InteractionTypes {
  NONE = 0,
  EMBEDDED_WEBSITE = 1,
  POSTER = 2,
  VIDEO = 3,
  EXTERNAL_CALL = 4,
  EXTENSION = 5,
  NOTE = 6,
  MODAL_EXTENSION = 7,
  COMPONENT_MODAL = 8,
}
