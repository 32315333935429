import { Buffer } from "buffer";
import { just } from "gather-common/dist/src/public/fpHelpers";
import { HttpV2Client } from "./public/http-client/httpV2Client";
import { HttpV2Paths } from "gather-http-common/dist/src/public/httpAPI";
import { FILE_SIZE_LIMIT } from "gather-http-common/dist/src/public/imageUpload";

const HIGHLIGHT_COLORS = [
  [255, 255, 0, 255],
  [255, 255, 0, 230],
  [255, 255, 0, 200],
  [255, 255, 0, 150],
  [255, 255, 0, 100],
  [255, 255, 0, 50],
];

export type Directory = "assets" | "maps" | "drawnBGs" | "player-detailed-view";

export const getSrc = (src?: string | Buffer) => {
  if (src instanceof Buffer) return URL.createObjectURL(new Blob([src]));

  return src;
};

const VALID_TYPES = new Set(["image/jpeg", "image/jpg", "image/png", "image/gif"]);

export const isValidImage = (file: File) => VALID_TYPES.has(file.type);

export const isImageUnderSizeLimit = (file: File) => file.size <= FILE_SIZE_LIMIT;

export const isValidImageUnderSizeLimit = (file: File) =>
  isValidImage(file) && isImageUnderSizeLimit(file);

export const uploadToStorage = async (
  src: string,
  _dir: Directory,
  spaceOrEvent: string,
  isEvent?: boolean,
): Promise<string> => {
  const response = await fetch(src);
  const fileData = await response.arrayBuffer();

  const body: { bytes: Buffer; spaceId?: string; eventId?: string; isEvent?: boolean } = {
    bytes: Buffer.from(fileData),
  };
  if (!isEvent) {
    body.spaceId = spaceOrEvent;
  } else {
    body.eventId = spaceOrEvent;
    body.isEvent = true;
  }
  const imageUrl = await HttpV2Client.post<string>(HttpV2Paths.UploadImages, {
    auth: true,
    params: {
      body,
    },
  });
  return imageUrl ?? "";
};

const getPixel = (width: number, data: Uint8ClampedArray, x: number, y: number) => {
  const startIndex = (y + width * x) * 4;
  return [data[startIndex], data[startIndex + 1], data[startIndex + 2], data[startIndex + 3]];
};

const setPixel = (width: number, data: Uint8ClampedArray, x: number, y: number, rgba: number[]) => {
  const newData = Object.assign(data);
  const startIndex = (y + width * x) * 4;
  newData[startIndex] = just(rgba[0]);
  newData[startIndex + 1] = just(rgba[1]);
  newData[startIndex + 2] = just(rgba[2]);
  newData[startIndex + 3] = just(rgba[3]);

  return newData;
};

const dist = (x1: number, y1: number, x2: number, y2: number) =>
  Math.ceil(Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2)));

// Returns 0 - maxDist or -1
const calculateDistToObject = (
  width: number,
  height: number,
  data: Uint8ClampedArray,
  x: number,
  y: number,
  maxDist: number,
) => {
  let minDistance = HIGHLIGHT_COLORS.length + 1;
  for (let xoff = -1 * maxDist; xoff <= maxDist; xoff++) {
    for (let yoff = -1 * maxDist; yoff <= maxDist; yoff++) {
      if (
        x + xoff >= 0 &&
        x + xoff < height &&
        y + yoff >= 0 &&
        y + yoff < width &&
        getPixel(width, data, x + xoff, y + yoff)[3] !== 0
      ) {
        minDistance = Math.min(minDistance, dist(0, 0, xoff, yoff));
      }
    }
  }
  if (minDistance > maxDist) return -1;

  return minDistance;
};

export const getHighlightURL = (image: string): Promise<string> =>
  new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!ctx) return reject();

    const img = new Image();
    img.crossOrigin = "Anonymous";

    img.onload = function () {
      try {
        const height = img.height;
        const width = img.width;
        ctx.canvas.height = height;
        ctx.canvas.width = width;
        ctx.drawImage(img, 0, 0);

        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        let data = imageData.data;
        const maxDist = HIGHLIGHT_COLORS.length;

        const dists: Array<Array<[number, number]>> = [];
        for (let i = 0; i < maxDist; i++) {
          dists.push([]);
        }

        for (let i = 0; i < height; i++) {
          for (let j = 0; j < width; j++) {
            const curDist = calculateDistToObject(width, height, data, i, j, maxDist);
            if (curDist > 0) {
              dists[curDist - 1]?.push([i, j]);
            }
          }
        }

        for (let i = 0; i < maxDist; i++) {
          const distRow: [number, number][] = just(dists[i]);
          for (let idx = 0; idx < distRow.length; idx++) {
            const pixelCoords = just(distRow[idx]);
            data = setPixel(width, data, pixelCoords[0], pixelCoords[1], just(HIGHLIGHT_COLORS[i]));
          }
        }

        ctx.putImageData(imageData, 0, 0);
        resolve(canvas.toDataURL());
      } catch {
        reject();
      }
    };

    img.onerror = reject;
    img.src = image;
  });
