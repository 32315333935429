import { useMutation, useQuery } from "react-query";

import * as UsersAPI from "./api";
import { deleteUsersByEmails } from "./api";
import QueryKeys from "features/queryKeys";

export const useUser = (userId: string) =>
  useQuery([QueryKeys.User, userId], () => UsersAPI.fetchUserDetails(userId), {
    initialData: undefined,
    enabled: userId.length > 0,
    retry: false,
    refetchOnWindowFocus: false,
  });

/**
 * Usage
 * const user = useUser(inputtedId);
 * const reservations = useUserReservations(user?.id);
 *
 * Refer to https://react-query.tanstack.com/guides/dependent-queries
 */

export const useUserReservations = (userId?: string) =>
  useQuery(
    [QueryKeys.UserReservations, userId],
    () => UsersAPI.fetchReservationsForUser(userId || ""),
    {
      initialData: undefined,
      enabled: !!userId,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

export const useUserSpaces = (userId?: string) =>
  useQuery([QueryKeys.UserSpaces, userId], () => UsersAPI.fetchUserSpaces(userId || ""), {
    initialData: undefined,
    enabled: !!userId,
    retry: false,
    refetchOnWindowFocus: false,
  });

export const useDeleteUsersByEmails = () =>
  useMutation({ mutationFn: ({ emails }: { emails: string[] }) => deleteUsersByEmails(emails) });
