import React, { FC, ChangeEvent, KeyboardEvent } from "react";

/* Material UI Components */
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";

interface Props {
  onKeyPress: (e: KeyboardEvent<HTMLInputElement>) => void;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  id: string;
  placeholder: string;
  label: string;
  sx?: SxProps<Theme>;
}

const SearchIdInput: FC<Props> = ({ onKeyPress, onChange, id, placeholder, label, sx = {} }) => (
  <Box
    sx={{
      m: 1,
      ml: 0,
      maxWidth: "100%",
      width: 350,
      ...sx,
    }}
  >
    <TextField
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon fontSize="small" />
          </InputAdornment>
        ),
      }}
      label={label}
      onChange={onChange}
      onKeyPress={onKeyPress}
      placeholder={placeholder}
      value={id}
      variant="outlined"
    />
  </Box>
);

export default SearchIdInput;
