import { DynamicGates } from "@gathertown/gather-game-common/dist/src/public/generated_DO_NOT_TOUCH/events";
import { determineGateValueFromSeedOrExposure } from "gather-gates/dist/src/public/gate";

export const DEFAULT_GATE_VALUE = { exposure: 0, spaces: [] };
export const VIDEO_SYSTEM_AUTOMATED_REPORT = "Video system automated debug reports";
export enum AvStrategy {
  Base = "base",
  Gather = "gather",
  Livekit = "livekit",
  LivekitSelfhost = "livekitselfhost",
}

export interface LiveKitToken {
  serverURL: string;
  token: string;
}

export const TWILIO_TOKEN_TTL_S = 2 * 60 * 60;

export const AVCLIENTSTATE_DEFAULT_PAGE_SIZE = 200;

export const getFullDynamicGates = (dynamicGates: Partial<DynamicGates>): DynamicGates => ({
  livekitEnabled: dynamicGates["livekitEnabled"] ?? { exposure: 0, spaces: [] },
  livekitSelfhostEnabled: dynamicGates["livekitSelfhostEnabled"],
  gatherEnabled: dynamicGates["gatherEnabled"],
});

export const calculateDynamicAvStrategy = (
  spaceId: string | undefined,
  gates: DynamicGates,
): {
  avStrategy: AvStrategy;
  minimumBuildTimestamp?: number;
} => {
  const baseResult = {
    avStrategy: AvStrategy.Base,
  };
  const gatherResult = {
    avStrategy: AvStrategy.Gather,
    minimumBuildTimestamp: gates.gatherEnabled?.minimumBuildTimestamp,
  };
  const livekitResult = {
    avStrategy: AvStrategy.Livekit,
    minimumBuildTimestamp: gates.livekitEnabled?.minimumBuildTimestamp,
  };
  const livekitSelfhostResult = {
    avStrategy: AvStrategy.LivekitSelfhost,
    minimumBuildTimestamp: gates.livekitSelfhostEnabled?.minimumBuildTimestamp,
  };

  // if we don't have a spaceId, just return the default, which is Base AV
  if (!spaceId) return baseResult;

  if (gates.livekitEnabled?.spaces.includes(spaceId)) return livekitResult;
  if (gates.livekitSelfhostEnabled?.spaces.includes(spaceId)) return livekitSelfhostResult;
  if (gates.gatherEnabled?.spaces.includes(spaceId)) return gatherResult;

  // Use seed for Livekit so distribution doesn't change
  const livekitRunningExposure = Math.min(gates.livekitEnabled?.exposure || 0, 1.0);
  if (
    determineGateValueFromSeedOrExposure(
      "livekitEnabled",
      { exposure: livekitRunningExposure, space: true, seed: 11 },
      { spaceId, userId: null },
    )
  ) {
    return livekitResult;
  }

  // The absolute exposures are affected by this decision tree, so keep track.
  // Note Gather backend exposure is ignored! It always gets whatever is left
  // Note: whitelists always throw everything off, but hopefully the effect isn't too great
  let runningExposure = 1.0 - livekitRunningExposure;

  const selfHostRunningExposure = Math.min(
    (gates.livekitSelfhostEnabled?.exposure || 0) / runningExposure,
    1.0,
  );
  if (
    determineGateValueFromSeedOrExposure(
      "livekitSelfhostEnabled",
      { exposure: selfHostRunningExposure, space: true },
      { spaceId, userId: null },
    )
  ) {
    return livekitSelfhostResult;
  }
  runningExposure *= 1.0 - selfHostRunningExposure;

  // No hits, fallback to Gather
  return gatherResult;
};
