import React, { FC, SyntheticEvent, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import PlusIcon from "@mui/icons-material/Add";

import TabWrapper from "components/dashboard/TabWrapper";
import PageContainer from "components/layout/dashboard/PageContainer";
import WearablesTable from "./partials/WearablesTable";
import { useWearables } from "features/wearables/hooks";
import { organizeWearablesData } from "./utils";
import AlertDialog from "components/dashboard/AlertDialog";
import useDeleteWearable from "features/wearables/mutations/useDeleteWearable";
import { Wearable, WearableType } from "gather-common/dist/src/public/resources/users";

const PAGE_TITLE = "Manage Wearables";
const WEARABLE_TYPES = Object.values(WearableType);
const DELETE_CONFIRMATION =
  "Are you sure you want to delete this wearable? This action is not reversible.";

const WearablesIndexPage: FC = () => {
  const { data: wearables, isLoading } = useWearables();
  const [currentTab, setCurrentTab] = useState<string>(WearableType.Skin);
  const [wearablesData, setWearablesData] = useState<{
    [wearableType: string]: { [wearableName: string]: Wearable[] };
  }>();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
  const [wearablesToDelete, setWearablesToDelete] = useState<Wearable[]>();
  const navigate = useNavigate();

  const handleTabsChange = useCallback(
    (_event: SyntheticEvent<Element>, value: string) => {
      setCurrentTab(value);
    },
    [setCurrentTab],
  );

  const handleNewWearables = useCallback(() => {
    navigate("/dashboard/wearables/new");
  }, [navigate]);

  const confirmDeletion = useCallback(
    (wearable: Wearable[]) => {
      setShowDeleteConfirmation(true);
      setWearablesToDelete(wearable);
    },
    [setShowDeleteConfirmation],
  );

  const onSuccessfulDeletion = () => {
    toast.success("Wearable was successfully deleted.");
  };

  const onDeletionError = () => {
    toast.error(`Error: Wearable "${wearablesToDelete}" could not be deleted.`);
  };

  const { mutate: deleteWearable } = useDeleteWearable({
    onSuccess: onSuccessfulDeletion,
    onError: onDeletionError,
  });

  const handleDelete = useCallback(
    (confirm: boolean) => {
      setShowDeleteConfirmation(false);

      if (confirm && wearablesToDelete) {
        wearablesToDelete?.map((wearable) => deleteWearable(wearable.id));
      }
    },
    [setShowDeleteConfirmation, deleteWearable, wearablesToDelete],
  );

  useEffect(() => {
    if (!wearables) return;

    const wearablesData = organizeWearablesData(wearables);
    setWearablesData(wearablesData);
  }, [wearables]);

  return (
    <PageContainer
      pageTitle={PAGE_TITLE}
      buttons={
        <Button
          color="primary"
          startIcon={<PlusIcon fontSize="small" />}
          variant="contained"
          onClick={handleNewWearables}
        >
          Add Wearables
        </Button>
      }
    >
      <Box sx={{ minWidth: 1100 }}>
        <Box>
          <Tabs
            indicatorColor="primary"
            onChange={handleTabsChange}
            scrollButtons="auto"
            textColor="primary"
            value={currentTab}
            variant="scrollable"
          >
            {WEARABLE_TYPES.map((tab) => (
              <Tab key={tab} label={tab} value={tab} />
            ))}
          </Tabs>

          <Divider />

          <TabWrapper>
            {isLoading && <Box>Loading...</Box>}

            {!isLoading && wearablesData && wearablesData[currentTab] && (
              <Card>
                <WearablesTable wearables={wearablesData[currentTab]} onDelete={confirmDeletion} />
              </Card>
            )}

            {!isLoading && !wearables && <Box>There's nothing here</Box>}
          </TabWrapper>
        </Box>
      </Box>

      <AlertDialog
        isOpen={showDeleteConfirmation}
        onClose={handleDelete}
        title={`Confirm Deletion of ${wearablesToDelete?.[0]?.name}`}
      >
        {DELETE_CONFIRMATION}
      </AlertDialog>
    </PageContainer>
  );
};

export default WearablesIndexPage;
