import { useQueryClient, useMutation } from "react-query";

import * as WearablesAPI from "features/wearables/api";
import QueryKeys from "features/queryKeys";

interface WearableMutationCallbacks {
  /** onError callback function for the mutation */
  onError?: () => void;
  /** onSuccess callback function for the mutation */
  onSuccess?: () => void;
}

interface WearablePartUpdateVariables {
  wearablePartId: string;
  data: WearablesAPI.EditableWearablePartFields;
}

/**
 * Mutation that deletes a wearable document
 * @param callbacks Object containing onError and onSuccess callback methods
 * @returns ReactQuery useMutation hook
 */
const useUpdateWearablePart = (callbacks?: WearableMutationCallbacks) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ wearablePartId, data }: WearablePartUpdateVariables) =>
      WearablesAPI.patchWearablePart(wearablePartId, data),
    {
      onError: (_err) => callbacks?.onError?.(),
      onSuccess: () => callbacks?.onSuccess?.(),
      onSettled: () => {
        queryClient.invalidateQueries([QueryKeys.Wearables]);
      },
    },
  );
};

export default useUpdateWearablePart;
