import React, { useEffect, FC } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Box, Drawer, Theme, useMediaQuery } from "@mui/material";

import Logo from "components/Logo";
import Scrollbar from "components/layout/Scrollbar";
import NavSection from "components/layout/dashboard/NavSection";
import { NAV_LINKS } from "./NavLinks";
import PermittedComponent from "components/authentication/PermittedComponent";

interface Props {
  onMobileClose: () => void;
  openMobile: boolean;
}

const DashboardSidebar: FC<Props> = ({ onMobileClose, openMobile }) => {
  const location = useLocation();
  const lgUp = useMediaQuery((theme: Theme) => theme?.breakpoints?.up("lg"));

  useEffect(() => {
    if (onMobileClose) {
      onMobileClose();
    }
  }, [onMobileClose, location.pathname]);

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: "none",
              xs: "flex",
            },
            justifyContent: "center",
            p: 2,
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40,
              }}
            />
          </RouterLink>
        </Box>
        <Box sx={{ p: 2 }}>
          {NAV_LINKS.map((section, index) => (
            <PermittedComponent
              permissions={section.permissions}
              requiresAll={section.requiresAll}
              key={`navlink-section-${index}`}
            >
              {/*
// @ts-expect-error Error auto-ignored when migrating to @types/react v18. PLEASE HELP FIX THESE! YES, YOU. It may be as simple as using React.PropsWithChildren<> or adding a missing typing. */}
              <NavSection
                pathname={location.pathname}
                sx={{
                  "& + &": {
                    mt: 3,
                  },
                }}
                {...section}
              />
            </PermittedComponent>
          ))}
        </Box>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "background.paper",
            height: "calc(100% - 64px)",
            top: "64px",
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: "background.paper",
          width: 280,
        },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

export default DashboardSidebar;
