import { ReservationPlan, ReservationType } from "gather-prisma-types/dist/src/public/client";
import { enumToHuman } from "gather-common/dist/src/public/ts-utils";

export const reservationTypeToHuman = enumToHuman(ReservationType, {
  Classroom: "Classroom",
  Conference: "Conference",
  Other: "Other",
  Party: "Party",
  RemoteWork: "Remote Work",
});

export const reservationPlanToHuman = enumToHuman(ReservationPlan, {
  Daily: "Daily",
  Monthly: "Monthly",
  TwoHour: "Two hours",
  Annual: "Annual",
});
