export interface ChartConfig {
  columns?: string[];
  title: string;
  query?: string;
}

const pliChart: ChartConfig = {
  query: "select payload->`recv.pliCountSum` as 'recv.pliCountSum' from ?",
  columns: ["recv.pliCountSum"],
  title: "PLI",
};
const nackChart: ChartConfig = {
  query: "select payload->`recv.nackCountSum` as 'recv.nackCountSum' from ?",
  columns: ["recv.nackCountSum"],
  title: "NACK",
};

const packetsLostPercentageChart: ChartConfig = {
  query:
    "select payload->`recv.packetsLostPercentage` * 100 as 'recv.packetsLostPercentage %',  1 as '1pct Threshold' from ?",
  columns: ["recv.packetsLostPercentage"],
  title: "Packets Lost Percentage",
};

const jitterChart: ChartConfig = {
  query:
    "SELECT payload->`recv.jitterMax` * 1000 AS 'recv.jitterMax', payload->`recv.jitterMean` * 1000 AS 'recv.jitterMean',  30 AS '30ms Threshold' FROM ?",
  columns: ["recv.jitterMean", "recv.jitterMax"],
  title: "Jitter",
};

const availableBitrateChart: ChartConfig = {
  query:
    "select payload->`recv.availableIncomingBitrate` / 1000 / 1000 as 'recv.availableIncomingBitrate KB', payload->`send.availableOutgoingBitrate` / 1000 / 1000 as 'send.availableOutgoingBitrate KB' from ?",
  columns: ["recv.availableIncomingBitrate", "send.availableOutgoingBitrate"],
  title: "Available Bitrate",
};

const outgoingBitrateChart: ChartConfig = {
  query: "select payload->`send.bitrateOut` / 1000 as 'send.bitrateOut KB' from ?",
  columns: ["send.bitrateOut"],
  title: "Outgoing Bitrate",
};
const incomingBitrateChart: ChartConfig = {
  query: "select payload->`recv.bitrateIn` / 1000 as 'recv.bitrateIn KB' from ?",
  columns: ["recv.bitrateIn"],
  title: "Incoming Bitrate",
};
const outgoingAudioBitrateChart: ChartConfig = {
  query: "select payload->`send.audio.bitrateOut` / 1000 as 'send.audio.bitrateOut KB' from ?",
  columns: ["send.audio.bitrateOut"],
  title: "Outgoing Audio Bitrate",
};
const incomingAudioBitrateChart: ChartConfig = {
  query: "select payload->`recv.audio.bitrateIn` / 1000 as 'recv.audio.bitrateIn KB' from ?",
  columns: ["recv.audio.bitrateIn"],
  title: "Incoming Audio Bitrate",
};
const outgoingVideoBitrateChart: ChartConfig = {
  query: "select payload->`send.video.bitrateOut` / 1000 as 'send.video.bitrateOut KB' from ?",
  columns: ["send.video.bitrateOut"],
  title: "Outgoing Video Bitrate",
};
const incomingVideoBitrateChart: ChartConfig = {
  query: "select payload->`recv.video.bitrateIn` / 1000 as 'recv.video.bitrateIn KB' from ?",
  columns: ["recv.video.bitrateIn"],
  title: "Incoming Video Bitrate",
};
const outgoingScreenBitrateChart: ChartConfig = {
  query: "select payload->`send.screen.bitrateOut` / 1000 as 'send.screen.bitrateOut KB' from ?",
  columns: ["send.screen.bitrateOut"],
  title: "Outgoing Screen Bitrate",
};
const sendRoundTripTimeChart: ChartConfig = {
  query:
    "select payload->`send.currentRoundTripTime` * 1000 as 'send.currentRoundTripTime ms', 150 as '150ms Threshold' from ?",
  columns: ["send.currentRoundTripTime"],
  title: "Send Round Trip Time",
};
const recvRoundTripTimeChart: ChartConfig = {
  query:
    "select payload->`recv.currentRoundTripTime` * 1000 as 'recv.currentRoundTripTime ms', 150 as '150ms Threshold' from ?",
  columns: ["recv.currentRoundTripTime"],
  title: "Recv Round Trip Time",
};

const totalRoundTripTimeChart: ChartConfig = {
  query:
    "select (payload->`recv.currentRoundTripTime` + payload->`send.currentRoundTripTime`) * 1000 as 'totalRoundTripTime ms', 300 as '300ms Threshold' from ?",
  columns: [],
  title: "Total Round Trip Time",
};

const audioConcealedSamplesDurationChart: ChartConfig = {
  query:
    "select payload->`recv.audio.concealedSamplesDuration` /  payload->`recv.audio.totalSamplesDurationSum` as 'recv.audio.concealedSamplesDuration' , 0.2 as '2% Threshold' from ?",
  title: "Audio Concealed Samples Duration",
};

export const charts: ChartConfig[] = [
  pliChart,
  nackChart,
  packetsLostPercentageChart,
  jitterChart,
  audioConcealedSamplesDurationChart,
  availableBitrateChart,
  outgoingBitrateChart,
  outgoingAudioBitrateChart,
  outgoingVideoBitrateChart,
  outgoingScreenBitrateChart,
  incomingBitrateChart,
  incomingAudioBitrateChart,
  incomingVideoBitrateChart,
  sendRoundTripTimeChart,
  recvRoundTripTimeChart,
  totalRoundTripTimeChart,
];
