/* External modules */
import React, { FC, useCallback, useRef, useState } from "react";

/* MUI Components */
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

/* Material Icons */
import StarEmpty from "@mui/icons-material/StarBorderPurple500Sharp";
import StarFull from "@mui/icons-material/StarPurple500Sharp";
import DotsIcon from "@mui/icons-material/MoreHoriz";

/* Local modules */
import WearableVariantSpritesheetsTable from "./WearableVariantSpritesheetsTable";

import {
  DEFAULT_WEARABLE_COLORS,
  Wearable,
  WearableColors,
} from "gather-common/dist/src/public/resources/users";

interface Props {
  variant: Wearable;
  index: number;
  onDelete?: (wearable: Wearable) => void;
}

interface StarProps {
  show: boolean;
}

const NoStar: FC<StarProps> = ({ show }) => (
  <Box
    sx={{
      opacity: show ? 1 : 0.2,
      transition: "opacity 0.2s ease-in-out",
    }}
  >
    <StarEmpty />
  </Box>
);

const WearableVariantRow: FC<Props> = ({ variant, index, onDelete }) => {
  const moreRef = useRef(null);
  const [showEmptyStar, setShowEmptyStar] = useState<boolean>(false);
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  const enableStar = useCallback(() => setShowEmptyStar(true), [setShowEmptyStar]);
  const disableStar = useCallback(() => setShowEmptyStar(false), [setShowEmptyStar]);

  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  const handleDelete = () => {
    setOpenMenu(false);
    onDelete?.(variant);
  };

  return (
    <TableRow
      hover
      key={`variantRow-${variant.color}-${index}`}
      onMouseEnter={enableStar}
      onMouseLeave={disableStar}
    >
      <TableCell>
        <Avatar variant="square" src={variant.previewUrl} />
      </TableCell>

      <TableCell>
        <Box sx={{ display: "flex", alignItems: "baseline", gap: 1 }}>
          <Box
            sx={{
              bgcolor:
                WearableColors[variant.type][variant.color] ??
                // @ts-expect-error Error auto-ignored when enabling noImplicitAny
                DEFAULT_WEARABLE_COLORS[variant.color],
              width: 14,
              height: 14,
              border: "solid 1px white",
              borderRadius: "3px",
            }}
          />
          <Typography color="textPrimary" variant="body2">
            {variant.color}
          </Typography>
        </Box>
      </TableCell>

      <TableCell>{variant.isDefault ? <StarFull /> : <NoStar show={showEmptyStar} />}</TableCell>

      <WearableVariantSpritesheetsTable variant={variant} />

      {onDelete && (
        <TableCell>
          <IconButton color="inherit" edge="end" onClick={handleMenuOpen} ref={moreRef}>
            <DotsIcon fontSize="small" />
          </IconButton>

          <Menu
            anchorEl={moreRef.current}
            anchorOrigin={{
              horizontal: "center",
              vertical: "bottom",
            }}
            keepMounted
            onClose={handleMenuClose}
            open={openMenu}
          >
            <MenuItem onClick={handleDelete}>Delete</MenuItem>
          </Menu>
        </TableCell>
      )}
    </TableRow>
  );
};

export default WearableVariantRow;
