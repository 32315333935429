/* External modules */
import React, { FC, useCallback, useState } from "react";
import { toast } from "react-hot-toast";

/* MUI Components */
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

/* Local modules */
import WearableVariantsTable from "./WearableVariantsTable";
import AlertDialog from "components/dashboard/AlertDialog";
import useDeleteWearable from "features/wearables/mutations/useDeleteWearable";
import { WearableEditForm } from "./WearableEditForm";
import { Wearable } from "gather-common/dist/src/public/resources/users";

interface Props {
  wearableVariants: Wearable[];
}

const DELETE_CONFIRMATION =
  "Are you sure you want to delete this variant? You cannot undo this action.";

const WearableDetails: FC<Props> = ({ wearableVariants }) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
  const [wearableToDelete, setWearableToDelete] = useState<Wearable>();

  const onSuccessfulDelete = () => {
    toast.success("Variant was successfully deleted.");
  };

  const onDeleteError = () => {
    toast.error("Error: Variant could not be deleted.");
  };

  const { mutate: deleteWearable } = useDeleteWearable({
    onSuccess: onSuccessfulDelete,
    onError: onDeleteError,
  });

  const handleDelete = useCallback(
    (confirm: boolean) => {
      setShowDeleteConfirmation(false);

      if (confirm && wearableToDelete) {
        deleteWearable(wearableToDelete.id);
      }
    },
    [deleteWearable, wearableToDelete],
  );

  const confirmDeletion = useCallback(
    (wearable: Wearable) => {
      setWearableToDelete(wearable);
      setShowDeleteConfirmation(true);
    },
    [setShowDeleteConfirmation, setWearableToDelete],
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: 4,
        alignItems: "flex-start",
        alignContent: "flex-start",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 4,
          alignItems: "flex-start",
          alignContent: "flex-start",
        }}
      >
        <WearableEditForm wearables={wearableVariants} />
      </Box>

      <Box>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", mb: 2 }}>
          <Typography variant="subtitle1" sx={{ width: "100%" }}>
            Variants
          </Typography>
        </Box>

        <WearableVariantsTable variants={wearableVariants} onDelete={confirmDeletion} />
      </Box>

      <AlertDialog
        isOpen={showDeleteConfirmation}
        onClose={handleDelete}
        title={`Confirm Deletion of ${wearableToDelete?.name} (${wearableToDelete?.color})`}
      >
        {DELETE_CONFIRMATION}
      </AlertDialog>
    </Box>
  );
};

export default WearableDetails;
