import { useAvClientStateUserFeedbackBySessionId } from "features/avClientState/hooks";
import { evolve, is, mapObjIndexed } from "ramda";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { Typography } from "@mui/material";
import { useTimestamps, useSessionMessages } from "state/avClientStateViewerSlice";
import { executeQuery } from "./Dashboard";
import { get } from "lodash";
import { attempJSONParse } from "./utils";

const AVERAGE_PACKETS_LOST_THRESHOLD = 1.0;
const TIMESTAMP_RANGE = 10 * 60 * 1000;

const PreScan = () => {
  const { sessionId } = useParams();
  const { data: feedback, isLoading } = useAvClientStateUserFeedbackBySessionId(sessionId);

  const parsedFeedback = useMemo(() => {
    type ParsedSFUClients = {
      [id: string]: {
        recvTransport?: {
          relay?: boolean;
          "local-candidate": string;
        };
      };
    };

    let protocol: string | undefined = undefined;
    let relay = false;
    const parsed = evolve(
      {
        feedback: (value: string) => {
          const parsedValue = attempJSONParse(value);

          return is(String, parsedValue)
            ? parsedValue
            : mapObjIndexed(
                (value) => (is(String, value) ? attempJSONParse(value) : value),
                parsedValue,
              );
        },
      },
      feedback ?? {},
    );
    const sfuClients: ParsedSFUClients = get(parsed, [
      "feedback",
      "peerManagerDump",
      "SFUs",
      "sfuClients",
    ]);
    if (sfuClients) {
      // Check if there are any relays
      const relays = Object.values(sfuClients).filter((v) => v?.recvTransport?.relay);
      relay = relays.length > 0;

      // Check if there are any protocols other than UDP
      const withProtocols = Object.values(sfuClients).filter(
        (v) => v?.recvTransport?.["local-candidate"],
      );
      if (withProtocols.length > 0) {
        protocol = "udp";
        const otherProtocols = withProtocols
          .map((v) => {
            const candidate = v?.recvTransport?.["local-candidate"];
            if (candidate) {
              const spaceIndex = candidate.indexOf(" ");
              if (spaceIndex > 0) return candidate.slice(spaceIndex + 1);
            }
            return protocol;
          })
          .filter((p) => p !== protocol);
        if (otherProtocols.length > 0) {
          // arbitrarily pick first protocol that isn't IDP
          protocol = otherProtocols[0];
        }
      }
    }

    return protocol ? { relay, msg: `Protocol: ${protocol}, Relay: ${relay}` } : null;
  }, [feedback]);

  const timestamps = useTimestamps();
  const lastTimestamp = Math.max(...timestamps);
  const sessionMessages = useSessionMessages();
  const bandwidthHealthData = useMemo(
    () => sessionMessages.filter(({ type }) => type.includes("av-bandwidth-health")),
    [sessionMessages],
  );

  const processedData = useMemo(() => {
    let query =
      "select avg(payload->`recv.packetsLostPercentage` * 100) as packetsLostPercentageAvg from ? where timestamp > ";
    query += lastTimestamp - TIMESTAMP_RANGE;
    const result = executeQuery(query, bandwidthHealthData);
    if (result) {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const data = result as [{ packetsLostPercentageAvg: number }];
      if (data.length < 1) return null;
      const average = data[0].packetsLostPercentageAvg ?? 0;

      return {
        high: average > AVERAGE_PACKETS_LOST_THRESHOLD,
        msg: `Average packets lost percentage: ${average.toFixed(2)}%`,
      };
    }

    return null;
  }, [bandwidthHealthData]);

  return (
    <>
      {!isLoading && (
        <div>
          <Typography
            variant="body2"
            color={parsedFeedback?.relay ? "red" : "white"}
            sx={{ display: "inline-block" }}
          >
            {parsedFeedback?.msg}
          </Typography>
          <Typography
            variant="body2"
            color={processedData?.high ? "red" : "white"}
            sx={{ display: "inline-block" }}
          >
            {processedData ? `${(parsedFeedback ? ", " : "") + processedData?.msg}` : null}
          </Typography>
        </div>
      )}
    </>
  );
};

export default PreScan;
