import React, { FC, memo } from "react";
import { RenderRowProps, WithRestProps } from "./types";
import { formatTimestamp, isNetworkTestResultMessage } from "./utils";
import { Box } from "@mui/material";
import { JSONTree } from "react-json-tree";

const expand = (_: (string | number)[], __: unknown, level: number) => level < 2;

export const RenderNetworkTestResultsRow: FC<WithRestProps<RenderRowProps>> = memo(
  ({ message, className = "", ...props }) => {
    if (!isNetworkTestResultMessage(message)) return null;
    const { timestamp, payload } = message;

    return (
      <div className={`row-message networkTestResults-message ${className}`} {...props}>
        <Box flexShrink={0}>{formatTimestamp(timestamp)}</Box>
        <div className="networkTestResults-message-container message-verbose">
          <JSONTree hideRoot shouldExpandNode={expand} data={payload} />
        </div>
      </div>
    );
  },
);
