/* External modules */
import React, { FC, useCallback, useState } from "react";
import ReactAudioPlayer from "react-audio-player";
import { toast } from "react-hot-toast";
// eslint-disable-next-line @gathertown/cross-module-import-paths -- Error autoignored while enabling eslint for gather-admin. If you're already touching this code, please clean this up while you're at it.
import { Interaction as InteractionTypes } from "gather-admin-common/dist/src/spaces/types";
import { isTemplateType, isInteractableTemplate } from "gather-common/dist/src/public/utils";

/* MUI Components */
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

/* MUI Icons */
import AddIcon from "@mui/icons-material/Add";

/* Local modules */
import DetailsCard from "components/dashboard/DetailsCard";
import BooleanLabel from "components/dashboard/BooleanLabel";
import Label from "components/dashboard/Label";
import TableCellTitle from "components/dashboard/TableCellTitle";
import ObjectVariantsTable from "./ObjectVariantsTable";
import EditObjectVariantForm from "./EditObjectVariantForm";
import NewObjectVariantForm from "./NewObjectVariantForm";
import useCreateVariant from "features/objectTemplates/mutations/useCreateVariant";
import useUpdateVariant from "features/objectTemplates/mutations/useUpdateVariant";
import useDeleteVariant from "features/objectTemplates/mutations/useDeleteVariant";
import { UpdatedVariantFields } from "features/objectTemplates/mutations/types";
import { isSavedVariant, buildVariantId } from "features/objectTemplates/utils";
import useUpdateDefaultVariant from "../../../../features/objectTemplates/mutations/useUpdateDefaultVariant";
import AlertDialog from "../../../../components/dashboard/AlertDialog";
import {
  ObjectTemplate,
  ObjectVariant,
} from "gather-common/dist/src/public/resources/objectTemplates";
import { NewObjectVariant } from "gather-admin-common/dist/src/public/objectTemplates/types";

const DELETE_CONFIRMATION =
  "Are you sure you want to delete this variant? You cannot undo this action.";

interface Props {
  objectTemplate: ObjectTemplate;
  id: string;
}

const ObjectTemplateDetails: FC<Props> = ({ objectTemplate, id }) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [variantToEdit, setVariantToEdit] = useState<
    NewObjectVariant | ObjectVariant | undefined
  >();
  const [variantToDelete, setVariantToDelete] = useState<
    NewObjectVariant | ObjectVariant | undefined
  >();

  const onSuccessfulCreation = () => {
    toast.success("Variant was successfully created.");
  };

  const onCreationError = () => {
    toast.error("Error: Variant could not be created.");
  };

  const { mutate: createVariant } = useCreateVariant({
    onSuccess: onSuccessfulCreation,
    onError: onCreationError,
  });

  const onSuccessfulUpdate = () => {
    toast.success("Variant was successfully updated.");
  };

  const onUpdateError = () => {
    toast.error("Error: Variant could not be updated.");
  };

  const { mutate: updateVariant } = useUpdateVariant({
    onSuccess: onSuccessfulUpdate,
    onError: onUpdateError,
  });

  const onSuccessfulDefaultUpdate = () => {
    toast.success("New default variant saved successfully.");
  };

  const onUpdateDefaultError = () => {
    toast.error("Error: Could not update default variant.");
  };

  const { mutate: updateDefaultVariant } = useUpdateDefaultVariant({
    onSuccess: onSuccessfulDefaultUpdate,
    onError: onUpdateDefaultError,
  });

  const onSuccessfulDelete = () => {
    toast.success("Variant was successfully deleted.");
  };

  const onDeleteError = () => {
    toast.error("Error: Variant could not be deleted.");
  };

  const { mutate: deleteVariant } = useDeleteVariant({
    onSuccess: onSuccessfulDelete,
    onError: onDeleteError,
  });

  const handleEdit = useCallback(
    (variant: NewObjectVariant | ObjectVariant) => {
      setVariantToEdit(variant);
      setIsEditing(true);
      setModalOpen(true);
    },
    [setVariantToEdit, setIsEditing, setModalOpen],
  );

  const handleModalClose = useCallback(() => {
    setIsEditing(false);
    setModalOpen(false);
  }, [setModalOpen, setIsEditing]);

  const handleModalOpen = useCallback(() => {
    setModalOpen(true);
  }, [setModalOpen]);

  const handleNewVariant = useCallback(
    (newVariant: NewObjectVariant) => {
      createVariant({ templateId: id, fields: newVariant });
      handleModalClose();
    },
    [createVariant, handleModalClose, id],
  );

  const handleEditVariant = useCallback(
    (updated: UpdatedVariantFields) => {
      const variantId = buildVariantId(variantToEdit);
      updateVariant({ templateId: id, variantId, fields: updated });
      handleModalClose();
    },
    [handleModalClose, variantToEdit, id, updateVariant],
  );

  const handleUpdateDefaultVariant = useCallback(
    (variant: ObjectVariant | NewObjectVariant) => {
      if (isSavedVariant(variant)) {
        // Find the current default variant and update it
        const currentDefault = objectTemplate.variants.find((item) => item.default);
        return updateDefaultVariant({
          templateId: id,
          currentDefault,
          newDefault: variant,
        });
      }

      toast.error(
        "Could not update default variant: it is still being saved. Please wait and try again.",
      );
    },
    [id, objectTemplate, updateDefaultVariant],
  );

  const handleDelete = useCallback(
    (confirm: boolean) => {
      setShowDeleteConfirmation(false);

      if (confirm && variantToDelete) {
        if (isSavedVariant(variantToDelete)) {
          const variantId = buildVariantId(variantToDelete);
          return deleteVariant({ templateId: id, variantId });
        }

        toast.error(
          "Could not delete default variant: it is still being saved. Please wait and try again.",
        );
      }
    },
    [id, deleteVariant, variantToDelete],
  );

  const confirmDeletion = useCallback(
    (variant: ObjectVariant | NewObjectVariant) => {
      setVariantToDelete(variant);
      setShowDeleteConfirmation(true);
    },
    [setShowDeleteConfirmation, setVariantToDelete],
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: 4,
        alignItems: "flex-start",
        alignContent: "flex-start",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 4,
          alignItems: "flex-start",
          alignContent: "flex-start",
        }}
      >
        <Box>
          <DetailsCard header="General Details" sx={{ maxWidth: 500, mb: 4 }}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCellTitle>ID</TableCellTitle>
                  <TableCell>
                    <Typography color="textPrimary" variant="body2">
                      {id}
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCellTitle>Name</TableCellTitle>
                  <TableCell>
                    <Typography color="textPrimary" variant="body2">
                      {objectTemplate.name}
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCellTitle>Description</TableCellTitle>
                  <TableCell>
                    <Typography color="textPrimary" variant="body2">
                      {objectTemplate.desc}
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCellTitle>Tags</TableCellTitle>
                  <TableCell sx={{ maxWidth: 250 }}>
                    {objectTemplate.tags.map((tag: string, index: number) => (
                      <Box component="span" sx={{ mr: 1, mt: 2 }} key={`${id}-${tag}`}>
                        <Label key={`tag-${index}`} color="primary">
                          {tag}
                        </Label>
                      </Box>
                    ))}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCellTitle>Interaction Type</TableCellTitle>
                  <TableCell>
                    <Typography color="textPrimary" variant="body2">
                      {InteractionTypes[objectTemplate.type]}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </DetailsCard>

          {objectTemplate.sound && (
            <DetailsCard header="Sound" sx={{ maxWidth: 500 }}>
              <TableRow>
                <TableCellTitle>Source</TableCellTitle>
                <TableCell>
                  <ReactAudioPlayer src={objectTemplate.sound.src} controls />
                  <Typography color="textPrimary" variant="body2">
                    {objectTemplate.sound.src}
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCellTitle>Is Positional?</TableCellTitle>
                <TableCell>
                  <BooleanLabel condition={!!objectTemplate.sound.isPositional} />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCellTitle>Loop?</TableCellTitle>
                <TableCell>
                  <BooleanLabel condition={objectTemplate.sound.loop} />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCellTitle>Max Distance</TableCellTitle>
                <TableCell>
                  <Typography color="textPrimary" variant="body2">
                    {objectTemplate.sound.maxDistance}
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCellTitle>Volume</TableCellTitle>
                <TableCell>
                  <Typography color="textPrimary" variant="body2">
                    {objectTemplate.sound.volume}
                  </Typography>
                </TableCell>
              </TableRow>
            </DetailsCard>
          )}
        </Box>

        {isInteractableTemplate(objectTemplate) && (
          <DetailsCard header="Interaction" sx={{ maxWidth: 500 }}>
            <TableRow>
              <TableCellTitle>Threshold Distance</TableCellTitle>
              <TableCell>
                <Typography color="textPrimary" variant="body2">
                  {objectTemplate.distThreshold}
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCellTitle>Preview Message</TableCellTitle>
              <TableCell>
                <Typography color="textPrimary" variant="body2">
                  {objectTemplate.previewMessage}
                </Typography>
              </TableCell>
            </TableRow>

            {isTemplateType(objectTemplate, InteractionTypes.EMBEDDED_WEBSITE) && (
              <>
                <TableRow>
                  <TableCellTitle>URL</TableCellTitle>
                  <TableCell>
                    <Typography color="textPrimary" variant="body2">
                      {objectTemplate.url}
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCellTitle>Deterministic URL Prefix</TableCellTitle>
                  <TableCell>
                    <Typography color="textPrimary" variant="body2">
                      {objectTemplate.deterministicUrlPrefix}
                    </Typography>
                  </TableCell>
                </TableRow>
              </>
            )}

            {isTemplateType(objectTemplate, InteractionTypes.POSTER) && (
              <>
                <TableRow>
                  <TableCellTitle>Preview</TableCellTitle>
                  <TableCell>
                    <Avatar
                      src={objectTemplate.preview}
                      variant="square"
                      sx={{
                        height: 100,
                        width: 100,
                        mx: "auto",
                        mt: 2,
                      }}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCellTitle>Image</TableCellTitle>
                  <TableCell>
                    <Avatar
                      src={`${objectTemplate.image}`}
                      variant="square"
                      sx={{
                        height: 100,
                        width: 100,
                        mx: "auto",
                        mt: 2,
                      }}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCellTitle>Blurb</TableCellTitle>
                  <TableCell>
                    <Typography color="textPrimary" variant="body2">
                      {objectTemplate.blurb}
                    </Typography>
                  </TableCell>
                </TableRow>
              </>
            )}

            {isTemplateType(objectTemplate, InteractionTypes.VIDEO) && (
              <TableRow>
                <TableCellTitle>Video</TableCellTitle>
                <TableCell>
                  <Typography color="textPrimary" variant="body2">
                    {objectTemplate.video}
                  </Typography>
                </TableCell>
              </TableRow>
            )}

            {isTemplateType(objectTemplate, InteractionTypes.EXTERNAL_CALL) && (
              <TableRow>
                <TableCellTitle>Zoom Link</TableCellTitle>
                <TableCell>
                  <Typography color="textPrimary" variant="body2">
                    {objectTemplate.zoomLink}
                  </Typography>
                </TableCell>
              </TableRow>
            )}

            {isTemplateType(objectTemplate, InteractionTypes.EXTENSION) && (
              <TableRow>
                <TableCellTitle>Extension Class</TableCellTitle>
                <TableCell>
                  <Typography color="textPrimary" variant="body2">
                    {objectTemplate.extensionClass}
                  </Typography>
                </TableCell>
              </TableRow>
            )}

            {isTemplateType(objectTemplate, InteractionTypes.NOTE) && (
              <TableRow>
                <TableCellTitle>Message</TableCellTitle>
                <TableCell>
                  <Typography color="textPrimary" variant="body2">
                    {objectTemplate.message}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </DetailsCard>
        )}
      </Box>

      <Box>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", mb: 2 }}>
          <Typography variant="subtitle1" sx={{ width: "100%" }}>
            Variants
          </Typography>

          <Box sx={{ width: 220 }}>
            <Button variant="contained" component="label" color="primary" onClick={handleModalOpen}>
              <AddIcon />
              Add Variant
            </Button>
          </Box>
        </Box>

        <ObjectVariantsTable
          variants={objectTemplate.variants}
          onEdit={handleEdit}
          onRemove={confirmDeletion}
          onUpdateDefault={handleUpdateDefaultVariant}
        />
      </Box>

      <Dialog
        fullWidth
        maxWidth="xs"
        onClose={handleModalClose}
        open={modalOpen}
        sx={{ overflowY: "auto" }}
      >
        {isEditing ? (
          <EditObjectVariantForm
            variantToEdit={variantToEdit}
            showDefaultToggle={false}
            onClose={handleModalClose}
            onSubmit={handleEditVariant}
          />
        ) : (
          <NewObjectVariantForm onClose={handleModalClose} onSubmit={handleNewVariant} />
        )}
      </Dialog>

      <AlertDialog
        isOpen={showDeleteConfirmation}
        onClose={handleDelete}
        title={`Confirm Deletion of ${buildVariantId(variantToDelete)}`}
      >
        {DELETE_CONFIRMATION}
      </AlertDialog>
    </Box>
  );
};

export default ObjectTemplateDetails;
