import { useQuery } from "react-query";

import * as DiscountsAPI from "./api";

/**
 * Fetches all discounts
 * @returns ReactQuery object where data is type of Discount | undefined
 */
export const useDiscounts = () =>
  useQuery("discounts", DiscountsAPI.fetchDiscounts, {
    initialData: undefined,
    retry: false,
  });
