import { AddPageActionParameters, NewrelicPriorityQueue } from "./newrelicPriorityQueue";
import { filter, mapObjIndexed } from "ramda";
import { NewRelicCustomEventAttributes } from "gather-common/dist/src/public/metrics/newrelic/customEvents";
import { isNotNil } from "gather-common/dist/src/public/fpHelpers";
// These constants comes from NR docs:
// https://docs.newrelic.com/docs/browser/new-relic-browser/browser-apis/addpageaction/
export const INTERVAL_LENGTH_MS = 30 * 1000;
// We -1 because we want to leave space for a possible `newRelicQueueOverflow` event,
// which we send so we know how much we're being throttled.
export const MAX_ACTIONS_PER_INTERVAL = 120 - 1;

// Lower value = higher priority. See `PriorityQueue`
export enum AddPageActionPriority {
  Urgent = -1,
  Default,
  Low,
}

/**
 * This is our custom wrapper over New Relic's browser agent that primarily aims to improve
 * the reliability of sending PageActions to NR via the `addPageActionReliably` API.
 * Their agent by default only supports 120 actions per 30s and drops any actions that exceed that limit,
 * which is undesirable for some critical actions.
 *
 * The tricky part here is that we don't know exactly how their agent defines the 30s windows.
 * Thus, we have to ensure we never exceed 120 actions in ANY possible 30s window.
 */
class NewRelicManager {
  static instance = new NewRelicManager();

  private sendQueue = new NewrelicPriorityQueue();

  // How many PageActions were sent anytime during the *last* interval?
  private actionsSentLastInterval = 0;
  // How many PageActions have been sent so far during *this* interval?
  // "This interval" ends when the current `setInterval` fires.
  private actionsSentThisInterval = 0;
  // How many PageActions have been dropped so far during this interval?
  private droppedActionsThisInterval = 0;

  private interval?: ReturnType<typeof setInterval>;

  private constructor() {}

  public start() {
    this.interval = setInterval(() => this.processQueue(), INTERVAL_LENGTH_MS);
  }

  // this is primarily a convenience function for testing so that we can reset
  // the state in between tests and make them independent
  reset() {
    this.destroy();
    this.sendQueue = new NewrelicPriorityQueue();
    this.actionsSentLastInterval = 0;
    this.actionsSentThisInterval = 0;
    this.droppedActionsThisInterval = 0;
    this.start();
  }

  // releases all the resources associated with this instance
  destroy() {
    this.interval && clearInterval(this.interval);
  }

  private actuallyAddPageAction(...args: AddPageActionParameters) {
    this.actionsSentThisInterval += 1;
    window.newrelic?.addPageAction(...args);
  }

  private processQueue() {
    const toSend = this.sendQueue.popN(MAX_ACTIONS_PER_INTERVAL - this.actionsSentThisInterval);

    toSend.forEach((args) => {
      this.actuallyAddPageAction(...args);
    });

    if (this.sendQueue.size() > 0 || this.droppedActionsThisInterval > 0) {
      const [maxFrequencyAction, maxFrequency] = this.sendQueue.highestFrequencyElement();

      this.actuallyAddPageAction("newRelicQueueOverflow", {
        droppedActions: this.droppedActionsThisInterval,
        pendingActions: this.sendQueue.size(),
        maxFrequency: maxFrequency || 0,
        maxFrequencyAction: maxFrequencyAction || "",
      });
    }

    this.actionsSentLastInterval = this.actionsSentThisInterval;
    this.actionsSentThisInterval = 0;
    this.droppedActionsThisInterval = 0;
  }

  /**
   * Reports a Browser PageAction event to New Relic.
   *
   * Replicates the behavior of the NR agent's `addPageAction` by dropping any actions
   * passed in during a period where MAX_ACTIONS_PER_INTERVAL actions have already / will be sent.
   * To ensure your action gets sent, use `addPageActionReliably` instead.
   */
  addPageAction = (...args: AddPageActionParameters) => {
    // If we're already past our limit for this period, drop this action.
    // Actions in the queue take priority since they've been waiting longer!
    if (this.actionsSentThisInterval + this.sendQueue.size() >= MAX_ACTIONS_PER_INTERVAL) {
      this.droppedActionsThisInterval++;
      return;
    }
    this.sendOrQueueAction(...args);
  };

  /**
   * Reports a Browser PageAction event to New Relic, with the added guarantee that the event
   * will never be dropped - all actions added reliably via this method will *eventually* get sent.
   *
   * @param priority Specify a more urgent priority to ensure your action gets sent ASAP when there's a backlog.
   */
  addPageActionReliably = (...args: Parameters<typeof this.sendOrQueueAction>) => {
    this.sendOrQueueAction(...args);
  };

  // Sends the action immediately if we're confident we can do so without violating the 120 action limit
  // for ANY 30s window.
  // Otherwise, queues the action to be sent later.
  private sendOrQueueAction(
    name: string,
    attributes?: Record<string, newrelic.SimpleType>,
    priority = AddPageActionPriority.Default,
  ) {
    if (
      // If it's possible >120 actions have already been sent in the last 30s, we cannot eager-send this action.
      //
      // The max # of actions that could possibly have been sent in the last 30s is the sum of:
      //   - # actions from the current (still in progress) interval. These definitely happened within the last 30s.
      //   - # actions from the past interval (they could've been sent 0.1ms before the interval ended, which would be
      //     within the last 30s)
      //
      // It's worth noting that this system of tracking `actionsSentLastInterval` isn't optimal. If we wanted to further
      // optimize latency, we could track the exact timestamps of when every action from the past interval was sent.
      // This system is much simpler while still reaping most of the benefit, though.
      this.actionsSentThisInterval + this.actionsSentLastInterval >=
      MAX_ACTIONS_PER_INTERVAL
    ) {
      this.sendQueue.push(priority, [name, attributes]);
    } else {
      // There's no way sending this action now could trigger the 120 action limit for ANY 30s window, so it's
      // safe to immediately send.
      this.actuallyAddPageAction(name, attributes);
    }
  }

  setCustomAttribute(...args: Parameters<typeof window.newrelic.setCustomAttribute>) {
    window.newrelic?.setCustomAttribute(...args);
  }

  addRelease(releaseName: string, releaseId: string) {
    window.newrelic?.addRelease(releaseName, releaseId);
  }

  // public interface used by gather-video-client globals
  addMetric = this.addPageAction;
  // public interface used by gather-video-client globals
  addMetricReliably = this.addPageActionReliably;
  // public interface used by gather-video-client globals
  setAttribute = this.setCustomAttribute;

  getCurrentCustomAttributes = (): NewRelicCustomEventAttributes =>
    // This doesn't seem to be explicitly documented by NR, but `newrelic.info.jsAttributes` looks like it's
    // a maintained mapping of all current custom attributes. This can be easily verified by modifying
    // a custom attribute and seeing that it's also updated in `jsAttributes`.
    //
    // We include all custom attributes here because these are very useful! They're included by default for PageActions
    // but not for Custom Events (b/c we have to proxy through our HTTP servers).
    filter(
      isNotNil,
      mapObjIndexed(
        // NR types this as `unknown` and not `NewRelicSimpleType` for some reason. We're leaving it this way for extra safety,
        // but these checks may be unnecessary.
        (value) =>
          // We need to be sure to send only valid numbers to the server - `NaN` doesn't pass `zod.number()`
          (typeof value === "number" && !isNaN(value)) ||
          typeof value === "string" ||
          typeof value === "boolean"
            ? value
            : null,
        window.newrelic?.info.jsAttributes ?? {},
      ),
    );
}

export const newRelicManager = NewRelicManager.instance;
