import React, { FC } from "react";
import { matchPath } from "react-router-dom";
import { List, ListSubheader } from "@mui/material";
import { SxProps } from "@mui/system";

import NavItem, { NavItemProps } from "./NavItem";
import PermittedComponent from "components/authentication/PermittedComponent";

interface NavItemsList {
  depth?: number;
  items: NavItemProps[];
  pathname: string;
}

interface ChildRouteProps {
  acc: React.ReactNode[];
  pathname: string;
  item: NavItemProps;
  depth: number;
}

const reduceChildRoutes = ({ acc, pathname, item, depth }: ChildRouteProps) => {
  const key = `${item.title}-${depth}`;
  const exactMatch = item.path
    ? !!matchPath(
        {
          path: item.path,
          end: true,
        },
        pathname,
      )
    : false;

  if (item.children) {
    const partialMatch = item.path
      ? !!matchPath(
          {
            path: item.path,
            end: false,
          },
          pathname,
        )
      : false;

    acc.push(
      <PermittedComponent permissions={item.permissions} requiresAll={item.requiresAll} key={key}>
        <NavItem
          active={partialMatch}
          depth={depth}
          icon={item.icon}
          info={item.info}
          open={partialMatch}
          path={item.path}
          title={item.title}
        >
          {renderNavItems({
            depth: depth + 1,
            // because I tried for 15 minutes and wanted to throw my laptop out the emergency exit
            // of the airplane because I couldn't get it to work
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            items: item.children as NavItemProps[],
            pathname,
          })}
        </NavItem>
      </PermittedComponent>,
    );
  } else {
    acc.push(
      <PermittedComponent permissions={item.permissions} requiresAll={item.requiresAll} key={key}>
        <NavItem
          active={exactMatch}
          depth={depth}
          icon={item.icon}
          info={item.info}
          path={item.path}
          title={item.title}
        />
      </PermittedComponent>,
    );
  }

  return acc;
};

const renderNavItems: FC<NavItemsList> = ({ depth = 0, items, pathname }) => (
  <List disablePadding>
    {items.reduce(
      (acc: React.ReactNode[], item) =>
        reduceChildRoutes({
          acc,
          item,
          pathname,
          depth,
        }),
      [],
    )}
  </List>
);

interface NavSectionProps {
  items: Array<NavItemProps>;
  pathname: string;
  title?: string;
  sx?: SxProps;
}

const NavSection: FC<NavSectionProps> = ({ items, pathname, title, ...other }) => {
  const navItems = renderNavItems({
    items,
    pathname,
  });

  if (title) {
    return (
      <List
        subheader={
          <ListSubheader
            disableGutters
            disableSticky
            sx={{
              color: "text.primary",
              fontSize: "0.75rem",
              lineHeight: 2.5,
              fontWeight: 700,
              textTransform: "uppercase",
            }}
          >
            {title}
          </ListSubheader>
        }
        {...other}
      >
        {navItems}
      </List>
    );
  }

  return <List>{navItems}</List>;
};

export default NavSection;
