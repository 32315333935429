import React, { FC, useState, useCallback, useMemo, ChangeEvent, MouseEvent } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import TablePagination from "@mui/material/TablePagination";
import PlusIcon from "@mui/icons-material/Add";

import TabWrapper from "components/dashboard/TabWrapper";
import SortSelect from "components/inputs/SortSelect";
import {
  applyActiveFilter,
  applyCancelledFilter,
  applyPastFilter,
  filterOptions,
  sortOptions,
} from "features/reservations/utils";
import { useSpaceReservations } from "features/spaces/hooks";
import { applySort, applyPagination } from "features/tables/utils";
import ReservationsTable from "pages/dashboard/reservations/partials/ReservationsTable";
import NewReservationForm from "pages/dashboard/reservations/partials/NewReservationForm";
import { AdminReservation } from "gather-admin-common/dist/src/public/reservations/types";

interface Props {
  spaceId?: string;
}

const ReservationsTab: FC<Props> = ({ spaceId }) => {
  const { data, isLoading } = useSpaceReservations(spaceId);
  const allRes = useMemo(() => data || [], [data]);

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [sort, setSort] = useState<string>("startDate|desc");
  const [currentPage, setCurrentPage] = useState(0);
  const [currentFilter, setCurrentFilter] = useState("all");
  const [limit, setLimit] = useState(5);
  const filteredReservations: AdminReservation[] = useMemo(() => {
    switch (currentFilter) {
      case "isActive":
        return applyActiveFilter(allRes);
      case "isPastEvent":
        return applyPastFilter(allRes);
      case "isCancelled":
        return applyCancelledFilter(allRes);
      default:
        return allRes;
    }
  }, [allRes, currentFilter]);

  const reservationsToDisplay: AdminReservation[] = useMemo(() => {
    const sortedReservations = applySort<AdminReservation>(filteredReservations, sort);
    return applyPagination<AdminReservation>(sortedReservations, currentPage, limit);
  }, [currentPage, limit, filteredReservations, sort]);

  const handleSortChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSort(event.target.value);
      setCurrentPage(0);
    },
    [setSort, setCurrentPage],
  );

  const handleLimitChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setLimit(parseInt(event.target.value, 10));
      setCurrentPage(0);
    },
    [setLimit, setCurrentPage],
  );

  const handlePageChange = useCallback(
    (_event: MouseEvent<HTMLButtonElement> | null, page: number) => {
      setCurrentPage(page);
    },
    [setCurrentPage],
  );

  const handleFilterChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setCurrentFilter(event.target.value);
      setCurrentPage(0);
    },
    [setCurrentFilter, setCurrentPage],
  );

  const handleModalClose = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  const handleModalOpen = useCallback(() => {
    setModalOpen(true);
  }, [setModalOpen]);

  // TODO: add loading state to reservations table
  return (
    <TabWrapper>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          mb: 2,
          ml: -1,
        }}
      >
        <Box sx={{ display: "flex" }}>
          <SortSelect onChange={handleSortChange} value={sort} options={sortOptions} />
          <SortSelect
            onChange={handleFilterChange}
            value={currentFilter}
            options={filterOptions}
            label="Filter by"
          />
        </Box>

        <Button
          color="primary"
          startIcon={<PlusIcon fontSize="small" />}
          variant="contained"
          onClick={handleModalOpen}
        >
          Add Reservation
        </Button>
      </Box>

      {isLoading && <Box sx={{ p: 2 }}>Loading...</Box>}

      {!isLoading && data && (
        <Card>
          <ReservationsTable reservations={reservationsToDisplay} />
          <TablePagination
            component="div"
            count={filteredReservations.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={currentPage}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
          />
        </Card>
      )}

      <Dialog fullWidth maxWidth="sm" onClose={handleModalClose} open={modalOpen}>
        <NewReservationForm spaceId={spaceId} onClose={handleModalClose} />
      </Dialog>
    </TabWrapper>
  );
};

export default ReservationsTab;
