import React, { FC } from "react";
import { PaneContent, ScrollPane } from "./ui";
import { JSONTree } from "react-json-tree";

export type StateDiffProps = {
  state?: Object | Object[];
};

const expand = (_: unknown, __: unknown, level: number) => 3 > level;

const StateDiff: FC<StateDiffProps> = ({ state }) => (
  <PaneContent>
    <ScrollPane>
      <JSONTree data={state} shouldExpandNode={expand} />
    </ScrollPane>
  </PaneContent>
);

export default StateDiff;
