import React, { FC } from "react";
import { Box } from "@mui/material";
import { SxProps } from "@mui/system";

interface Props {
  sx?: SxProps;
}

const Logo: FC<Props> = (props) => (
  <Box component="svg" version="1.1" viewBox="0 0 40.67 55.04" {...props}>
    <title>Gather Logo</title>

    <g fill="#FFFFFF">
      <path d="M33.09,54.86C23,52,31,37.41,38.78,44.49c4.41,4.07.08,11.83-5.69,10.37Zm2.63-3.23c2.91-1.22,2-6-1.29-5.85-4.93,0-3.17,7.87,1.29,5.85Z" />
      <path d="M20,45.68c-8.77-1.46-5.55-14.73,2.88-12C29.8,36,27.1,46.76,20,45.68Zm2-3.3c2.92-1.06,2.17-6.11-1.19-5.86C16,36.45,17.56,44.21,22,42.38Z" />
      <path d="M33.4,38.28c-8-1.69-5.18-13.9,2.65-12.06s5.07,13.72-2.65,12.06ZM36,35c4.42-2.28-.82-8.59-3.88-4.54C30.27,33,33.28,36.49,36,35Z" />
      <path d="M4.81,35.53c-6.35-1.14-6.44-10.84-.12-12.08C14.41,21.7,14.51,37.05,4.81,35.53Zm2.46-3.22c3.76-1.64,1-7.48-2.61-5.57-3.5,1.71-.94,7.1,2.61,5.57Z" />
      <path d="M18.83,27.72C9.92,25.61,15,12,23.1,16.25c6.19,3.18,2.47,13.06-4.27,11.47Zm2.42-3.16c3-1,2.36-6.11-1.09-5.92C15.37,18.58,16.83,26.22,21.25,24.56Z" />
      <path d="M32.75,20.44c-7.68-2-4.9-13.68,2.88-12s4.93,14.08-2.88,12Zm2.37-3c3.32-.81,2.67-6.17-.88-6-4.56,0-3.44,7.32.88,6Z" />
      <path d="M9.17,18.22c-2,0,1.28-7.82,1.86-10.39-.57-1.24-2.69-5.66-2.75-6.17A1.52,1.52,0,0,1,10.17.05c.67.19.8.37,2.15,3.25a13,13,0,0,0,1.5,2.85c.1.09,9.24,3,9.29,3.18,1,.79.42,2.81-.93,2.7.14.23-8.3-2.62-8.38-2.53-.9,1-1.7,10.59-4.63,8.72Z" />
    </g>
  </Box>
);

export default Logo;
