import React, { FC } from "react";
import { AdminRoleTypePrisma as AdminRole } from "gather-prisma-types/dist/src/public/client";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

import AdminUserRow from "./AdminUserRow";
import { AdminUser } from "gather-admin-common/dist/src/public/auth/types";

interface Props {
  users: AdminUser[];
  onToggleSort: () => void;
  onDelete?: (userId: string) => void;
  onUpdate?: (userId: string, roles: AdminRole[]) => void;
  currentSortAsc: boolean;
}

const UsersTable: FC<Props> = ({ users, onToggleSort, currentSortAsc, onDelete, onUpdate }) => (
  <Box sx={{ minWidth: "100%" }}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell
            onClick={onToggleSort}
            sx={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            Email
            <Box
              sx={{
                mb: -1,
                ml: -1,
              }}
            >
              {currentSortAsc ? (
                <ArrowDropDownIcon color="primary" sx={{ ml: 2 }} fontSize="small" />
              ) : (
                <ArrowDropUpIcon color="primary" sx={{ ml: 2 }} fontSize="small" />
              )}
            </Box>
          </TableCell>
          <TableCell>Roles</TableCell>

          {(onUpdate || onDelete) && <TableCell>Actions</TableCell>}
        </TableRow>
      </TableHead>

      <TableBody>
        {users.map((data: AdminUser, index: number) => (
          <AdminUserRow
            key={data.id}
            user={data}
            index={index}
            onDelete={onDelete}
            onUpdate={onUpdate}
          />
        ))}
      </TableBody>
    </Table>
  </Box>
);

export default UsersTable;
