import React, { FC } from "react";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import DiscountRow from "./DiscountRow";
import { AdminDiscount } from "features/discounts/types";

interface Props {
  discounts: AdminDiscount[];
  displayType: string;
  canEdit: boolean;
  onEdit?: (discountId: string) => void;
  onDelete?: (discountId: string) => void;
}

const DiscountsTable: FC<Props> = ({ discounts, displayType, canEdit, onEdit, onDelete }) => {
  const showSubscriptionColumn = displayType === "subscription";
  const showMultiplierColumn = displayType === "multiplier";
  const showAllColumn = displayType === "all";

  return (
    <Box sx={{ minWidth: "100%" }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell> {/* Description will go under */}
            {showAllColumn && <TableCell>Type</TableCell>}
            {showSubscriptionColumn && <TableCell>Code</TableCell>}
            {showMultiplierColumn && <TableCell>Multiplier</TableCell>}
            <TableCell sx={{ minWidth: 100 }}>Uses</TableCell> {/* numUses / maxUses */}
            <TableCell>Event Length</TableCell>
            <TableCell>Start</TableCell>
            <TableCell>End</TableCell>
            <TableCell>State</TableCell>
            {canEdit && (onEdit || onDelete) && <TableCell>Actions</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {discounts.map((discount) => (
            <DiscountRow
              key={`row-${discount.id}`}
              discount={discount}
              displayType={displayType}
              canEdit={canEdit}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default DiscountsTable;
