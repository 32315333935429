import { MoreVert } from "@mui/icons-material";
import { Divider, IconButton, ListSubheader, Menu, MenuItem } from "@mui/material";
import { NR_DASHBOARDS, createDashboardUrl } from "gather-admin-common/dist/src/public/newrelic";
import { keys } from "ramda";
import React, { FC } from "react";
import { useCurrentSession } from "state/avClientStateViewerSlice";

export type ParticipantMenuProps = {
  participant: string;
};

const AvailableDashboardMenuItems: FC<
  ParticipantMenuProps & {
    handleClose: () => void;
  }
> = ({ participant, handleClose }) => {
  const currentSession = useCurrentSession();

  return (
    <>
      <ListSubheader key="participant-menu-dashboard-header">New Relic Dashboards</ListSubheader>
      <Divider />
      {keys(NR_DASHBOARDS).map((key) => {
        const value = NR_DASHBOARDS[key];
        return (
          <MenuItem
            key={value.entityId}
            href={createDashboardUrl(key, {
              userId: participant,
              spaceId: currentSession?.spaceId ?? "%",
            })}
            target="_blank"
            component="a"
            onClick={handleClose}
          >
            {value.title}
          </MenuItem>
        );
      })}
    </>
  );
};

const ParticipantMenu: FC<ParticipantMenuProps> = ({ participant }) => {
  const currentSession = useCurrentSession();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        id="participant-menu-button"
        aria-controls={open ? "participant-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        edge="end"
        aria-label="options"
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="participant-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          dense: true,
          "aria-labelledby": "participant-menu-button",
        }}
      >
        <ListSubheader key="participant-menu-user-header">{participant}</ListSubheader>
        <Divider />
        <MenuItem
          key={"participant-menu-user-details"}
          href={"/dashboard/users?user=" + participant}
          target="_blank"
          component="a"
          onClick={handleClose}
        >
          Go to User Details
        </MenuItem>
        <MenuItem
          key={"participant-menu-user-cst"}
          href={"/dashboard/client-state?spaceIdOrUserId=" + participant}
          target="_blank"
          component="a"
          onClick={handleClose}
        >
          Search CSTs by user
        </MenuItem>
        <MenuItem
          key={"participant-menu-space-cst"}
          href={"/dashboard/client-state?spaceIdOrUserId=" + currentSession?.spaceId}
          target="_blank"
          component="a"
          onClick={handleClose}
        >
          Search CSTs by space
        </MenuItem>
        <AvailableDashboardMenuItems participant={participant} handleClose={handleClose} />
      </Menu>
    </div>
  );
};

export default ParticipantMenu;
