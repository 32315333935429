import * as Comlink from "comlink";
import { createWorker } from "./create";
import { newRelicManager } from "gather-browser-newrelic-manager/dist/src/public/newRelicManager";
import type GatherWorker from "./main.worker";

const worker = createWorker<typeof GatherWorker>(
  Comlink,
  () => new Worker(new URL("./main.worker.js", import.meta.url)),
);

worker.setMetricsReporter(
  Comlink.proxy((...args: Parameters<typeof newRelicManager.addPageActionReliably>) =>
    newRelicManager.addPageActionReliably(...args),
  ),
);
worker.beginCollecting();

export default worker;
