import React, { FC } from "react";

import CategoriesContainer from "./partials/CategoriesContainer";
import PageContainer from "components/layout/dashboard/PageContainer";

const PAGE_TITLE = "Manage Object Picker Categories";

const ObjectTemplateCategoriesIndexPage: FC = () => (
  <PageContainer pageTitle={PAGE_TITLE}>
    <CategoriesContainer />
  </PageContainer>
);

export default ObjectTemplateCategoriesIndexPage;
