import { axios } from "gather-common-including-video/dist/src/public/axios";
import { authTokenManager } from "gather-auth-client/dist/src/public/auth";
import { AdminDiscount, DiscountUpdate } from "./types";

const ORIGIN = process.env.REACT_APP_API_BASE_PATH;

/**
 * API request to fetch all discounts in the database
 * @returns list of AdminDiscounts
 */
export const fetchDiscounts = async (): Promise<AdminDiscount[]> => {
  const authToken = await authTokenManager.waitForToken();

  try {
    const response = await axios.get(`${ORIGIN}/api/v2/admin/discounts`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    return response.data;
  } catch (e) {
    let message = "Failed to retrieve discounts.";

    if (e instanceof Error) {
      message = e.message;
    }

    return Promise.reject(message);
  }
};

/**
 * API request to create a new discount
 * @param newDiscount - form body of discount being created
 * @returns AdminDscount
 */
export const submitNewDiscount = async (newDiscount: AdminDiscount): Promise<AdminDiscount> => {
  const authToken = await authTokenManager.waitForToken();

  try {
    return await axios.post(`${ORIGIN}/api/v2/admin/discounts`, newDiscount, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
  } catch (e) {
    let message = "Failed to create new discount.";

    if (e instanceof Error) {
      message = e.message;
    }

    return Promise.reject(message);
  }
};

/**
 * API request to delete a discount
 * @param discountId - ID of discount being deleted
 * @returns void Promise
 */
export const deleteDiscount = async (discountId: string | undefined): Promise<void> => {
  const authToken = await authTokenManager.waitForToken();

  try {
    if (discountId) {
      return axios.delete(`${ORIGIN}/api/v2/admin/discounts/${discountId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
    } else {
      throw new Error("Missing discount ID");
    }
  } catch (e) {
    let message = "Failed to delete discount.";

    if (e instanceof Error) {
      message = e.message;
    }

    return Promise.reject(message);
  }
};

/**
 * API request to update a discount
 * @param discountId - ID of the discount being updated
 * @param updatedDiscount - form body of the updated discount
 * @returns Promise
 */
export const updateDiscount = async (
  discountId: string | undefined,
  updatedDiscount: DiscountUpdate,
): Promise<void> => {
  const authToken = await authTokenManager.waitForToken();

  try {
    if (discountId) {
      await axios.patch(`${ORIGIN}/api/v2/admin/discounts/${discountId}`, updatedDiscount, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
    } else {
      throw new Error("Missing discount ID");
    }
  } catch (e) {
    let message = "Failed to update discount.";

    if (e instanceof Error) {
      message = e.message;
    }

    return Promise.reject(message);
  }
};
