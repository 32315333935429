import * as zod from "zod";

import { CoreRole } from "@gathertown/gather-game-common/dist/src/public/Player";

/**
 * @deprecated Please use WearableType generated by Prisma!
 * When this is updated make sure to update ZodDBOutfit
 * Also make sure to update in events.proto for the game server
 */
export enum WearableType {
  Skin = "skin",
  Hair = "hair",
  FacialHair = "facial_hair",
  Top = "top",
  Bottom = "bottom",
  Shoes = "shoes",
  Hat = "hat",
  Glasses = "glasses",
  Other = "other",
  Costume = "costume",
  Mobility = "mobility",
  Jacket = "jacket",
}

/**
 * @deprecated Please use WearableSubType generated by Prisma!
 */
export enum WearableSubType {
  Seasonal = "seasonal",
  Internal = "internal",
  Experimental = "experimental",
}

/**
 * @deprecated Please use WearableLayer generated by Prisma!
 * ordered from back to front
 */
export enum WearableLayerLegacy {
  Mobility_Back = "mobility back",
  Other_Back = "other back",
  Hat_Back = "hat back",
  Hair_Back = "hair back",
  Jacket_Back = "jacket back",
  Skin_Front = "skin front",
  Bottom_Front = "bottom front",
  Shoes_Front = "shoes front",
  Top_Front = "top front",
  Jacket_Front = "jacket front",
  Other_Middle = "other middle",
  Glasses_Front = "glasses front",
  Facial_Hair_Front = "facial_hair front",
  Hair_Front = "hair front",
  Hat_Front = "hat front",
  Other_Front = "other front",
  Mobility_Front = "mobility front",
  Costume_Front = "costume front",
}

export const WearableLayerOrderMap: Record<WearableLayerLegacy, number> = {
  [WearableLayerLegacy.Mobility_Back]: 0,
  [WearableLayerLegacy.Other_Back]: 10,
  [WearableLayerLegacy.Hat_Back]: 20,
  [WearableLayerLegacy.Hair_Back]: 30,
  [WearableLayerLegacy.Jacket_Back]: 40,
  [WearableLayerLegacy.Skin_Front]: 50,
  [WearableLayerLegacy.Bottom_Front]: 60,
  [WearableLayerLegacy.Shoes_Front]: 70,
  [WearableLayerLegacy.Top_Front]: 80,
  [WearableLayerLegacy.Jacket_Front]: 90,
  [WearableLayerLegacy.Other_Middle]: 100,
  [WearableLayerLegacy.Glasses_Front]: 110,
  [WearableLayerLegacy.Facial_Hair_Front]: 120,
  [WearableLayerLegacy.Hair_Front]: 130,
  [WearableLayerLegacy.Hat_Front]: 140,
  [WearableLayerLegacy.Other_Front]: 150,
  [WearableLayerLegacy.Mobility_Front]: 160,
  [WearableLayerLegacy.Costume_Front]: 170,
};

export const DEFAULT_WEARABLE_COLORS = {
  red: "#F03827",
  orange: "#FBA055",
  yellow: "#FFCA43",
  olive: "#66783E",
  green: "#349141",
  viridian: "#1D766B",
  blue: "#2DBDFF",
  indigo: "#7284FC",
  purple: "#9869B1",
  magenta: "#E83DC4",
  pink: "#F76898",
  brown: "#C0734F",
  cream: "#E3CAAE",
  white: "#FFFFFF",
  grey: "#9193A6",
  black: "#32343B",
};

export const DEFAULT_HAIR_COLORS = {
  white: "#D0D0D0",
  black: "#453E50",
  brown: "#674239",
  caramel: "#AB7144",
  "dirty blond": "#9F8553",
  blond: "#F2B55B",
  auburn: "#933A29",
  ginger: "#E4863A",
  pink: "#FF9CA6",
  purple: "#846ECA",
  blue: "#498BC5",
  green: "#8AB36E",
};

export const WearableColors: Record<WearableType | WearableSubType, { [color: string]: string }> = {
  [WearableType.Skin]: {
    1: "#fddab5",
    2: "#f9bf95",
    3: "#d8885c",
    4: "#ab633b",
    5: "#75412b",
  },
  [WearableType.Hair]: DEFAULT_HAIR_COLORS,
  [WearableType.FacialHair]: DEFAULT_HAIR_COLORS,
  [WearableType.Hat]: DEFAULT_WEARABLE_COLORS,
  [WearableType.Glasses]: DEFAULT_WEARABLE_COLORS,
  [WearableType.Other]: DEFAULT_WEARABLE_COLORS,
  [WearableType.Top]: DEFAULT_WEARABLE_COLORS,
  [WearableType.Bottom]: DEFAULT_WEARABLE_COLORS,
  [WearableType.Shoes]: DEFAULT_WEARABLE_COLORS,
  [WearableType.Mobility]: DEFAULT_WEARABLE_COLORS,
  [WearableType.Jacket]: DEFAULT_WEARABLE_COLORS,
  [WearableType.Costume]: {}, // Will use its subtypes
  [WearableSubType.Seasonal]: {
    ...DEFAULT_WEARABLE_COLORS,
    black: "#454345",
  },
  [WearableSubType.Internal]: {
    ...DEFAULT_WEARABLE_COLORS,
    black: "#454345",
  },
  [WearableSubType.Experimental]: {
    ...DEFAULT_WEARABLE_COLORS,
    black: "#454345",
  },
};

export const RequiredWearables = new Set<WearableType | WearableSubType>([WearableType.Skin]);

// null means no wearable, empty object means random, object keys are query params for wearables in db
export const InitialWearables: Record<
  WearableType,
  null | { name: string } | { color: string; name: string } | Record<string, string>
> = {
  [WearableType.Skin]: { color: "3", name: "typical" },
  [WearableType.Hair]: {},
  [WearableType.FacialHair]: null,
  [WearableType.Top]: { name: "t shirt" },
  [WearableType.Bottom]: { name: "pants" },
  [WearableType.Shoes]: { color: "black", name: "generic" },
  [WearableType.Hat]: null,
  [WearableType.Glasses]: null,
  [WearableType.Other]: null,
  [WearableType.Costume]: null,
  [WearableType.Mobility]: null,
  [WearableType.Jacket]: null,
};

/**
 * @deprecated Please use WearablePart generated by Prisma!
 * ordered from back to front
 */
const ZodWearablePart = zod.object({
  spritesheetId: zod.string(),
  // Only needed for admin dashboard upload wearables step
  spritesheetUrl: zod.string().optional(),
  layerId: zod.nativeEnum(WearableLayerLegacy),
});

export const ZodWearableParts = zod.array(ZodWearablePart);

/**
 * @deprecated Please use Wearable generated by Prisma!
 * ordered from back to front
 */
export const ZodWearable = zod.object({
  color: zod.string(),
  name: zod.string(),
  type: zod.nativeEnum(WearableType),
  style: zod.string().nullable().optional(),
  subType: zod.nativeEnum(WearableSubType).nullable().optional(),
  isDefault: zod.boolean(),
  previewUrl: zod.string(),
  parts: ZodWearableParts,
  startDate: zod.string().nullable().optional(),
  endDate: zod.string().nullable().optional(),
});

/**
 * @deprecated Please use Wearable generated by Prisma!
 * ordered from back to front
 */
export type WearableDB = zod.infer<typeof ZodWearable>;

/**
 * @deprecated Please use WearablePart generated by Prisma!
 * ordered from back to front
 */
export type WearablePart = zod.infer<typeof ZodWearablePart>;

/**
 * @deprecated Please use Wearable generated by Prisma!
 * ordered from back to front
 */
export type Wearable = WearableDB & {
  id: string;
};

export type Outfit = {
  [key in WearableType]: Wearable | null;
};

export const UPDATE_ROLE_PERMISSIONS: Record<CoreRole, CoreRole[]> = {
  [CoreRole.GeneralMember]: [CoreRole.GeneralMember, CoreRole.Guest],
  [CoreRole.Builder]: [CoreRole.GeneralMember, CoreRole.Guest],
  [CoreRole.Mod]: [
    CoreRole.Builder,
    CoreRole.Mod,
    CoreRole.GeneralMember,
    CoreRole.Speaker,
    CoreRole.Guest,
  ],
  [CoreRole.Owner]: [
    CoreRole.Owner,
    CoreRole.Builder,
    CoreRole.Mod,
    CoreRole.GeneralMember,
    CoreRole.Speaker,
    CoreRole.Guest,
  ],
  [CoreRole.Speaker]: [],
  [CoreRole.Guest]: [],
  [CoreRole.RecordingClient]: [],
};

export type SpritesheetDB = {
  url: string;
};
