import { axios } from "gather-common-including-video/dist/src/public/axios";
import { authTokenManager } from "gather-auth-client/dist/src/public/auth";

import { ReservationUpdateInput } from "gather-prisma-types/dist/src/public/client";
import {
  AdminReservation,
  NewAdminReservationFields,
} from "gather-admin-common/dist/src/public/reservations/types";

/**
 * API request to fetch a reservation's details
 * @param reservationId - ID of the reservation being requested
 * @returns AdminReservation
 */
export const fetchReservationById: (
  reservationId: string | undefined,
) => Promise<AdminReservation> = async (reservationId) => {
  const authToken = await authTokenManager.waitForToken();
  const origin = process.env.REACT_APP_API_BASE_PATH;

  try {
    if (reservationId) {
      const response = await axios.get<AdminReservation>(
        `${origin}/api/v2/admin/reservations/${reservationId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        },
      );
      const reservation: AdminReservation = response?.data;

      return reservation;
    } else {
      throw new Error("Missing reservationId");
    }
  } catch (e) {
    let message = "Failed to retrieve reservations.";

    if (e instanceof Error) {
      message = e.message;
    }

    return Promise.reject(message);
  }
};

/**
 * API request to create a new reservation
 * @param newReservation - form body of reservation being created
 * @returns void promise
 */
export const submitNewReservation = async (
  newReservation: NewAdminReservationFields,
): Promise<void> => {
  const authToken = await authTokenManager.waitForToken();
  const origin = process.env.REACT_APP_API_BASE_PATH;

  try {
    return await axios.post(`${origin}/api/v2/admin/reservations`, newReservation, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
  } catch (e) {
    let message = "Failed to create new reservation.";

    if (e instanceof Error) {
      message = e.message;
    }

    return Promise.reject(message);
  }
};

/**
 * API Request to update a reservation's raw data fields
 * @param reservationId - ID of the reservation to be updated
 * @param fields - form fields that were submitted
 * @returns AdminReservation
 */
export const updateReservation: (
  reservationId: string | undefined,
  fields: ReservationUpdateInput,
) => Promise<AdminReservation> = async (reservationId, fields) => {
  const authToken = await authTokenManager.waitForToken();
  const origin = process.env.REACT_APP_API_BASE_PATH;

  try {
    if (reservationId) {
      return axios.patch(`${origin}/api/v2/admin/reservations/${reservationId}`, fields, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
    } else {
      throw new Error("Missing reservationId");
    }
  } catch (e) {
    let message = "Failed to update reservation.";

    if (e instanceof Error) {
      message = e.message;
    }

    return Promise.reject(message);
  }
};

/**
 * API request to delete a reservation
 * @param reservationId - ID of the reservation to be deleted
 * @returns reservationId
 */
export const deleteReservation: (reservationId: string | undefined) => Promise<void> = async (
  reservationId,
) => {
  const authToken = await authTokenManager.waitForToken();
  const origin = process.env.REACT_APP_API_BASE_PATH;

  try {
    if (reservationId) {
      return axios.delete(`${origin}/api/v2/admin/reservations/${reservationId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
    } else {
      throw new Error("Missing reservationId");
    }
  } catch (e) {
    let message = "Failed to delete reservation.";

    if (e instanceof Error) {
      message = e.message;
    }

    return Promise.reject(message);
  }
};
