import { axios } from "gather-common-including-video/dist/src/public/axios";
// Webpack version 5+ does not support Buffer natively anymore
import { Buffer } from "buffer";
import { authTokenManager } from "gather-auth-client/dist/src/public/auth";
import HttpV2Client from "../../http-client/httpV2Client";
import { isNil } from "ramda";
import { HttpV2Paths } from "gather-http-common/dist/src/public/httpAPI";
import { Wearable, WearablePart } from "gather-common/dist/src/public/resources/users";

const ORIGIN = process.env.REACT_APP_API_BASE_PATH;

/**
 * API request to fetch all wearables in the database.
 * @returns list of Wearables
 */
export const fetchWearables = async (): Promise<Wearable[]> => {
  const authToken = await authTokenManager.waitForToken();

  try {
    const response = await axios.get<Wearable[]>(`${ORIGIN}/api/v2/admin/wearables`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    return response.data;
  } catch (e) {
    let message = "Failed to retrieve wearables.";

    if (e instanceof Error) {
      message = e.message;
    }

    return Promise.reject(message);
  }
};

/**
 * API request to upload wearables
 * @param wearables - form body of wearables being uploaded
 * @returns Wearable[] promise
 */
export const submitWearables = async (wearables: Partial<Wearable>[]): Promise<Wearable[]> => {
  const authToken = await authTokenManager.waitForToken();

  try {
    const response = await axios.post(`${ORIGIN}/api/v2/admin/wearables`, wearables, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    return response.data;
  } catch (e) {
    let message = "Failed to upload wearables.";

    if (e instanceof Error) {
      message = e.message;
    }

    return Promise.reject(message);
  }
};

/**
 * API request to upload wearables
 * @param fileBuffer - buffer of file to be uploaded
 * @returns string promise
 */
export const uploadWearableImage = async (fileBuffer: Buffer) => {
  try {
    const authToken = await authTokenManager.waitForToken();

    const res = await axios.post(
      `${ORIGIN}/api/v2/admin/wearable-image`,
      { bytes: fileBuffer },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      },
    );

    return res.data;
  } catch (e) {
    let message = "Failed to upload wearable image.";

    if (e instanceof Error) {
      message = e.message;
    }

    return Promise.reject(message);
  }
};

/**
 * API request to upload wearables
 * @param src - form body of wearables being uploaded
 * @returns string promise
 */
export const uploadWearableImageFromRelativePath = async (src: string): Promise<string> => {
  const response = await fetch(src);
  const fileData = await response.arrayBuffer();
  const fileBuffer = Buffer.from(fileData);

  return uploadWearableImage(fileBuffer);
};

export type EditableWearableFields = Pick<
  Wearable,
  "name" | "type" | "subType" | "style" | "isDefault"
>;

/**
 * API request to bulk patch wearables using a record
 * @param data - record consisted of id and data to be updated
 * @returns Record<string, Wearable> Promise
 */
export const patchWearables = async (data: Record<string, EditableWearableFields>) => {
  const response = HttpV2Client.patch<Record<string, Wearable>>(HttpV2Paths.AdminWearables, {
    params: { body: data },
    auth: true,
  });
  if (isNil(response)) throw new Error("No response from endpoint");

  return response;
};

/**
 * API request to delete a wearable
 * @param wearableId - ID of wearable being deleted
 * @returns void Promise
 */
export const deleteWearable = async (wearableId: string): Promise<void> => {
  const authToken = await authTokenManager.waitForToken();

  try {
    return axios.delete(`${ORIGIN}/api/v2/admin/wearables/${wearableId}`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
  } catch (e) {
    let message = "Failed to delete wearable.";

    if (e instanceof Error) {
      message = e.message;
    }

    return Promise.reject(message);
  }
};

export type EditableWearablePartFields = Partial<Pick<WearablePart, "spritesheetUrl" | "layerId">>;

/**
 * API request to update a wearable
 * @param wearablePartId - ID of wearable part to be patched
 * @param data - data to be patched
 * @returns void Promise
 */
export const patchWearablePart = async (
  wearablePartId: string,
  data: EditableWearablePartFields,
): Promise<void> => {
  try {
    return HttpV2Client.patch(HttpV2Paths.AdminWearablePart, {
      params: {
        body: data,
        path: { wearablePart: wearablePartId },
      },
      auth: true,
    });
  } catch (e) {
    let message = "Failed to patch wearable part.";

    if (e instanceof Error) {
      message = e.message;
    }

    return Promise.reject(message);
  }
};
