import React, {
  ChangeEvent,
  FC,
  KeyboardEvent,
  MouseEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import Tab from "@mui/material/Tab";
import TablePagination from "@mui/material/TablePagination";
import Tabs from "@mui/material/Tabs";
import PlusIcon from "@mui/icons-material/Add";

import SearchIdInput from "components/inputs/SearchIdInput";
import SortSelect from "components/inputs/SortSelect";
import PageContainer from "components/layout/dashboard/PageContainer";
import {
  applyActiveFilter,
  applyCancelledFilter,
  applyPastFilter,
  filterOptions,
  sortOptions,
} from "features/reservations/utils";
import { useSpaceReservations } from "features/spaces/hooks";
import { applyPagination, applySort } from "features/tables/utils";
import useSearchParams from "features/useSearchParams";
import { extractSpaceIDFromUserInput } from "utils/handleSpaceID";
import ReservationsTable from "./partials/ReservationsTable";
import NewReservationForm from "pages/dashboard/reservations/partials/NewReservationForm";

import { AdminReservation } from "gather-admin-common/dist/src/public/reservations/types";

const PAGE_TITLE = "Manage Reservations";

const ReservationsIndexPage: FC = () => {
  const navigate = useNavigate();
  const queryParam = useSearchParams();
  const initialSpace = decodeURIComponent(queryParam.get("space") ?? "");
  const [spaceId, setSpaceId] = useState<string>(initialSpace);
  const [spaceIdInputValue, setSpaceIdInputValue] = useState<string>(initialSpace);

  const { data, isLoading } = useSpaceReservations(spaceId);
  const allRes = useMemo(() => data || [], [data]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [sort, setSort] = useState("startDate|desc"); // Start Date (desc) will be the default option
  const [currentTab, setCurrentTab] = useState("all");
  const [currentPage, setCurrentPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const filteredReservations: AdminReservation[] = useMemo(() => {
    switch (currentTab) {
      case "isActive":
        return applyActiveFilter(allRes);
      case "isPastEvent":
        return applyPastFilter(allRes);
      case "isCancelled":
        return applyCancelledFilter(allRes);
      default:
        return allRes;
    }
  }, [allRes, currentTab]);

  const reservationsToDisplay: AdminReservation[] = useMemo(() => {
    const sortedReservations = applySort<AdminReservation>(filteredReservations, sort);
    return applyPagination<AdminReservation>(sortedReservations, currentPage, limit);
  }, [currentPage, limit, filteredReservations, sort]);

  const handleSpaceIdChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSpaceIdInputValue(event.target.value);
    },
    [setSpaceIdInputValue],
  );

  const handlePageChange = useCallback(
    (_event: MouseEvent<HTMLButtonElement> | null, page: number) => {
      setCurrentPage(page);
    },
    [setCurrentPage],
  );

  const handleLimitChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setLimit(parseInt(event.target.value, 10));
      setCurrentPage(0);
    },
    [setLimit, setCurrentPage],
  );

  const handleSortChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSort(event.target.value);
      setCurrentPage(0);
    },
    [setSort, setCurrentPage],
  );

  const handleTabsChange = useCallback(
    // Lint warning auto-ignored when enabling the no-explicit-any rule. Fix this the next time this code is edited! TODO: @ENG-4294 Clean these up! See the linear task for guidance on how to do so.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (_event: SyntheticEvent<Element>, value: any) => {
      setCurrentTab(value);
      setCurrentPage(0);
    },
    [setCurrentTab, setCurrentPage],
  );

  const handleSpaceIdKeyPress = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        const newSpaceId = extractSpaceIDFromUserInput(spaceIdInputValue);
        setSpaceId(newSpaceId);
      }
    },
    [setSpaceId, spaceIdInputValue],
  );

  const handleModalClose = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  const handleModalOpen = useCallback(() => {
    setModalOpen(true);
  }, [setModalOpen]);

  const updateQueryParam = useCallback(() => {
    const params = new URLSearchParams({ space: encodeURIComponent(spaceId) });
    navigate(`${window.location.pathname}${spaceId.length ? `?${params.toString()}` : ""}`, {
      replace: true,
    });
  }, [spaceId]);

  useEffect(() => {
    if (data) {
      updateQueryParam();
      setCurrentPage(0); // Reset to first page again
      setCurrentTab("all"); // Reset to the "All" tab
    }
  }, [updateQueryParam, data, setCurrentTab]);

  return (
    <PageContainer
      pageTitle={PAGE_TITLE}
      buttons={
        <Button
          color="primary"
          startIcon={<PlusIcon fontSize="small" />}
          variant="contained"
          onClick={() => handleModalOpen()}
        >
          Add Reservation
        </Button>
      }
    >
      <Box sx={{ minWidth: 1100 }}>
        <Card>
          <Tabs
            indicatorColor="primary"
            onChange={handleTabsChange}
            scrollButtons="auto"
            textColor="primary"
            value={currentTab}
            variant="scrollable"
          >
            {filterOptions.map((tab) => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
          <Divider />

          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexWrap: "wrap",
              m: -1,
              mt: 1,
              p: 2,
            }}
          >
            <SearchIdInput
              onKeyPress={handleSpaceIdKeyPress}
              onChange={handleSpaceIdChange}
              id={spaceIdInputValue}
              placeholder="Enter Space ID or URL here"
              label="Search for Space"
            />
            <SortSelect onChange={handleSortChange} value={sort} options={sortOptions} />
          </Box>

          {isLoading && <Box sx={{ p: 2 }}>Loading...</Box>}

          {!isLoading && data && (
            <>
              <ReservationsTable reservations={reservationsToDisplay} />
              <TablePagination
                component="div"
                count={filteredReservations.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={currentPage}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
              />
            </>
          )}
        </Card>
      </Box>

      <Dialog fullWidth maxWidth="sm" onClose={handleModalClose} open={modalOpen}>
        {modalOpen && <NewReservationForm spaceId={spaceId} onClose={handleModalClose} />}
      </Dialog>
    </PageContainer>
  );
};

export default ReservationsIndexPage;
