import { getWindowLocation } from "./browser";
import { getSpaceIdFromPath as getSpaceIdFromPathCommon } from "gather-common/dist/src/public/utils";
import { Logger } from "./Logger";
import { matchPath } from "react-router-dom";

export function getInStudio(): boolean {
  const pathToParse = decodeURIComponent(getWindowLocation().pathname);

  const studioPattern = "/app/:room/:name/studio";

  const match = matchPath(
    {
      path: studioPattern,
      end: true,
      caseSensitive: true,
    },
    pathToParse,
  );

  return !!match;
}

export function getInStudioMainApp(): boolean {
  const pathToParse = decodeURIComponent(getWindowLocation().pathname);

  const studioPattern = "/app/:room/:name/studioMainApp";

  const match = matchPath(
    {
      path: studioPattern,
      end: true,
      caseSensitive: true,
    },
    pathToParse,
  );

  return !!match;
}

export function getSpaceIdFromPath(path?: string): string | null {
  const pathToParse = path || decodeURIComponent(getWindowLocation().pathname);

  return getSpaceIdFromPathCommon(pathToParse);
}

// Use for convenience from callsites where there should always be a room to return.
export function getAndAssertSpaceIdFromPath(): string {
  const spaceId = getSpaceIdFromPath();
  if (spaceId === null) {
    console.error("getAndAssertSpaceIdFromPath failed to find the spaceId!");
    return "";
  }
  return spaceId;
}

export const arrayJoinWithLast = (arr: string[], regularSeparator: string, lastSeparator: string) =>
  arr.slice(0, -1).join(regularSeparator) + (arr.length > 1 ? lastSeparator : "") + arr.slice(-1);

export const loadFont = (fontName: string, fontPath: string) => {
  const newFont = new FontFace(fontName, `url(${fontPath})`);
  newFont
    .load()
    .then((loadedFont) => {
      document.fonts.add(loadedFont);
    })
    .catch((e) => {
      Logger.error("Loading of font " + fontName + " located at " + fontPath + " failed.", e);
    });
};

// These methods are only here so that they can be mocked for tests due to scoping/resolving issues around singletons
// that use the above methods on construction. The actual implementation for these methods for testing
// is in the __mocks__/utils.ts
// See thread: https://gather-town.slack.com/archives/C01JZE3D5AA/p1697559958287459?thread_ts=1697555504.817039&cid=C01JZE3D5AA
export const simulateFocusChangeForTest = (_focused: boolean) => {
  throw new Error("simulateFocusChange should not be called outside of tests");
};

export const simulateVisibilityChangeForTest = (_status: DocumentVisibilityState) => {
  throw new Error("simulateVisibilityChange should not be called outside of tests");
};
