import { axios } from "gather-common-including-video/dist/src/public/axios";
import { authTokenManager } from "gather-auth-client/dist/src/public/auth";
const ORIGIN = process.env.REACT_APP_API_BASE_PATH;

export const copySpaceMaps: (sourceSpace: string, targetSpace: string) => Promise<void> = async (
  sourceSpace,
  targetSpace,
) => {
  const authToken = await authTokenManager.waitForToken();

  try {
    await axios.post(
      `${ORIGIN}/api/v2/admin/spaces/${encodeURIComponent(
        sourceSpace,
      )}/copy-maps/${encodeURIComponent(targetSpace)}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      },
    );

    return;
  } catch (error) {
    const message =
      (axios.isAxiosError(error) && error.response?.data?.message) ?? "Failed to copy space maps.";
    throw new Error(message);
  }
};
