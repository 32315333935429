import React, { createContext, useEffect, useState } from "react";
import { THEMES } from "theme/types";
import { Settings } from "features/settings/types";

const initialSettings = {
  responsiveFontSizes: true,
  roundedCorners: true,
  theme: THEMES.LIGHT,
};

export const restoreSettings = () => {
  let settings = null;

  try {
    const storedData = window.localStorage.getItem("settings");

    if (storedData) {
      settings = JSON.parse(storedData);
    } else {
      settings = {
        responsiveFontSizes: true,
        roundedCorners: true,
        theme: window.matchMedia("(prefers-color-scheme: dark)").matches
          ? THEMES.DARK
          : THEMES.LIGHT,
      };
    }
  } catch (err) {
    console.error(err);
    // If stored data is not a strigified JSON this will fail,
    // that's why we catch the error
  }

  return settings;
};

// Lint warning auto-ignored when enabling the no-explicit-any rule. Fix this the next time this code is edited! TODO: @ENG-4294 Clean these up! See the linear task for guidance on how to do so.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const storeSettings = (settings: any) => {
  window.localStorage.setItem("settings", JSON.stringify(settings));
};

type SettingsContextType = {
  settings: Settings;
  saveSettings: (value: Settings) => void;
};

const SettingsContext = createContext<SettingsContextType>({
  settings: initialSettings,
  saveSettings: () => {},
});

interface Props {
  children: string | React.ReactNode;
}

export const SettingsProvider: React.FC<Props> = ({ children }) => {
  const [settings, setSettings] = useState(initialSettings);

  useEffect(() => {
    const restoredSettings = restoreSettings();

    if (restoredSettings) {
      setSettings(restoredSettings);
    }
  }, []);

  // Lint warning auto-ignored when enabling the no-explicit-any rule. Fix this the next time this code is edited! TODO: @ENG-4294 Clean these up! See the linear task for guidance on how to do so.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const saveSettings = (updatedSettings: any = {}) => {
    setSettings(updatedSettings);
    storeSettings(updatedSettings);
  };

  return (
    <SettingsContext.Provider
      value={{
        settings,
        saveSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
