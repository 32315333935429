import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  Typography,
  Dialog,
} from "@mui/material";
import TabWrapper from "components/dashboard/TabWrapper";
import { sendPushNotification } from "features/pushNotifications/api";
import React, { FC, useCallback, useState } from "react";
import { UserDevice } from "gather-prisma-types/dist/src/public/client";
import SendPushNotificationForm from "./SendPushNotificationForm";
import { toast } from "react-hot-toast";
import { useUserDevices } from "features/pushNotifications/hooks";

interface RowProp {
  userDevice: UserDevice;
}

const DeviceRow: FC<RowProp> = ({ userDevice }) => (
  <TableRow>
    <TableCell>
      <Typography color="textPrimary" variant="body2">
        {userDevice.deviceToken}
      </Typography>
    </TableCell>
    <TableCell>
      <Typography color="textPrimary" variant="body2">
        {userDevice.deviceType ?? "Unknown"}
      </Typography>
    </TableCell>
    <TableCell>
      <Typography color="textPrimary" variant="body2">
        {userDevice.createdAt.toLocaleString()}
      </Typography>
    </TableCell>
    <TableCell>
      <Typography color="textPrimary" variant="body2">
        {userDevice.updatedAt.toLocaleString()}
      </Typography>
    </TableCell>
  </TableRow>
);

type TabProps = {
  userId: string;
};

const UserDeviceTokensTab: FC<TabProps> = ({ userId }: TabProps) => {
  const { data: devices, isLoading } = useUserDevices(userId);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const handlePushNotification = useCallback(
    async (args: { title: string; body: string }) => {
      try {
        await sendPushNotification(userId, args);
        toast.success("Sent push notifications successfully");
      } catch (e) {
        toast.error("Failed to send push notifications");
      } finally {
        setIsConfirmationModalOpen(false);
      }
    },
    [devices],
  );
  return (
    <>
      <TabWrapper>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 4 }}>
          <Box sx={{ width: "100%" }}>
            <Button
              sx={{ mt: 1, mb: 2 }}
              size="large"
              variant="contained"
              onClick={() => {
                setIsConfirmationModalOpen(true);
              }}
            >
              Send test push notification
            </Button>
            <Card>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Device Token</TableCell>
                    <TableCell>Device Type</TableCell>
                    <TableCell>Created At</TableCell>
                    <TableCell>Updated At</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {isLoading ? (
                    <TableRow>
                      <TableCell colSpan={4} style={{ textAlign: "center" }}>
                        Loading...
                      </TableCell>
                    </TableRow>
                  ) : devices ? (
                    devices.map((device) => (
                      <DeviceRow key={device.deviceToken} userDevice={device} />
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4} style={{ textAlign: "center" }}>
                        No devices registered yet
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Card>
          </Box>
        </Box>
      </TabWrapper>
      <Dialog fullWidth maxWidth="sm" open={isConfirmationModalOpen}>
        <SendPushNotificationForm
          onCancel={() => setIsConfirmationModalOpen(false)}
          onSubmit={handlePushNotification}
        />
      </Dialog>
    </>
  );
};

export default ({ userId }: { userId?: string }) => {
  if (userId) return <UserDeviceTokensTab userId={userId} />;

  return null;
};
