import { GatedFeature } from "./GatedFeature";
import { switchEnv } from "gather-env-config/dist/src/public/env";

export type CustomCheckResult = { matched: false } | { matched: true; result: boolean };

// hash function from https://github.com/darkskyapp/string-hash
const MAX_HASH = 4294967295;
function hash(str: string): number {
  let hash = 5381,
    i = str.length;

  while (i) {
    hash = (hash * 33) ^ str.charCodeAt(--i);
  }

  /* JavaScript does bitwise operations (like XOR, above) on 32-bit signed
   * integers. Since we want the results to be always positive, convert the
   * signed int to an unsigned by doing an unsigned bitshift. */
  return hash >>> 0;
}

type GatedFeatName = string;

export const isBlacklisted = (
  feature: GatedFeature,
  { userId, spaceId }: { userId: string | null; spaceId: string | null },
) =>
  (userId && feature.blacklist?.includes(userId)) ||
  (spaceId && feature.blacklist?.includes(spaceId));

export const isWhitelisted = (
  feature: GatedFeature,
  { userId, spaceId }: { userId: string | null; spaceId: string | null },
) =>
  (userId && feature.whitelist?.includes(userId)) ||
  (spaceId && feature.whitelist?.includes(spaceId));

export const clusterCheck = (
  feature: GatedFeature,
  { clusterDomain }: { clusterDomain?: string },
) => clusterDomain && (feature?.exposureByCluster?.[clusterDomain] || 0) > Math.random();

export const determineGateValueShared = (
  featName: GatedFeatName,
  feature: GatedFeature,
  {
    userId,
    spaceId,
    clusterDomain,
    spaceCreationDate,
  }: {
    userId: string | null;
    spaceId: string | null;
    spaceCreationDate?: Date;
    clusterDomain?: string;
  },
  customChecks?: () => CustomCheckResult,
): boolean => {
  // check blacklist, then whitelist
  if (isBlacklisted(feature, { userId, spaceId })) return false;

  if (isWhitelisted(feature, { userId, spaceId })) return true;

  if (clusterCheck(feature, { clusterDomain })) return true;

  if (
    feature.onlyEnableIfSpaceCreatedAfterDate &&
    (!spaceCreationDate || feature.onlyEnableIfSpaceCreatedAfterDate > spaceCreationDate)
  ) {
    return false;
  }

  if (
    feature.onlyEnableIfSpaceCreatedBeforeDate &&
    spaceCreationDate &&
    feature.onlyEnableIfSpaceCreatedBeforeDate <= spaceCreationDate
  ) {
    return false;
  }

  if (customChecks) {
    const checks = customChecks();
    if (checks.matched) return checks.result;
  }

  return determineGateValueFromSeedOrExposure(featName, feature, { userId, spaceId });
};

export const determineGateValueFromSeedOrExposure = (
  featName: GatedFeatName,
  feature: GatedFeature,
  { userId, spaceId }: { userId: string | null; spaceId: string | null },
): boolean => {
  if (feature.seed !== undefined) return determineGateValueWithSeed(feature, { userId, spaceId });

  return determineGateValueFromExposure(featName, feature.exposure, feature.space, {
    userId,
    spaceId,
  });
};

export const determineGateValueFromExposure = (
  name: string,
  exposure: number,
  isSpaceGate: boolean,
  { userId, spaceId }: { userId: string | null; spaceId: string | null },
) => {
  // the order of the string concat matters. The hash function biases towards the start
  // of the string, so if two featNames are similar (i.e. Feature_1 and Feature_2), the
  // distribution is no longer uniform. Put the unique id first
  const objHash = isSpaceGate
    ? spaceId
      ? hash(spaceId + name)
      : null
    : userId
    ? hash(userId + name)
    : null;
  if (objHash === null) return false;

  return objHash < MAX_HASH * exposure;
};

export const determineGateVariantIndex = (
  name: string,
  numberOfVariants: number,
  // Either Space ID or User ID depending on the type, used to determine gate
  uniqueGatePropId: string,
) => {
  // the order of the string concat matters. The hash function biases towards the start
  // of the string, so if two featNames are similar (i.e. Feature_1 and Feature_2), the
  // distribution is no longer uniform. Put the unique id first
  const objHash = hash(`${uniqueGatePropId}${name}`);

  if (objHash === MAX_HASH) return numberOfVariants - 1;

  return Math.floor((objHash / MAX_HASH) * numberOfVariants);
};

// DO NOT USE -- this is used temporarily to keep the livekit gate population stable
// This will be removed once livekit rolls out to 100%
export const determineGateValueWithSeed = (
  feature: GatedFeature,
  { userId, spaceId }: { userId: string | null; spaceId: string | null },
): boolean => {
  const objHash = feature.space ? (spaceId ? hash(spaceId) : null) : userId ? hash(userId) : null;
  if (objHash === null) return false;

  // @ts-expect-error feature.seed is added later
  const selection = feature.exposure * feature.seed;
  const quot = Math.floor(selection);
  const remain = selection - quot;
  if (feature.seed !== undefined) {
    return (
      objHash % feature.seed < quot ||
      (objHash % feature.seed === quot && objHash < MAX_HASH * remain)
    );
  } else {
    return false;
  }
};

export function getFeature<GatedFeatName extends string | number | symbol>(
  FEATURES: Record<GatedFeatName, GatedFeature>,
  featureName: GatedFeatName,
) {
  const feature = FEATURES[featureName];
  return feature;
}

export const getForceSetGateForTestingEnv = (exposure: number) =>
  switchEnv({
    prod: () => undefined,
    staging: () => undefined,
    dev: () => undefined,
    local: () => undefined,
    test: () => exposure === 1,
  });
