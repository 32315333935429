import { enumToHuman } from "../ts-utils";
import {
  GatherEventAttendanceNumberPrisma,
  GatherEventCategory,
  GatherEventPrisma,
  GatherEventProfessionalCategory,
  GatherEventMessagePrisma,
  GatherEventMessageReplyPrisma,
} from "gather-prisma-types/dist/src/public/client";

export enum GatherEventAccessRole {
  Owner = "Owner",
  Editor = "Editor",
}

export enum GatherEventAccessRoleEnumMigration {
  owner = "Owner",
  editor = "Editor",
}

export type GatherEventAccessRoles = { [role in GatherEventAccessRole]?: boolean };

// GatherEventAccess (no 's' in Event)
// is used for the shape of the data store in the db
export type GatherEventAccess = {
  [id: string]: GatherEventAccessRoles;
};

// GatherEventsAccess (with an 's' in Events)
// is used for the shape of the data returned from the db to the client
export type GatherEventsAccess = {
  email: string;
  owner?: boolean;
  editor?: boolean;
};

export enum GatherEventCategoryMigration {
  "Professional or business" = "ProfessionalOrBusiness",
  "Community or organization" = "CommunityOrOrganization",
  "Education or academia" = "EducationOrAcademia",
  "Friends or family" = "FriendsOrFamily",
  "Other" = "Other",
}

const DEPRECATED_GATHER_EVENT_CATEGORIES: GatherEventCategory[] = [
  GatherEventCategory.FriendsOrFamily,
];
export const GATHER_EVENT_CATEGORIES = Object.values(GatherEventCategory).filter(
  (x) => !DEPRECATED_GATHER_EVENT_CATEGORIES.includes(x),
);

export const gatherEventCategoryToHuman = enumToHuman(GatherEventCategory, {
  ProfessionalOrBusiness: "Professional or business",
  CommunityOrOrganization: "Community or organization",
  EducationOrAcademia: "Education or academia",
  FriendsOrFamily: "Friends or family", // deprecated
  Other: "Other",
});

export enum GatherEventAttendanceNumberMigration {
  "0-25" = "num_0_to_25",
  "25-50" = "num_25_to_50",
  "50-100" = "num_50_to_100",
  "100-200" = "num_100_to_200",
  "200+" = "num_200_orMore",
  // legacy values
  "26-100" = "num_26_to_100Legacy",
  "101+" = "num_101_orMoreLegacy",
}

export const gatherEventAttendanceNumberToHuman = enumToHuman(GatherEventAttendanceNumberPrisma, {
  num_0_to_25: "0-25",
  num_25_to_50: "25-50",
  num_50_to_100: "50-100",
  num_100_to_200: "100-200",
  num_200_orMore: "200+",
  num_26_to_100Legacy: "26-100",
  num_101_orMoreLegacy: "101+",
});

const HIDDEN_PROFESSIONAL_CATEGORIES: GatherEventProfessionalCategory[] = [
  GatherEventProfessionalCategory.NA,
];
export const GATHER_EVENT_PROFESSIONAL_CATEGORIES = Object.values(
  GatherEventProfessionalCategory,
).filter((x) => !HIDDEN_PROFESSIONAL_CATEGORIES.includes(x));

export enum GatherEventProfessionalCategoryOptionMigration {
  Internal = "internal",
  External = "external",
  "N/A" = "NA",
}

export const gatherEventProfessionalCategoryOptionToHuman = enumToHuman(
  GatherEventProfessionalCategory,
  {
    internal: "Internal: Attendees are in my team or organization.",
    external: "External: Attendees are outside of my team or organization.",
    NA: "N/A",
  },
);

export type GatherEvent = Omit<
  GatherEventPrisma,
  "startDate" | "endDate" | "createdAt" | "updatedAt"
> & {
  startDate: string;
  endDate: string;
  createdAt: string;
  updatedAt: string;
};

export type GatherEventSpeaker = {
  id: string;
  name: string;
  email: string;
  title: string;
  bio: string;
};

export type GatherEventLocation = {
  id: string;
  roomId: string;
  name: string;
  x: number;
  y: number;
  pathwayId?: string;
};

export type GatherEventSession = {
  id: string;
  title: string;
  description?: string;
  start: string;
  end: string;
  speakers: string[];
  locationId: string;
  roomId: string;
  x: number;
  y: number;
  closed: boolean;
  customizeRoomEnabled: boolean;
  chatEnabled: boolean;
  qaEnabled: boolean;
  massMuteEnabled: boolean;
  approveQuestionsEnabled: boolean;
  objectId: string;
  eventId: string;
};

export type GatherEventSessionData = Omit<GatherEventSession, "speakers" | "locationId"> & {
  speakers: GatherEventSpeaker[];
  location?: GatherEventLocation;
};

export enum GatherEventMessageRecipientMigration {
  "QA" = "QA",
  "ROOM_CHAT" = "RoomChat",
}

export enum GatherEventMessageRecipientBackwardsMigration {
  "QA" = "QA",
  "RoomChat" = "ROOM_CHAT",
}

export enum GatherEventMessageRecipientLegacy {
  QA = "QA",
  ROOM_CHAT = "ROOM_CHAT",
}

export type GatherEventMessageReply = Omit<
  GatherEventMessageReplyPrisma,
  "id" | "updatedAt" | "createdAt" | "gatherEventMessageId"
>;

export type GatherEventMessageLegacy = Omit<
  GatherEventMessagePrisma,
  "recipient" | "updatedAt" | "createdAt" | "likedUsers" | "answeredLive" | "mapId"
> & {
  recipient: GatherEventMessageRecipientLegacy;
  unixTime: number;
  likes: number;
  liked: boolean;
  replied: boolean;
  roomId: string;
  reply?: GatherEventMessageReply | null;
};

export enum GatherEventRoomType {
  Talk = "Talk",
  Breakout = "Breakout",
  Booth = "Booth",
  PosterSessions = "PosterSessions",
  Lounge = "Lounge",
  Game = "Game",
}
