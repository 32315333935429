import { Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React, { FC } from "react";
import ReservationRow from "./ReservationRow";
import { AdminReservation } from "gather-admin-common/dist/src/public/reservations/types";

interface Props {
  reservations: AdminReservation[];
  showReserver?: boolean;
}

const ReservationsTable: FC<Props> = ({ reservations, showReserver = true }) => (
  <Box sx={{ minWidth: "100%" }}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Reservation ID</TableCell>
          <TableCell>Start Date</TableCell>
          <TableCell>End Date</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Capacity</TableCell>
          {showReserver && <TableCell>Reserver ID</TableCell>}
        </TableRow>
      </TableHead>

      <TableBody>
        {reservations.map((data: AdminReservation) => (
          <ReservationRow key={data.id} reservation={data} showReserver={showReserver} />
        ))}
      </TableBody>
    </Table>
  </Box>
);

export default ReservationsTable;
