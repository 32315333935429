import React, { FC, FocusEvent } from "react";

/* MUI Components */
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";

interface Props {
  name: string;
  value: string[];
  defaultValue?: string[];
  options?: string[];
  error?: boolean;
  helperText?: React.ReactNode;
  placeholder?: string;
  label?: string;
  onChange: (newValue: string[]) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
}

const TagField: FC<Props> = ({
  value,
  defaultValue = [],
  options = [],
  name,
  error,
  helperText,
  placeholder = "Add a tag by pressing enter",
  label = "Tags",
  onChange,
  onBlur,
}) => (
  <Autocomplete
    fullWidth
    multiple
    freeSolo
    onChange={(_event, newValue) => onChange(newValue)}
    value={value}
    options={options}
    defaultValue={defaultValue}
    renderTags={(
      value: string[],
      getTagProps: (arg0: { index: number }) => JSX.IntrinsicAttributes,
    ) =>
      value.map((option, index) => (
        <Chip key={index} variant="outlined" label={option} {...getTagProps({ index })} />
      ))
    }
    renderInput={(params) => (
      <TextField
        {...params}
        label={label}
        placeholder={placeholder}
        name={name}
        error={error}
        helperText={helperText}
        variant="outlined"
        onBlur={onBlur}
      />
    )}
  />
);

export default TagField;
