/* External modules */
import React, {
  FC,
  useState,
  useCallback,
  ChangeEvent,
  MouseEvent,
  useEffect,
  useMemo,
} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { ClickAwayListener, TextFieldProps } from "@mui/material";
import { ChromePicker, ColorResult } from "react-color";

export type ConverterType = "rgba" | "rgb" | "hex" | "rgba_rgb" | "rgba_hex";
export const DEFAULT_CONVERTER: ConverterType = "rgba_hex";

export const converters: { [key in ConverterType]: (c: ColorResult) => string } = {
  rgba: (c) => `rgba(${c.rgb.r}, ${c.rgb.g}, ${c.rgb.b}, ${c.rgb.a})`,
  rgb: (c) => `rgb(${c.rgb.r}, ${c.rgb.g}, ${c.rgb.b})`,
  hex: (c) => c.hex,

  rgba_rgb: (c) => (c.rgb.a === 1 ? converters.rgb(c) : converters.rgba(c)),
  rgba_hex: (c) => (c.rgb.a === 1 ? converters.hex(c) : converters.rgba(c)),
};

interface PickerDialogProps {
  value?: string;
  onChange?: (r: ColorResult) => void;
  onClick: (e: MouseEvent<HTMLDivElement>) => void;
}

const PickerDialog: FC<PickerDialogProps> = ({ value, onClick, onChange }) => (
  <Box sx={{ position: "absolute", zIndex: (theme) => theme.zIndex.tooltip }}>
    <Box
      sx={{ position: "fixed", top: "0px", right: "transformX(-50%)", bottom: "0px", left: "50%" }}
      onClick={onClick}
    />
    <ChromePicker color={value} onChange={onChange} />
  </Box>
);

export interface ColorPickerProps {
  convert?: ConverterType;
  name?: string;
  id?: string;
  defaultValue?: string;
  value?: string;
  hintText?: string;
  placeholder?: string;
  label?: string;
  floatingLabelText?: string;
  onChange: (color: string) => void;
}

const ColorPicker: FC<Omit<TextFieldProps, "onChange"> & ColorPickerProps> = ({
  // ColorPicker
  onChange,
  // onBlur,
  convert = DEFAULT_CONVERTER,

  // Text field
  name,
  id,
  hintText,
  placeholder,
  floatingLabelText,
  label,
  value,
  defaultValue = "#000",

  ...otherFields
}) => {
  const [showPicker, setShowPicker] = useState<boolean>(false);
  const defaultColor = useMemo(() => value ?? defaultValue, [value, defaultValue]);
  const [color, setColor] = useState<string>(defaultColor);

  const handleShowPicker = useCallback(() => setShowPicker(true), [setShowPicker]);
  const handleHidePicker = useCallback(() => setShowPicker(false), [setShowPicker]);

  useEffect(() => {
    setColor(defaultColor);
  }, [value, setColor, defaultColor]);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setColor(event.target.value);
      onChange(event.target.value);
    },
    [setColor, onChange],
  );

  const handlePickerClick = useCallback(() => {
    handleHidePicker();
    onChange(color);
  }, [handleHidePicker, onChange, color]);

  const handlePickerChange = useCallback(
    (c: ColorResult) => {
      const newColor = converters[convert](c);
      setColor(newColor);
      onChange(newColor);
    },
    [setColor, onChange, convert],
  );

  const handleBlur = useCallback(() => {
    handleHidePicker();
  }, [handleHidePicker]);

  return (
    <ClickAwayListener onClickAway={handleBlur}>
      <Box sx={{ position: "relative" }}>
        <TextField
          name={name}
          id={id}
          label={floatingLabelText || label}
          value={color}
          placeholder={hintText || placeholder}
          onClick={handleShowPicker}
          onChange={handleInputChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Box
                  sx={{
                    background: color,
                    border: "solid 1px",
                    borderColor: (theme) => theme.palette.divider,
                    width: 25,
                    height: 25,
                  }}
                />
              </InputAdornment>
            ),
          }}
          {...otherFields}
        />

        {showPicker && (
          <PickerDialog value={color} onClick={handlePickerClick} onChange={handlePickerChange} />
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default ColorPicker;
