import React, { FC, useState, SyntheticEvent } from "react";
// eslint-disable-next-line @gathertown/cross-module-import-paths -- Error autoignored while enabling eslint for gather-admin. If you're already touching this code, please clean this up while you're at it.
import { AdminSpaceMap } from "gather-admin-common/dist/src/spaces/types";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { nord as dark } from "react-syntax-highlighter/dist/esm/styles/prism";
import stringify from "json-stable-stringify";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import SlideTransition from "@mui/material/Slide";

interface Props {
  open: boolean;
  map?: AdminSpaceMap;
  onClose: () => void;
}

const MapJsonModal: FC<Props> = ({ open, onClose, map }) => {
  const [currentTab, setCurrentTab] = useState<keyof AdminSpaceMap | "all">("all");
  const sortedTabs = (map && Object.keys(map).sort()) || [];

  const handleChange = (_event: SyntheticEvent, newValue: keyof AdminSpaceMap | "all") => {
    setCurrentTab(newValue);
  };

  return (
    <Dialog
      fullScreen
      onClose={onClose}
      open={open}
      TransitionComponent={SlideTransition}
      PaperProps={{
        sx: {
          backgroundColor: "background.default",
        },
      }}
    >
      {open && map && (
        <>
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Map JSON
              </Typography>
            </Toolbar>
          </AppBar>

          <Box
            sx={{
              p: 3,
              backgroundColor: "background.default",
              height: "100%",
              width: "100%",
              minHeight: "calc(100% - 64px)",
              display: "flex",
            }}
          >
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={currentTab}
              onChange={handleChange}
              sx={{
                borderRight: 1,
                borderColor: "divider",
                flexGrow: 0,
                flexShrink: 0,
                flexBasis: "250px",
              }}
            >
              <Tab label="All" value="all" />
              {sortedTabs.map((tab) => (
                <Tab label={tab} value={tab} key={tab} />
              ))}
            </Tabs>

            <Box
              sx={{
                pr: 3,
                pl: 3,
                maxHeight: "calc(100%)",
                overflowY: "auto",
              }}
            >
              <SyntaxHighlighter
                language="json"
                style={dark}
                wrapLines={true}
                lineProps={{ style: { wordBreak: "break-all", whiteSpace: "pre-wrap" } }}
              >
                {map
                  ? currentTab === "all"
                    ? stringify(map, { space: "  " })
                    : typeof map[currentTab] === "object"
                    ? stringify(map[currentTab], { space: "  " })
                    : map[currentTab] === null
                    ? stringify("null")
                    : map[currentTab] === false
                    ? stringify("false")
                    : map[currentTab] === true
                    ? stringify("true")
                    : map[currentTab]
                  : "null"}
              </SyntaxHighlighter>
            </Box>
          </Box>
        </>
      )}
    </Dialog>
  );
};

export default MapJsonModal;
