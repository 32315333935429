import DotsIcon from "@mui/icons-material/MoreHoriz";
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Typography,
} from "@mui/material";
import * as ObjectTemplateCategoryGroupAPI from "features/objectTemplateCategories/api";
import { objectTemplateCategoryGroupMutation } from "features/objectTemplateCategories/mutations";
import React, { forwardRef, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import { ObjectTemplateCategoryGroup } from "gather-common/dist/src/public/resources/objectTemplates";

interface Props {
  category: string;
  dragging: boolean;
  index: number;
  group: ObjectTemplateCategoryGroup;
}

const StyledCardContent = styled(CardContent)(`
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 12px;
  padding-bottom: 8px;
  &:last-child {
    padding-bottom: 10px;
  }
`);

const StyledCard = styled(Card)(`
  padding: 0;
  padding-top: 0;
  cursor: pointer;
`);

const Category = forwardRef<HTMLDivElement, Props>(
  ({ category, group, index, dragging, ...other }, ref) => {
    const [openMenu, setOpenMenu] = useState(false);
    const queryClient = useQueryClient();
    const moreRef = useRef(null);
    const mutation = useMutation(
      (categoryGroup: ObjectTemplateCategoryGroup) =>
        ObjectTemplateCategoryGroupAPI.modify(categoryGroup),
      objectTemplateCategoryGroupMutation(queryClient),
    );

    const handleMenuOpen = () => {
      setOpenMenu(true);
    };

    const handleMenuClose = () => {
      setOpenMenu(false);
    };

    const removeCategory = () => {
      group.categories.splice(index, 1); // Removes from source array
      mutation.mutate(group);
    };

    const handleDelete = async () => {
      // TODO @alecia: going to replace with a nicer looking confirmation dialog from mui soon
      const shouldDelete = window.confirm("Are you sure you want to delete this category?"); // nosemgrep: javascript.lang.best-practice.leftover_debugging.javascript-confirm

      if (shouldDelete) {
        try {
          setOpenMenu(false);
          removeCategory();
        } catch (err) {
          console.error(err);
          toast.error("Something went wrong!");
        }
      } else {
        setOpenMenu(false);
      }
    };

    return (
      <Box
        ref={ref}
        sx={{
          outline: "none",
          py: 1,
        }}
        {...other}
      >
        <StyledCard
          raised={dragging}
          sx={{
            ...(dragging && {
              backgroundColor: "background.paper",
            }),
            "&:hover": {
              backgroundColor: "background.default",
            },
          }}
          variant={dragging ? "elevation" : "outlined"}
        >
          <StyledCardContent
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography color="textPrimary" variant="subtitle1" gutterBottom={false}>
              {category}
            </Typography>

            <IconButton color="inherit" edge="end" onClick={handleMenuOpen} ref={moreRef}>
              <DotsIcon fontSize="small" />
            </IconButton>
          </StyledCardContent>
        </StyledCard>

        <Menu
          anchorEl={moreRef.current}
          anchorOrigin={{
            horizontal: "center",
            vertical: "bottom",
          }}
          keepMounted
          onClose={handleMenuClose}
          open={openMenu}
        >
          <MenuItem onClick={handleDelete}>Delete</MenuItem>
        </Menu>
      </Box>
    );
  },
);

export default Category;
