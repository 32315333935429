import axios, { AxiosError } from "axios";
import type {
  AxiosAdapter as AxiosAdapterLib,
  AxiosError as AxiosErrorLib,
  AxiosRequestConfig as AxiosRequestConfigLib,
  AxiosResponse as AxiosResponseLib,
  CancelTokenSource as CancelTokenSourceLib,
} from "axios";
import { StatusCodes } from "./http";

export { axios };
export type {
  AxiosAdapterLib as AxiosAdapter,
  AxiosErrorLib as AxiosError,
  AxiosRequestConfigLib as AxiosRequestConfig,
  AxiosResponseLib as AxiosResponse,
  CancelTokenSourceLib as CancelTokenSource,
};

export const hasAPIErrorCode = <T>(error: AxiosError | Error | unknown, errorCode: T) =>
  axios.isAxiosError(error) && error.response?.data?.code === errorCode;

export const hasAPIStatusCode = (error: AxiosError | Error | unknown, statusCode: StatusCodes) =>
  axios.isAxiosError(error) && error.response?.status === statusCode;
