/* External modules */
import React, { FC } from "react";
import { FormikProps } from "formik";

/* Material UI */
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

/* Local Modules */
import TagField from "components/inputs/TagField";
import { ObjectTemplateFields } from "features/objectTemplates/types";

const GeneralFields: FC<FormikProps<ObjectTemplateFields>> = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
}) => (
  <Box sx={{ maxWidth: 600 }}>
    <Box
      sx={{
        display: "flex",
        gap: 3,
        justifyContent: "flex-start",
        flexWrap: "wrap",
      }}
    >
      <Typography variant="subtitle1">General Details</Typography>

      <TextField
        fullWidth
        error={Boolean(touched.name && errors.name)}
        helperText={touched.name && errors.name}
        label="Name"
        name="name"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.name}
        variant="outlined"
      />

      <TextField
        fullWidth
        multiline
        rows={2}
        error={Boolean(touched.desc && errors.desc)}
        helperText={touched.desc && errors.desc}
        label="Description"
        name="desc"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.desc}
        variant="outlined"
      />

      <TagField
        value={values.tags}
        name="tags"
        error={Boolean(touched.tags && errors.tags)}
        helperText={touched.tags && errors.tags}
        onChange={(newValue) => setFieldValue("tags", newValue)}
        onBlur={handleBlur}
      />
    </Box>
  </Box>
);

export default GeneralFields;
