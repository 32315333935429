import React, { FC } from "react";
import ReactMarkdown from "markdown-to-jsx";

import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import LaunchIcon from "@mui/icons-material/Launch";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import TabWrapper from "components/dashboard/TabWrapper";
import DetailsCard from "components/dashboard/DetailsCard";
import TableCellTitle from "components/dashboard/TableCellTitle";
import { markdownOptions } from "components/dashboard/Markdown";
import BooleanLabel from "components/dashboard/BooleanLabel";
import { displayDate } from "features/reservations/utils";
import { applySort } from "features/tables/utils";
import { useSpace, useSpaceRecordingBudgetInfo } from "features/spaces/hooks";
import SpaceTrialExpiry from "./SpaceTrialExpiry";
import { DateTime } from "luxon";
import { Link as RouterLink } from "react-router-dom";
import { getAppBasePath } from "../../../../utils/basePath";
import { isNotNil } from "gather-common/dist/src/public/fpHelpers";

interface Props {
  spaceId?: string;
}

interface Announcement {
  id: string;
  message: string;
  createdAt: string;
  updatedAt: string;
}

const SpaceDetailsTab: FC<Props> = ({ spaceId }) => {
  const { data: space, isLoading } = useSpace(spaceId);
  const { data: meetingRecordingBudgetInfo } = useSpaceRecordingBudgetInfo(spaceId);
  const basePath = getAppBasePath();
  const roomHref = `${basePath}/app/${space?.id.replace("\\", "/")}`;
  const bannedAddresses: string[] = [];
  const pinnedAnnouncements: Announcement[] = [];
  const announcements: Announcement[] = [];
  const hasPassword = isNotNil(space?.password) && space.password.length > 0;
  const requiresEmailDomain =
    space?.settings.emailDomains !== undefined && space?.settings.emailDomains.length > 0;

  if (space?.bannedIPs) {
    Object.keys(space.bannedIPs).map((ip) => bannedAddresses.push(ip));
  }

  if (space?.settings.informationBoard && space?.settings.informationBoard.pinned) {
    const temp = applySort<Announcement>(space.settings.informationBoard.pinned, "createdAt|desc");
    temp.map((msg) => pinnedAnnouncements.push(msg));
  }

  if (space?.settings.informationBoard && space?.settings.informationBoard.announcements) {
    const temp = applySort<Announcement>(
      space?.settings.informationBoard.announcements,
      "createdAt|desc",
    );
    temp.map((msg) => announcements.push(msg));
  }

  return (
    <TabWrapper>
      {isLoading && <Box sx={{ p: 2 }}>Loading...</Box>}

      {!isLoading && space && (
        <>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 4 }}>
            <Box sx={{ width: "100%", maxWidth: 500 }}>
              <DetailsCard header="General Details" sx={{ mb: 4 }}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCellTitle>ID</TableCellTitle>
                      <TableCell>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <Typography color="textPrimary" variant="body2">
                            {space?.id}
                          </Typography>

                          <Tooltip title="Launch in Gather" placement="top" arrow>
                            <Link
                              color="textPrimary"
                              href={roomHref}
                              underline="none"
                              fontSize="small"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              <LaunchIcon
                                fontSize="small"
                                sx={{
                                  ml: 1,
                                  mb: -0.5,
                                }}
                              />
                            </Link>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCellTitle>Name</TableCellTitle>
                      <TableCell>
                        <Typography color="textPrimary" variant="body2">
                          {space?.name}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCellTitle>Map</TableCellTitle>
                      <TableCell>
                        <Link
                          color="textPrimary"
                          href={`/dashboard/mapmaker/inspector/${encodeURIComponent(
                            space?.id || "",
                          )}/maps/${encodeURIComponent(space?.map || "")}`}
                          underline="always"
                          fontSize="small"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {space?.map}
                        </Link>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCellTitle>Use Case</TableCellTitle>
                      <TableCell>
                        <Typography color="textPrimary" variant="body2">
                          {space?.reason}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCellTitle>Guest Check In Enabled?</TableCellTitle>
                      <TableCell>
                        <BooleanLabel condition={!!space.settings.guestCheckInEnabled} />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCellTitle>Has Guest List?</TableCellTitle>
                      <TableCell>
                        <BooleanLabel condition={!!space?.hasGuestList} />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCellTitle>Email Domain Access</TableCellTitle>
                      <TableCell>
                        <BooleanLabel condition={requiresEmailDomain} />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCellTitle>Has Password?</TableCellTitle>
                      <TableCell>
                        <BooleanLabel condition={hasPassword} />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCellTitle>Is Template?</TableCellTitle>
                      <TableCell>
                        <BooleanLabel condition={!!space?.isTemplate} />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCellTitle>Active Users</TableCellTitle>
                      <TableCell>
                        <Typography color="textPrimary" variant="body2">
                          {space?.roomCount ?? 0}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCellTitle>Creation Time</TableCellTitle>
                      <TableCell>
                        <Typography color="textPrimary" variant="body2">
                          {displayDate(space?.creationDate)}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCellTitle>Free Trial</TableCellTitle>
                      <TableCell>
                        <SpaceTrialExpiry
                          spaceId={space.id}
                          endsAt={
                            space?.trial?.endsAt ? DateTime.fromISO(space.trial.endsAt) : null
                          }
                        />
                      </TableCell>
                    </TableRow>

                    {space?.trial && (
                      <>
                        <TableRow>
                          <TableCellTitle>Free Trial Initiating User</TableCellTitle>
                          <TableCell>
                            <Typography color="textPrimary" variant="body2">
                              <Link
                                color="textPrimary"
                                component={RouterLink}
                                to={`/dashboard/users?user=${encodeURIComponent(
                                  space.trial.initializingUserId || "",
                                )}`}
                                underline="always"
                              >
                                {space.trial.initializingUserId}
                              </Link>
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                </Table>
              </DetailsCard>
            </Box>

            <Box sx={{ width: "100%", maxWidth: 500 }}>
              <DetailsCard header="Connection Info" sx={{ mb: 4 }}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCellTitle>Game Server URL</TableCellTitle>
                      <TableCell>
                        <Typography color="textPrimary" variant="body2">
                          {space?.serverURL}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCellTitle>Max Capacity</TableCellTitle>
                      <TableCell>
                        <Typography color="textPrimary" variant="body2">
                          {space?.maxCapacity}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCellTitle>Banned IPs</TableCellTitle>
                      <TableCell>
                        <List sx={{ pt: 0, pb: 0, mb: 0 }}>
                          {bannedAddresses.map((ip) => (
                            <ListItem disableGutters sx={{ pt: 0 }} key={ip}>
                              {ip}
                            </ListItem>
                          ))}
                        </List>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </DetailsCard>

              {meetingRecordingBudgetInfo && (
                <DetailsCard header="Meeting Recording Budget Info" sx={{ mb: 4 }}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCellTitle>Has Valid Paid Plan</TableCellTitle>
                        <TableCell>
                          <Typography color="textPrimary" variant="body2">
                            {meetingRecordingBudgetInfo.hasValidPaidPlanForRecording ? "Yes" : "No"}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCellTitle>Budget Used Ms</TableCellTitle>
                        <TableCell>
                          <Typography color="textPrimary" variant="body2">
                            {meetingRecordingBudgetInfo.recordingTimeUsedMs}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCellTitle>Budget Total</TableCellTitle>
                        <TableCell>
                          <Typography color="textPrimary" variant="body2">
                            {meetingRecordingBudgetInfo.recordingBudgetMs}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCellTitle>Recording Window Start/Monthly Reset</TableCellTitle>
                        <TableCell>
                          <Typography color="textPrimary" variant="body2">
                            {meetingRecordingBudgetInfo.currentSubscriptionStart}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </DetailsCard>
              )}

              <DetailsCard header="Space Settings" sx={{ mt: 4 }}>
                <Table sx={{ mt: 0 }}>
                  <TableBody>
                    <TableRow>
                      <TableCellTitle>Flags</TableCellTitle>
                      <TableCell>
                        <FormControlLabel
                          sx={{ display: "block" }}
                          control={
                            <Checkbox
                              color="primary"
                              disabled
                              checked={!!space?.settings.disableChat}
                            />
                          }
                          label="Disable Chat"
                        />

                        <FormControlLabel
                          sx={{ display: "block" }}
                          control={
                            <Checkbox
                              color="primary"
                              disabled
                              checked={!!space?.settings.disableChatPersist}
                            />
                          }
                          label="Disable Chat Persist"
                        />

                        <FormControlLabel
                          sx={{ display: "block" }}
                          control={
                            <Checkbox
                              color="primary"
                              disabled
                              checked={!!space?.settings.disableScreenshare}
                            />
                          }
                          label="Disable Screenshare"
                        />

                        <FormControlLabel
                          sx={{ display: "block" }}
                          control={
                            <Checkbox
                              color="primary"
                              disabled
                              checked={!!space?.settings.forceMute}
                            />
                          }
                          label="Force Mute"
                        />

                        <FormControlLabel
                          sx={{ display: "block" }}
                          control={
                            <Checkbox
                              color="primary"
                              disabled
                              checked={!!space?.settings.globalBuild}
                            />
                          }
                          label="Global Build"
                        />

                        <FormControlLabel
                          sx={{ display: "block" }}
                          control={
                            <Checkbox
                              color="primary"
                              disabled
                              checked={!!space?.settings.disableTutorial}
                            />
                          }
                          label="Disable Tutorial"
                        />

                        <FormControlLabel
                          sx={{ display: "block" }}
                          control={
                            <Checkbox
                              color="primary"
                              disabled
                              checked={!!space?.settings.disableInvite}
                            />
                          }
                          label="Disable Invite"
                        />

                        <FormControlLabel
                          sx={{ display: "block" }}
                          control={
                            <Checkbox
                              color="primary"
                              disabled
                              checked={!!space?.settings.allowStaffAccess}
                            />
                          }
                          label="Allow Gather Staff Access"
                        />

                        <FormControlLabel
                          sx={{ display: "block" }}
                          control={
                            <Checkbox
                              color="primary"
                              disabled
                              checked={!!space?.settings.requireLogin}
                            />
                          }
                          label="Require Login"
                        />

                        <FormControlLabel
                          sx={{ display: "block" }}
                          control={
                            <Checkbox
                              color="primary"
                              disabled
                              checked={!!space?.settings.enableRecordingForMembersV2}
                            />
                          }
                          label="Meeting Recording Enabled"
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCellTitle>Email Domains</TableCellTitle>
                      <TableCell>
                        <List sx={{ pt: 0, pb: 0, mb: 0 }}>
                          {space?.settings.emailDomains &&
                            space?.settings.emailDomains.map((domain) => (
                              <ListItem disableGutters sx={{ pt: 0 }} key={domain}>
                                {domain}
                              </ListItem>
                            ))}
                        </List>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCellTitle>Gather Labs Features</TableCellTitle>
                      <TableCell>
                        <List sx={{ pt: 0, pb: 0, mb: 0 }}>
                          {space?.settings.gatherLabsFeaturesEnabled &&
                            space?.settings.gatherLabsFeaturesEnabled.map((feature) => (
                              <ListItem disableGutters sx={{ pt: 0 }} key={feature}>
                                {feature}
                              </ListItem>
                            ))}
                        </List>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCellTitle>Pinned Announcements</TableCellTitle>
                      <TableCell>
                        <List sx={{ pt: 0, pb: 0, mb: 0 }}>
                          {pinnedAnnouncements.map((msg) => (
                            <ListItem key={msg.id}>
                              <ReactMarkdown options={markdownOptions}>{msg.message}</ReactMarkdown>
                            </ListItem>
                          ))}
                        </List>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCellTitle>Announcements</TableCellTitle>
                      <TableCell>
                        <List sx={{ pt: 0, pb: 0, mb: 0 }}>
                          {announcements.map((msg) => (
                            <ListItem key={msg.id}>
                              <ReactMarkdown options={markdownOptions}>{msg.message}</ReactMarkdown>
                            </ListItem>
                          ))}
                        </List>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </DetailsCard>
            </Box>
          </Box>
        </>
      )}
    </TabWrapper>
  );
};

export default SpaceDetailsTab;
