/** External modules */
import React, {
  FC,
  useEffect,
  useState,
  useCallback,
  ChangeEvent,
  KeyboardEvent,
  SyntheticEvent,
} from "react";
import { useNavigate } from "react-router-dom";

/** MUI Components */
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

/** Material Icons */
import RunIcon from "@mui/icons-material/DirectionsRun";
import ManIcon from "@mui/icons-material/Man";

/** Local modules */
import { Tab as TabType } from "components/types";
import TabWrapper from "components/dashboard/TabWrapper";
import SearchIdInput from "components/inputs/SearchIdInput";
import PageContainer from "components/layout/dashboard/PageContainer";
import useSearchParams from "features/useSearchParams";
import { useSpace } from "features/spaces/hooks";
import useToggleStaffAccess from "features/spaces/mutations/useToggleStaffAccess";
import { extractSpaceIDFromUserInput } from "utils/handleSpaceID";

/** Partials */
import MapTab from "./partials/MapTab";
import SpaceDetailsTab from "./partials/SpaceDetailsTab";
import UsersTab from "./partials/UsersTab";
import ReservationsTab from "./partials/ReservationsTab";
import PermittedComponent from "components/authentication/PermittedComponent";
import { AdminPermission as Can } from "gather-admin-common/dist/src/public/roles/types";

const PAGE_TITLE = "Manage Spaces";

const tabs: TabType[] = [
  {
    label: "Space Details",
    value: "space",
  },
  {
    label: "Maps",
    value: "maps",
  },
  {
    label: "Users",
    value: "users",
  },
  {
    label: "Reservations",
    value: "reservations",
  },
];

const ReservationsIndexPage: FC = () => {
  const navigate = useNavigate();
  const queryParam = useSearchParams();
  const initialSpace = decodeURIComponent(queryParam.get("space") ?? "");
  const [spaceIdInputValue, setSpaceIdInputValue] = useState<string>(
    decodeURIComponent(initialSpace),
  );
  const [spaceId, setSpaceId] = useState<string>(initialSpace);
  const { data, isLoading } = useSpace(spaceId);
  const [currentTab, setCurrentTab] = useState("space");
  const toggleStaffAccess = useToggleStaffAccess();

  const handleSpaceIdChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSpaceIdInputValue(event.target.value);
    },
    [setSpaceIdInputValue],
  );

  const handleTabsChange = useCallback(
    (_event: SyntheticEvent<Element>, value: string) => {
      setCurrentTab(value);
    },
    [setCurrentTab],
  );

  const handleSpaceIdKeyPress = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        const newSpaceId = extractSpaceIDFromUserInput(spaceIdInputValue);
        setSpaceId(newSpaceId);
      }
    },
    [setSpaceId, spaceIdInputValue],
  );

  const updateQueryParam = useCallback(() => {
    const params = new URLSearchParams({ space: encodeURIComponent(spaceId) });
    navigate(`${window.location.pathname}${spaceId.length ? `?${params.toString()}` : ""}`, {
      replace: true,
    });
  }, [spaceId]);

  // TODO @alecia - refactor into space update method later
  const handleToggleStaffAccess = useCallback(() => {
    toggleStaffAccess.mutate({ spaceId });
  }, [spaceId, toggleStaffAccess]);

  useEffect(() => {
    if (data) {
      updateQueryParam();
      setCurrentTab("space"); // Reset to the main space details tab
    }
  }, [updateQueryParam, data, setCurrentTab]);

  const getCurrentTabContents = () => {
    switch (currentTab) {
      case "space":
        return <SpaceDetailsTab spaceId={data?.id} />;
      case "maps":
        return <MapTab spaceId={data?.id} />;
      case "users":
        return <UsersTab spaceId={data?.id} />;
      case "reservations":
        return <ReservationsTab spaceId={spaceId} />;
      default:
        return <TabWrapper>Please let the Core App team know how you ended up here...</TabWrapper>;
    }
  };

  return (
    <PageContainer
      pageTitle={PAGE_TITLE}
      buttons={
        // TODO @alecia - remove this button when feature for updating space is implemented
        !isLoading &&
        data && (
          <PermittedComponent permissions={[Can.ToggleSpaceStaffAccess]} requiresAll={true}>
            <Button
              color="primary"
              startIcon={
                data?.settings.allowStaffAccess === true ? (
                  <RunIcon fontSize="small" />
                ) : (
                  <ManIcon fontSize="small" />
                )
              }
              variant="contained"
              onClick={handleToggleStaffAccess}
            >
              {data.settings.allowStaffAccess ? "Disable" : "Enable"} Gather Staff Access
            </Button>
          </PermittedComponent>
        )
      }
    >
      <Box sx={{ minWidth: 1100 }}>
        <SearchIdInput
          onKeyPress={handleSpaceIdKeyPress}
          onChange={handleSpaceIdChange}
          id={spaceIdInputValue}
          placeholder="Enter Space ID or URL here"
          label="Search for Space"
        />

        {isLoading && <Box sx={{ p: 2 }}>Loading...</Box>}

        {!isLoading && data && (
          <Box>
            <Tabs
              indicatorColor="primary"
              onChange={handleTabsChange}
              scrollButtons="auto"
              textColor="primary"
              value={currentTab}
              variant="scrollable"
            >
              {tabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </Tabs>
            <Divider />

            {getCurrentTabContents()}
          </Box>
        )}
      </Box>
    </PageContainer>
  );
};

export default ReservationsIndexPage;
