import * as zod from "zod";

// Verify that a user works for gather via their email address
export function validateGatherEmail(email = "") {
  const emailSchema = zod
    .string()
    .email()
    .refine((e) => e.slice(-12) === "@gather.town");
  return emailSchema.safeParse(email).success;
}
