import * as ObjectTemplateCategoryGroupAPI from "features/objectTemplateCategories/api";
import { objectTemplateCategoryGroupMutation } from "features/objectTemplateCategories/mutations";
import useObjectTemplateCategories from "features/objectTemplateCategories/useObjectTemplateCategories";
import React, { FC, useCallback } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { toast } from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import objFromArray from "utils/objFromArray";
import CategoryGroup from "./CategoryGroup";
import { ObjectTemplateCategoryGroup } from "gather-common/dist/src/public/resources/objectTemplates";

interface Droppable {
  droppableId: string;
  index: number;
}

const CategoriesContainer: FC = () => {
  const categoryGroupsQuery = useObjectTemplateCategories();
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (categoryGroup: ObjectTemplateCategoryGroup) =>
      ObjectTemplateCategoryGroupAPI.modify(categoryGroup),
    objectTemplateCategoryGroupMutation(queryClient),
  );

  const moveCard = useCallback(
    (source: Droppable, destination: Droppable) => {
      const categoryGroups = categoryGroupsQuery.data
        ? objFromArray(categoryGroupsQuery.data, "id")
        : {};
      const sourceCategoryGroup = categoryGroups[source.droppableId];
      if (!sourceCategoryGroup) return;
      const sameGroup = source.droppableId === destination.droppableId;
      const [category] = sourceCategoryGroup.categories.splice(source.index, 1); // Removes from source array
      if (!category) return;

      if (sameGroup) {
        sourceCategoryGroup.categories.splice(destination.index, 0, category); // Re-add in new position to same array
      } else {
        const targetCategoryGroup = categoryGroups[destination.droppableId];
        if (!targetCategoryGroup) return;
        targetCategoryGroup.categories.splice(destination.index, 0, category); // Re-add in new position in target array
        // Update target categoryGroup
        mutation.mutate(targetCategoryGroup);
      }
      // Update source categoryGroup
      mutation.mutate(sourceCategoryGroup);
    },
    [categoryGroupsQuery, mutation],
  );

  const handleDragEnd = useCallback(
    // @ts-expect-error Error auto-ignored when migrating to @types/react v18. PLEASE HELP FIX THESE! YES, YOU. It may be as simple as using React.PropsWithChildren<> or adding a missing typing.
    async ({ source, destination, draggableId }) => {
      try {
        // Dropped outside the column
        if (!destination) {
          console.log("dropped outside of the column");
          return;
        }

        // Card has not been moved
        if (source.droppableId === destination.droppableId && source.index === destination.index) {
          console.log("card not moved");
          return;
        }

        if (source.droppableId === destination.droppableId) {
          // Moved to the same column on different position
          console.log("moved to same column, different position", source, destination, draggableId);
          if (categoryGroupsQuery?.data) {
            moveCard(source, destination);
          }
        } else {
          // Moved to another column
          console.log("moved to different column", source, destination, draggableId);
          if (categoryGroupsQuery?.data) {
            moveCard(source, destination);
          }
        }
      } catch (err) {
        console.error(err);
        toast.error("Something went wrong!");
      }
    },
    [categoryGroupsQuery, moveCard],
  );

  if (categoryGroupsQuery?.data) {
    return (
      // @ts-expect-error Error auto-ignored when migrating to @types/react v18. PLEASE HELP FIX THESE! YES, YOU. It may be as simple as using React.PropsWithChildren<> or adding a missing typing.
      <DragDropContext onDragEnd={handleDragEnd}>
        {categoryGroupsQuery?.data?.map((group) => (
          <CategoryGroup group={group} key={group.name} />
        ))}
      </DragDropContext>
    );
  } else {
    return <div>Loading categories...</div>;
  }
};

export default CategoriesContainer;
