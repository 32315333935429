import { AxiosRequestConfig } from "gather-common-including-video/dist/src/public/axios";
import { PromiseHttpClient, RequestConfig } from "./constants";
import axiosClient from "./axiosClient";
import {
  DEFAULT_PATH_PARAM_USER,
  HttpV2Paths,
  RequestMethod,
} from "gather-http-common/dist/src/public/httpAPI";
import { just } from "gather-common/dist/src/public/fpHelpers";
import { Logger } from "./Logger";

// Match a path param preceded by /: and followed by either / or nothing.
// Examples matching "param": /path/:param, /path/:param/more
const MATCH_PATH_PARAM = /\/:\w+(?=\/|$)/g;

interface AuthManagerWrapper {
  waitForToken: () => Promise<string>;
}

const BASE_DEFAULT_PATH_PARAMS = {
  // Instructs the endpoint to read from current logged in user (authToken)
  user: () => DEFAULT_PATH_PARAM_USER,
};

type RequestConfigWithoutErrorSuppress = RequestConfig & { suppressErrors?: false };

// A client providing standardized ways to access our HTTP API v2.
class HttpV2Client {
  private apiBasePath: string; // Base URL to make requests to
  private authManager: AuthManagerWrapper; // To return the auth token
  private defaultPathParams?: Record<string, () => string | number>; // Default path param values to use when not explicitly provided
  private webWorkerClient?: PromiseHttpClient; // Optional client to handle background requests
  private customHeaders?: Record<string, string>;

  constructor(options: {
    apiBasePath: string;
    authManager: AuthManagerWrapper;
    defaultPathParams?: Record<string, () => string | number>;
    webWorkerClient?: PromiseHttpClient;
    customHeaders?: Record<string, string>;
  }) {
    this.apiBasePath = options.apiBasePath;
    this.authManager = options.authManager;
    this.defaultPathParams = { ...BASE_DEFAULT_PATH_PARAMS, ...options.defaultPathParams };
    this.webWorkerClient = options.webWorkerClient;
    this.customHeaders = options.customHeaders;
  }

  // Replaces path param placeholders (e.g. /:space or /:user) with their actual param values.
  processPath(path: HttpV2Paths, pathParams?: Record<string, string>) {
    const matches = path.match(MATCH_PATH_PARAM);
    if (!matches) return path;

    let processedPath: string = path;
    for (const path of matches) {
      const param = path.slice(2); // path starts with /:
      const paramValue: string | number | undefined =
        pathParams?.[param] ?? this.defaultPathParams?.[param]?.() ?? undefined;
      if (!paramValue) throw new Error(`No value provided for path param ${param}!`);

      processedPath = processedPath.replace(`:${param}`, encodeURIComponent(paramValue));
    }

    return processedPath;
  }

  /**
   * Make an HTTP request with method `method` to path `path`.
   * @returns A promise. If `suppressErrors` is enabled, this may resolve to `undefined`.
   *          Otherwise, this always resolves to `TResponse`.
   */
  private async request<TResponse>(
    method: RequestMethod,
    path: HttpV2Paths,
    config?: RequestConfigWithoutErrorSuppress, // no error suppression
  ): Promise<TResponse>; // no `| undefined`
  private async request<TResponse>(
    method: RequestMethod,
    path: HttpV2Paths,
    config: RequestConfig, // possible error suppression
  ): Promise<TResponse | undefined>; // yes `| undefined`
  private async request<TResponse>(
    method: RequestMethod,
    path: HttpV2Paths,
    config: RequestConfig = {},
  ): Promise<TResponse | undefined> {
    if (config?.suppressErrors) {
      return this.requestSuppressingErrors(method, path, config);
    } else {
      return this.requestWithoutErrorSuppression(method, path, config);
    }
  }

  /**
   * Makes an HTTP request WITHOUT error suppression - exceptions will bubble to the caller.
   *
   * @returns A promise resolving to `TResponse`. Notably, this DOES NOT resolve to `TResponse | undefined`,
   *          and that's enforced by the return type below.
   */
  private async requestWithoutErrorSuppression<TResponse>(
    method: RequestMethod,
    path: HttpV2Paths,
    config: Omit<RequestConfig, "suppressErrors"> = {},
  ): Promise<TResponse> {
    const finalPath = `${this.apiBasePath}/api/v2${this.processPath(path, config.params?.path)}`;
    const axiosConfig: AxiosRequestConfig = {
      params: config.params?.query,
      adapter: config.customAdapter,
    };

    if (config.auth) {
      const authToken = await this.authManager.waitForToken();
      axiosConfig.headers = {
        authorization: `Bearer ${authToken}`,
      };
    }

    axiosConfig.headers = Object.assign(axiosConfig.headers ?? {}, this.customHeaders);

    const body = config.params?.body;
    const useWorker = config.useWorker;

    if (useWorker && !this.webWorkerClient)
      throw new Error("No WebWorkerClient initialized in HttpV2Client");

    const client = just(useWorker ? this.webWorkerClient : axiosClient);

    switch (method) {
      case RequestMethod.Get:
        return client.get(finalPath, axiosConfig);
      case RequestMethod.Post:
        return client.post(finalPath, body, axiosConfig);
      case RequestMethod.Patch:
        return client.patch(finalPath, body, axiosConfig);
      case RequestMethod.Put:
        return client.put(finalPath, body, axiosConfig);
      case RequestMethod.Delete:
        return client.delete(finalPath, { data: body, ...axiosConfig });
      default:
        throw new Error(`Unrecognized method ${method}`);
    }
  }

  /**
   * Makes an HTTP request WITH error suppression. Exceptions are caught here and never bubbled
   * to the caller.
   *
   * @returns A promise resolving to `TResponse | undefined`. Always resolves to `undefined` if an
   *          exception occurs.
   */
  private async requestSuppressingErrors<TResponse>(
    method: RequestMethod,
    path: HttpV2Paths,
    config: Omit<RequestConfig, "suppressErrors"> = {},
  ): Promise<TResponse | undefined> {
    try {
      return await this.requestWithoutErrorSuppression(method, path, config);
    } catch (e) {
      Logger.error(`HttpV2Client error in ${method} ${path}: ${e}`);
      return;
    }
  }

  /**
   * Supports updating the API base path at runtime.
   */
  setApiBasePath(apiBasePath: string) {
    this.apiBasePath = apiBasePath;
  }

  async get<TResponse>(
    path: HttpV2Paths,
    config?: RequestConfigWithoutErrorSuppress,
  ): Promise<TResponse>;
  async get<TResponse>(path: HttpV2Paths, config: RequestConfig): Promise<TResponse | undefined>;
  async get<TResponse>(path: HttpV2Paths, config: RequestConfig = {}) {
    return this.request<TResponse>(RequestMethod.Get, path, config);
  }

  async post<TResponse>(
    path: HttpV2Paths,
    config?: RequestConfigWithoutErrorSuppress,
  ): Promise<TResponse>;
  async post<TResponse>(path: HttpV2Paths, config: RequestConfig): Promise<TResponse | undefined>;
  async post<TResponse>(path: HttpV2Paths, config: RequestConfig = {}) {
    return this.request<TResponse>(RequestMethod.Post, path, config);
  }

  async patch<TResponse>(
    path: HttpV2Paths,
    config?: RequestConfigWithoutErrorSuppress,
  ): Promise<TResponse>;
  async patch<TResponse>(path: HttpV2Paths, config: RequestConfig): Promise<TResponse | undefined>;
  async patch<TResponse>(path: HttpV2Paths, config: RequestConfig = {}) {
    return this.request<TResponse>(RequestMethod.Patch, path, config);
  }

  async put<TResponse>(
    path: HttpV2Paths,
    config?: RequestConfigWithoutErrorSuppress,
  ): Promise<TResponse>;
  async put<TResponse>(path: HttpV2Paths, config: RequestConfig): Promise<TResponse | undefined>;
  async put<TResponse>(path: HttpV2Paths, config: RequestConfig = {}) {
    return this.request<TResponse>(RequestMethod.Put, path, config);
  }

  async delete<TResponse>(
    path: HttpV2Paths,
    config?: RequestConfigWithoutErrorSuppress,
  ): Promise<TResponse>;
  async delete<TResponse>(path: HttpV2Paths, config: RequestConfig): Promise<TResponse | undefined>;
  async delete<TResponse>(path: HttpV2Paths, config: RequestConfig = {}) {
    return this.request<TResponse>(RequestMethod.Delete, path, config);
  }
}

export default HttpV2Client;
