import PriorityQueue from "./priorityQueue";

export type AddPageActionParameters = Parameters<typeof window.newrelic.addPageAction>;

export class NewrelicPriorityQueue extends PriorityQueue<AddPageActionParameters> {
  highestFrequencyElement() {
    const frequencyMap = new Map<string, number>();
    this.queue.forEach((pageActions) => {
      pageActions?.forEach(([name]) => frequencyMap.set(name, (frequencyMap.get(name) || 0) + 1));
    });
    return [...frequencyMap.entries()].reduce((a, e) => (e[1] > (a?.[1] || 0) ? e : a), ["", 0]);
  }
}
