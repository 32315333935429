import { useQuery } from "react-query";
import QueryKeys from "../queryKeys";

import * as ReservationsAPI from "./api";

/**
 * Fetches a Reservation by reservationId
 * @param resId - the ID of the reservation to be fetched
 * @returns React Query object where data is type of Reservation | undefined
 */
export const useReservationDetails = (resId?: string) =>
  useQuery([QueryKeys.Reservation, resId], () => ReservationsAPI.fetchReservationById(resId), {
    initialData: undefined,
    enabled: !!resId,
    retry: false,
    refetchOnWindowFocus: false,
  });
