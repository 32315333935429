import React, { FC, PropsWithChildren } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { experimentalStyled, AppBar, Box, IconButton, Toolbar, Button } from "@mui/material";
import MenuIcon from "@mui/icons-material/MenuRounded";

import AccountPopover from "components/dashboard/AccountPopover";
import { ChevronLeft } from "@mui/icons-material";

const ViewerNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === "light" && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: "none",
    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === "dark" && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: "none",
  }),
  zIndex: theme.zIndex.drawer + 100,
}));

interface Props {
  onToggleMobileNav: () => void;
}

const ViewerNavbar: FC<PropsWithChildren<Props>> = ({ onToggleMobileNav, children, ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <ViewerNavbarRoot {...props}>
      <Toolbar sx={{ minHeight: 64 }}>
        <IconButton color="inherit" onClick={onToggleMobileNav} sx={{ display: { lg: "none" } }}>
          <MenuIcon fontSize="small" />
        </IconButton>

        <Button
          onClick={(e) => {
            if (location.key !== "default") {
              e.preventDefault();
              navigate(-1);
            }
          }}
          color="inherit"
          component={RouterLink}
          to="/dashboard/client-state"
          variant="text"
          startIcon={<ChevronLeft />}
        >
          Back
        </Button>

        <Box sx={{ flexGrow: 1, ml: 2 }}>{children}</Box>
        <Box sx={{ ml: 2 }}>
          <AccountPopover />
        </Box>
      </Toolbar>
    </ViewerNavbarRoot>
  );
};

export default ViewerNavbar;
