import { Buffer } from "buffer";
// eslint-disable-next-line @gathertown/cross-module-import-paths -- Error autoignored while enabling eslint for gather-admin. If you're already touching this code, please clean this up while you're at it.
import { hasOwnProperty } from "gather-common/dist/src/utils";
import { UpdatedVariantFields } from "./mutations/types";
import { isNil } from "gather-common/dist/src/public/fpHelpers";
import { ObjectVariant } from "gather-common/dist/src/public/resources/objectTemplates";
import { NewObjectVariant } from "gather-admin-common/dist/src/public/objectTemplates/types";

export const trimColor = (color: string) =>
  color.replace(/[^A-Za-z\d#]/g, "").replace(/#(?=\S)/g, "");

export const buildVariantId = (variant?: NewObjectVariant | ObjectVariant) => {
  const color = variant?.color.replace(/[^A-Za-z\d#]/g, "").replace(/#(?=\S)/g, "");
  const optionalId = `${color}-${variant?.orientation}`;
  return variant?.id || optionalId;
};

export function isSavedVariant(variant: unknown): variant is ObjectVariant {
  return (
    variant !== null &&
    typeof variant === "object" &&
    hasOwnProperty(variant, "normal") &&
    typeof variant.normal === "string"
  );
}

export const getImagePreviewUrl = (file: Buffer) =>
  "data:image;base64," + Buffer.from(file).toString("base64");

export const buildSpritesheet = (fields: UpdatedVariantFields) => {
  if (
    isNil(fields.spritesheetUrl) ||
    isNil(fields.framingWidth) ||
    isNil(fields.framingHeight) ||
    isNil(fields.frameRate) ||
    isNil(fields.startFrame) ||
    isNil(fields.endFrame)
  ) {
    return;
  }
  return {
    spritesheetUrl: fields.spritesheetUrl,
    framing: {
      frameWidth: fields.framingWidth,
      frameHeight: fields.framingHeight,
    },
    animations: {
      default: {
        useSequenceAsRange: true,
        loop: true,
        frameRate: fields.frameRate,
        sequence: [fields.startFrame, fields.endFrame],
      },
    },
    currentAnim: "default",
  };
};
