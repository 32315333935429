import { axios } from "gather-common-including-video/dist/src/public/axios";
import {
  BrowserVideoClientStateSession,
  BrowserVideoClientStateSessionIndex,
} from "gather-prisma-types/dist/src/public/client";
import { authTokenManager } from "gather-auth-client/dist/src/public/auth";
import { AVCLIENTSTATE_DEFAULT_PAGE_SIZE } from "gather-common/dist/src/public/av";
const ORIGIN = process.env.REACT_APP_API_BASE_PATH;

export type AvClientSessionParams = {
  userAgentOs?: string;
  userAgentBrowser?: string;
  avStrategy?: string;
  spaceIdOrUserId?: string;
  email?: string;
  id?: string;
};

export const fetchAvClientStateSessions = async (
  page = 1,
  pageSize = AVCLIENTSTATE_DEFAULT_PAGE_SIZE,
  params: AvClientSessionParams = {},
): Promise<BrowserVideoClientStateSessionIndex[]> => {
  const authToken = await authTokenManager.waitForToken();

  try {
    const response = await axios.get(`${ORIGIN}/api/v2/admin/av-client-state/sessions`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      params: { page, pageSize, ...params },
    });

    return response.data;
  } catch (e) {
    let message = "Failed to retrieve A/V client state sessions.";

    if (e instanceof Error) {
      message = e.message;
    }

    return Promise.reject(message);
  }
};

export const fetchAvClientStateSessionById = async (
  sessionId?: string,
): Promise<BrowserVideoClientStateSession | undefined> => {
  const authToken = await authTokenManager.waitForToken();

  try {
    if (sessionId) {
      const response = await axios.get(
        `${ORIGIN}/api/v2/admin/av-client-state/sessions/${sessionId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        },
      );

      return response.data;
    } else {
      throw new Error("Missing sessionId");
    }
  } catch (e) {
    let message = "Failed to retrieve A/V client state session.";

    if (e instanceof Error) {
      message = e.message;
    }

    return Promise.reject(message);
  }
};

export const fetchAvClientStateUserFeedbackBySessionId = async (
  sessionId?: string,
): Promise<BrowserVideoClientStateSession | undefined> => {
  const authToken = await authTokenManager.waitForToken();

  try {
    if (sessionId) {
      const response = await axios.get(
        `${ORIGIN}/api/v2/admin/av-client-state/sessions/${sessionId}/userFeedback`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        },
      );

      return response.data;
    } else {
      throw new Error("Missing sessionId");
    }
  } catch (e) {
    let message = "Failed to retrieve user feedback.";

    if (e instanceof Error) {
      message = e.message;
    }

    return Promise.reject(message);
  }
};
