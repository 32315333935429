import { Wearable } from "gather-common/dist/src/public/resources/users";

export const organizeWearablesData = (wearables: Wearable[]) => {
  const wearablesData: { [wearableType: string]: { [wearableName: string]: Wearable[] } } = {};
  for (const wear of wearables) {
    if (!wearablesData[wear.type]) {
      wearablesData[wear.type] = {};
    }
    const wearableType = wearablesData[wear.type];
    if (!wearablesData[wear.type]?.[wear.name] && wearableType) {
      wearableType[wear.name] = [];
    }
    if (wearableType) {
      wearableType[wear.name]?.push(wear);
    }
  }

  // TODO: [OA-246] Sort wearables alphabetically
  return wearablesData;
};
