// This file currently need to stay as Node.js CommonJS module since it's also used in Webpack
// https://linear.app/gather-town/issue/PP-1720/pre-build-gather-modules-needed-by-webpack

const isTest = process.env.ENVIRONMENT === "test";
const isLocal = process.env.ENVIRONMENT === "local";
const isDev = process.env.ENVIRONMENT === "dev";
const isStaging = process.env.ENVIRONMENT === "staging";
const isProd = process.env.ENVIRONMENT === "prod";
const isLocalOrTest = isLocal || isTest;
const isDevOrLocal = isDev || isLocal;
const isDevOrLocalOrTest = isDevOrLocal || isTest;
const isCI = process.env.GITHUB_ACTIONS !== undefined;

// Running with doppler env `scripts` still usually has a `ENVIRONMENT=prod`, so we use a custom
// env var to identify when we're in the gather-scripts environment, in cases where we want to
// disambiguate "we're using prod env, but we're actually running locally"
const isGatherScripts = process.env.IS_GATHER_SCRIPTS !== undefined;
// a little hacky, but happens to be true for right now. This allows us to distinguish integ/E2E
// test environments from unit test environments.
const isUnitTest = isTest && process.env.NODE_ENV === "test";

module.exports = {
  isTest,
  isLocal,
  isDev,
  isStaging,
  isProd,
  isLocalOrTest,
  isDevOrLocal,
  isDevOrLocalOrTest,
  isCI,
  isGatherScripts,
  isUnitTest,
};
