import { axios } from "gather-common-including-video/dist/src/public/axios";
import { authTokenManager } from "gather-auth-client/dist/src/public/auth";
import { toast } from "react-hot-toast";
import { ObjectTemplateCategoryGroup } from "gather-common/dist/src/public/resources/objectTemplates";

export const fetch: () => Promise<ObjectTemplateCategoryGroup[]> = async () => {
  const authToken = await authTokenManager.waitForToken();
  const origin = process.env.REACT_APP_API_BASE_PATH;

  try {
    const response = await axios.get<ObjectTemplateCategoryGroup[]>(
      `${origin}/api/v2/admin/object-templates/category-groups`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    const categoryGroups: ObjectTemplateCategoryGroup[] = response?.data;

    return categoryGroups;
  } catch (error) {
    const message =
      (axios.isAxiosError(error) && error.response?.data?.message) ??
      "Failed to retrieve object template categories.";
    toast.error(message);
    return Promise.reject(message);
  }
};

export const modify: (categoryGroup: ObjectTemplateCategoryGroup) => Promise<void> = async (
  categoryGroup,
) => {
  const authToken = await authTokenManager.waitForToken();
  const origin = process.env.REACT_APP_API_BASE_PATH;

  try {
    await axios.put<ObjectTemplateCategoryGroup>(
      `${origin}/api/v2/admin/object-templates/category-groups/${categoryGroup.id}`,
      {
        categories: categoryGroup.categories,
      },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
  } catch (error) {
    const message =
      (axios.isAxiosError(error) && error.response?.data?.message) ??
      "Failed to modify object template category";
    toast.error(message);
    return Promise.reject(message);
  }
};
