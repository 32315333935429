export enum Env {
  test = "test",
  local = "local",
  dev = "dev",
  staging = "staging",
  prod = "prod",
}

export const currentEnv = (): Env => {
  const env = process.env.ENVIRONMENT || "";

  // normally I'd use enumFromValue but didn't want to make a dependency for this package
  if (!(env in Env)) throw new Error(`Unexpected environment: ${env}`);

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return Env[env as Env];
};

export function switchEnv<T>(envs: { [env in Env]: () => T }): T;
export function switchEnv<T>(envs: { [env in Env]: (() => T) | null }): null;
export function switchEnv<T>(envs: { [env in Env]: (() => T) | null }): T | null {
  const env = currentEnv();
  const res = envs[env];
  if (res === null) return null;
  return res();
}
