/**
 * Ramda is great, but it's not always great with TS, or being readable. This is a grab-bag
 * of utility functions that add aliases, handy extensions of Ramda, or functions that better
 * wrangle the type requirements on TS side.
 */
import {
  both,
  complement,
  compose,
  head,
  identity,
  indexBy,
  is,
  isEmpty,
  last,
  maxBy,
  move,
  Ord,
  pipeWith,
  pluck,
  prop,
  reduce,
  reduceRight,
  reject,
  symmetricDifference,
  trim,
} from "ramda";
import { assertNonEmpty, assertNotNil } from "./ts-utils";

/**
 * Checks if it's "just" the value, and turns a maybe type into a definite type. Naming convention
 * borrowed from FP concept of Maybe/Just:
 * https://engineering.dollarshaveclub.com/typescript-maybe-type-and-module-627506ecc5c8
 */
export const just = <T>(x?: T | null, message = "Expected something, got nothing"): T => {
  assertNotNil(x, message);
  return x;
};

/*
 * Sometimes you want to filter for an element within an array and transform it to a new value.
 * findTransform is an optimization/QOL helper for when that find and transform use the same operation.
 * EG: when you want to find and return an element embedded in a 2D array
 *
 * Params:
 *
 * array - the array to search
 * transform - A function similar to the predicate of an array.find,
 *   but returns Output | undefined instead of a boolean
 *
 * Usage:
 *
 *   type Position = { x: number, y: number };
 *   type Tile = { position: Position };
 *
 *   const tileMap: { [key in string] : Tile[] } = { ... };
 *   const targetPosition = { x: 1, y: 1 };
 *
 *   const tile = findTransform(
 *     Object.values(tileMap),
 *     (tiles) => tiles.find((tile) => tile.x === targetPosition.x && tile.y === targetPosition.y)
 *   );
 *
 */
export function findTransform<Input, Output>(
  array: Input[],
  transform: (element: Input, index: number, array: Input[]) => Output | undefined,
): Output | undefined {
  let element: Output | undefined;

  array.some((e, index, array) => {
    element = transform(e, index, array);
    return element !== undefined;
  });

  return element;
}

/*
 * A wrapper around findTransform for when you want to find an element within a map of arrays
 * Params:
 *
 * array - the map to search
 * predicate - The predicate you'd use when calling array.find on of the mapped arrays
 *
 * Usage:
 *
 *   type Position = { x: number, y: number };
 *   type Tile = { position: Position };
 *
 *   const tileMap: { [key in string] : Tile[] } = { ... };
 *   const targetPosition = { x: 1, y: 1 };
 *
 *   const [key, targetPosition] = findInArrayMap(tileMap, (position) =>
 *     isPosEqual(position, pos),
 *   );
 */

export const findInArrayMap = <Element>(
  map: { [key in string]: Element[] },
  predicate: (element: Element, index: number, array: Element[]) => boolean,
): [string, Element] | [] => {
  const entries = Object.entries(map);

  return (
    findTransform(entries, ([key, array], index) => {
      const element = array.find((e) => predicate(e, index, array));
      return element ? [key, element] : undefined;
    }) ?? []
  );
};

export const getId = prop("id");

/**
 * Conditionally returns props to an object if a condition is met. Useful for adding props to an object
 * in a minimal way.
 *
 * Usage:
 * const someObj = {
 *  id: "123",
 *  name: "Alex",
 *  ...maybeReturnProps(hasEmail(user), { email: user.email }),
 * }
 */
export const maybeReturnProps = <T extends Record<string, unknown>>(condition: boolean, props: T) =>
  condition ? props : {};

/**
 * Converts an array of elements into an object with each key as an element's id.
 * Basically, takes an array and "indexes by" the ID. Seems tautological but it's hard to put
 * differently.
 */
export const indexById = <T extends Record<"id", string | number>>(
  xs: T[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/consistent-type-assertions
): Record<string | number, T> => indexBy(getId as (x: T) => string | number, xs);

/**
 * Pulls out a slice of data from array of objects. Commonly used in tests, but applicable
 * anywhere. Feel free to add other common props as they come up.
 */
export const idsOf = pluck("id");
export const namesOf = pluck("name");

// Exists as a very slightly faster (because it doesn't support currying) impl compared to ramda's.
// Using ours vs. ramda's will almost never matter.
export const isNil = (value: unknown): value is null | undefined => value == null;
// Same as `isNil` above.
export const isNotNil = <T>(value: T | null | undefined): value is T => value != null;

export const isUndefined = (value: unknown): value is undefined => value === undefined;
export const isNotUndefined = <T>(value: T | undefined): value is T => value !== undefined;

export const isNotEmpty = complement(isEmpty);

/**
 * Useful for type refinements when you have an array/object/string that maybe exists, and you only
 * want to do something if (a) it's not nil, and (b) it (is or is not) empty. This function does that.
 *
 *   isNotNilAndNotEmpty([1, 2, 3]) => true
 *   isNotNilAndNotEmpty({ a: 1 }) => true
 *   isNotNilAndNotEmpty("hello") => true

 *   isNotNilAndEmpty([]) => true
 *   isNotNilAndEmpty({}) => true
 *   isNotNilAndEmpty("") => true
 *
 *   isNotNilAndEmpty(null) => false
 *   isNotNilAndNotEmpty(undefined) => false
 *
 * Why does this exist? First, to help with type refinements:
 *
 *   const foo: { a?: number } | undefined = getFoo()
 *   if (isNotNilAndNotEmpty(foo)) {
 *     // foo is now { a?: number }
 *     // and we know it's not empty
 *   }
 *   if (isNotNilAndEmpty(foo)) {
 *     // foo is now { a?: number }
 *     // and we know it's empty
 *   }
 *
 * The "empty vs not empty" is hard to impossible to represent cleanly in TypeScript, so we call
 * that "business logic refinement". It's not perfect, but it gets us most of the way there.
 *
 * **CAVEAT**
 *
 * The `else` block kind of lies:
 *
 *   ```ts
 *   if (isNotNilAndNotEmpty(foo)) {
 *   } else {
 *     // foo is `undefined`, which is not necessarily true;
 *     // it might be defined but be empty
 *   }
 *   ```
 *
 * However, the "long hand" form of this doesn't do much better. So if you need behavior where
 * you care about acting on `foo` either when it's empty or not, then create nested if statements.
 *
 *   ```ts
 *   if (!isNil(foo)) {
 *     // do your emptiness checks here
 *   }
 *   ```
 *
 * You can refer to the tests for examples of the type refinement in action.
 *
 * Most people make the mistake of thinking that `isEmpty(null) === true`, but it's not. See more
 * here: https://github.com/ramda/ramda/issues/2507. While I disagree from the perspective of ease
 * of use, I can see the technical soundness of their point. So we have a helper here to do what
 * people would "normally expect", and we're explicit about it. Could've called it `isReallyEmpty`
 * but that seems subjective.
 *
 * See tests for example values.
 *
 * You could use these, but isBlank / isPresent will often capture what you want, and is
 * more terse.
 */
export const isNotNilAndNotEmpty = <T>(x: T | undefined | null): x is T =>
  both(isNotNil, isNotEmpty)(x);
// We need an anonymous function to get around some TS limitations.
export const isNotNilAndEmpty = <T>(x: T | undefined | null): x is T => both(isNotNil, isEmpty)(x);
export const isNilOrEmpty = (x: unknown): x is undefined | null | Record<string, never> | [] | "" =>
  !isNotNilAndNotEmpty(x);

/**
 * Alternative to Array.isArray that correctly narrows the type for arrays.
 */
export const isArray = <T>(arg: unknown): arg is readonly T[] => Array.isArray(arg);

/**
 * There was a useful method in Rails called `.blank?` that made some smart choices about blank
 * strings and boolean values. It's the same as `isNilOrEmpty`, except:
 *
 *   isBlank(null) => true
 *   isBlank(undefined) => true
 *   isBlank("  ") => true
 *   isBlank(true) => false
 *   isBlank(false) => true
 *   isBlank({}) => true
 *   ... and so on, the rest mirror the `emptiness` check from before.
 *
 * The inverse holds for `isPresent`.
 *
 * `isBlank` doesn't provide type refinement for Maybe types, because it might still be
 * `null | undefined`. We could've chosen to have `isBlank` be false for those values, but it
 * didn't make sense from the perspective of "would an engineer expect `null` to be blank?" It
 * seemed less confusing to have null/undefined be "blank" and not provide type refinement.
 * If you care about type refinement, use the `isNotNilAndEmpty` or `isNotNilAndNotEmpty` options
 * above.
 *
 * See tests for example values and type refinements.
 */
export const isBlank = <T>(x: T): boolean =>
  is(String, x)
    ? isNotNilAndEmpty(trim(x))
    : is(Boolean, x)
    ? !x
    : x === undefined || x === null
    ? true
    : isNotNilAndEmpty(x);
export const isPresent = <T>(x: T): x is Exclude<T, null | undefined | "" | false> =>
  complement(isBlank)(x);

/**
 * Drops false and nil values (null, undefined) from an iterable collection.
 */
export function compact<T>(x: readonly T[]): Exclude<T, null | undefined | false>[];
export function compact<T>(
  x: Record<string, T>,
): Record<string, Exclude<T, null | undefined | false>>;
export function compact<T>(xs: readonly T[] | Record<string, T>) {
  return reject((x) => x === false || isNil(x), xs);
}

/**
 * Drops nil values (but not false values) from an iterable collection.
 */
export const compactNil = reject(isNil);

// A way to type safely access the head / last of an array
export const headOr = <T>(xs: T[], fallback: T): T => (isNotEmpty(xs) ? just(head(xs)) : fallback);
export const lastOr = <T>(xs: T[], fallback: T): T => (isNotEmpty(xs) ? just(last(xs)) : fallback);

/**
 * Very simple helper that expresses an IIFE without the awkward syntax (which requires you to
 * notice the easy-to-miss trailing () invocation)
 *
 * It's meant as a stand-in for do-expressions (https://github.com/tc39/proposal-do-expressions)
 * until that proposal is ratified into JS syntax.
 */
export const doIt = <T>(fn: () => T) => fn();

/**
 * Moves the given element to the front of the array. If the element is not found,
 * the array remains the same.
 */
export const moveElementToFrontOfArray = <T>(element: T, arr: T[]) => {
  const elementIndex = arr.findIndex((e) => e === element);

  if (elementIndex === -1) return arr;

  return move(elementIndex, 0, arr);
};

/**
 * Moves the given list of elements to the front of the array if they are present.
 * The order of the elements will be retained.
 */
export const moveElementsToFrontOfArray = <T>(elements: T[], arr: T[]) =>
  reduceRight(moveElementToFrontOfArray, arr, elements);

/**
 * Checks if two arrays have the same values, regardless of order.
 */
export const eqValues = <T>(list1: T[], list2: T[]) =>
  compose<[T[], T[]], T[], boolean>(isEmpty, symmetricDifference)(list1, list2);

export const maxOf = (xs: Ord[]): Ord => maxByOf(identity, xs);
export const minOfWithIndex = (xs: Ord[]): { min: Ord; index: number } =>
  minByOfWithIndex(identity, xs);

/**
 * Expects xs to be non-empty or throws a runtime error. We don't use NonEmptyArray to make it
 * easier for DX.
 */
export const maxByOf = <T>(fn: (x: T) => Ord, xs: T[]) => {
  assertNonEmpty(xs);
  return reduce((acc, elem) => maxBy(fn, acc, elem), xs[0], xs);
};
export const minByOfWithIndex = <T>(fn: (x: T) => Ord, xs: T[]): { min: T; index: number } => {
  assertNonEmpty(xs);
  return xs.reduce<{ min: T; index: number }>(
    (acc, elem, index) => {
      const minValue = fn(elem);
      return minValue < fn(acc.min) ? { min: elem, index } : acc;
    },
    { min: xs[0], index: 0 },
  );
};

/**
 * Run each function awaiting the result of the previous one.
 * */
export const pipeAsync = pipeWith(async (f, res) => f(await res));

/**
 * Checks if a value is a string.
 */
export const isString = (value: unknown): value is string => typeof value === "string";

/**
 * Wraps in an array if it isn't already an array. `null` or `undefined` returns
 * an empty array.
 */
export function asArray(input: null | undefined): [];
export function asArray<T>(input: T | T[]): T[];
export function asArray<T>(input: null | undefined | T | T[]): [] | T[] {
  return isNil(input) ? [] : Array.isArray(input) ? input : [input];
}
