import { axios } from "gather-common-including-video/dist/src/public/axios";
import { AdminUserSpace } from "gather-admin-common/dist/src/public/spaces/types";
import { authTokenManager } from "gather-auth-client/dist/src/public/auth";
import { toast } from "react-hot-toast";
import HttpV2AdminClient from "http-client/httpV2Client";
import { getErrorMessage } from "gather-common/dist/src/public/utils";
import { HttpV2Paths } from "gather-http-common/dist/src/public/httpAPI";
import { SpaceUserResource } from "gather-common/dist/src/public/resources/space";
import { AdminReservation } from "gather-admin-common/dist/src/public/reservations/types";
import { RecordingPrisma } from "gather-common/dist/src/public/resources/recording";

const ORIGIN = process.env.REACT_APP_API_BASE_PATH;

export const fetchUserDetails: (emailOrUserId: string) => Promise<SpaceUserResource> = async (
  emailOrUserId,
) => {
  const authToken = await authTokenManager.waitForToken();

  try {
    const response = await axios.get<SpaceUserResource>(
      `${ORIGIN}/api/v2/admin/users/${emailOrUserId}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      },
    );

    return response?.data;
  } catch (error) {
    const message = getErrorMessage(error, "Failed to retrieve user details.");
    toast.error(message);
    return Promise.reject(message);
  }
};

export const fetchReservationsForUser: (userId: string) => Promise<AdminReservation[]> = async (
  userId,
) => {
  const authToken = await authTokenManager.waitForToken();

  try {
    const response = await axios.get<AdminReservation[]>(
      `${ORIGIN}/api/v2/admin/users/${userId}/reservations`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      },
    );

    return response?.data;
  } catch (error) {
    const message = getErrorMessage(error, "Failed to retrieve reservations for user.");
    toast.error(message);
    return Promise.reject(message);
  }
};

export const fetchUserSpaces: (userId: string) => Promise<AdminUserSpace> = async (userId) => {
  const authToken = await authTokenManager.waitForToken();

  try {
    const response = await axios.get<AdminUserSpace>(
      `${ORIGIN}/api/v2/admin/users/${userId}/spaces`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      },
    );

    return response?.data;
  } catch (error) {
    const message = getErrorMessage(error, "Failed to retrieve joined spaces for user.");
    toast.error(message);
    return Promise.reject(message);
  }
};

export const deleteUsersByEmails = async (emails: string[]) => {
  try {
    const response = await HttpV2AdminClient.delete<{ emails: string[] }>(
      HttpV2Paths.AdminAuthUsers,
      {
        auth: true,
        params: { body: { emails } },
      },
    );

    return response?.emails ?? [];
  } catch (error) {
    const message = getErrorMessage(error, "Failed to delete users.");
    toast.error(message);
    return Promise.reject(message);
  }
};

export const fetchUserRecordings: (
  spaceId: string,
  userId: string,
) => Promise<RecordingPrisma[]> = async (spaceId, userId) => {
  const authToken = await authTokenManager.waitForToken();

  try {
    const response = await axios.get<RecordingPrisma[]>(
      `${ORIGIN}/api/v2/admin/spaces/${spaceId}/users/${userId}/recordings`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      },
    );

    return response?.data;
  } catch (error) {
    const message = getErrorMessage(error, "Failed to retrieve recordings for user.");
    toast.error(message);
    return Promise.reject(message);
  }
};
