import { GameMapHttpPublic } from "@gathertown/gather-game-common/dist/src/public/gameMap";
import { CoreRole, SpaceResource } from "gather-common/dist/src/public/resources/space";
import { SpaceTrialResource } from "gather-http-common/dist/src/public/resources/spaceTrials";

// this is a public facing type, hence GameMapHttpPublic, not GameMapDB
export interface AdminSpaceMap extends GameMapHttpPublic {
  spaceId: string;
}
export type MapObject = NonNullable<AdminSpaceMap["objects"]>[number];

export { Interaction } from "@gathertown/gather-game-common/dist/src/public/gameMap";

export interface AdminSpace extends SpaceResource {
  maxCapacity?: number;
  trial: SpaceTrialResource | null;
}

export interface AdminUserSpace {
  spaces: SpaceResource[];
  userRoles: {
    [key: string]: CoreRole[];
  };
}
