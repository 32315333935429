import React, { FC } from "react";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { IconButton, Typography } from "@mui/material";
import { PlayCircleFilledRounded } from "@mui/icons-material";
import { BrowserVideoClientStateSessionIndex } from "gather-prisma-types/dist/src/public/client";
import { Link as RouterLink } from "react-router-dom";
import styled from "@emotion/styled";

const RowCell = styled(TableCell)`
  word-break: break-word;
`;

interface Props {
  sessions?: BrowserVideoClientStateSessionIndex[];
}

const ClientStateSessionsTable: FC<Props> = React.memo(function ClientStateSessionsTable({
  sessions,
}) {
  return (
    <Box sx={{ minWidth: "100%" }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>UserID</TableCell>
            <TableCell>SpaceID</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Message</TableCell>
            <TableCell>Strategy</TableCell>
            <TableCell>Platform</TableCell>
            <TableCell>View</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sessions?.map((session) => (
            <TableRow hover key={session.id}>
              <RowCell>
                <Typography color="textPrimary">{session.id}</Typography>
              </RowCell>
              <RowCell>
                <Typography color="textPrimary">{session.playerId}</Typography>
              </RowCell>
              <RowCell>
                <Typography color="textPrimary">{session.spaceId}</Typography>
              </RowCell>
              <RowCell>
                <Typography color="textPrimary">{`${session.createdAt}`}</Typography>
              </RowCell>
              <RowCell sx={{ maxWidth: 300 }}>
                <Typography color="textPrimary">{`${session.message ?? ""}`}</Typography>
              </RowCell>
              <RowCell>
                <Typography color="textPrimary">{session.avStrategy}</Typography>
              </RowCell>
              <RowCell>
                <Typography color="textPrimary">{session.userAgentBrowser}</Typography>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography color="textSecondary">{session.userAgentOs}</Typography>
                  <Typography color="textSecondary">{session.userAgentVersion}</Typography>
                </div>
              </RowCell>
              <RowCell>
                <IconButton
                  component={RouterLink}
                  to={`/dashboard/client-state/${session.id}`}
                  aria-label="view"
                  size="large"
                >
                  <PlayCircleFilledRounded fontSize="inherit" />
                </IconButton>
              </RowCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
});

export default ClientStateSessionsTable;
