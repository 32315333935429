type GatherEventListener<TArgs extends unknown[]> = (...args: TArgs) => void;

type EventListenerOptions = {
  leading: boolean;
};

/**
 * A drop-in replacement of Node's EventEmitter from the 'events' module. This implementation
 * is strictly-typed with instances of `GatherEventEmitter` being parameterized by:
 *     - TEvent: an enum whose values are the only valid events that can be emitted
 *     - TEventCallbackArgs: a mapping between an event value from `TEvent` to the types
 *        of the corresponding event listener's arguments.
 *
 * Instances of `GatherEventEmitter` listen for published events and run all
 * the installed listeners for that event.
 *
 * Note that we also have a `PubSub` class which may be useful for more basic cases where
 * we only have one set of data to subscribe to rather than multiple events.
 */
export class GatherEventEmitter<
  TEvent extends string | number,
  TEventCallbackArgs extends { [key in TEvent]: unknown[] },
> {
  private eventListeners: {
    [key in TEvent]?: GatherEventListener<TEventCallbackArgs[TEvent]>[] | undefined;
  } = {};
  private lastValues: {
    [key in TEvent]?: TEventCallbackArgs[key];
  } = {};

  // Returns a callback to remove the event listener.
  // specify triggerWithLastValue to immediately trigger this event listener with the
  // last value published for this event
  addEventListener<T extends TEvent>(
    event: T,
    callback: GatherEventListener<TEventCallbackArgs[T]>,
    options: EventListenerOptions = { leading: false },
  ): VoidFunction {
    const listeners: GatherEventListener<TEventCallbackArgs[T]>[] =
      this.eventListeners[event] ?? [];
    listeners.push(callback);
    // @ts-expect-error It's tough to get this typing exactly right, but it's easy to manually verify this line is correct.
    this.eventListeners[event] = listeners;
    const lastValue = this.lastValues[event];
    if (options.leading && lastValue) {
      callback(...lastValue);
    }
    return () => this.removeEventListener(event, callback);
  }

  removeEventListener<T extends TEvent>(
    event: T,
    callback: GatherEventListener<TEventCallbackArgs[T]>,
  ) {
    const listeners = this.eventListeners[event];
    if (listeners) {
      this.eventListeners[event] = listeners.filter((f) => f !== callback);
    }
  }

  publishEvent<T extends TEvent>(event: T, ...data: TEventCallbackArgs[T]) {
    this.lastValues[event] = data;
    const listeners = this.eventListeners[event];
    if (!listeners) return;

    for (let i = 0; i < listeners.length; i++) {
      try {
        listeners[i]?.(...data);
      } catch (error) {
        console.error(error);
      }
    }
  }
}
