import { Box, Divider, experimentalStyled, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { DateTime } from "luxon";
import { JSONTree } from "react-json-tree";
import { omit } from "ramda";

import { useAvClientStateSessionById } from "features/avClientState/hooks";
import { RoleProvider } from "features/roles/RoleContext";
import ViewerNavbar from "./partials/ViewerNavbar";
import ClientStateToolPage from "./clientStateTool/ClientStateToolPage";
import MetadataDrawer from "./clientStateTool/ui/MetadataDrawer";
import LiveKitMetadata from "./clientStateTool/LiveKitMetadata";
import PreScan from "./clientStateTool/PreScan";

const DashboardLayoutRoot = experimentalStyled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  height: "100%",
  overflow: "hidden",
  position: "fixed",
  width: "100%",
}));

const DashboardLayoutWrapper = experimentalStyled("div")(() => ({
  display: "flex",
  flex: "auto",
  overflow: "hidden",
  position: "fixed",
  paddingTop: "64px",
  width: "100%",
}));

const DashboardLayoutContainer = experimentalStyled("div")({
  display: "flex",
  flex: "auto",
  overflow: "hidden",
});

const DashboardLayoutContent = experimentalStyled("div")({
  flex: "auto",
  overflow: "hidden",
});

const ViewerPage = () => {
  const { sessionId } = useParams();
  const { data: session, isLoading } = useAvClientStateSessionById(sessionId);

  return (
    <div>
      <Helmet title={`Viewing: ${sessionId} | Gather Admin`}></Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          minHeight: "100%",
          overflowY: "auto",
        }}
      ></Box>
      <RoleProvider>
        <DashboardLayoutRoot>
          <ViewerNavbar onToggleMobileNav={() => {}}>
            <Box>
              <Typography>{sessionId}</Typography>

              {isLoading ? (
                <>Loading...</>
              ) : (
                <>
                  <Typography variant="body2" sx={{ display: "inline-block" }}>
                    {DateTime.fromISO(`${session?.createdAt}`).toLocaleString(
                      DateTime.DATETIME_MED,
                    )}{" "}
                    &bull; {session?.userAgentBrowser} {session?.userAgentVersion},{" "}
                    {session?.userAgentOs}, {session?.electron ? "Desktop" : "Web"} &bull;{" "}
                  </Typography>{" "}
                  <MetadataDrawer label="More">
                    <Box sx={{ width: 400, padding: "16px 16px" }}>
                      <LiveKitMetadata session={session} />
                      <Typography variant="h4">Session Metadata</Typography>
                      <Divider sx={{ margin: "16px 0" }} />
                      <JSONTree hideRoot data={omit(["session"], session)} />
                    </Box>
                  </MetadataDrawer>
                  <PreScan />
                </>
              )}
            </Box>
          </ViewerNavbar>
          <DashboardLayoutWrapper>
            <DashboardLayoutContainer>
              <DashboardLayoutContent>
                <ClientStateToolPage session={session} />
              </DashboardLayoutContent>
            </DashboardLayoutContainer>
          </DashboardLayoutWrapper>
        </DashboardLayoutRoot>
      </RoleProvider>
    </div>
  );
};

export default ViewerPage;
