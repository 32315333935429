// TODO @victor: clean up this file and/or this entire module
import {
  CoordsMap,
  Interaction,
  MapObject,
} from "@gathertown/gather-game-common/dist/src/public/gameMap";
import { GameState } from "@gathertown/gather-game-common/dist/src/public/gameState";
import {
  CoreRole,
  MoveDirection,
  Player,
  SpriteDirection,
} from "@gathertown/gather-game-common/dist/src/public/Player";
import { WireSpaceUser } from "@gathertown/gather-game-common/dist/src/public/generated_DO_NOT_TOUCH/events";
import {
  BoundingBox,
  MapPosition,
  Point,
} from "@gathertown/gather-game-common/dist/src/public/Position";
import { all, pipe } from "ramda";
import {
  GATHER_OFFICE_ID,
  MAX_MEDIUM_LENGTH,
  MAX_NAME_LENGTH,
  MAX_SPACE_NAME_LENGTH,
  Orientation,
  VALID_SPACE_NAME_PATTERN,
} from "./constants";
import { SpaceUserResource } from "./resources/space";
import { UPDATE_ROLE_PERMISSIONS } from "./resources/users";
import {
  EmbeddedWebsiteTemplate,
  ExtensionObjectTemplate,
  ExternalCallTemplate,
  InteractableTemplate,
  NoInteractionTemplate,
  NoteTemplate,
  ObjectTemplate,
  PosterTemplate,
  VideoTemplate,
} from "./resources/objectTemplates";
import { GatherEventMessageRecipient } from "gather-prisma-types/dist/src/public/client";
import { isObject, migrateEnum } from "./ts-utils";
import {
  GatherEventMessageRecipientBackwardsMigration,
  GatherEventMessageRecipientLegacy,
  GatherEventMessageRecipientMigration,
} from "./resources/events";
import { NonEmptyArray } from "gather-common-including-video/dist/src/public/tsUtils";
import { isNil, isNilOrEmpty, isNotNil, just } from "./public/fpHelpers";
import { axios } from "gather-common-including-video/dist/src/public/axios";
import { AuthUser } from "./resources/user";
import {
  dangerouslyCastToPoint,
  manhattanDistance,
} from "@gathertown/gather-game-common/dist/src/public/positionUtils";
import { isLocalOrTest } from "gather-env-config/dist/src/public/envShared";

export function clamp(num: number, min: number, max: number) {
  return num <= min ? min : num >= max ? max : num;
}

export function getNeighbors(
  x: number,
  y: number,
  includeDiagonalNeighbors = false,
): NonEmptyArray<Point> {
  const result: NonEmptyArray<Point> = [
    { x: x - 1, y },
    { x: x + 1, y },
    { x, y: y + 1 },
    { x, y: y - 1 },
  ];
  if (includeDiagonalNeighbors) {
    result.push(
      { x: x - 1, y: y - 1 },
      { x: x - 1, y: y + 1 },
      { x: x + 1, y: y + 1 },
      { x: x + 1, y: y - 1 },
    );
  }
  return result;
}

export function hasPlayerBlock(x: number, y: number, map: string, players: GameState<Player>) {
  for (const playerId in players) {
    // `player` always exists here. Preferring to do this over using `Object.entries` because this function is frequently invoked.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const player = players[playerId]!;
    if (player.map === map && !player.ghost && player.x === x && player.y === y) return playerId;
  }
  return false;
}

export function hasBlock(
  x: number,
  y: number,
  map: string,
  collisions: CoordsMap<true>,
  players: GameState<Player>,
  dimensions: [number, number],
) {
  if (y < 0 || y >= dimensions[1] || x < 0 || x >= dimensions[0]) return true;

  const yObj = collisions[y];
  if (yObj?.[x]) return true;

  return !!hasPlayerBlock(x, y, map, players);
}

/** Gets the manhattan distance closest unblocked coordinate from `source` out of `coords`
 * If coordsMap and source's map are different, then returns first position of `coords`
 */
export function getClosestUnblockedPosition(
  source: MapPosition,
  coords: Point[],
  coordsMapId: string, // map the `coords` belong to, can be different than source.map
  collisions: CoordsMap<true>,
  players: GameState<Player>,
  dimensions: [number, number],
): MapPosition | null {
  const firstCoord = coords[0];
  if (!firstCoord) return null;

  if (coordsMapId !== source.map) return { ...firstCoord, map: coordsMapId };

  let closestPoint = firstCoord;
  for (const { x, y } of coords.slice(1)) {
    const sourceAsPoint = dangerouslyCastToPoint(source);
    if (
      !hasBlock(x, y, coordsMapId, collisions, players, dimensions) &&
      (!closestPoint ||
        manhattanDistance({ x, y }, sourceAsPoint) < manhattanDistance(closestPoint, sourceAsPoint))
    ) {
      closestPoint = { x, y };
    }
  }
  return { ...closestPoint, map: coordsMapId };
}

export function dist(x1: number, x2: number, y1: number, y2: number) {
  return Math.sqrt((x1 - x2) ** 2 + (y1 - y2) ** 2);
}

// can return negative numbers! this is the outwards distance from the boundary of the object
export function getDistanceFromObjectBoundary(
  position?: Point,
  obj?: { x: number; y: number; width: number; height: number },
): number | null {
  if (!position || !obj) return null;

  // Calculate distance from the player, middle of the square
  const playerX = position.x + 0.5;
  const playerY = position.y + 0.5;
  // distance to edge
  const xDist = Math.max(obj.x - playerX, playerX - obj.x - obj.width);
  const yDist = Math.max(obj.y - playerY, playerY - obj.y - obj.height);
  return Math.max(xDist, yDist) + Math.abs((xDist + yDist) / 100000000000);
  // ^ fractional amount added to prefer objects with lower euclidean distance to boundary, if there's a tie
}

// if start time is in the past or within the next fifteen minutes,
// return start time that is fifteen minutes in the future
export const getStartDatePlusFifteenMins = (startDate: Date) => {
  const newStartDate = new Date(Date.now() + 15 * 60000);
  if (startDate < newStartDate) {
    return newStartDate;
  } else {
    return startDate;
  }
};

// returns true if given time is after now; false, otherwise
export const checkTimeInFuture = (time: Date) => new Date(Date.now()) < time;

export const VALID_ID_CHARS = "qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM1234567890";
export const makeId = (n: number) =>
  Array.from(new Array(n).keys())
    .map(() => VALID_ID_CHARS[Math.floor(Math.random() * VALID_ID_CHARS.length)])
    .join("");

export const buildSpaceId = (name: string, slug: string) => `${slug}\\${name}`;

// TODO: remove this and just use intersectsPos?
export const intersects = (
  pointerX: number,
  pointerY: number,
  targetX: number,
  targetY: number,
  targetWidth = 1,
  targetHeight = 1,
) =>
  pointerX < targetX + targetWidth &&
  pointerX >= targetX &&
  pointerY < targetY + targetHeight &&
  pointerY >= targetY;

// given box coordinates _x1, _y1, _x2, _y2,
// parse them to ensure (x1,y1) is top left and (x2,y2) is bottom right.
export const parseBoxCoordinates = (
  _x1: number,
  _y1: number,
  _x2: number,
  _y2: number,
): BoundingBox => {
  let x1 = _x1,
    y1 = _y1,
    x2 = _x2,
    y2 = _y2;

  if (_x1 > _x2) {
    x1 = _x2;
    x2 = _x1;
  }

  if (_y1 > _y2) {
    y1 = _y2;
    y2 = _y1;
  }
  return { x1, y1, x2, y2 };
};

// given box coordinates (x1, y1) = top left, (x2, y2) = bottom right,
// and given target coordinates (targetX1, targetY1) = target top left, target width, and target height,
// return true if target intersects with the box
export const intersectsBox = (
  boxCoordinates: BoundingBox,
  targetX1: number,
  targetY1: number,
  targetWidth = 1,
  targetHeight = 1,
): boolean => {
  const { x1, y1, x2, y2 } = boxCoordinates;
  const targetX2 = targetX1 + targetWidth - 1;
  const targetY2 = targetY1 + targetHeight - 1;

  return x1 <= targetX2 && x2 >= targetX1 && y1 <= targetY2 && y2 >= targetY1;
};

export const overlapsBox = (
  boxCoordinates: BoundingBox,
  targetX1: number,
  targetY1: number,
  targetWidth = 1,
  targetHeight = 1,
): boolean => {
  const { x1, y1, x2, y2 } = boxCoordinates;
  const targetX2 = targetX1 + targetWidth - 1;
  const targetY2 = targetY1 + targetHeight - 1;

  return x1 <= targetX1 && x2 >= targetX2 && y1 <= targetY1 && y2 >= targetY2;
};

export const intersectsPos = (pointer: Point, target: Point, targetWidth = 1, targetHeight = 1) =>
  intersects(pointer.x, pointer.y, target.x, target.y, targetWidth, targetHeight);

// why is this here?: https://www.notion.so/gathertown/Maps-Templates-and-New-Spaces-f215815e08fb4732ba4066ef9d9f1dbf
export const randomizeObjectUrlFromPrefix = (obj: Partial<MapObject>): void => {
  // written this way to avoid side effects if there's no deterministicUrlPrefix
  if (obj.properties?.deterministicUrlPrefix) {
    obj.properties = {
      ...obj.properties,
      url: generateURLFromPrefix(obj.properties.deterministicUrlPrefix),
    };
  }
};

const generateURLFromPrefix = (prefix: string) =>
  prefix + makeId(16) + "table" + Math.floor(Math.random() * 10);

export const directionFromSpriteDirection = (sd: SpriteDirection): MoveDirection | null => {
  switch (sd) {
    case SpriteDirection.Down:
    case SpriteDirection.DownAlt:
      return MoveDirection.Down;
    case SpriteDirection.Left:
    case SpriteDirection.LeftAlt:
      return MoveDirection.Left;
    case SpriteDirection.Right:
    case SpriteDirection.RightAlt:
      return MoveDirection.Right;
    case SpriteDirection.Up:
    case SpriteDirection.UpAlt:
      return MoveDirection.Up;
    case SpriteDirection.Dance1:
    case SpriteDirection.Dance2:
    case SpriteDirection.Dance3:
    case SpriteDirection.Dance4:
      return MoveDirection.Dance;
    default:
      return null;
  }
};

export const getLeftDirection = (md: MoveDirection | null): MoveDirection | null => {
  switch (md) {
    case MoveDirection.Up:
      return MoveDirection.Left;
    case MoveDirection.Right:
      return MoveDirection.Up;
    case MoveDirection.Down:
      return MoveDirection.Right;
    case MoveDirection.Left:
      return MoveDirection.Down;
    default:
      return null;
  }
};

export const roomURLIdToDBId = (roomURLId: string) => roomURLId.replace("/", "\\");

export const arrayAverage = (array: number[]) =>
  array.length > 0 ? array.reduce((acc, e) => acc + e) / array.length : undefined;

export const getRoleNames = (roles?: CoreRole[]) =>
  roles && roles.length > 0 ? roles.join(", ") : "Guest";

export function isCoreRole(role: string | undefined): role is CoreRole {
  return !isNil(role) && Object.values(CoreRole).some((coreRole) => coreRole === role);
}

export function getRoleWithFallbackToGuestRole(role: string | undefined): CoreRole {
  return isCoreRole(role) ? role : CoreRole.Guest;
}

export function getCoreRolesFromStringArray(roles: string[]): CoreRole[] {
  const coreRoles: CoreRole[] = [];
  roles.forEach((role) => isCoreRole(role) && coreRoles.push(role));
  return coreRoles;
}

export const isMemberRole = (role?: CoreRole | string): role is CoreRole =>
  isCoreRole(role) && role !== CoreRole.Guest;

export const isMember = (user?: SpaceUserResource | WireSpaceUser | null) =>
  isMemberRole(user?.role);

export const hasSpaceRole = (
  currentSpaceRole: string | null | undefined,
  requiredSpaceRoles: string[],
) => isNotNil(currentSpaceRole) && requiredSpaceRoles.includes(currentSpaceRole);

export const hasPermissionToEditUser = (
  requestingUserRole: string | undefined,
  userToUpdateNewAndRemovedRoles: string[],
): boolean => {
  // the requesting user must be a space user
  if (!isMemberRole(requestingUserRole)) return false;

  // if the user to update is a guest, the requesting user always has permission to edit
  if (userToUpdateNewAndRemovedRoles.length === 0) return true;

  const rolesAllowedToUpdate = UPDATE_ROLE_PERMISSIONS[requestingUserRole];

  return all(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    (role: string) => rolesAllowedToUpdate.includes(role as CoreRole),
    userToUpdateNewAndRemovedRoles,
  );
};

export async function delay(delayMs: number) {
  return new Promise((res) => {
    setTimeout(res, delayMs);
  });
}

// returns true on all special chars EXCEPT for [ ,-,_]
export const containsSpecialCharsExcludingSeparators = (str: string): boolean => {
  const regex = new RegExp(/[$&+,:;=?@#%!|'"`~<>.^*(){}[\]\\/]/);
  return regex.test(str);
};

export const isValidSpaceName = (spaceName: string) =>
  spaceName.match(VALID_SPACE_NAME_PATTERN) && spaceName.length <= MAX_SPACE_NAME_LENGTH;

export const getMoveDirection = (nextPos: Point, initialPos: Point | Player) => {
  let direction: MoveDirection | null = null;
  if (nextPos.x === initialPos.x) {
    if (nextPos.y < initialPos.y) {
      direction = MoveDirection.Up;
    } else {
      direction = MoveDirection.Down;
    }
  } else if (nextPos.y === initialPos.y) {
    if (nextPos.x < initialPos.x) {
      direction = MoveDirection.Left;
    } else {
      direction = MoveDirection.Right;
    }
  } else {
    // this should never happen, path finding is broken
  }
  return direction;
};

export const buildPlayerFromPartial = (
  playerId: string,
  initialInfo: Partial<Player> | null = null,
): Player => {
  const player: Player = new Player(playerId);
  for (const k in initialInfo) {
    // @ts-expect-error Error auto-ignored when enabling noImplicitAny. It's possible this is incorrect.
    // TODO: @ENG-4160 Clean these up! If you're already touching this code, please clean this up while you're at it.
    if (initialInfo[k] !== undefined) {
      // @ts-expect-error Error auto-ignored when enabling noImplicitAny. It's possible this is incorrect.
      // TODO: @ENG-4160 Clean these up! If you're already touching this code, please clean this up while you're at it.
      player[k] = initialInfo[k];
    }
  }
  // this used to be a ...{initialInfo}, but if that has a field _set_ to undefined, it will set the resulting field to undefined
  player.name = player.name.substring(0, MAX_NAME_LENGTH);
  player.textStatus = player.textStatus.substring(0, MAX_MEDIUM_LENGTH);
  return player;
};

export const chunkArray = <T>(fullArray: T[], chunkSize: number): T[][] => {
  if (chunkSize === 0) return [fullArray];

  return fullArray.reduce((chunkedArray: T[][], value, arrIndex) => {
    const chunkIndex = Math.floor(arrIndex / chunkSize);

    if (!chunkedArray[chunkIndex]) {
      chunkedArray[chunkIndex] = [];
    }
    // @ts-expect-error Error auto-ignored when enabling TS noUncheckedIndexedAccess. If you're already touching this code, please clean this up while you're at it.
    // TODO: @ENG-4257 Clean these up! See the linear task for more context and advice for cleaning up.
    chunkedArray[chunkIndex].push(value);
    return chunkedArray;
  }, []);
};

// Use this at the end of switch statements (or anywhere else with branching logic) where you want
// to have TS guarantee your switch clauses were exhaustive.
// Inspiration: https://stackoverflow.com/a/39419171/2672869
export function assertUnreachable(x: never): never {
  throw new Error(`assertUnreachable was reachable. received: ${x}`);
}

// When setting date property values, HubSpot recommends using the ISO 8601 complete date
// format, YYYY-MM-DD, as indicated here: https://developers.hubspot.com/docs/api/faq
export const getFormattedHubSpotDate = (date?: Date) => {
  if (!date) {
    date = new Date(Date.now());
  }
  return date.toISOString().substring(0, 10);
};

// Lint warning auto-ignored when enabling the no-explicit-any rule. Fix this the next time this code is edited! TODO: @ENG-4294 Clean these up! See the linear task for guidance on how to do so.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const allSettled = (promises: Promise<any>[]) => {
  const mappedPromises = promises.map((p) =>
    p
      .then((value) => ({
        status: "fulfilled",
        value,
      }))
      .catch((reason) => ({
        status: "rejected",
        reason,
      })),
  );
  return Promise.all(mappedPromises);
};

export const itemFromItemString = (
  itemString: string,
): { id: string; image: string } | undefined => {
  try {
    return JSON.parse(itemString);
  } catch {
    return undefined;
  }
};

export const vehicleFromVehicleId = (
  vehicleId: string,
): { id: string; vehicleSpritesheet?: string; vehicleNormal?: string } | undefined => {
  try {
    return JSON.parse(vehicleId);
  } catch {
    return undefined;
  }
};

// Typeguard to disambiguate an ObjectTemplate's by its type
/* A typeguard to disambiguate an ObjectTemplate by its type
Usage:
if (isTemplateType(someTemplate, Interaction.VIDEO)) {
  console.log(someTemplate.video) // no type error, because Typescript knows someTemplate : VideoTemplate
}
*/
export function isTemplateType<T extends Interaction>(
  obj: ObjectTemplate,
  type: T,
): obj is T extends Interaction.NONE
  ? NoInteractionTemplate
  : T extends Interaction.EMBEDDED_WEBSITE
  ? EmbeddedWebsiteTemplate
  : T extends Interaction.POSTER
  ? PosterTemplate
  : T extends Interaction.VIDEO
  ? VideoTemplate
  : T extends Interaction.EXTERNAL_CALL
  ? ExternalCallTemplate
  : T extends Interaction.EXTENSION
  ? ExtensionObjectTemplate
  : T extends Interaction.MODAL_EXTENSION
  ? ExtensionObjectTemplate
  : T extends Interaction.NOTE
  ? NoteTemplate
  : never {
  return obj.type === type;
}

// Source: https://fettblog.eu/typescript-hasownproperty/
export function hasOwnProperty<X extends object, Y extends PropertyKey>(
  obj: X,
  prop: Y,
): obj is X & Record<Y, unknown> {
  return obj.hasOwnProperty(prop);
}

export function isInteractableTemplate(obj: unknown): obj is InteractableTemplate {
  return (
    obj !== null &&
    typeof obj === "object" &&
    hasOwnProperty(obj, "distThreshold") &&
    typeof obj.distThreshold === "number"
  );
}

export const getRandomPointNearPoint = (x: number, y: number, offset = 5) => {
  const offsetX = Math.floor(Math.random() * offset);
  const offsetY = Math.floor(Math.random() * offset);

  return {
    x: Math.random() > 0.5 ? x + offsetX : x - offsetX,
    y: Math.random() > 0.5 ? y + offsetY : y - offsetY,
  };
};

export const gatherURLRegex =
  /^https?:\/\/(?:((?:(\w|\d|-|\.)*\.)?gather\.town)|(?:localhost:8080))/;
export const gatherSpaceURLRegex =
  /^https?:\/\/(?:((?:(\w|\d|-|\.)*\.)?gather\.town)|(?:localhost:8080))\/app/;
const gatherLocalSpaceURLRegex =
  /^https?:\/\/((?:localhost:(?:3000|8080))|(?:.*ngrok(?:-free)?.app))\/app/;

export const isURLGatherSpace = (urlToCheck: string) =>
  urlToCheck.match(gatherSpaceURLRegex) ||
  (isLocalOrTest && urlToCheck.match(gatherLocalSpaceURLRegex));

export const getSpaceURLParams = (urlToParse: string) => {
  // Ensure non-Gather URLs aren't parsed
  if (!isURLGatherSpace(urlToParse)) return null;

  try {
    const parsedUrl = new URL(urlToParse);
    return parsedUrl.searchParams;
  } catch {
    // Not a valid URL
    return null;
  }
};

// This is duplicated in the gather-react-native module. We should probably put this in a shared util.
export const getErrorMessage = (error: unknown, fallbackMessage = "Unknown Error"): string => {
  if (axios.isAxiosError(error)) {
    return (
      error.response?.data.message ?? error.response?.data.errors?.[0]?.message ?? fallbackMessage
    );
  } else {
    return errMsgOrDefault(error);
  }
};

export const guaranteedError = (error: unknown): Error => {
  if (error instanceof Error) return error;

  // Without an actual Error, we don't have a stacktrace. Get a stacktrace for the bad error
  // by throwing an error locally.
  try {
    // noinspection ExceptionCaughtLocallyJS
    throw new Error("Thrown error was not an instance of Error");
  } catch (e) {
    if (!(e instanceof Error)) return Error("this will never happen");
    console.error(`Caught error not instanceof Error.

  Original error: ${error}

  Stacktrace:\n\n${e.stack}`);
    return e;
  }
};

export const errMsgOrDefault = (e: unknown): string => guaranteedError(e).message;

type ErrorContextAttributes = Record<string, string | number>;

export const isErrorContextAttributes = (
  attributes: unknown,
): attributes is ErrorContextAttributes =>
  isObject(attributes) &&
  Object.values(attributes).every(
    (value) => typeof value === "string" || typeof value === "number",
  );

export class ErrorContext {
  constructor(public attributes: ErrorContextAttributes, public originalError?: unknown) {}
}

export const buildErrorContext = (error: unknown) =>
  error ? new ErrorContext({}, error) : undefined;

export const errString = (err: unknown): string => {
  // tweak the order things are printed in so it's more useful
  let propertyNames = Object.getOwnPropertyNames(err);
  if (propertyNames.includes("message")) {
    // bring 'message' to the front
    propertyNames = ["message"].concat(propertyNames.filter((n) => n !== "message"));
  }
  if (propertyNames.includes("stack")) {
    // send stack to the back
    propertyNames = propertyNames.filter((n) => n !== "stack").concat(["stack"]);
  }
  return JSON.stringify(err, propertyNames);
};

// Parses gather url with two capture groups
// (1) UUID portion of space ID
// (2) space name portion of space ID

const parseGatherPath = (url: string) =>
  url.match(/^\/(?:app|dashboard|mapmaker)\/(\w*)\/([^/?]*)/);

const parseGatherURL = (url: string) =>
  url.match(
    /^https?:\/\/(?:(?:(?:\w|\d|-|\.)*\.)?gather\.town|(?:localhost:8080)|(?:.*ngrok(?:-free)?\.app))\/(?:app|dashboard|mapmaker)\/(\w*)\/([^/?]*)/,
  );

export const getSpaceNameFromURL = (url: string) => {
  const match = parseGatherURL(decodeURIComponent(url));
  return match?.[2] || "";
};

export const getSpaceIdFromURL = (url: string) => {
  const match = parseGatherURL(decodeURIComponent(url));
  return match ? `${match[1]}\\${match[2]}` : "";
};

export const getSpaceIdFromPath = (path: string) => {
  const match = parseGatherPath(decodeURIComponent(path));
  return match ? `${match[1]}\\${match[2]}` : "";
};

export const validateSpaceId = (spaceId: string) =>
  /^[a-zA-Z\d]+[/\\][a-zA-Z\d_-\s]+$/.test(spaceId);

export const isValidURL: (str: string) => boolean = (str) => {
  try {
    new URL(str); // if this line fails, it will throw an error and indicate it is not a valid URL
    return true;
  } catch (_) {
    return false;
  }
};

interface ParseOptions {
  markdownLinks: boolean;
}

export const parseTextFromHTML = (
  html: string,
  options: ParseOptions = { markdownLinks: true },
) => {
  // Replace line breaks
  html = html.replace(/<br>/g, "\n");

  // Replace list items. Assumes list items do not contain other HTML tags.
  html = html.replace(/<li>(.*?)<\/li>/g, (_, p1) => `\n• ${p1}`);

  // Add new line at the beginning and end of an unordered list
  html = html.replace(/<\/?ul>/g, "\n");

  // parses anchor tags into markdown links
  // use linkify() to turn markdown into links
  if (options.markdownLinks) {
    const anchorMatches = html.matchAll(/<a.*?href="(.*?)".*?>.+<\/a>/g);
    Array(...anchorMatches).forEach((match) => {
      const matchedTag = match[0] || "";
      const matchedURL = match[1] || "";
      const matchedInnerText = new DOMParser().parseFromString(matchedTag, "text/html").body
        .textContent;

      if (matchedTag && matchedURL && matchedInnerText) {
        html = html.replace(matchedTag, `[${matchedInnerText}](${matchedURL})`);
      }
    });
  }

  return new DOMParser().parseFromString(html, "text/html").documentElement.textContent;
};

export const isGatherOfficeSpace = (spaceId: string) => spaceId === GATHER_OFFICE_ID;

export function getSpacePathFromId(spaceId: string): string | null {
  const parts = spaceId.split("\\");
  const length = parts.length;
  if (length >= 2)
    return `/app/${parts[length - 2]}/${encodeURIComponent(parts[length - 1] ?? "")}`;

  return null;
}

export const getUrlFromSpaceId = (spaceId: string, baseUrl = "https://gather.town") => {
  const path = getSpacePathFromId(spaceId);
  if (!path) return "";
  return `${baseUrl}${path}`;
};

export function getNameFromSpace(spaceId: string): string {
  const temp = spaceId.split("\\");
  if (temp.length >= 2) {
    return just(temp[temp.length - 1]);
  } else {
    // Error
    return spaceId;
  }
}

export const gatherEventMessageRecipientToLegacy = (
  recipient: GatherEventMessageRecipient | GatherEventMessageRecipientLegacy,
) =>
  migrateEnum(
    recipient,
    GatherEventMessageRecipientBackwardsMigration,
    GatherEventMessageRecipientLegacy,
  );

export const gatherEventMessageRecipientToPrisma = (
  recipient: GatherEventMessageRecipient | GatherEventMessageRecipientLegacy,
) => migrateEnum(recipient, GatherEventMessageRecipientMigration, GatherEventMessageRecipient);

export const isAnonymous = (user: Pick<AuthUser, "email"> | null) => isNilOrEmpty(user?.email);

export const getRandomNumberInRange = (min: number, max: number) =>
  min + Math.random() * (max - min);

export const getRandomIntegerInRange = pipe(getRandomNumberInRange, Math.floor);

export const isOrientationType = (input: number): input is Orientation => input in Orientation;

// only allow alphanumeric, dash, underscore, and dot
export function sanitizeFilename(inputString: string) {
  return inputString.replace(/[^.A-Za-z0-9_-]+/g, "-");
}
