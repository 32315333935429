// This file currently need to stay as Node.js CommonJS module since it's also used in Webpack
// https://linear.app/gather-town/issue/PP-1720/pre-build-gather-modules-needed-by-webpack

const { isLocalOrTest } = require("gather-env-config/src/envShared");

const getApiBasePath = () => {
  // Meant to be replaced at compile time by Webpack
  // by `EnvironmentPlugin` from modules that will import this
  // or default to `window.location.origin` for backward compatibility
  if (process.env.API_BASE_PATH) {
    return process.env.API_BASE_PATH.match(/^https?:\/\//)
      ? `${process.env.API_BASE_PATH}`
      : `https://${process.env.API_BASE_PATH}`;
  }

  // gather-admin uses REACT_APP_API_BASE_PATH instead of API_BASE_PATH,
  // so check that env variable too to make this work for both gather-browser
  // and gather-admin
  if (process.env.REACT_APP_API_BASE_PATH) {
    return process.env.REACT_APP_API_BASE_PATH.match(/^https?:\/\//)
      ? `${process.env.REACT_APP_API_BASE_PATH}`
      : `https://${process.env.REACT_APP_API_BASE_PATH}`;
  }

  // This file gets loaded into amplitudes web workers where window doesn't exist.
  // It's safe to return nothing here as amplitude is not using this value
  if (typeof window === "undefined") return "";
  if (isLocalOrTest) {
    // noinspection HttpUrlsUsage
    return `http://${window.location.hostname || "localhost"}:3000`;
  }

  return window.location.origin;
};

const API_BASE_PATH = getApiBasePath();

const getSpriteServerUrl = () => {
  if (process.env.SPRITE_BASE_PATH) {
    return process.env.SPRITE_BASE_PATH.match(/^https?:\/\//)
      ? `${process.env.SPRITE_BASE_PATH}`
      : `https://${process.env.SPRITE_BASE_PATH}`;
  }

  const env = process.env.ENVIRONMENT || "prod";
  const branchName = process.env.GIT_BRANCH;
  const isBrowser = typeof window !== "undefined";

  // noinspection HttpUrlsUsage
  const urls = {
    prod: "https://dynamic-assets.gather.town",
    staging: "https://dynamic-assets.staging.gather.town",
    dev: branchName
      ? `https://${branchName}-dynamic-assets.dev.k8s.gather.town`
      : "https://dynamic-assets.staging.gather.town",
    local: `http://${isBrowser ? window.location.hostname : "localhost"}:3002`,
    test: `http://${isBrowser ? window.location.hostname : "localhost"}:3002`,
  };

  return urls[env];
};

const SPRITE_BASE_PATH = getSpriteServerUrl();

module.exports = { API_BASE_PATH, SPRITE_BASE_PATH };
