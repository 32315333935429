import {
  axios,
  AxiosRequestConfig,
  AxiosResponse,
} from "gather-common-including-video/dist/src/public/axios";
import { PromiseHttpClient } from "./constants";
import { AxiosClientEvent, axiosClientEventEmitter } from "./axiosClientEventEmitter";

// exception handler can be passed anything
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const axiosErrorHandler = (error?: any) => {
  if (error?.response?.status === 403 && error?.response?.data?.error === "SSO_AUTHENTICATION") {
    axiosClientEventEmitter.publishEvent(AxiosClientEvent.SSOAuthenticationFailed);
  }
  throw error;
};

const axiosClient: PromiseHttpClient = {
  get: <TResponse>(finalPath: string, axiosConfig: AxiosRequestConfig): Promise<TResponse> =>
    axios
      .get<TResponse>(finalPath, axiosConfig)
      .then((r: AxiosResponse) => r.data)
      .catch(axiosErrorHandler),
  post: <TResponse, D>(
    finalPath: string,
    body: D,
    axiosConfig?: AxiosRequestConfig<D>,
  ): Promise<TResponse> =>
    axios
      .post<TResponse>(finalPath, body, axiosConfig)
      .then((r: AxiosResponse) => r.data)
      .catch(axiosErrorHandler),
  patch: <TResponse, D>(
    finalPath: string,
    body: D,
    axiosConfig?: AxiosRequestConfig<D>,
  ): Promise<TResponse> =>
    axios
      .patch<TResponse>(finalPath, body, axiosConfig)
      .then((r: AxiosResponse) => r.data)
      .catch(axiosErrorHandler),
  put: <TResponse, D>(
    finalPath: string,
    body: D,
    axiosConfig?: AxiosRequestConfig<D>,
  ): Promise<TResponse> =>
    axios
      .put<TResponse>(finalPath, body, axiosConfig)
      .then((r: AxiosResponse) => r.data)
      .catch(axiosErrorHandler),
  delete: <TResponse>(finalPath: string, axiosConfig: AxiosRequestConfig): Promise<TResponse> =>
    axios
      .delete<TResponse>(finalPath, axiosConfig)
      .then((r: AxiosResponse) => r.data)
      .catch(axiosErrorHandler),
};

export default axiosClient;
