import Link from "@mui/material/Link";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import ChipList from "components/dashboard/ChipList";
import React, { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import { SpaceUserResource } from "gather-common/dist/src/public/resources/space";

interface Props {
  user: SpaceUserResource;
  // onDelete?: (userId: string) => void; // TODO: future dev
  // onUpdate?: (userId: string, roles: CoreRole[]) => void; // TODO: future dev
}

const UserRow: FC<Props> = ({ user }) => (
  <TableRow hover key={user.id}>
    <TableCell>
      <Typography color="textSecondary" variant="body2">
        {user.id}
      </Typography>
    </TableCell>

    <TableCell>
      <Link
        component={RouterLink}
        to={`/dashboard/users?user=${encodeURIComponent(user.email || "")}`}
        underline="always"
        color="textPrimary"
        variant="body2"
      >
        {user.email}
      </Link>
    </TableCell>

    <TableCell>
      <ChipList list={user.role ? [user.role] : []} />
    </TableCell>
  </TableRow>
);

export default UserRow;
