// these type shenanigans are to make sure you can only pass in an enum where the values match the key names
export const stringToEnum = <T, K>(
  str: string,
  enumType: { [K in keyof T]: K },
  enumName: string,
): K => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const value = (enumType as unknown as { [k: string]: K })[str];
  if (typeof value === "undefined") throw new Error(`Invalid value for enum ` + enumName);

  return value;
};

export type MakeFieldsRequired<T, K extends keyof T> = Required<Pick<T, K>> & T;
