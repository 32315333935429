/* External modules */
import React, { FC } from "react";
import { Field, FormikProps } from "formik";

/* MUI Components */
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

/* Material Icons */
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { DateTime } from "luxon";
import {
  WearableColors,
  WearableLayerLegacy,
  WearableSubType,
  WearableType,
} from "gather-common/dist/src/public/resources/users";

export interface WearableFields {
  type: WearableType | "";
  subType: WearableSubType | "";
  isDefault: boolean;
  startDate?: DateTime | null;
  endDate?: DateTime | null;
  style?: string;
}

interface Props {
  setFiles: (files: FileList | null) => void;
  log: JSX.Element[];
  setLog: (log: JSX.Element[]) => void;
}

const WearablesForm: FC<Props & FormikProps<WearableFields>> = (props) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldValue,
    isSubmitting,
  } = props;

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Box sx={{ display: "flex", gap: 10, mt: 1 }}>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Box
            sx={{
              minWidth: 200,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="h6">Valid Layer IDs:</Typography>
            <ul>
              {Object.values(WearableLayerLegacy).map((id) => (
                <li key={id}>{id}</li>
              ))}
            </ul>
          </Box>

          <Box
            sx={{
              minWidth: 240,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {values.type && (
              <>
                <Typography variant="h6">Valid Colors for "{values.type}":</Typography>
                <ul>
                  {(values.type === WearableType.Costume && values.subType
                    ? [...Object.keys(WearableColors[values.subType])]
                    : [...Object.keys(WearableColors[values.type])]
                  ).map((id) => (
                    <li key={id}>{id}</li>
                  ))}
                </ul>
              </>
            )}
          </Box>
        </Box>

        <Box sx={{ width: "100%" }}>
          <Box sx={{ maxWidth: 220 }}>
            <Typography variant="subtitle1" sx={{ width: "100%" }}>
              Select Wearable Type
            </Typography>
            <TextField
              fullWidth
              select
              label="Wearable Type"
              name="type"
              value={values.type}
              onChange={handleChange}
              error={Boolean(touched.type && errors.type)}
              helperText={touched.type && errors.type}
            >
              {Object.values(WearableType).map((type) => (
                <MenuItem key={type} value={type}>
                  <Typography>{type}</Typography>
                </MenuItem>
              ))}
            </TextField>
          </Box>

          {values.type === WearableType.Costume && (
            <>
              <Box sx={{ mt: 3, maxWidth: 220 }}>
                <Typography variant="subtitle1" sx={{ width: "100%" }}>
                  Select Wearable Subtype
                </Typography>
                <TextField
                  fullWidth
                  select
                  label="Wearable Subtype"
                  name="subType"
                  value={values.subType}
                  onChange={handleChange}
                  error={Boolean(touched.subType && errors.subType)}
                  helperText={touched.subType && errors.subType}
                >
                  {Object.values(WearableSubType).map((subType) => (
                    <MenuItem key={subType} value={subType}>
                      <Typography>{subType}</Typography>
                    </MenuItem>
                  ))}
                </TextField>
              </Box>

              <Box sx={{ mt: 3, display: "flex" }}>
                <Box>
                  <Typography variant="subtitle1" sx={{ width: "100%" }}>
                    Set Costume Start Time (Optional)
                  </Typography>
                  <DateTimePicker
                    label="Start Time"
                    value={values.startDate}
                    onChange={(value: unknown) => setFieldValue("startDate", value)}
                    renderInput={(inputProps: TextFieldProps) => (
                      <TextField
                        type="datetime-local"
                        name="startDate"
                        {...inputProps}
                        error={Boolean(errors.startDate)}
                        helperText={errors.startDate}
                        onBlur={handleBlur}
                        variant="outlined"
                      />
                    )}
                  />
                </Box>

                <Box sx={{ ml: 2 }}>
                  <Typography variant="subtitle1" sx={{ width: "100%" }}>
                    Set Costume End Time (Optional)
                  </Typography>
                  <DateTimePicker
                    label="End Time"
                    value={values.endDate}
                    onChange={(value: unknown) => setFieldValue("endDate", value)}
                    renderInput={(inputProps: TextFieldProps) => (
                      <TextField
                        type="datetime-local"
                        name="endDate"
                        {...inputProps}
                        error={Boolean(errors.endDate)}
                        helperText={errors.endDate}
                        onBlur={handleBlur}
                        variant="outlined"
                      />
                    )}
                  />
                </Box>
              </Box>
            </>
          )}

          <Box sx={{ mt: 3 }}>
            <Field
              as={FormControlLabel}
              type="checkbox"
              name="isDefault"
              control={<Checkbox />}
              label="isDefault"
            />
          </Box>

          <Box sx={{ mt: 3 }}>
            <Typography variant="subtitle1" sx={{ width: "100%" }}>
              Wearable Style
            </Typography>
            <TextField
              fullWidth
              label="Wearable Style"
              name="style"
              value={values.style}
              onChange={handleChange}
            ></TextField>
          </Box>

          <Box sx={{ mt: 3, display: "flex" }}>
            <Box>
              <Typography variant="subtitle1" sx={{ width: "100%" }}>
                Upload Wearables Directory:
              </Typography>
            </Box>
            <Box sx={{ ml: 2 }}>
              <input
                type="file"
                name="fileList"
                id="mapImage"
                onChange={(e) => props.setFiles(e.target.files)}
                // @ts-expect-error
                webkitdirectory=""
              />
            </Box>
          </Box>

          <Box sx={{ p: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button
              color="primary"
              disabled={isSubmitting}
              sx={{ ml: 1 }}
              type="submit"
              variant="contained"
            >
              Add Wearables
            </Button>
          </Box>
        </Box>
      </Box>

      <Divider sx={{ mt: 5 }} />

      <Box sx={{ p: 2, display: "flex", justifyContent: "flex-end", flexDirection: "column" }}>
        <Typography variant="h6" sx={{ width: "100%" }}>
          Logs
        </Typography>
        {props.log}
      </Box>
    </form>
  );
};

export default WearablesForm;
