import { DateTime } from "luxon";
import { SortOption } from "components/inputs/SortSelect";
import { AdminReservation } from "gather-admin-common/dist/src/public/reservations/types";

/**
 * Simple decorator for dates in the Gather Admin front-end
 * @param date - Date to be converted into a string
 * @param format - string format for how the date will be displayed
 * @returns string
 */
export const displayDate = (date: string | null | undefined, format = "MMM d, y | h:mm a") => {
  if (date) {
    const dt = DateTime.fromISO(date).toLocal();
    return dt.toFormat(format);
  }

  return "";
};

/**
 * Filters out any cancelled and/or expired reservations
 * @param reservations - list of reservations to filter
 * @returns AdminReservation[]
 */
export const applyActiveFilter: (reservations: AdminReservation[]) => AdminReservation[] = (
  reservations,
) =>
  reservations.filter((reservation) => {
    let matches = true;
    const pastEvent = reservation.endDate && new Date(reservation.endDate) < new Date();

    if (reservation.canceled || pastEvent) {
      matches = false;
    }

    return matches;
  });

/**
 * Filters out any uncancelled reservations
 * @param reservations - list of reservations to filter
 * @returns AdminReservation[]
 */
export const applyCancelledFilter: (reservations: AdminReservation[]) => AdminReservation[] = (
  reservations,
) =>
  reservations.filter((reservation) => {
    const pastEvent = reservation.endDate && new Date(reservation.endDate) < new Date();
    return reservation.canceled && pastEvent;
  });

/**
 * Filters out any unexpired reservations
 * @param reservations - list of reservations to filter
 * @returns AdminReservation[]
 */
export const applyPastFilter: (reservations: AdminReservation[]) => AdminReservation[] = (
  reservations,
) =>
  reservations.filter((reservation) => {
    const pastEvent = reservation.endDate && new Date(reservation.endDate) < new Date();
    return pastEvent;
  });

/**
 * Common sorting options for reservations
 */
export const sortOptions: SortOption[] = [
  {
    label: "Start Date (asc)",
    value: "startDate|asc",
  },
  {
    label: "Start Date (desc)",
    value: "startDate|desc",
  },
  {
    label: "End Date (asc)",
    value: "endDate|asc",
  },
  {
    label: "End Date (desc)",
    value: "endDate|desc",
  },
  {
    label: "Capacity (lowest)",
    value: "capacity|asc",
  },
  {
    label: "Capacity (highest)",
    value: "capacity|desc",
  },
];

/**
 * Common filtering and tab options for reservations
 */
export const filterOptions: SortOption[] = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Active & Upcoming",
    value: "isActive",
  },
  {
    label: "Past",
    value: "isPastEvent",
  },
  {
    label: "Cancelled",
    value: "isCancelled",
  },
];
