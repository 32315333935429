import { keys } from "lodash";
import { fromPairs } from "ramda";
import React, { FC, useMemo } from "react";
import { JSONTree } from "react-json-tree";
import { useCurrentTimestamp, useSessionMessages } from "state/avClientStateViewerSlice";
import { Pane } from "./ui";
import { getSnapshots, isPlayerInfoMessage } from "./utils";
import { ViewerMessage } from "./types";
import { getVideoStats, VIDEO_STATS_KEYS_TO_PATH } from "./videoOverview.logic";

export type VideoOverviewProps = {
  snapshot?: ViewerMessage;
};

const VideoOverview: FC<VideoOverviewProps> = () => {
  const currentTimestamp = useCurrentTimestamp();
  const sessionMessages = useSessionMessages();
  const rawSnapshots = useMemo(() => getSnapshots(sessionMessages), [sessionMessages]);
  const snapshots = useMemo(() => fromPairs(rawSnapshots), [rawSnapshots]);

  const snapshot = snapshots[currentTimestamp ?? ""];
  const videoStats = useMemo(
    () =>
      snapshot && isPlayerInfoMessage(snapshot)
        ? getVideoStats(VIDEO_STATS_KEYS_TO_PATH, snapshot)
        : {},
    [snapshot],
  );

  return (
    <Pane>
      <div className="videoStats">
        {keys(videoStats).map((participant) => (
          <div key={participant} className="video-block">
            <div>
              <div>{participant}</div>
              <JSONTree hideRoot data={videoStats[participant]} />
            </div>
          </div>
        ))}
      </div>
    </Pane>
  );
};

export default VideoOverview;
