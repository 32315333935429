import React, { FC, SyntheticEvent, ChangeEvent, useCallback, useState, useMemo } from "react";
import { toast } from "react-hot-toast";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import PlusIcon from "@mui/icons-material/Add";

import AlertDialog from "components/dashboard/AlertDialog";
import TabWrapper from "components/dashboard/TabWrapper";
import SortSelect from "components/inputs/SortSelect";
import PageContainer from "components/layout/dashboard/PageContainer";
import DiscountsTable from "./partials/DiscountsTable";
import useRoles from "features/roles/useRoles";
import { AdminDiscount } from "features/discounts/types";
import { useDiscounts } from "features/discounts/hooks";
import NewDiscountForm from "./partials/NewDiscountForm";
import useDeleteDiscount from "features/discounts/mutations/useDeleteDiscount";
import EditDiscountForm from "./partials/EditDiscountForm";
import {
  applyDiscountIdFilter,
  applyDiscountStateFilter,
  applyDiscountTypeFilter,
} from "features/discounts/utils";
import { AdminPermission as Can } from "gather-admin-common/dist/src/public/roles/types";

const PAGE_TITLE = "Manage Discounts";
const DEFAULT_SEARCH_TEXT = "Search by ID, Description, or Subscription Code";
const MULTIPLIER_SEARCH_TEXT = "Search by ID or Description";
const DELETE_CONFIRMATION =
  "Are you sure you want to delete this discount? This action is not reversible.";

const stateFilterOptions = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Active",
    value: "Active",
  },
  {
    label: "Max Uses",
    value: "Max Uses",
  },
  {
    label: "Not Started",
    value: "Not Started",
  },
  {
    label: "Expired",
    value: "Expired",
  },
];

const DiscountsIndexPage: FC = () => {
  const { data: discounts, isLoading } = useDiscounts();
  const typeList: string[] = ["all", "multiplier", "subscription"];
  const { permissions } = useRoles();
  const [currentTab, setCurrentTab] = useState<string>("all");
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [isCreating, setIsCreating] = useState<boolean>(false); // determines which button to display on modal
  const [discountToUpdate, setDiscountToUpdate] = useState<AdminDiscount>();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
  const [discountToDelete, setDiscountToDelete] = useState<string>("");
  const [stateFilter, setStateFilter] = useState("all");
  const [idFilter, setIdFilter] = useState<string>("");

  const filteredDiscounts: AdminDiscount[] = useMemo(() => {
    if (!discounts) return [];

    const filteredByType = applyDiscountTypeFilter(discounts || [], currentTab);
    const filteredByState = applyDiscountStateFilter(filteredByType, stateFilter);
    const finalFilteredResults = applyDiscountIdFilter(filteredByState, idFilter);

    return finalFilteredResults;
  }, [discounts, currentTab, stateFilter, idFilter]);

  const handleTabsChange = useCallback(
    (_event: SyntheticEvent<Element>, value: string) => {
      setCurrentTab(value);
    },
    [setCurrentTab],
  );

  const handleStateFilterChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setStateFilter(event.target.value);
    },
    [setStateFilter],
  );

  const handleDiscountIdChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setIdFilter(event.target.value);
    },
    [setIdFilter],
  );

  const handleModalOpen = useCallback(
    (isNew: boolean) => {
      setModalOpen(true);
      setIsCreating(isNew);
    },
    [setModalOpen, setIsCreating],
  );

  const handleModalClose = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  const handleEdit = useCallback(
    (discountId: string) => {
      if (discounts) {
        const discountIndex = discounts.findIndex((d) => d.id === discountId);
        const target = discounts[discountIndex];
        setDiscountToUpdate(target);
        handleModalOpen(false);
      }
    },
    [handleModalOpen, setDiscountToUpdate, discounts],
  );

  const onSuccessfulDeletion = () => {
    toast.success("Discount was successfully deleted.");
  };

  const onDeletionError = () => {
    toast.error(`Error: Discount "${discountToDelete}" could not be deleted.`);
  };

  const confirmDeletion = useCallback(
    (discount: string) => {
      setShowDeleteConfirmation(true);
      setDiscountToDelete(discount);
    },
    [setShowDeleteConfirmation],
  );

  const { mutate: deleteDiscount } = useDeleteDiscount({
    onSuccess: onSuccessfulDeletion,
    onError: onDeletionError,
  });

  const handleDelete = useCallback(
    (confirm: boolean) => {
      setShowDeleteConfirmation(false);

      if (confirm) {
        deleteDiscount(discountToDelete);
      }
    },
    [setShowDeleteConfirmation, deleteDiscount, discountToDelete],
  );

  return (
    <PageContainer
      pageTitle={PAGE_TITLE}
      buttons={
        <Button
          color="primary"
          startIcon={<PlusIcon fontSize="small" />}
          variant="contained"
          onClick={() => handleModalOpen(true)}
        >
          Add Discount
        </Button>
      }
    >
      <Box sx={{ minWidth: 1100 }}>
        <Box>
          <Tabs
            indicatorColor="primary"
            onChange={handleTabsChange}
            scrollButtons="auto"
            textColor="primary"
            value={currentTab}
            variant="scrollable"
          >
            {typeList.map((tab) => (
              <Tab key={tab} label={tab} value={tab} />
            ))}
          </Tabs>

          <Divider />

          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexWrap: "wrap",
              pl: 1,
              pr: 1,
              mt: 2,
              ml: -1,
              gap: 1,
            }}
          >
            <Box
              sx={{
                maxWidth: "100%",
                width: 500,
              }}
            >
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  ),
                }}
                onChange={handleDiscountIdChange}
                placeholder={
                  currentTab === "multiplier" ? MULTIPLIER_SEARCH_TEXT : DEFAULT_SEARCH_TEXT
                }
                value={idFilter}
                variant="outlined"
              />
            </Box>

            <Box
              sx={{
                maxWidth: "100%",
                width: 150,
              }}
            >
              <SortSelect
                onChange={handleStateFilterChange}
                value={stateFilter}
                options={stateFilterOptions}
                label="Filter by"
              />
            </Box>
          </Box>

          <TabWrapper>
            {isLoading && <Box>Loading...</Box>}

            {!isLoading && discounts && (
              <Card>
                <DiscountsTable
                  discounts={filteredDiscounts}
                  displayType={currentTab}
                  canEdit={permissions.includes(Can.ManageDiscounts)}
                  onEdit={handleEdit}
                  onDelete={confirmDeletion}
                />
              </Card>
            )}

            {!isLoading && !discounts && <Box>There's nothing here</Box>}
          </TabWrapper>
        </Box>
      </Box>

      <Dialog fullWidth maxWidth="sm" onClose={handleModalClose} open={modalOpen}>
        {modalOpen &&
          (isCreating ? (
            <NewDiscountForm onClose={handleModalClose} />
          ) : (
            <EditDiscountForm onClose={handleModalClose} discount={discountToUpdate} />
          ))}
      </Dialog>

      <AlertDialog
        isOpen={showDeleteConfirmation}
        onClose={handleDelete}
        title={`Confirm Deletion of ${discountToDelete}`}
      >
        {DELETE_CONFIRMATION}
      </AlertDialog>
    </PageContainer>
  );
};

export default DiscountsIndexPage;
