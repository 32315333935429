import React, { FC } from "react";
import Label from "components/dashboard/Label";

interface Props {
  condition: boolean;
}

const BooleanLabel: FC<Props> = ({ condition }) => (
  <Label color={condition ? "success" : "info"}>{condition ? "Yes" : "No"}</Label>
);

export default BooleanLabel;
