import {
  ActivityStatus,
  DBOutfit,
  Inventory,
  MoveDirectionEnum_ENUM as MoveDirection,
  RequestToLeadResponseEnum_ENUM as RequestToLeadResponse,
  SpriteDirectionEnum_ENUM as SpriteDirection,
  WireObject,
} from "./generated_DO_NOT_TOUCH/events";

export { SpriteDirection, MoveDirection, RequestToLeadResponse };

// saved customization when a desk was unclaimed
export interface BackedUpDeskObjectDB {
  obj: WireObject | undefined;
  offsetX: number;
  offsetY: number;
}

// when desks are unclaimed we save the customizations so that they can be reapplied to newly claimed desks
export interface BackupDeskObjectsDB {
  objects: { [key: string]: BackedUpDeskObjectDB };
}

// TODO: Change this when we deprecate the locked field from deskinfo
// an assigned desk must have a `mapId` and `deskId`
export type AssignedDeskInfoDB = {
  mapId: string;
  deskId: string;
  locked?: boolean;
  lastDeskObjects?: BackupDeskObjectsDB;
};

// an unassigned desk must have both `mapId` and `deskId` unset
export type UnassignedDeskInfoDB = {
  mapId?: "" | undefined;
  deskId?: "" | undefined;
  lastDeskObjects?: BackupDeskObjectsDB;
};

export type DeskInfoDB = Omit<AssignedDeskInfoDB | UnassignedDeskInfoDB, "description">;

export function isAssignedDeskInfo(
  deskInfo: DeskInfoDB | undefined | null,
): deskInfo is AssignedDeskInfoDB {
  return !!deskInfo?.mapId && !!deskInfo.deskId;
}

// types shared between MemberInfo and Player -- ideally there are none :P (NGN-109)
// Note: as we separate them, make the removed fields `never` where they shouldn't be, so TS helps catch bugs
export type BaseRoomUserDB = {
  city?: string;
  connected?: boolean;
  country?: string;
  currentlyEquippedWearables?: DBOutfit;
  description?: string;
  deskInfo?: DeskInfoDB;
  handRaisedAt?: string;
  map?: string; // used by officeConfiguration.ts
  name?: string;
  personalImageId?: string;
  personalImageUrl?: string;
  phone?: string;
  profileImageId?: string;
  profileImageUrl?: string;
  pronouns?: string;
  startDate?: string;
  timezone?: string;
  title?: string;
  x?: number; // used by officeConfiguration.ts
  y?: number; // used by officeConfiguration.ts
  // spaceUserUuid is the 'id' field in the SpaceUser table in CRDB, which needs its own property while the migration
  // to CRDB is in progress
  spaceUserUuid?: string;
  lastVisited?: string;
};

export enum PlayerStatusOption {
  Available = "Available",
  Busy = "Busy",
  DoNotDisturb = "DoNotDisturb",
}

export enum PlayerStatusChangeSource {
  Manual = "Manual",
  SleepManager = "SleepManager",
}

export enum PlayerStatusEndOption {
  NONE_SELECTED = "NONE_SELECTED",
  THIRTY_MINUTES = "THIRTY_MINUTES",
  ONE_HOUR = "ONE_HOUR",
  TWO_HOURS = "TWO_HOURS",
  TOMORROW = "TOMORROW",
}

export interface AmbientPlayersMuted {
  [playerId: string]: boolean;
}

export type PlayerStatus = {
  status?: PlayerStatusOption;
  statusChangeSource?: PlayerStatusChangeSource;
  statusExpiresAt?: string;
  statusExpiresToStatus?: PlayerStatusOption;
  statusExpiresToChangeSource?: PlayerStatusChangeSource;
  statusExpiresToExpireTime?: string;
};
// { [key in keyof Required<MemberInfoDB>]: true } forces us to keep this object updated with all required or optional fields in MemberInfoDB
// This allows us to do runtime checks and picking/omitting of fields when reading from CRDB.
const PlayerStatusSelect: { [key in keyof Required<PlayerStatus>]: true } = {
  status: true,
  statusChangeSource: true,
  statusExpiresAt: true,
  statusExpiresToStatus: true,
  statusExpiresToChangeSource: true,
  statusExpiresToExpireTime: true,
};
export const playerStatusFields = Object.keys(PlayerStatusSelect);

// This is the schema for player info persisted to the db. These fields are stored in the
// `rooms/users` collection, and should be set through actions sent to the game server:
// `gameSpace.setPlayerRoomInfo`.
// Right now everything is optional, because we don't have any real guarantees the data in the
// DB is actually not null.
// If you add something here, it will be persisted to the DB. Also make sure add it to the
// `PlayerDBFields` class below.
// (This will eventually be generated from Prisma.)
export type PlayerDB = PlayerStatus &
  BaseRoomUserDB & {
    affiliation?: string;
    allowScreenPointer?: boolean;
    ambientPlayersMuted?: AmbientPlayersMuted;
    direction?: SpriteDirection;
    displayEmail?: string;
    emojiStatus?: string;
    focusModeEndTime?: string;
    inventory?: Inventory;
    isNpc?: boolean;
    itemString?: string;
    lastRaisedHand?: string;
    lastVisited?: string;
    textStatus?: string;
    statusEndOption?: PlayerStatusEndOption; // Will get deprecated in [APP-6196]
    statusUpdatedAt?: string; // Will get deprecated in [APP-6196]
    // if it's not actually in the DB, it'll be provided on reading from the DB via the converter
    role?: CoreRole;
  };

// This is currently redundant to PlayerDB, but there will come a time when not all fields on
// PlayerDB are optional, and then the partial type will become more significant.
export type PlayerDBPartial = Partial<PlayerDB>;

// This class is used for runtime checking of the fields on a data blob passed to the game-server.
// It needs to be a class that implements the type, so we can instantiate a dummy object and then
// do runtime checks about the fields on the incoming payload, e.g. iterating across the keys
// of the dummy object fields and validating things about the payload.
// It also serves as the base class to `Player` which is used to create a default Player object.
// These are fields that are part of the schema, so default values here will be the default
// values persisted to the DB.
export class PlayerDBFields implements PlayerDB {
  affiliation = "";
  allowScreenPointer = true;
  connected = false;
  // have to set this as undefined so the field exists on an instance of PlayerDBFields
  currentlyEquippedWearables?: DBOutfit = undefined;
  description = "";
  deskInfo: DeskInfoDB = {};
  displayEmail = "";
  emojiStatus = "";
  focusModeEndTime = "";
  ambientPlayersMuted: AmbientPlayersMuted = {};
  inventory: Inventory = { items: {}, order: {} };
  isNpc = false;
  itemString = "";
  map = "";
  name = "";
  personalImageId = "";
  personalImageUrl = "";
  phone = "";
  profileImageId = "";
  profileImageUrl = "";
  pronouns = "";
  textStatus = "";
  timezone = "";
  title = "";
  city = "";
  country = "";
  status: PlayerStatusOption = PlayerStatusOption.Available;
  statusUpdatedAt = "";
  statusEndOption = PlayerStatusEndOption.NONE_SELECTED;
  statusChangeSource: PlayerStatusChangeSource = PlayerStatusChangeSource.Manual;
  statusExpiresAt = "";
  statusExpiresToStatus: PlayerStatusOption = PlayerStatusOption.DoNotDisturb;
  statusExpiresToChangeSource: PlayerStatusChangeSource = PlayerStatusChangeSource.SleepManager;
  statusExpiresToExpireTime = "";
  lastRaisedHand = "";
  lastVisited = "";
  handRaisedAt = "";
  startDate = "";
  x = 0;
  y = 0;
  direction: SpriteDirection = SpriteDirection.Down;
  spaceUserUuid = "";
  role: CoreRole = CoreRole.Guest;
}

// { [key in keyof Required<PlayerDB>]: true } forces us to keep this object updated with all required or optional fields in PlayerDB
// This allows us to do runtime checks and picking/omitting of fields when reading from CRDB.
const PlayerDBSelect: { [key in keyof Required<PlayerDB>]: true } = {
  affiliation: true,
  allowScreenPointer: true,
  ambientPlayersMuted: true,
  city: true,
  connected: true,
  country: true,
  currentlyEquippedWearables: true,
  description: true,
  deskInfo: true,
  direction: true,
  displayEmail: true,
  emojiStatus: true,
  focusModeEndTime: true,
  handRaisedAt: true,
  inventory: true,
  isNpc: true,
  itemString: true,
  lastRaisedHand: true,
  map: true,
  name: true,
  personalImageId: true,
  personalImageUrl: true,
  phone: true,
  profileImageId: true,
  profileImageUrl: true,
  pronouns: true,
  startDate: true,
  status: true,
  statusEndOption: true,
  statusUpdatedAt: true,
  statusChangeSource: true,
  statusExpiresAt: true,
  statusExpiresToStatus: true,
  statusExpiresToChangeSource: true,
  statusExpiresToExpireTime: true,
  textStatus: true,
  timezone: true,
  title: true,
  x: true,
  y: true,
  spaceUserUuid: true,
  role: true,
  lastVisited: true,
};

export const PlayerFields = Object.keys(PlayerDBSelect);

// Player is what the server and client use in-memory, not necessarily what is stored in the DB.
// Look to the PlayerDB to get the DB schema.
export class Player extends PlayerDBFields {
  userUuid = ""; // TODO [APP-6128] Deprecate this field.
  spaceUserUuid = "";
  ghost = 0;
  // TODO This should survive a server restart, but it doesn't. We need to fix that after this feature prototype.
  //  Maybe on the client side? https://linear.app/gather-town/issue/APP-7268/[tracking][exp]-allow-users-to-shimmy-througharound-other-people
  shimmy = false;
  spotlighted = 0;
  emote: string | undefined = undefined;
  away = false;
  activelySpeaking = 0;
  lastActive = "";
  lastInputId = 0;
  whisperId = "";
  isSignedIn = false;
  eventStatus = "";
  inConversation = false;
  vehicleId = "";
  speedModifier = 1;
  isAlone = true;
  isMobile = false;
  followTarget = "";
  manualVideoSrc = "";
  subtitle = "";
  activityStatus: ActivityStatus = {};

  constructor(public readonly id: string) {
    super();
  }
}

// Owner, Builder, General Member are for RW
// Mod and Speaker are for events
export enum CoreRole {
  Builder = "Builder",
  Mod = "Mod",
  Owner = "Owner",
  GeneralMember = "GeneralMember",
  Speaker = "Speaker",
  RecordingClient = "RecordingClient",
  Guest = "Guest",
}
