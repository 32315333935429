import React, { FC, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";

/* MUI Components */
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";

/* Material Icons */
// import ClearIcon from "@mui/icons-material/Clear"; // TODO @alecia: add back in
import DeleteIcon from "@mui/icons-material/Delete";

import PermittedComponent from "components/authentication/PermittedComponent";
import PageContainer from "components/layout/dashboard/PageContainer";
import { BreadcrumbTrail } from "components/layout/dashboard/Breadcrumbs";
import { useWearables } from "features/wearables/hooks";
import { organizeWearablesData } from "./utils";
import WearableDetails from "./partials/WearableDetails";
import useDeleteWearable from "features/wearables/mutations/useDeleteWearable";
import AlertDialog from "components/dashboard/AlertDialog";
import { Wearable } from "gather-common/dist/src/public/resources/users";
import { AdminPermission as Can } from "gather-admin-common/dist/src/public/roles/types";

const PAGE_TITLE = "Wearable Details";
const DELETE_CONFIRMATION =
  "Are you sure you want to delete this wearable? This action is not reversible.";

const WearablesDetailsPage: FC = () => {
  const { wearableName } = useParams();
  const { data: wearables, isLoading } = useWearables();
  const [wearableData, setWearableData] = useState<Wearable[]>();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
  const navigate = useNavigate();

  const onSuccessfulDeletion = () => {
    toast.success("Wearable was successfully deleted.");
  };

  const onDeletionError = () => {
    toast.error(`Error: Wearable "${wearableName}" could not be deleted.`);
  };

  const confirmDeletion = useCallback(
    () => setShowDeleteConfirmation(true),
    [setShowDeleteConfirmation],
  );

  const { mutate: deleteWearable } = useDeleteWearable({
    onSuccess: onSuccessfulDeletion,
    onError: onDeletionError,
  });

  const handleDelete = useCallback(
    (confirm: boolean) => {
      setShowDeleteConfirmation(false);

      if (confirm && wearableData) {
        wearableData.map((wearable) => deleteWearable(wearable.id));
        navigate("/dashboard/wearables");
      }
    },
    [setShowDeleteConfirmation, deleteWearable, wearableData, navigate],
  );

  const tempName = decodeURIComponent(wearableName ?? "");

  const breadcrumbs: BreadcrumbTrail[] = [
    {
      to: "/dashboard/wearables",
      title: "Manage Wearables",
    },
  ];

  useEffect(() => {
    if (!wearables) return;

    const wearablesData = organizeWearablesData(wearables);
    Object.values(wearablesData).map((wearablesByType) => {
      if (tempName in wearablesByType) {
        setWearableData(wearablesByType[tempName]);
      }
    });
  }, [wearables]);

  return (
    <PageContainer
      pageTitle={PAGE_TITLE}
      breadcrumbs={breadcrumbs}
      buttons={
        !isLoading &&
        wearableData && (
          <PermittedComponent permissions={[Can.ManageWearables]} requiresAll={true}>
            <Tooltip title="NOTE: You cannot undo this" placement="top" arrow>
              <Button
                color="error"
                onClick={confirmDeletion}
                variant="contained"
                startIcon={<DeleteIcon />}
              >
                Delete
              </Button>
            </Tooltip>
          </PermittedComponent>
        )
      }
    >
      {isLoading ? (
        <Box>Loading...</Box>
      ) : (
        wearableData && <WearableDetails wearableVariants={wearableData} />
      )}
      <AlertDialog
        isOpen={showDeleteConfirmation}
        onClose={handleDelete}
        title={`Confirm Deletion of ${wearableName}`}
      >
        {DELETE_CONFIRMATION}
      </AlertDialog>
    </PageContainer>
  );
};

export default WearablesDetailsPage;
