// List of all possible permissions
enum MAP_INSPECTOR_PERMISSIONS {
  ViewMapInspector = "ViewMapInspector",
  UpdateMaps = "UpdateMaps",
  UpdateTutorialAreas = "UpdateTutorialAreas",
}

enum OBJECT_PICKER_PERMISSIONS {
  ManageObjectTemplateCategories = "ManageObjectTemplateCategories",
  ViewObjectTemplates = "ViewObjectTemplates",
  ManageObjectTemplates = "ManageObjectTemplates",
}

enum WEARABLES_PERMISSIONS {
  ManageWearables = "ManageWearables",
}

enum RESERVATIONS_PERMISSIONS {
  CreateReservations = "CreateReservations",
  ViewReservations = "ViewReservations",
  UpdateReservations = "UpdateReservations",
  DeleteReservations = "DeleteReservations",
}

enum DISCOUNTS_PERMISSIONS {
  ViewDiscounts = "ViewDiscounts",
  ManageDiscounts = "ManageDiscounts",
}

enum ROLES_PERMISSIONS {
  ManageRoles = "ManageRoles",
}

enum SPACES_PERMISSIONS {
  ManageSpaces = "ManageSpaces",
  ViewSpaces = "ViewSpaces",
  EditSpaces = "EditSpaces",
  ToggleSpaceStaffAccess = "ToggleSpaceStaffAccess", // TODO @alecia - remove this permission later
}

enum USERS_PERMISSIONS {
  ManageUsers = "ManageUsers",
}

enum ADMIN_USERS_PERMISSIONS {
  ManageAdminUsers = "ManageAdminUsers",
  UpdateAdminUsers = "UpdateAdminUsers",
}

enum AV_PERMISSIONS {
  ViewClientStateSessions = "ViewClientStateSessions",
}

enum MOBILE_PERMISSIONS {
  ManageMobileDeviceTokens = "ManageMobileDeviceTokens",
}

export const AdminPermission = {
  ...MAP_INSPECTOR_PERMISSIONS,
  ...OBJECT_PICKER_PERMISSIONS,
  ...WEARABLES_PERMISSIONS,
  ...RESERVATIONS_PERMISSIONS,
  ...DISCOUNTS_PERMISSIONS,
  ...ROLES_PERMISSIONS,
  ...SPACES_PERMISSIONS,
  ...USERS_PERMISSIONS,
  ...ADMIN_USERS_PERMISSIONS,
  ...AV_PERMISSIONS,
  ...MOBILE_PERMISSIONS,
};

export type AdminPermission =
  | MAP_INSPECTOR_PERMISSIONS
  | OBJECT_PICKER_PERMISSIONS
  | WEARABLES_PERMISSIONS
  | RESERVATIONS_PERMISSIONS
  | DISCOUNTS_PERMISSIONS
  | ROLES_PERMISSIONS
  | SPACES_PERMISSIONS
  | USERS_PERMISSIONS
  | ADMIN_USERS_PERMISSIONS
  | AV_PERMISSIONS
  | MOBILE_PERMISSIONS;

export interface AdminRoleGroup {
  permissions: AdminPermission[];
}
