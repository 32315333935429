import { throttle } from "lodash";
import React, { FC, PropsWithChildren, useEffect, useMemo, useRef } from "react";
import scrollIntoView from "smooth-scroll-into-view-if-needed";

export function autoScrollClasses(currentTimestamp: number, timestamp: number) {
  return `${currentTimestamp === timestamp ? "autoscroll" : ""} ${
    currentTimestamp < timestamp ? "future" : "past"
  }`;
}

const AutoScroll: FC<PropsWithChildren<{ watch: unknown; className: string }>> = ({
  children,
  watch,
  ...props
}) => {
  const bodyRef = useRef<HTMLDivElement>(null);

  const debouncedScrollIntoView = useMemo(
    () =>
      throttle(scrollIntoView, 150, {
        leading: false,
        trailing: true,
      }),
    [],
  );

  useEffect(() => {
    if (watch) {
      // Chrome does not like to animate scrolling for multiple elements at the same time
      const el = bodyRef.current;

      const target = el?.querySelector(".autoscroll") ?? el?.querySelector(".future");
      if (target) {
        debouncedScrollIntoView(target, {
          scrollMode: "if-needed",
          block: "center",
          inline: "center",
          behavior: "smooth",
        });
      }
    }
  }, [watch]);

  return (
    <div ref={bodyRef} {...props}>
      {children}
    </div>
  );
};

export default AutoScroll;
