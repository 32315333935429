/* External modules */
import React, { FC } from "react";

/* Local modules */
import PageContainer from "components/layout/dashboard/PageContainer";
import { BreadcrumbTrail } from "components/layout/dashboard/Breadcrumbs";
import NewObjectTemplateForm from "./partials/NewObjectTemplateForm";

const PAGE_TITLE = "New Object Template";

const breadcrumbs: BreadcrumbTrail[] = [
  {
    to: "/dashboard/mapmaker/objects",
    title: "Manage Object Templates",
  },
];

const ObjectTemplateNewFormPage: FC = () => (
  <PageContainer pageTitle={PAGE_TITLE} breadcrumbs={breadcrumbs}>
    <NewObjectTemplateForm />
  </PageContainer>
);

export default ObjectTemplateNewFormPage;
