import { axios } from "gather-common-including-video/dist/src/public/axios";
import { AdminRoleTypePrisma as AdminRole } from "gather-prisma-types/dist/src/public/client";
import { authTokenManager } from "gather-auth-client/dist/src/public/auth";
import { AdminUser } from "gather-admin-common/dist/src/public/auth/types";

const ORIGIN = process.env.REACT_APP_API_BASE_PATH;

export const fetchAdminUsers: () => Promise<AdminUser[]> = async () => {
  const authToken = await authTokenManager.waitForToken();

  try {
    const response = await axios.get(`${ORIGIN}/api/v2/admin/admin-users`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    const users: AdminUser[] = response?.data;
    return users;
  } catch (error) {
    const message =
      (axios.isAxiosError(error) && error.response?.data?.message) ?? "Failed to retrieve users.";
    throw new Error(message);
  }
};

export const submitNewUser: (email: string, roles: AdminRole[]) => Promise<AdminUser> = async (
  email,
  roles,
) => {
  const authToken = await authTokenManager.waitForToken();

  try {
    const response = await axios.post(
      `${ORIGIN}/api/v2/admin/admin-users`,
      {
        email,
        roles,
      },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    const newUser: AdminUser = {
      email,
      roles,
      id: response.data,
    };
    return newUser;
  } catch (error) {
    const message =
      (axios.isAxiosError(error) && error.response?.data?.message) ?? "Failed to create new user.";
    throw new Error(message);
  }
};

export const deleteUser: (userId: string | undefined) => Promise<void> = async (userId) => {
  const authToken = await authTokenManager.waitForToken();

  try {
    if (userId) {
      await axios.delete(`${ORIGIN}/api/v2/admin/admin-users/${userId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
    } else {
      throw new Error("Missing userId");
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.warn(error.response?.data?.message);
    }
    throw new Error("Failed to remove admin user");
  }
};

export const updateUser: (userId: string | undefined, roles: AdminRole[]) => Promise<void> = async (
  userId,
  roles,
) => {
  const authToken = await authTokenManager.waitForToken();

  try {
    if (userId) {
      await axios.put(
        `${ORIGIN}/api/v2/admin/admin-users/${userId}`,
        {
          roles,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        },
      );
    } else {
      throw new Error("Missing userId");
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.warn(error.response?.data?.message);
    }
    throw new Error("Failed to update admin user");
  }
};
