import LaunchIcon from "@mui/icons-material/Launch";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import React, { FC } from "react";
// import { toast } from "react-hot-toast"; // TODO: use again
import { timeZonesNames } from "@vvo/tzdb";
import { Link as RouterLink } from "react-router-dom";
import { Field, Formik } from "formik";
import { CheckboxWithLabel } from "formik-mui";
import * as Yup from "yup";

import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";
import Link from "@mui/material/Link";
import MenuItem from "@mui/material/MenuItem";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import DetailsCard from "components/dashboard/DetailsCard";
import Label from "components/dashboard/Label";
import TableCellTitle from "components/dashboard/TableCellTitle";
import { displayDate } from "features/reservations/utils";
import useRoles from "features/roles/useRoles";
import useUpdateReservation from "features/reservations/mutations/useUpdateReservation";
import { ReservationType } from "gather-prisma-types/dist/src/public/client";
import { reservationTypeToHuman } from "gather-http-common/dist/src/public/reservation";
import { is } from "ramda";
import { getAppBasePath } from "../../../../utils/basePath";
import { AdminReservation } from "gather-admin-common/dist/src/public/reservations/types";
import { AdminPermission as Can } from "gather-admin-common/dist/src/public/roles/types";

interface Props {
  /** Reservation being displayed */
  reservation: AdminReservation;
  /** Flag that determines to either show the form or the reservation details */
  isEditing: boolean;
  /** Toggles whether the form is showing or not */
  toggleEditMode: () => void;
}

const ReservationDetails: FC<Props> = ({ reservation, isEditing, toggleEditMode }) => {
  const basePath = getAppBasePath();
  const roomHref = `${basePath}/app/${reservation.roomId.replace("\\", "/")}`;
  const pastEvent = reservation.endDate ? new Date(reservation.endDate) <= new Date() : false;
  const upcomingEvent = new Date(reservation.startDate) > new Date();
  const { permissions } = useRoles();
  const canManageUsers = permissions.includes(Can.ManageUsers);
  const canManageSpaces = permissions.includes(Can.ManageSpaces);
  const updateMutation = useUpdateReservation();

  let statusLabel: React.ReactNode;

  if (reservation.canceled) {
    statusLabel = <Label color="warning">Cancelled</Label>;
  } else if (pastEvent) {
    statusLabel = <Label color="secondary">Past</Label>;
  } else if (upcomingEvent) {
    statusLabel = <Label color="primary">Upcoming</Label>;
  } else {
    statusLabel = <Label color="success">Active</Label>;
  }

  const publicEventInfoValues = [
    reservation.name,
    reservation.description,
    reservation.shortDescription,
    reservation.uploadedImages,
    reservation.tags,
    reservation.languages,
  ];
  const showPublicEventInfo = publicEventInfoValues.some((value) => value !== undefined);

  return (
    <Formik
      initialValues={{
        capacity: reservation.capacity || 0,
        startDate: reservation.startDate || new Date(),
        endDate: reservation.endDate || null,
        price: reservation.price || 0,
        eventTimezone: reservation.eventTimezone || "America/Los_Angeles",
        reservationType: reservation.reservationType || ReservationType.Other,
        reserver: reservation.reserver || "",
        canceled: reservation.canceled || false,
        paid: reservation.paid || false,
        paymentIntentIds: (reservation.paymentIntentIds || []).join(", "), // manual reservations from old dashboard have paymentIntentIds as undefined
      }}
      validationSchema={Yup.object().shape({
        capacity: Yup.number().positive().integer().required(),
        startDate: Yup.date().nullable(true).required("Start Date is required."),
        endDate: Yup.date()
          .nullable(true)
          .when("canceled", {
            is: true,
            then: Yup.date()
              .nullable(true)
              .required("End Date is required when reservation is cancelled."),
          }),
        price: Yup.number().min(0).required(),
        eventTimezone: Yup.string().max(255).required(),
        reservationType: Yup.string().required(),
        reserver: Yup.string().required(),
        canceled: Yup.boolean(),
        paid: Yup.boolean(),
        paymentIntentIds: Yup.string(),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        updateMutation.mutate({
          resId: reservation.id,
          fields: {
            ...reservation,
            ...values,
            price: is(String, values.price) ? parseFloat(values.price) : values.price,
            paymentIntentIds: values.paymentIntentIds.split(", "),
            startDate: values.startDate.toString(),
            endDate: values.endDate !== null ? values.endDate.toString() : null,
          },
        });

        setSubmitting(false);
        toggleEditMode();
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ maxWidth: 600 }}>
              <DetailsCard header="General Info">
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCellTitle>Status</TableCellTitle>

                      <TableCell>
                        {isEditing ? (
                          <FormGroup>
                            <Field
                              component={CheckboxWithLabel}
                              type="checkbox"
                              name="canceled"
                              Label={{ label: "Cancelled" }}
                            />
                            <FormHelperText sx={{ mt: -1 }}>
                              Note: Canceling via this form will not refund the client.
                            </FormHelperText>
                          </FormGroup>
                        ) : (
                          statusLabel
                        )}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCellTitle>Reservation Type</TableCellTitle>

                      <TableCell>
                        {isEditing ? (
                          <TextField
                            select
                            fullWidth
                            label="Reservation Type"
                            name="reservationType"
                            value={values.reservationType}
                            onChange={handleChange}
                            error={Boolean(touched.reservationType && errors.reservationType)}
                            helperText={touched.reservationType && errors.reservationType}
                          >
                            {Object.values(ReservationType).map((type, index) => (
                              <MenuItem key={`type-${index}`} value={type}>
                                {reservationTypeToHuman(type)}
                              </MenuItem>
                            ))}
                          </TextField>
                        ) : (
                          <Typography color="textPrimary" variant="body2">
                            {reservation.reservationType
                              ? reservationTypeToHuman(reservation.reservationType)
                              : ""}
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCellTitle>Reservation ID</TableCellTitle>

                      <TableCell>
                        <Typography color="textPrimary" variant="body2">
                          {reservation.id}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCellTitle>Space ID</TableCellTitle>

                      <TableCell>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          {canManageSpaces ? (
                            <Link
                              color="textPrimary"
                              component={RouterLink}
                              to={`/dashboard/spaces?space=${encodeURIComponent(
                                reservation.roomId,
                              )}`}
                              underline="always"
                              fontSize="small"
                            >
                              {reservation.roomId}
                            </Link>
                          ) : (
                            <Typography color="textPrimary" variant="body2">
                              {reservation.roomId}
                            </Typography>
                          )}

                          <Tooltip title="Launch in Gather" placement="top" arrow>
                            <Link
                              color="textPrimary"
                              href={roomHref}
                              underline="none"
                              fontSize="small"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              <LaunchIcon
                                fontSize="small"
                                sx={{
                                  ml: 1,
                                }}
                              />
                            </Link>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCellTitle>Reserver</TableCellTitle>

                      <TableCell>
                        {isEditing ? (
                          <TextField
                            error={touched.reserver && Boolean(errors.reserver)}
                            helperText={touched.reserver && errors.reserver}
                            label="Reserver"
                            name="reserver"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.reserver}
                            variant="outlined"
                            fullWidth
                            required
                          />
                        ) : (
                          <>
                            {canManageUsers ? (
                              <Link
                                color="textPrimary"
                                component={RouterLink}
                                to={`/dashboard/users?user=${encodeURIComponent(
                                  reservation.reserverEmail || "",
                                )}`}
                                underline="always"
                              >
                                {reservation.reserverEmail}
                              </Link>
                            ) : (
                              <Typography color="textPrimary" variant="body2">
                                {reservation.reserverEmail}
                              </Typography>
                            )}
                            <Typography color="textSecondary" variant="subtitle2">
                              {reservation.reserver}
                            </Typography>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </DetailsCard>

              <DetailsCard header="Scheduling" sx={{ mt: 4 }}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCellTitle>Start Date</TableCellTitle>

                      <TableCell>
                        {isEditing ? (
                          <DateTimePicker
                            label="Start Date"
                            value={values.startDate}
                            onChange={(value: unknown) => setFieldValue("startDate", value)}
                            renderInput={(inputProps: TextFieldProps) => (
                              <TextField
                                type="datetime-local"
                                name="startDate"
                                {...inputProps}
                                error={Boolean(errors.startDate)}
                                helperText={errors.startDate}
                                onBlur={handleBlur}
                                required
                                variant="outlined"
                              />
                            )}
                          />
                        ) : (
                          <Typography color="textPrimary" variant="body2">
                            {displayDate(reservation.startDate)}
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCellTitle>End Date</TableCellTitle>

                      <TableCell>
                        {isEditing ? (
                          <DateTimePicker
                            label="End Date"
                            value={values.endDate}
                            onChange={(value: unknown) => setFieldValue("endDate", value)}
                            renderInput={(inputProps: TextFieldProps) => (
                              <TextField
                                type="datetime-local"
                                name="endDate"
                                {...inputProps}
                                error={Boolean(errors.endDate)}
                                helperText={errors.endDate}
                                onBlur={handleBlur}
                                variant="outlined"
                              />
                            )}
                          />
                        ) : reservation.endDate ? (
                          <Typography color="textPrimary" variant="body2">
                            {displayDate(reservation.endDate)}
                          </Typography>
                        ) : (
                          <Label color="primary">Ongoing Subscription</Label>
                        )}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCellTitle>Timezone</TableCellTitle>
                      <TableCell>
                        {isEditing ? (
                          <Autocomplete
                            disablePortal
                            options={timeZonesNames}
                            value={values.eventTimezone}
                            onSelect={handleChange}
                            onBlur={handleBlur}
                            renderInput={(params) => (
                              <TextField
                                label="Timezone"
                                name="eventTimezone"
                                error={Boolean(touched.eventTimezone && errors.eventTimezone)}
                                helperText={touched.eventTimezone && errors.eventTimezone}
                                variant="outlined"
                                {...params}
                              />
                            )}
                          />
                        ) : (
                          <Typography color="textPrimary" variant="body2">
                            {reservation.eventTimezone}
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </DetailsCard>

              {showPublicEventInfo && (
                <DetailsCard header="Public Event Info" sx={{ mt: 4 }}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCellTitle>Name</TableCellTitle>
                        <TableCell>
                          <Typography color="textPrimary" variant="body2">
                            {reservation.name}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCellTitle>Description</TableCellTitle>
                        <TableCell>
                          <Typography color="textPrimary" variant="body2">
                            {reservation.description}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCellTitle>Short Description</TableCellTitle>
                        <TableCell>
                          <Typography color="textPrimary" variant="body2">
                            {reservation.shortDescription}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCellTitle>Uploaded Images</TableCellTitle>
                        <TableCell>
                          {reservation.uploadedImages?.map((image: string, index: number) => (
                            <Card
                              key={`image-${index}`}
                              sx={{
                                width: 100,
                              }}
                            >
                              <CardMedia
                                image={image}
                                sx={{
                                  height: 100,
                                }}
                              />
                            </Card>
                          ))}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCellTitle>Languages</TableCellTitle>
                        <TableCell>
                          <Typography color="textPrimary" variant="body2">
                            {reservation.languages?.join(", ")}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCellTitle>Tags</TableCellTitle>
                        <TableCell>
                          {reservation.tags?.map((tag: string, index: number) => (
                            <Box component="span" sx={{ mr: 1 }}>
                              <Label key={`tag-${index}`} color="primary">
                                {tag}
                              </Label>
                            </Box>
                          ))}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </DetailsCard>
              )}
            </Box>

            <Box
              sx={{
                ml: 4,
                maxWidth: 600,
              }}
            >
              <DetailsCard header="Payment Details">
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCellTitle>Status</TableCellTitle>
                      <TableCell>
                        {isEditing ? (
                          <FormGroup>
                            <Field
                              component={CheckboxWithLabel}
                              type="checkbox"
                              name="paid"
                              Label={{ label: "Paid" }}
                            />
                            <FormHelperText sx={{ mt: -1 }}>
                              Note: Marking as paid will make the reservation active in our system
                              but does not change anything in Stripe.
                            </FormHelperText>
                          </FormGroup>
                        ) : (
                          <Label color={reservation.paid ? "success" : "error"}>
                            {reservation.paid ? "Paid" : "Unpaid"}
                          </Label>
                        )}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCellTitle>Plan</TableCellTitle>
                      <TableCell>
                        <Typography color="textPrimary" variant="body2">
                          {reservation.plan}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCellTitle>Capacity</TableCellTitle>
                      <TableCell>
                        {isEditing ? (
                          <TextField
                            error={Boolean(touched.capacity && errors.capacity)}
                            type="number"
                            helperText={touched.capacity && errors.capacity}
                            label="Capacity"
                            name="capacity"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            required
                            value={values.capacity}
                            variant="outlined"
                          />
                        ) : (
                          <Typography color="textPrimary" variant="body2">
                            {reservation.capacity}
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCellTitle>Price</TableCellTitle>
                      <TableCell>
                        {isEditing ? (
                          <TextField
                            name="price"
                            label="Price"
                            onChange={handleChange}
                            value={values.price}
                            helperText={touched.price && errors.price}
                            error={Boolean(touched.price && errors.price)}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            required
                          />
                        ) : (
                          <Typography color="textPrimary" variant="body2">
                            {`$${parseFloat(reservation.price.toString()).toFixed(2)}`}
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCellTitle>Discount Code</TableCellTitle>
                      <TableCell>
                        <Typography color="textPrimary" variant="body2">
                          {reservation.discountCode}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCellTitle>Payment Failed At</TableCellTitle>
                      <TableCell>
                        <Typography color="textPrimary" variant="body2">
                          {reservation.paymentFailedAt ? (
                            displayDate(reservation.paymentFailedAt)
                          ) : (
                            <Label color={"info"}>Never Failed</Label>
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCellTitle>Payment Failure Context</TableCellTitle>
                      <TableCell>
                        <Typography color="textPrimary" variant="body2">
                          {reservation.paymentFailureContext ? (
                            reservation.paymentFailureContext
                          ) : (
                            <Label color={"info"}>Never Failed</Label>
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCellTitle>Invoice ID</TableCellTitle>
                      <TableCell>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <Typography color="textPrimary" variant="body2">
                            {reservation.invoiceId}
                          </Typography>

                          {reservation.invoiceUrl && (
                            <Tooltip title="View Invoice Document" placement="top" arrow>
                              <Link
                                color="textPrimary"
                                href={reservation.invoiceUrl}
                                underline="none"
                                fontSize="small"
                              >
                                <LaunchIcon
                                  fontSize="small"
                                  sx={{
                                    ml: 1,
                                  }}
                                />
                              </Link>
                            </Tooltip>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCellTitle>Payment Intent Ids</TableCellTitle>
                      <TableCell>
                        {isEditing ? (
                          <TextField
                            name="paymentIntentIds"
                            label="Payment Intent Ids"
                            onChange={handleChange}
                            value={values.paymentIntentIds}
                            helperText={touched.paymentIntentIds && errors.paymentIntentIds}
                            error={Boolean(touched.paymentIntentIds && errors.paymentIntentIds)}
                            multiline
                          />
                        ) : (
                          <Typography color="textPrimary" variant="body2">
                            {(reservation.paymentIntentIds || []).join(", ")}
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </DetailsCard>

              {reservation.hasUnpaidInvoice && (
                <DetailsCard header="Unpaid Invoice Details" sx={{ mt: 4 }}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCellTitle>Unpaid Price</TableCellTitle>
                        <TableCell>
                          <Typography color="textPrimary" variant="body2">
                            {reservation?.unpaidPrice &&
                              `$${parseFloat(reservation.unpaidPrice.toString()).toFixed(2)}`}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCellTitle>Unpaid Capacity</TableCellTitle>
                        <TableCell>
                          <Typography color="textPrimary" variant="body2">
                            {reservation.unpaidCapacity}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCellTitle>Unpaid Start Date</TableCellTitle>
                        <TableCell>
                          <Typography color="textPrimary" variant="body2">
                            {displayDate(reservation.unpaidStartDate)}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCellTitle>Unpaid End Date</TableCellTitle>
                        <TableCell>
                          {reservation.unpaidEndDate ? (
                            <Typography color="textPrimary" variant="body2">
                              {displayDate(reservation.unpaidEndDate)}
                            </Typography>
                          ) : (
                            <Label color="primary">Subscription</Label>
                          )}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCellTitle>Unpaid Event Timezone</TableCellTitle>
                        <TableCell>
                          <Typography color="textPrimary" variant="body2">
                            {reservation.unpaidEventTimezone}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </DetailsCard>
              )}

              {isEditing && (
                <Box sx={{ mt: 4, textAlign: "right" }}>
                  <Button type="submit" disabled={isSubmitting} variant="contained">
                    Save Changes
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default ReservationDetails;
