import React, { FC, PropsWithChildren } from "react";
import MUIDrawer from "@mui/material/Drawer";
import { Box, ClickAwayListener, Link } from "@mui/material";

const MetadataDrawer: FC<PropsWithChildren<{ label: string }>> = ({ label, children }) => {
  const [state, setState] = React.useState(false);

  const toggleDrawer = (open: boolean) => () => {
    setState(open);
  };

  return (
    <Box sx={{ display: "inline-block" }}>
      <ClickAwayListener onClickAway={toggleDrawer(false)}>
        <>
          <Link sx={{ cursor: "pointer" }} onClick={toggleDrawer(true)}>
            {label}
          </Link>
          <MUIDrawer anchor={"right"} open={state} onClose={toggleDrawer(false)}>
            {children}
          </MUIDrawer>
        </>
      </ClickAwayListener>
    </Box>
  );
};

export default MetadataDrawer;
