import React, { ReactNode } from "react";

import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import GroupIcon from "@mui/icons-material/Group";
import CalendarIcon from "@mui/icons-material/EventRounded";
import HomeIcon from "@mui/icons-material/HomeRounded";
import ToolIcon from "@mui/icons-material/HandymanRounded";
import WorldIcon from "@mui/icons-material/TravelExplore";
import UsersIcon from "@mui/icons-material/PeopleRounded";
import CouponIcon from "@mui/icons-material/SellRounded";
import HangerIcon from "@mui/icons-material/CheckroomRounded";
import ReplayIcon from "@mui/icons-material/Replay";
import { AdminPermission as Can } from "gather-admin-common/dist/src/public/roles/types";

// import LockIcon from "@mui/icons-material/LockOpenRounded";

export interface NavItemObject {
  title: string;
  path: string;
  icon?: ReactNode;
  children?: Array<NavItemObject>;
  permissions?: Can[];
  requiresAll?: boolean;
}

export interface NavSectionObject {
  title?: string;
  permissions?: Can[];
  requiresAll?: boolean;
  items: NavItemObject[];
}

const MAPMAKER_LINKS = {
  title: "Mapmaker",
  path: "/dashboard/mapmaker",
  icon: <ToolIcon fontSize="small" />,
  permissions: [Can.ViewMapInspector, Can.ViewObjectTemplates, Can.ManageObjectTemplateCategories],
  children: [
    {
      title: "Map Inspector",
      path: "/dashboard/mapmaker/inspector",
      permissions: [Can.ViewMapInspector],
    },
    {
      title: "Object Templates",
      path: "/dashboard/mapmaker/objects",
      permissions: [Can.ViewObjectTemplates],
    },
    {
      title: "Object Picker Categories",
      path: "/dashboard/mapmaker/object-categories",
      permissions: [Can.ManageObjectTemplateCategories],
    },
  ],
};

// const WEARABLE_LINKS = {
//   title: "Wearables",
//   path: "/dashboard/wearables",
//   icon: <WatchIcon fontSize="small" />,
//   permissions: [Can.ManageWearables],
//   children: [
//     {
//       title: "View All",
//       path: "/dashboard/wearables",
//     },
//     {
//       title: "New Wearable",
//       path: "/dashboard/wearables/new",
//     },
//   ],
// };

const RESERVATION_LINKS = {
  title: "Reservations",
  path: "/dashboard/reservations",
  icon: <CalendarIcon fontSize="small" />,
  permissions: [Can.ViewReservations],
};

const SPACES_LINKS = {
  title: "Spaces",
  path: "/dashboard/spaces",
  icon: <WorldIcon fontSize="small" />,
  permissions: [Can.ViewReservations],
};

const DISCOUNT_LINKS = {
  title: "Discounts",
  path: "/dashboard/discounts",
  icon: <CouponIcon fontSize="small" />,
  permissions: [Can.ManageDiscounts],
};

const WEARABLES_LINKS = {
  title: "Wearables",
  path: "/dashboard/wearables",
  icon: <HangerIcon fontSize="small" />,
  permissions: [Can.ManageWearables],
};

// const ROLES_PERMISSIONS_LINKS = {
//   title: "Roles & Permissions",
//   path: "/dashboard/roles",
//   icon: <LockIcon fontSize="small" />,
//   permissions: [Can.ManageRoles],
//   children: [
//     {
//       title: "View All",
//       path: "/dashboard/roles",
//     },
//     {
//       title: "New Role Group",
//       path: "/dashboard/roles/new",
//     },
//   ],
// };

const USER_MANAGEMENT_LINKS = {
  title: "User Lookup",
  path: "/dashboard/users",
  icon: <ManageAccountsIcon fontSize="small" />,
  permissions: [Can.ManageUsers],
};

const BULK_USER_MANAGEMENT_LINKS = {
  title: "Bulk Manage Users",
  path: "/dashboard/bulk-users",
  icon: <GroupIcon fontSize="small" />,
  permissions: [Can.ManageUsers],
};

const ADMIN_USER_MANAGEMENT_LINKS = {
  title: "Admin Users",
  path: "/dashboard/admin-users",
  icon: <UsersIcon fontSize="small" />,
  permissions: [Can.ManageAdminUsers],
};

const AV_MANAGEMENT_LINKS = {
  title: "A/V Client State",
  path: "/dashboard/client-state",
  icon: <ReplayIcon fontSize="small" />,
  permissions: [Can.ViewClientStateSessions],
};

export const NAV_LINKS: Array<NavSectionObject> = [
  {
    title: "General",
    items: [
      {
        title: "Home",
        path: "/dashboard",
        icon: <HomeIcon fontSize="small" />,
      },
      MAPMAKER_LINKS,
      SPACES_LINKS,
      RESERVATION_LINKS,
      DISCOUNT_LINKS,
      WEARABLES_LINKS,
      USER_MANAGEMENT_LINKS,
      BULK_USER_MANAGEMENT_LINKS,
      AV_MANAGEMENT_LINKS,
    ],
  },
  {
    title: "Advanced",
    permissions: [Can.ManageAdminUsers],
    items: [
      // ROLES_PERMISSIONS_LINKS,
      ADMIN_USER_MANAGEMENT_LINKS,
    ],
  },
];
