import React, { FC } from "react";
import { toast } from "react-hot-toast";
import * as Yup from "yup";
import { Formik, Field } from "formik";
import { Switch } from "formik-mui";
import { timeZonesNames } from "@vvo/tzdb";

import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import InfoIcon from "components/dashboard/InfoIcon";
import useCreateReservation from "features/reservations/mutations/useCreateReservation";
import useAuth from "features/authentication/useAuth";
import { ReservationPlan, ReservationType } from "gather-prisma-types/dist/src/public/client";
import {
  reservationTypeToHuman,
  reservationPlanToHuman,
} from "gather-http-common/dist/src/public/reservation";

const DEFAULT_SPACE_CAPACITY = 25;

interface Props {
  spaceId?: string;
  customerId?: string;
  onClose: () => void;
}

const NewReservationForm: FC<Props> = ({ spaceId, customerId, onClose }) => {
  const { user } = useAuth();

  const onSuccessfulCreation = () => {
    toast.success("Reservation was successfully created.");
  };

  const onCreationError = () => {
    toast.error(`Error: Reservation could not be created.`);
  };

  const { mutate: createRes } = useCreateReservation({
    onSuccess: onSuccessfulCreation,
    onError: onCreationError,
  });

  if (!user) return <div>Loading...</div>;

  const VALIDATION_SCHEMA = Yup.object().shape({
    roomId: Yup.string().required("Space ID is required."),
    customerEmailOrId: Yup.string().when("disableInvoice", {
      is: false,
      then: Yup.string().required(
        "Email or user ID of the customer is required when sending an invoice.",
      ),
    }),
    capacity: Yup.number().required("Capacity is required."),
    startDate: Yup.date().nullable(true).required("Start date is required."),
    endDate: Yup.date().nullable(true),
    plan: Yup.string().required("Plan is required"),
    price: Yup.number().min(0).required("Price is required."),
    eventTimezone: Yup.string().max(255).required(),
    reservationType: Yup.string(),
    otherReservationType: Yup.string(),
    disableInvoice: Yup.boolean(),
  });

  const showSpaceField = spaceId === undefined || spaceId.length === 0;
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);

  return (
    <Formik
      initialValues={{
        roomId: spaceId ?? "",
        customerEmailOrId: customerId ?? "",
        capacity: DEFAULT_SPACE_CAPACITY,
        startDate: today.toISOString(),
        endDate: tomorrow.toISOString(),
        plan: ReservationPlan.Daily,
        price: 0,
        reservationType: ReservationType.Other,
        otherReservationType: "",
        disableInvoice: false,
        eventTimezone: "America/Los_Angeles",
      }}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={async (values, { resetForm, setStatus, setSubmitting }) => {
        try {
          const fields = {
            ...values,
            startDate: values.startDate.toString(),
            endDate: values.endDate ? values.endDate.toString() : null,
          };
          createRes({ fields, userId: user?.id });
          onClose();
          resetForm();
          setStatus({ success: true });
          setSubmitting(false);
        } catch (error) {
          if (error instanceof Error) {
            const msg = error.message;
            toast.error(msg);
          }
          setStatus({ success: false });
          setSubmitting(false);
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box sx={{ p: 3 }}>
            <Typography align="center" color="textPrimary" gutterBottom variant="h5">
              Add Reservation
            </Typography>

            <Box sx={{ display: "flex" }}>
              <Box sx={{ mt: 2 }}>
                <FormControlLabel
                  control={
                    <Field
                      type="checkbox"
                      label="Disable Invoice"
                      name="disableInvoice"
                      component={Switch}
                    />
                  }
                  label={
                    <InfoIcon text="If invoice is disabled, you will be recorded as the reserver and the client will be unable to edit this reservation.">
                      Disable Invoice
                    </InfoIcon>
                  }
                />

                {!values.disableInvoice && customerId === undefined && (
                  <TextField
                    fullWidth
                    error={Boolean(touched.customerEmailOrId && errors.customerEmailOrId)}
                    helperText={touched.customerEmailOrId && errors.customerEmailOrId}
                    label="Customer Email or ID"
                    name="customerEmailOrId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.customerEmailOrId}
                    variant="outlined"
                  />
                )}
              </Box>
            </Box>

            {showSpaceField && (
              <Box sx={{ mt: 3, display: "flex" }}>
                <TextField
                  fullWidth
                  error={Boolean(touched.roomId && errors.roomId)}
                  helperText={touched.roomId && errors.roomId}
                  label="Space ID"
                  name="roomId"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.roomId}
                  variant="outlined"
                />
              </Box>
            )}

            <Box sx={{ mt: 3, display: "flex" }}>
              <Box sx={{ maxWidth: 200, flexGrow: 1 }}>
                <TextField
                  select
                  required
                  fullWidth
                  label="Plan"
                  name="plan"
                  value={values.plan}
                  onChange={handleChange}
                  error={Boolean(touched.plan && errors.plan)}
                  helperText={touched.plan && errors.plan}
                >
                  {Object.values(ReservationPlan).map((resType, index) => (
                    <MenuItem key={`resType-${index}`} value={resType}>
                      {reservationPlanToHuman(resType)}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>

              <Box sx={{ maxWidth: 200, flexGrow: 1, ml: 2 }}>
                <TextField
                  select
                  required
                  fullWidth
                  label="Purpose"
                  name="reservationType"
                  value={values.reservationType}
                  onChange={handleChange}
                  error={Boolean(touched.reservationType && errors.reservationType)}
                  helperText={touched.reservationType && errors.reservationType}
                >
                  {Object.values(ReservationType).map((type, index) => (
                    <MenuItem key={`type-${index}`} value={type}>
                      {reservationTypeToHuman(type)}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>

              <Box sx={{ maxWidth: 200, flexGrow: 1, ml: 2 }}>
                <TextField
                  fullWidth
                  error={Boolean(touched.otherReservationType && errors.otherReservationType)}
                  helperText={touched.otherReservationType && errors.otherReservationType}
                  label="Other Type"
                  name="otherReservationType"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.otherReservationType}
                  variant="outlined"
                />
              </Box>
            </Box>

            <Box sx={{ mt: 3, display: "flex" }}>
              <DateTimePicker
                label="Start Date"
                value={values.startDate}
                onChange={(value: unknown) => setFieldValue("startDate", value)}
                renderInput={(inputProps: TextFieldProps) => (
                  <TextField
                    required
                    type="datetime-local"
                    name="startDate"
                    {...inputProps}
                    error={Boolean(errors.startDate)}
                    helperText={errors.startDate}
                    onBlur={handleBlur}
                    variant="outlined"
                  />
                )}
              />

              <Box sx={{ ml: 2 }}>
                <DateTimePicker
                  label="End Date"
                  value={values.endDate}
                  onChange={(value: unknown) => setFieldValue("endDate", value)}
                  renderInput={(inputProps: TextFieldProps) => (
                    <TextField
                      type="datetime-local"
                      name="endDate"
                      {...inputProps}
                      error={Boolean(errors.endDate)}
                      helperText={errors.endDate}
                      onBlur={handleBlur}
                      variant="outlined"
                    />
                  )}
                />
              </Box>
            </Box>

            <Box sx={{ mt: 3 }}>
              <Autocomplete
                disablePortal
                options={timeZonesNames}
                value={values.eventTimezone}
                onSelect={handleChange}
                onBlur={handleBlur}
                renderInput={(params) => (
                  <TextField
                    required
                    label="Timezone"
                    name="eventTimezone"
                    error={Boolean(touched.eventTimezone && errors.eventTimezone)}
                    helperText={touched.eventTimezone && errors.eventTimezone}
                    variant="outlined"
                    {...params}
                  />
                )}
              />
            </Box>

            <Box sx={{ display: "flex", mt: 3 }}>
              <Box sx={{ maxWidth: 100 }}>
                <TextField
                  required
                  type="number"
                  error={Boolean(touched.capacity && errors.capacity)}
                  helperText={touched.capacity && errors.capacity}
                  label="Capacity"
                  name="capacity"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.capacity}
                  variant="outlined"
                />
              </Box>

              <Box sx={{ ml: 2, maxWidth: 150 }}>
                <TextField
                  name="price"
                  label="Price"
                  type="number"
                  onChange={handleChange}
                  value={values.price}
                  helperText={touched.price && errors.price}
                  error={Boolean(touched.price && errors.price)}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  required
                />
              </Box>
            </Box>
          </Box>

          <Divider />

          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              p: 2,
            }}
          >
            <Box sx={{ flexGrow: 1 }} />
            <Button color="primary" onClick={onClose} variant="text">
              Cancel
            </Button>

            <Button
              color="primary"
              disabled={isSubmitting}
              sx={{ ml: 1 }}
              type="submit"
              variant="contained"
            >
              Create
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default NewReservationForm;
