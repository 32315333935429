import { FC, memo } from "react";

import { Box } from "@mui/material";
import { always, is, isEmpty, tryCatch } from "ramda";
import { RenderRowProps, WithRestProps } from "./types";
import { formatMultiArgumentPayloads, formatTimestamp, isRawMessage } from "./utils";

export const RenderRawRow: FC<WithRestProps<RenderRowProps>> = memo(
  ({ message, className = "", ...props }) => {
    if (!isRawMessage(message)) return null;
    const { messageId: _messageId, payload, type, timestamp, ...rest } = message;
    const hasOtherFields = !isEmpty(rest);

    return (
      <div className={`row-message raw-message ${className}`} {...props}>
        <Box flexShrink={0}>{formatTimestamp(timestamp)}</Box>
        <Box display={"flex"} gap={4}>
          <Box fontWeight={"bold"} whiteSpace="nowrap">
            {type}
          </Box>
          <Box>
            {hasOtherFields ? <Box>{JSON.stringify(rest)}</Box> : null}
            <Box>
              {is(String, payload)
                ? tryCatch(formatMultiArgumentPayloads, always(payload))(payload)
                : JSON.stringify(payload)}
            </Box>
          </Box>
        </Box>
      </div>
    );
  },
);
