import { useQueryClient, useMutation } from "react-query";

import * as ObjectTemplatesAPI from "features/objectTemplates/api";
import QueryKeys from "features/queryKeys";
import { ObjTempMutationCallbacks, ObjTempMutationContext } from "./types";
import {
  ObjectTemplate,
  ObjectVariant,
} from "gather-common/dist/src/public/resources/objectTemplates";
import { NewObjectTemplateFields } from "gather-admin-common/dist/src/public/objectTemplates/types";

/**
 * Mutation that creates an object template document
 * @param callbacks Object containing onError and onSuccess callback methods
 * @returns ReactQuery useMutation hook
 */
const useCreateObjectTemplate = (callbacks?: ObjTempMutationCallbacks) => {
  const queryClient = useQueryClient();

  return useMutation(
    (newTemplate: NewObjectTemplateFields) =>
      ObjectTemplatesAPI.submitNewObjectTemplate(newTemplate),
    {
      onMutate: async (newTemplate) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        queryClient.cancelQueries([QueryKeys.ObjectTemplates]);

        // Snapshot the previous value of all templates currently cached
        const previousTemplates =
          queryClient.getQueryData<ObjectTemplate[]>([QueryKeys.ObjectTemplates]) ?? [];

        const updated = [...previousTemplates];

        // Convert NewObjectTemplateFields to a plain ol' ObjectTemplate
        const convertedVariants: ObjectVariant[] = newTemplate.variants.map((variant) => ({
          color: variant.color,
          normal: "",
          highlighted: "",
          orientation: variant.orientation,
          default: variant.default,
        }));

        const convertedSound = newTemplate.sound
          ? {
              ...newTemplate.sound,
              src: "",
            }
          : undefined;

        const converted: ObjectTemplate = {
          ...newTemplate,
          variants: convertedVariants,
          sound: convertedSound,
        };
        updated.push(converted);

        // Update the cached data
        queryClient.setQueryData<ObjectTemplate[]>(QueryKeys.ObjectTemplates, updated);

        const context: ObjTempMutationContext = { previousTemplates };

        return context;
      },
      onError: async (_err, _data, context?: ObjTempMutationContext) => {
        const { previousTemplates = [] } = context || {};

        queryClient.setQueryData<ObjectTemplate[]>([QueryKeys.ObjectTemplates], previousTemplates);

        callbacks?.onError?.();
      },
      onSuccess: () => {
        callbacks?.onSuccess?.();
      },
      onSettled: () => {
        queryClient.invalidateQueries([QueryKeys.ObjectTemplates]);
      },
    },
  );
};

export default useCreateObjectTemplate;
