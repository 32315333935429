import {
  DBOutfit,
  DynamicGates,
} from "@gathertown/gather-game-common/dist/src/public/generated_DO_NOT_TOUCH/events";
import {
  Area,
  AreaCategory,
  Areas,
  BaseArea,
  DBArea,
  DBAreas,
} from "@gathertown/gather-game-common/dist/src/public/gameMap";
import { BaseRoomUserDB, CoreRole } from "@gathertown/gather-game-common/dist/src/public/Player";
import * as zod from "zod";
import { UseCases } from "../useCases";
import {
  Permission,
  SpawnTokenPrisma,
  SpawnTokenType,
} from "gather-prisma-types/dist/src/public/client";
import { AuthUser } from "./user";
import { keys } from "ramda";

// noinspection JSUnusedGlobalSymbols -- there's some bad data, the IDE thinks it's not necessary
export enum CoreRoleEnumMigration {
  "DEFAULT_BUILDER" = "Builder",
  "DEFAULT_MOD" = "Mod",
  "DEFAULT_OWNER" = "Owner",
  // Lint warning auto-ignored when enabling the no-duplicate-enum-values rule. Remove it once the enum migration is complete.
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  "OWNER" = "Owner",
  "DEFAULT_MEMBER" = "GeneralMember",
  // Lint warning auto-ignored when enabling the no-duplicate-enum-values rule. Remove it once the enum migration is complete.
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  "GENERAL_MEMBER" = "GeneralMember",
  "SPEAKER" = "Speaker",
  "RECORDING_CLIENT" = "RecordingClient",
  "GUEST" = "Guest",
}

export type SpaceRolePermissionsMap = {
  [role in CoreRole]: Partial<Record<Permission, boolean>>;
};

// Currently, if the `guestPassStatus` is not set on the space user field,
// it means the guest has not been explicitly given a guest pass
// (and therefore it was never explicitly hidden or revoked).
export enum GuestPassStatus {
  // Either has an active guess pass or once that has expired
  Admitted = "ADMITTED",
  Revoked = "REVOKED",
  Hidden = "HIDDEN",
}

type SpaceUserSettings = {
  pinnedUsers: string[];
};

export const ANY_MEMBER_ROLE_RW = [
  CoreRole.GeneralMember,
  CoreRole.Builder,
  CoreRole.Mod,
  CoreRole.Owner,
];

export const remoteWorkSurfacedRoles = [CoreRole.Owner, CoreRole.Builder, CoreRole.GeneralMember];

export const eventsSurfacedRoles = [CoreRole.Owner, CoreRole.Mod, CoreRole.Builder];

export const remoteWorkDeprecatingRoles = {
  [CoreRole.Mod]: "Mod",
};

export const remoteWorkOwnerRoles = Object.keys(remoteWorkSurfacedRoles)
  .filter((role) => !Object.keys(remoteWorkDeprecatingRoles).includes(role))
  // Lint warning auto-ignored when enabling the consistent-type-assertions rule. TODO: @ENG-4304 Correct the type assertion next time this code is edited!
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
  .map((role) => role as CoreRole);

export interface Item {
  count: number;
  preview: string;
  type: string;
}

export type HasAccess =
  | boolean
  | {
      // Time of access expiry in unix time (seconds)
      expiresAtUnixTime: number;
    };

// See notes on BaseRoomUserDB for definition of MemberInfo
export type MemberInfoDB = BaseRoomUserDB & {
  hasAccess?: HasAccess;
  guestPassStatus?: GuestPassStatus;
  mobileLastActive?: string | null;
  // `becameMemberAt` is generally meant to be used just once, upon "Guest" -> "Member" (any member role) promotion, check
  // gather-prisma-backend/src/middleware/spaceUserChanges.ts
  becameMemberAt?: string | null;
  currentGuestUsageMinutes?: number;
  id: string;
  // spaceId should never be null, especially after the data is moved into CRDB
  // we are just being extra careful to avoid throwing in a collection when deriving spaceId from just(snapshot.ref.parent.parent?.id)
  // this field is not read from, so it's fine to type it like this for now
  // SpaceUserPrisma would have the correct type
  spaceId: string | null;
  spaceUserSetting?: SpaceUserSettings;
  // if it's not actually in the DB, it'll be provided on reading from the DB via the converter
  role: CoreRole;
};

// { [key in keyof Required<MemberInfoDB>]: true } forces us to keep this object updated with all required or optional fields in MemberInfoDB
// This allows us to do runtime checks and picking/omitting of fields when reading from CRDB.
const MemberInfoDBSelect: { [key in keyof Required<MemberInfoDB>]: true } = {
  becameMemberAt: true,
  city: true,
  connected: true,
  country: true,
  currentGuestUsageMinutes: true,
  currentlyEquippedWearables: true,
  description: true,
  deskInfo: true,
  guestPassStatus: true,
  handRaisedAt: true,
  hasAccess: true,
  id: true,
  spaceUserUuid: true,
  lastVisited: true,
  map: true,
  mobileLastActive: true,
  name: true,
  personalImageId: true,
  personalImageUrl: true,
  phone: true,
  profileImageId: true,
  profileImageUrl: true,
  pronouns: true,
  role: true,
  spaceId: true,
  startDate: true,
  timezone: true,
  title: true,
  x: true,
  y: true,
  spaceUserSetting: true,
};

export const MemberInfoDBFields = Object.keys(MemberInfoDBSelect);

// The original type of SpaceUserResource was PlayerDBPartial and some optional fields in MemberInfoDB
// Ideally we'd not do Partial here since we know these fields are returned by CRDB
// However this will take a bit of time to clean up as it's causing a bunch of TS errors
// and we didn't want to be blocked on the SpaceUser migration
// TODO [OCTO-643] Make SpaceUserResource not Partial<...>
export type SpaceUserResource = Pick<MemberInfoDB, "id"> &
  Partial<
    Pick<
      MemberInfoDB,
      | "city"
      | "country"
      | "currentlyEquippedWearables"
      | "description"
      | "deskInfo"
      | "guestPassStatus"
      | "hasAccess"
      | "lastVisited"
      | "map"
      | "name"
      | "personalImageId"
      | "personalImageUrl"
      | "phone"
      | "profileImageId"
      | "profileImageUrl"
      | "pronouns"
      | "role"
      | "startDate"
      | "timezone"
      | "title"
      | "spaceUserUuid"
      | "currentGuestUsageMinutes"
    > &
      Pick<AuthUser, "email"> & { userUuid: string }
  >;

// The prisma space user resource has differently named fields from the legacy resource, so we need to duplicate this resource
// type until we add a converter between legacy and prisma SpaceUser resources.
// When adding a field here, you must also add the field below in SpaceUserSelectPrismaResourceFields.
// TODO to clean this up https://linear.app/gather-town/issue/OCTO-645/add-converter-to-convert-space-user-fields-from-legacy-to-prisma
export const SpaceUserResourceSelect: { [key in keyof Required<SpaceUserResource>]: true } = {
  city: true,
  country: true,
  currentlyEquippedWearables: true,
  description: true,
  deskInfo: true,
  email: true,
  guestPassStatus: true,
  hasAccess: true,
  id: true,
  lastVisited: true,
  map: true,
  name: true,
  personalImageId: true,
  personalImageUrl: true,
  phone: true,
  profileImageId: true,
  profileImageUrl: true,
  pronouns: true,
  role: true,
  startDate: true,
  timezone: true,
  title: true,
  userUuid: true,
  spaceUserUuid: true,
  currentGuestUsageMinutes: true,
};

// The prisma space user resource has differently named fields from the legacy resource, so we need to duplicate this resource
// type until we add a converter between legacy and prisma SpaceUser resources.
// When adding a field here, you must also add the field above in SpaceUserResourceSelect.
// TODO to clean this up https://linear.app/gather-town/issue/OCTO-645/add-converter-to-convert-space-user-fields-from-legacy-to-prisma
export const SpaceUserPrismaResourceSelect = {
  id: true,
  city: true,
  country: true,
  outfit: true,
  description: true,
  desk: true,
  inventory: true,
  guestPassStatus: true,
  guestPassExpiresAt: true,
  hasAccess: true,
  userFirestoreId: true,
  spaceFirestoreId: true,
  lastVisitedAt: true,
  mapFirestoreId: true,
  name: true,
  personalImageId: true,
  personalImageUrl: true,
  phone: true,
  profileImageId: true,
  profileImageUrl: true,
  pronouns: true,
  role: true,
  timezone: true,
  title: true,
};

export const SpaceUserResourceFields = keys(SpaceUserResourceSelect);

export type Space = {
  id: string;
  map: string;
  name: string;
  defaultRoom?: {
    id: string;
    backgroundImagePath: string;
    dimensions: [number, number];
  };
};

// distinct from space info because this is the stuff rendered on your dashboards and explore
export type UserHomeSpaceResource = {
  id: string;
  description?: string;
  lastVisited: string | null;
  numActive: number;
  currentUserRole?: CoreRole;
  backgroundImagePath?: string;
  region?: string;
  map: {
    dimensions: [number, number];
    backgroundImagePath: string; // url
  };
};

type BaseSpawnToken = Pick<SpawnTokenPrisma, "id" | "mapId" | "spaceId"> & {
  createdAt: string;
  updatedAt: string;
};

export type TileSpawnToken = BaseSpawnToken & {
  type: SpawnTokenType.SpawnTile;
  spawnId: string;
};

export type DeskSpawnToken = BaseSpawnToken & {
  type: SpawnTokenType.Desk;
  deskOwnerId: string;
};

export type NookSpawnToken = BaseSpawnToken & {
  type: SpawnTokenType.Nook;
  nookId: string;
  eventId?: string;
  timestamp: string | null;
};

export type DefaultSpawnToken = BaseSpawnToken & {
  type: SpawnTokenType.DefaultSpawnTile;
  eventId?: string;
};

export type SpawnToken = TileSpawnToken | DeskSpawnToken | NookSpawnToken | DefaultSpawnToken;

export const eventLocationIsSpawnToken = (
  location: Object | string | undefined | null,
): location is NookSpawnToken =>
  !!location && typeof location !== "string" && location.hasOwnProperty("type");

export type InformationBoard = {
  pinned: Announcement[];
  announcements: Announcement[];
};

export const MAX_INFO_BOARD_ANNOUNCEMENTS_LENGTH = 5;

export type Announcement = {
  id: string;
  message: string;
  createdAt: string;
  updatedAt: string;
};

const ZodAnnouncementMap = zod.object({
  id: zod.string(),
  message: zod.string(),
  createdAt: zod.string(),
  updatedAt: zod.string(),
});

export const WriteableSpaceSettingsSchema = zod.object({
  allowStaffAccess: zod.boolean().optional(),
  autoPromoteMembersEmailDomains: zod.array(zod.string()).optional().nullable(),
  autoPromoteMembersEnabled: zod.boolean().optional().nullable(),
  betaFeaturesEnabled: zod.array(zod.string()).optional(),
  disableChat: zod.boolean().optional(),
  disableChatPersist: zod.boolean().optional(),
  disableInvite: zod.boolean().optional(),
  disableScreenshare: zod.boolean().optional(),
  disableTutorial: zod.boolean().optional(),
  emailDomains: zod.array(zod.string()).optional(),
  enableRecordingForMembersV2: zod.boolean().optional(),
  gatherLabsFeaturesEnabled: zod.array(zod.string()).optional(),
  globalBuild: zod.boolean().optional(),
  guestCheckInEnabled: zod.boolean().optional(),
  preloadAllAssets: zod.boolean().optional(),
  requireLogin: zod.boolean().optional(),
});

const ReadableSpaceSettingsSchema = WriteableSpaceSettingsSchema.merge(
  zod.object({
    forceMute: zod.boolean().optional(),
    informationBoard: zod
      .object({
        pinned: zod.array(ZodAnnouncementMap).optional(),
        announcements: zod.array(ZodAnnouncementMap).optional(),
      })
      .optional(),
    spaceWasRWHangoutOnCreation: zod.boolean().optional(),
  }),
);

export const ZodSpaceSettingsKeys = ReadableSpaceSettingsSchema.keyof();

export type SpaceSettingsKeys = zod.infer<typeof ZodSpaceSettingsKeys>;

export type SpaceSettingsMap = zod.infer<typeof ReadableSpaceSettingsSchema>;

export type EnabledChat = "GLOBAL_CHAT" | "LOCAL_CHAT" | "ROOM_CHAT";

export type GuestList = {
  [email in string]: GuestListEntry;
};

export interface GuestListEntry {
  name: string;
  role?: string;
  affiliation?: string;
}

export interface GuestListEmailEntry {
  email: string;
  name: string;
  role?: string;
  affiliation?: string;
}

export interface WhitelistData extends Partial<GuestListEmailEntry> {
  sprites?: number[];
}

export interface UserAccess extends WhitelistData {
  hasAccess: boolean;
  type?: "guestCheckIn" | "password" | "whitelist" | "none";
}

export type DynamicFeatureGateDB = DynamicGates;

interface LeaderBoardData {
  playerId: string;
  playerName?: string;
  /** @deprecated; readonly now */
  playerOutfitString?: string;
  playerWearables?: DBOutfit;
  finishedTime: number;
  date: string;
}

export type GrandPrixLeaderboard = Record<string, LeaderBoardData[]>;

export type BannedUserIdsOrIPs = {
  [userIdOrIP: string]: {
    name: string;
  };
};

export type SpaceInfoDB = {
  affiliation: string | null;
  bannedIPs: BannedUserIdsOrIPs;
  closed: boolean;
  crdbUuid: string;
  creationDate: string;
  emailWhitelist: GuestList;
  enabledChats: EnabledChat[];
  grandPrixLeaderboards: GrandPrixLeaderboard;
  hadCopyUploadErrors: boolean;
  iCalLink: string | null;
  isTemplate: boolean; // true means publicly copyable!
  map: string;
  modPassword: string | null;
  name: string;
  officeConfigurationBackups: string[];
  officeConfigurationSourceSpace: string | null;
  password: string | null;
  reason: UseCases;
  roomCount: number;
  serverURL: string | null;
  settings: SpaceSettingsMap;
  styles: string[];
  whitelistHelpContact: string | null;
  writeId: string | null;
};

export type SpaceInfo = SpaceInfoDB & {
  id: string;
};

export type SpaceResource = Pick<
  SpaceInfo,
  | "id"
  | "affiliation"
  | "bannedIPs"
  | "closed"
  | "crdbUuid"
  | "creationDate"
  | "emailWhitelist"
  | "enabledChats"
  | "grandPrixLeaderboards"
  | "hadCopyUploadErrors"
  | "iCalLink"
  | "isTemplate"
  | "map"
  | "modPassword"
  | "name"
  | "officeConfigurationBackups"
  | "officeConfigurationSourceSpace"
  | "password"
  | "reason"
  | "roomCount"
  | "serverURL"
  | "settings"
  | "styles"
  | "whitelistHelpContact"
  | "writeId"
> & {
  hasGuestList: boolean;
};

export type PublicSpaceResource = Pick<
  SpaceResource,
  | "id"
  | "affiliation"
  | "bannedIPs"
  | "closed"
  | "crdbUuid"
  | "creationDate"
  | "enabledChats"
  | "grandPrixLeaderboards"
  | "hadCopyUploadErrors"
  | "hasGuestList"
  | "iCalLink"
  | "isTemplate"
  | "map"
  | "modPassword"
  | "name"
  | "officeConfigurationBackups"
  | "officeConfigurationSourceSpace"
  | "reason"
  | "roomCount"
  | "serverURL"
  | "settings"
  | "styles"
  | "whitelistHelpContact"
  | "writeId"
>;

export type { Area, Areas, BaseArea, DBArea, DBAreas };
export { AreaCategory };

export type SpaceMapSize = {
  mapSize: number;
  maxMapSize: number;
  usagePercentage: number;
};
