import React, { FC } from "react";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import PublicNavbar from "./PublicNavbar";

interface Props {
  children?: React.ReactNode;
}

const PublicLayout: FC<Props> = ({ children }) => (
  <Box
    sx={{
      backgroundColor: "background.default",
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
    }}
  >
    <PublicNavbar />
    {children || <Outlet />}
  </Box>
);

export default PublicLayout;
