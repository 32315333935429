import { useQueryClient, useMutation } from "react-query";

import * as ReservationsAPI from "features/reservations/api";
import QueryKeys from "features/queryKeys";
import {
  UpdateReservationVariables,
  UpdateReservationContext,
  ReservationMutationCallbacks,
} from "./types";
import { updateDetailsCache, updateReserverCaches, updateSpaceCache } from "./utils";
import { AdminReservation } from "gather-admin-common/dist/src/public/reservations/types";

/**
 * Mutation hook that updates a reservation
 * @param queryClient QueryClient instance from react-query
 * @returns ReactQuery useMutation hook
 */
const useUpdateReservation = (callbacks?: ReservationMutationCallbacks) => {
  const queryClient = useQueryClient();

  return useMutation(
    (variables: UpdateReservationVariables) =>
      ReservationsAPI.updateReservation(variables.resId, variables.fields),
    {
      onMutate: async (variables) => {
        const { resId } = variables;
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        queryClient.cancelQueries([QueryKeys.Reservation, resId]);

        const { previousReservation, updatedReservation } = updateDetailsCache(
          queryClient,
          variables,
        );
        const previousSpaceList = updateSpaceCache(
          queryClient,
          variables,
          previousReservation,
          updatedReservation,
        );
        const prevReserverLists = updateReserverCaches(
          queryClient,
          variables,
          previousReservation,
          updatedReservation,
        );
        return { previousReservation, previousSpaceList, ...prevReserverLists };
      },

      onError: (_err, { resId, fields }, context?: UpdateReservationContext) => {
        const {
          previousReservation,
          previousSpaceList,
          previousNewReserverList,
          previousOldReserverList,
        } = context || {};

        // Restore old cached values if api request fails
        if (previousReservation) {
          queryClient.setQueryData<AdminReservation>(
            [QueryKeys.Reservation, resId],
            previousReservation,
          );

          if (previousSpaceList) {
            queryClient.setQueryData<AdminReservation[]>(
              [QueryKeys.SpaceReservations, previousReservation.roomId],
              previousSpaceList,
            );
          }

          if (previousOldReserverList) {
            queryClient.setQueryData<AdminReservation[]>(
              [QueryKeys.UserReservations, previousReservation.reserver],
              previousOldReserverList,
            );

            if (previousNewReserverList) {
              queryClient.setQueryData<AdminReservation[]>(
                [QueryKeys.UserReservations, fields.reserver],
                previousNewReserverList,
              );
            }
          }
        }

        if (callbacks?.onError) {
          callbacks?.onError();
        }
      },

      onSuccess: (
        _data: unknown,
        _variables: UpdateReservationVariables,
        context?: UpdateReservationContext,
      ) => {
        if (context?.previousReservation) {
          if (callbacks?.onSuccess) {
            callbacks?.onSuccess();
          }
        }
      },

      onSettled: (_data, _err, { resId, fields }, context) => {
        // Always refetch after error or success:
        queryClient.invalidateQueries([QueryKeys.Reservation, resId]);

        if (context) {
          if (context.previousSpaceList) {
            const { roomId } = context.previousReservation || {};
            queryClient.invalidateQueries([QueryKeys.SpaceReservations, roomId]);
          }

          if (context.previousNewReserverList) {
            const { reserver: newReserver } = fields;
            queryClient.invalidateQueries([QueryKeys.UserReservations, newReserver]);
          }

          if (context.previousOldReserverList) {
            const { reserver: oldReserver } = context.previousReservation || {};
            queryClient.invalidateQueries([QueryKeys.UserReservations, oldReserver]);
          }
        }
      },
    },
  );
};

export default useUpdateReservation;
