import { useQuery } from "react-query";

import * as SpacesAPI from "./api";
import QueryKeys from "features/queryKeys";

/**
 * Fetches a space's details using React Query
 * @param spaceId - the ID of the space
 * @returns React Query object where data is type of SpaceInfo | undefined
 */
export const useSpace = (spaceId?: string) =>
  useQuery([QueryKeys.Space, spaceId], () => SpacesAPI.fetchSpace(spaceId), {
    initialData: undefined,
    enabled: !!spaceId && spaceId.length > 0,
    retry: false,
    refetchOnWindowFocus: false,
  });

/**
 * Fetches the raw data for a specific space map using React Query
 * @param mapId - the ID of the map being requested
 * @param spaceId - the ID of the space that the map belongs to
 * @returns React Query object where data is type of GameMapDB[] | undefined
 */
export const useSpaceMap = (mapId: string, spaceId?: string) =>
  useQuery(
    [QueryKeys.SpaceMaps, spaceId, { id: mapId }],
    () => SpacesAPI.fetchSpaceMap(spaceId, mapId),
    {
      initialData: undefined,
      enabled: mapId.length > 0,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

/**
 * Fetches a list of maps for a space using React Query
 * @param spaceId - the ID of the space that the maps belong to
 * @returns React Query object where data is type of GameMapDB[] | undefined
 */
export const useSpaceMaps = (spaceId?: string) =>
  useQuery([QueryKeys.SpaceMaps, spaceId], () => SpacesAPI.fetchSpaceMaps(spaceId), {
    initialData: undefined,
    enabled: !!spaceId,
    retry: false,
    refetchOnWindowFocus: false,
  });

/**
 * Fetches a list of reservations for a space using React Query
 * @param spaceId - the ID of the space that the reservations belong to
 * @returns React Query object where data is type of Reservation[] | undefined
 */
export const useSpaceReservations = (spaceId?: string) =>
  useQuery(
    [QueryKeys.SpaceReservations, spaceId],
    () => SpacesAPI.fetchSpaceReservations(spaceId),
    {
      initialData: undefined,
      enabled: !!spaceId,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

/**
 * Fetches a list of users that belong to a space using React Query
 * @param spaceId - the ID of the space that the users belong to
 * @returns React Query object where data is type of User[] | undefined
 */
export const useSpaceUsers = (spaceId?: string) =>
  useQuery([QueryKeys.SpaceUsers, spaceId], () => SpacesAPI.fetchSpaceUsers(spaceId), {
    initialData: undefined,
    enabled: !!spaceId,
    retry: false,
    refetchOnWindowFocus: false,
  });

export const useSpaceRecordingBudgetInfo = (spaceId?: string) =>
  useQuery(
    [QueryKeys.SpaceRecordingBudgetInfo, spaceId],
    () => SpacesAPI.getSpaceRecordingBudgetInfo(spaceId),
    {
      initialData: undefined,
      enabled: !!spaceId,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );
