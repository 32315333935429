import Box from "@mui/material/Box";
import LaunchIcon from "@mui/icons-material/Launch";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import DetailsCard from "components/dashboard/DetailsCard";
import TableCellTitle from "components/dashboard/TableCellTitle";
import TabWrapper from "components/dashboard/TabWrapper";
import React, { FC, useMemo } from "react";
import { SpaceUserResource } from "gather-common/dist/src/public/resources/space";
import { keys } from "ramda";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import {
  DashboardContext,
  NR_DASHBOARDS,
  createDashboardUrl,
} from "gather-admin-common/dist/src/public/newrelic";

interface Props {
  user?: SpaceUserResource;
}

const UserDetailsTab: FC<Props> = ({ user }) => {
  const userDashboardLinks = useMemo(() => {
    const userDashboardContext: DashboardContext = {
      userId: user?.id,
      spaceId: "%",
    };

    // TODO: Filter available dashboards to just those that should be shown on the
    //   user details page (currently that's all dashboards)
    return Object.fromEntries(
      keys(NR_DASHBOARDS).map((dashboard) => {
        const nrDashboard = NR_DASHBOARDS[dashboard];
        if (!nrDashboard) throw new Error(`Invalid dashboard key provided: ${dashboard}`);

        return [
          nrDashboard.title,
          {
            url: createDashboardUrl(dashboard, userDashboardContext),
            helpText: nrDashboard.helpText,
          },
        ];
      }),
    );
  }, [user]);

  return (
    <TabWrapper>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 4 }}>
        <Box sx={{ width: "100%", maxWidth: 500 }}>
          <DetailsCard header="General Details" sx={{ mb: 4 }}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCellTitle>ID</TableCellTitle>
                  <TableCell>
                    <Typography color="textPrimary" variant="body2">
                      {user?.id}
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCellTitle>Email</TableCellTitle>
                  <TableCell>
                    <Typography color="textPrimary" variant="body2">
                      {user?.email}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </DetailsCard>

          <DetailsCard header="User Metrics and Dashboards" sx={{ mb: 4, p: 1 }}>
            <List dense={true}>
              {Object.entries(userDashboardLinks).map(([title, data]) => (
                <ListItem>
                  <ListItemText primary={title} secondary={data.helpText} />
                  <Tooltip title="Open in New Relic">
                    <Link href={data.url ? data.url : ""} target="_blank" rel="noopener noreferrer">
                      <ListItemIcon>
                        <LaunchIcon />
                      </ListItemIcon>
                    </Link>
                  </Tooltip>
                </ListItem>
              ))}
              <ListItem>
                <ListItemText
                  primary="Client State Tool"
                  secondary="Search for all CSTs by this User"
                />
                <Tooltip title="Go to Client State Tool">
                  <Link
                    href={"/dashboard/client-state?spaceIdOrUserId=" + user?.id}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ListItemIcon>
                      <LaunchIcon />
                    </ListItemIcon>
                  </Link>
                </Tooltip>
              </ListItem>
            </List>
          </DetailsCard>

          {user && (
            <DetailsCard header="Desktop Logs" sx={{ mb: 4, p: 1 }}>
              <List dense={true}>
                <ListItem>
                  <ListItemText
                    primary="Desktop Logs"
                    secondary="Submitted desktop logs in Sentry"
                  />
                  <Tooltip title="Go to SendDesktopLogs in Sentry">
                    <Link
                      href={`https://gather-21.sentry.io/issues/3827860431/events/?project=4503898214105088&query=user.id%3A%22${user.id}%22&referrer=issue-stream&statsPeriod=30d&stream_index=0`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ListItemIcon>
                        <LaunchIcon />
                      </ListItemIcon>
                    </Link>
                  </Tooltip>
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Desktop Performance Traces"
                    secondary="Submitted performance traces"
                  />
                  <Tooltip title="Go to SendPerfTrace in Sentry">
                    <Link
                      href={`https://gather-21.sentry.io/issues/5680463589/events/?project=4503898214105088&query=user.id%3A%22${user.id}%22&referrer=issue-stream&statsPeriod=30d&stream_index=0`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ListItemIcon>
                        <LaunchIcon />
                      </ListItemIcon>
                    </Link>
                  </Tooltip>
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="All Desktop Events"
                    secondary="All artifacts uploaded to Sentry, including crashes and errors"
                  />
                  <Tooltip title="Go to full user event log in Sentry">
                    <Link
                      href={`https://gather-21.sentry.io/issues/?project=4503898214105088&query=user.id%3A%22${user.id}%22&referrer=issue-stream&statsPeriod=30d&stream_index=0`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ListItemIcon>
                        <LaunchIcon />
                      </ListItemIcon>
                    </Link>
                  </Tooltip>
                </ListItem>
              </List>
            </DetailsCard>
          )}
        </Box>
      </Box>
    </TabWrapper>
  );
};

export default UserDetailsTab;
