import React, { FC, useCallback } from "react";
import {
  useAVClientStateViewerDispatchers,
  useCurrentTimestamp,
  useSessionMessages,
} from "state/avClientStateViewerSlice";
import { AutoScroll, autoScrollClasses, PaneContent, ScrollPane } from "./ui";
import { ViewerMessage, WithRestProps } from "./types";

export type EventsProps = {
  renderers: (message: ViewerMessage) => FC<WithRestProps<{ message: ViewerMessage }>> | null;
};

const Events: FC<EventsProps> = ({ renderers }) => {
  const timestamp = useCurrentTimestamp();
  const messages = useSessionMessages();

  const { dispatchClickedToTimestamp } = useAVClientStateViewerDispatchers();

  const setTimestamp = useCallback((timestamp: number) => {
    dispatchClickedToTimestamp(timestamp);
  }, []);

  return (
    <PaneContent>
      <ScrollPane>
        <AutoScroll watch={timestamp} className="events-body">
          {messages.map((message) => {
            const RowComponent = renderers(message);
            return RowComponent ? (
              <div
                key={message.messageId}
                className={autoScrollClasses(timestamp, message.timestamp)}
                onClick={() => setTimestamp?.(message.timestamp)}
              >
                <RowComponent message={message} />
              </div>
            ) : null;
          })}
        </AutoScroll>
      </ScrollPane>
    </PaneContent>
  );
};

export default Events;
