import { useQueryClient, useMutation } from "react-query";

import * as ReservationsAPI from "features/reservations/api";
import QueryKeys from "features/queryKeys";
import { ReservationMutationCallbacks } from "./types";
import { AdminReservation } from "gather-admin-common/dist/src/public/reservations/types";

/**
 * Mutation that deletes a reservation document
 * @param callbacks Object containing onError and onSuccess callback methods
 * @returns ReactQuery useMutation hook
 */
const useDeleteReservation = (callbacks?: ReservationMutationCallbacks) => {
  const queryClient = useQueryClient();

  return useMutation((resId: string) => ReservationsAPI.deleteReservation(resId), {
    onMutate: async (resId: string) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      queryClient.cancelQueries([QueryKeys.Reservation, resId]);

      // Snapshot the previous values of Reservation
      const previousReservation = queryClient.getQueryData<AdminReservation>([
        QueryKeys.Reservation,
        resId,
      ]);

      // Snapshot the previous values of spaceReservations for this reservation's space
      const previousSpaceList = queryClient.getQueryData<AdminReservation[]>([
        QueryKeys.SpaceReservations,
        previousReservation?.roomId,
      ]);

      // Remove the reservation from the space's list
      if (previousSpaceList) {
        const updatedSpaceArray = previousSpaceList.filter((res) => res.id !== resId);

        queryClient.setQueryData<AdminReservation[]>(
          [QueryKeys.SpaceReservations, previousReservation?.roomId],
          updatedSpaceArray,
        );
      }

      // Snapshot the previous values for userReservations
      const previousUserList = queryClient.getQueryData<AdminReservation[]>([
        QueryKeys.UserReservations,
        previousReservation?.reserver,
      ]);

      // Remove the reservation from the user's list
      if (previousUserList) {
        const updatedUserArray = previousUserList.filter((res) => res.id !== resId);

        queryClient.setQueryData<AdminReservation[]>(
          [QueryKeys.UserReservations, previousReservation?.reserver],
          updatedUserArray,
        );
      }

      return { previousReservation, previousSpaceList, previousUserList };
    },
    onError: (_err, _variables, context) => {
      if (context?.previousReservation) {
        const { previousReservation, previousSpaceList, previousUserList } = context;

        if (previousReservation) {
          queryClient.setQueryData<AdminReservation>(
            [QueryKeys.Reservation, previousReservation?.id],
            previousReservation,
          );
        }

        if (previousSpaceList) {
          queryClient.setQueryData<AdminReservation[]>(
            [QueryKeys.SpaceReservations, previousReservation?.roomId],
            previousSpaceList,
          );
        }

        if (previousUserList) {
          queryClient.setQueryData<AdminReservation[]>(
            [QueryKeys.UserReservations, previousReservation?.reserver],
            previousUserList,
          );
        }
      }

      if (callbacks?.onError) {
        callbacks?.onError();
      }
    },

    onSuccess: (_data, resId, context) => {
      if (context?.previousReservation) {
        queryClient.removeQueries([QueryKeys.Reservation, resId]);

        if (callbacks?.onSuccess) {
          callbacks?.onSuccess();
        }
      }
    },

    onSettled: (_data, _err, resId) => {
      queryClient.invalidateQueries([QueryKeys.Reservation, resId]);
    },
  });
};

export default useDeleteReservation;
