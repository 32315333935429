const PROD_CONFIG = {
  apiKey: process.env.REACT_APP_PROD_CLIENT_FIREBASE_API_KEY,
  authDomain: "google-auth.gather.town",
  databaseURL: "https://gather-town.firebaseio.com",
  projectId: "gather-town",
  storageBucket: "gather-town.appspot.com",
  messagingSenderId: process.env.REACT_APP_PROD_CLIENT_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_PROD_CLIENT_FIREBASE_APP_ID,
};

const DEV_CONFIG = {
  apiKey: process.env.REACT_APP_DEV_CLIENT_FIREBASE_API_KEY,
  authDomain: "gather-town-dev.firebaseapp.com",
  databaseURL: "https://gather-town-dev.firebaseio.com",
  projectId: "gather-town-dev",
  storageBucket: "gather-town-dev.appspot.com",
  messagingSenderId: process.env.REACT_APP_DEV_CLIENT_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_DEV_CLIENT_FIREBASE_APP_ID,
};

const env = process.env.NODE_ENV;
export default env === "development" ? DEV_CONFIG : PROD_CONFIG;
