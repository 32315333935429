import React, { useMemo } from "react";
import alasql from "alasql";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Colors,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useTimestamps, useSessionMessages } from "state/avClientStateViewerSlice";
import { BaseMessage } from "./types";
import { startCase } from "lodash";
import { ChartConfig, charts } from "./chart.config";
import { clone, pipe, uniq, flatten, keys, map } from "ramda";
import { isViewerMessageObject } from "./utils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Colors,
);

ChartJS.defaults.color = "white";

export const options = {
  responsive: false,
  plugins: {
    colors: {
      enabled: true,
      forceOverride: true,
    },
    legend: {
      position: "bottom" as const,
    },
    title: {
      display: false,
    },
  },
};

const createOptions = (title: string) => ({
  ...clone(options),
  plugins: {
    ...clone(options.plugins),
    title: { ...clone(options.plugins.title), display: true, text: title },
  },
});

export const executeQuery = (query: string, data: Record<string, unknown>[]) => {
  try {
    return alasql(query, [data]);
  } catch (e) {
    console.error("Execute query failed", query, e);
    return [];
  }
};

export const uniqColumnKeys = pipe(map(keys), flatten, uniq);

const processQuery = (results: Record<string, unknown>[]) =>
  uniqColumnKeys(results).map((column) => ({
    label: startCase(column),
    data: results.map((result) => result[column]),
  }));

const prepareData = (
  chart: ChartConfig,
  _timestamps: number[],
  sessionMessages: BaseMessage[],
) => ({
  title: chart.title,
  labels: sessionMessages.map((m) => new Date(m.timestamp).toLocaleTimeString()),
  datasets: chart.query
    ? processQuery(executeQuery(chart.query, sessionMessages))
    : chart.columns?.map((dataset) => ({
        label: startCase(dataset),
        data: sessionMessages.filter(isViewerMessageObject).map((m) => m.payload[dataset]),
      })) ?? [],
});

const Dashboard = () => {
  const timestamps = useTimestamps();
  const sessionMessages = useSessionMessages();
  const bandwidthHealthData = useMemo(
    () => sessionMessages.filter(({ type }) => type.includes("av-bandwidth-health")),
    [sessionMessages],
  );

  const chartData = useMemo(
    () => charts.map((chart) => prepareData(chart, timestamps, bandwidthHealthData)),
    [timestamps, bandwidthHealthData],
  );

  return (
    <div className="chart-container">
      {chartData.map((data) => (
        <Line
          key={data.title}
          className="chart-box"
          width={500}
          height={400}
          options={createOptions(data.title)}
          plugins={[Colors]}
          data={data}
        />
      ))}
    </div>
  );
};

export default Dashboard;
