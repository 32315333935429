class PriorityQueue<T> {
  queue: Map<number, T[]> = new Map<number, T[]>();

  // lower priorities get popped off sooner
  push(priority: number, value: T) {
    if (!this.queue.get(priority)) {
      this.queue.set(priority, []);
    }
    this.queue.get(priority)?.push(value);
  }

  // lower priorities get popped off sooner
  popN(n: number): T[] {
    let topN: T[] = [];
    for (const priority of Array.from(this.queue.keys()).sort()) {
      const nToAdd = Math.max(n - topN.length, 0);
      const toAdd = this.queue.get(priority)?.slice(0, nToAdd) ?? [];
      const remaining = this.queue.get(priority)?.slice(nToAdd) ?? [];
      topN = topN.concat(toAdd);
      this.queue.set(priority, remaining);
      if (topN.length === n) {
        break;
      }
    }
    return topN;
  }

  size() {
    return Array.from(this.queue.values()).reduce((acc: number, elts) => acc + elts.length, 0);
  }
}

export default PriorityQueue;
