import React, { FC } from "react";
import { Card, CardHeader, Divider } from "@mui/material";
import { SxProps } from "@mui/system";

interface Props {
  header: string;
  children: string | React.ReactNode;
  sx?: SxProps;
}

const DetailsCard: FC<Props> = ({ header, children, ...other }) => (
  <Card {...other}>
    <CardHeader title={header} />
    <Divider />
    {children}
  </Card>
);

export default DetailsCard;
