import React, { FC } from "react";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";

interface ContainerProps {
  text: string;
  children: string | React.ReactNode;
  onLeft?: boolean;
}

interface TooltipProps {
  text: string;
  onLeft: boolean;
}

const InfoTooltip: FC<TooltipProps> = ({ text, onLeft }) => (
  <Tooltip title={text} placement="top" arrow>
    <InfoIcon
      fontSize="small"
      sx={{
        mr: onLeft ? 1 : 0,
        ml: onLeft ? 0 : 1,
        cursor: "pointer",
      }}
    />
  </Tooltip>
);

const InfoWrapper: FC<ContainerProps> = ({ children, text, onLeft = false }) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
    }}
  >
    {onLeft && <InfoTooltip text={text} onLeft={true} />}
    <span>{children}</span>
    {!onLeft && <InfoTooltip text={text} onLeft={false} />}
  </Box>
);

export default InfoWrapper;
