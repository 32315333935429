import { auth, firebase } from "gather-firebase-client/dist/src/public/firebase";

const MINUTES_TO_MILLISECONDS = 60000;
const NETWORK_RETRY_INTERVAL_MILLISECONDS = 5000;
// If the token will expire within `TOKEN_EXPIRATION_BUFFER_MINUTES` minutes, we
// consider it worthwhile to proactively refresh the token.
const TOKEN_EXPIRATION_BUFFER_MINUTES = 10;
const TOKEN_EXPIRATION_BUFFER_MILLISECONDS =
  TOKEN_EXPIRATION_BUFFER_MINUTES * MINUTES_TO_MILLISECONDS;

// `null` signifies that there is no Firebase user.
type TokenChangedListenerInput = {
  user: firebase.User;
  token: string;
} | null;

type TokenChangedListener = (input: TokenChangedListenerInput) => void;

interface AuthToken {
  token: string;
  expirationTime: number;
}

// Authentication document:
// https://www.notion.so/gathertown/Authentication-a11020c5bdc74e08aede6c173ae30338#8558fd2fa9a04aa8a523f33da7731229
//
// Singleton class for utility functions that manage the Firebase ID token. The
// token is encrypted and stores a Firebase Auth UID to refer to a user. We
// pass the token around to identify a user in requests.
//
// AuthTokenManager doesn't really contain the logic around actually signing in the user, it just is a store for the
// actual token. Consumers of this singleton will set listeners with `#addTokenChangedListener` and create sign-in
// logic.
//
// Several of these functions are merely different ways to fetch the Firebase ID
// token. Some info about Firebase ID tokens:
// - Tokens expire after an hour.
// - Firebase will cache tokens until they expire.
// - Tokens are attached to a Firebase user, so there is no token if there is no
//   user.
// Because Firebase caches tokens, fetching the token is immediate and
// error-free most of the time. When the token does expire, however, we need to
// fire a network request to refresh the token. Therefore, for a function to
// reliably return an unexpired token, the function needs to be async
// (`AuthManager.getToken()`, `AuthManager.waitForToken()`).
//
// Still, it's useful to be able to get tokens synchronously
// (`AuthManager.latestToken()`). To achieve this, we proactively refresh tokens
// before they expire and save the latest token. It's nevertheless still
// possible for the token to expire (in particular, if the user put their device
// to sleep for a long time). `AuthManager.latestToken()` will usually work, but
// the `async` token-retrieving functions are more reliable because they'll
// refresh the token if it's expired.
class AuthManager {
  static instance = new AuthManager();

  // The most recent Firebase ID token.
  private _authToken?: AuthToken;
  // Promise that resolves when ongoing call of `this.refreshToken()` finishes.
  private _activeRefreshTokenCall?: Promise<string | undefined>;
  // Handle to the `setTimeout` call that proactively refreshes the token before
  // expiration.
  private _refreshTokenTimeout?: ReturnType<typeof setTimeout>;

  private constructor() {
    auth.onIdTokenChanged(async (user) => {
      if (!user) {
        this._authToken = undefined;
        if (this._refreshTokenTimeout !== undefined) {
          clearTimeout(this._refreshTokenTimeout);
          this._refreshTokenTimeout = undefined;
        }
        return;
      }
      // We don't expect user.getIdTokenResult() to error here since Firebase
      // should cache the fresh token.
      const idTokenResult = await user.getIdTokenResult();
      const expirationTime = Date.parse(idTokenResult.expirationTime);
      this._authToken = { token: idTokenResult.token, expirationTime };

      // Proactively refresh token before it expires.
      if (this._refreshTokenTimeout !== undefined) {
        clearTimeout(this._refreshTokenTimeout);
      }
      const timeToExpiration = expirationTime - Date.now();
      this._refreshTokenTimeout = setTimeout(() => {
        this._refreshTokenTimeout = undefined;
        this.refreshToken();
      }, Math.max(0, timeToExpiration - TOKEN_EXPIRATION_BUFFER_MILLISECONDS));
    });

    // The timing of `this_refreshTokenTimeout` can be inaccurate if a player
    // puts their computer to sleep. Let's also check the token when the user
    // focuses on the window---if the window was focused when the player puts
    // their computer to sleep, then the 'focus' event will fire when the player
    // wakes the computer.
    if (typeof window === "undefined") return;
    window.addEventListener("focus", () => {
      if (!this._authToken) return;

      const timeToExpiration = this._authToken.expirationTime - Date.now();
      if (timeToExpiration < TOKEN_EXPIRATION_BUFFER_MILLISECONDS) {
        this.refreshToken();
      }
    });
  }

  // Same as Firebase's `getIdToken()` except that it catches errors.
  // Details:
  // - If there is an unexpired token immediately available, return it.
  // - If the token is expired, refresh it (but this may fail due to network
  //   issues) and return it.
  // - If there is no Firebase user or token refresh fails, return `undefined`.
  async getToken(): Promise<string | undefined> {
    const forceRefresh = false;
    try {
      const token = await auth.currentUser?.getIdToken(forceRefresh);
      return token;
    } catch (err) {
      console.error("getToken:", err);
      return undefined;
    }
  }

  // Returns a promise that resolves when an unexpired token is available. The
  // difference between this function and `getToken()` is that this function may
  // hang for arbitrarily long waiting for the token unless an AbortSignal is
  // passed and that is aborted.
  // Details:
  // - If there is an unexpired token immediately available, return it.
  // - If the token is expired, refresh it and return it, retrying on network
  // failure. May hang for arbitrarily long while waiting for network
  // connectivity.
  // - If there is no Firebase user, we can't refresh the token. Wait for some
  // action to set the user, and then return the user's token.
  async waitForToken(signal?: AbortSignal): Promise<string> {
    const token = await this._getTokenWithNetworkRetry(false, signal);
    if (token) return token;

    // There's no Firebase user. We'll wait until some action sets the user.
    console.warn("waitForToken: Waiting for Firebase user");
    let resolve: (token: string) => void;
    const promise = new Promise<string>((resolve_, _reject) => {
      resolve = resolve_;
    });
    const removeTokenListener = this.addTokenChangedListener((input) => {
      if (input === null) return;

      resolve(input.token);
    });
    promise.then(removeTokenListener);
    return promise;
  }

  // Returns the most recent token. Unlike `getToken`, this function is not
  // async, so it may return an expired token in the uncommon situation where
  // we've failed to refresh the token during the past hour.
  latestToken(): string | undefined {
    return this._authToken?.token;
  }

  // Returns the expiration time of the most recent token.
  latestTokenExpirationTime(): number | undefined {
    return this._authToken?.expirationTime;
  }

  // Force a refresh of the current token. May hang for arbitrarily long while
  // waiting for network connectivity.
  refreshToken = async () => {
    // If `_activeRefreshTokenCall` is set, there's another iteration of
    // `refreshToken()` running. We can just wait for that iteration to complete
    // instead of potentially accumulating a lot of distinct
    // `this._getTokenWithNetworkRetry()` calls and spamming Firebase.

    if (!this._activeRefreshTokenCall) {
      this._activeRefreshTokenCall = this._getTokenWithNetworkRetry(true);
    }
    await this._activeRefreshTokenCall;
    this._activeRefreshTokenCall = undefined;
  };

  // Add listener that will be called whenever the token changes. Returns a
  // callback to unsubscribe the listener.
  addTokenChangedListener(listener: TokenChangedListener): firebase.Unsubscribe {
    return auth.onIdTokenChanged(async (user) => {
      if (!user) {
        listener(null);
        return;
      }
      // We don't expect user.getIdTokenResult() to error here since Firebase
      // should cache the fresh token.
      const token = await user.getIdToken();
      listener({ user, token });
    });
  }

  // Firebase `getIdToken()`, except we retry on network failures. Returns an
  // unexpired token, or returns `undefined` if there is no Firebase user. May
  // hang for arbitrarily long while waiting for network connectivity, unless
  // an AbortSignal is passed and that is aborted.
  private async _getTokenWithNetworkRetry(
    forceRefresh = false,
    signal?: AbortSignal,
  ): Promise<string | undefined> {
    let token = undefined;
    while (auth.currentUser && token === undefined) {
      if (signal?.aborted) {
        throw signal.reason instanceof Error
          ? signal.reason
          : new Error(`aborted: ${signal.reason}`);
      }

      try {
        token = await auth.currentUser.getIdToken(forceRefresh);
      } catch (err) {
        // @ts-expect-error Error auto-ignored when enabling useUnknownInCatchVariables. It's possible this is incorrect.
        // TODO: @ENG-4157 Clean these up! If you're already touching this code, please clean this up while you're at it.
        if (err.code !== "auth/network-request-failed") throw err;

        console.error("getTokenWithNetworkRetry: network request failed");
        // on network failure: sleep, then retry
        await new Promise((resolve) => setTimeout(resolve, NETWORK_RETRY_INTERVAL_MILLISECONDS));
      }
    }
    return token;
  }
}

export default AuthManager.instance;
