import React, { FC, useState, useCallback, ChangeEvent, useEffect, MouseEvent } from "react";
import _ from "lodash";
import {
  AdminSpaceMap,
  MapObject as ObjectDB,
} from "gather-admin-common/dist/src/public/spaces/types";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import TablePagination from "@mui/material/TablePagination";

import ObjectsTable from "../ObjectsTable";
import ObjectInstanceModal from "../ObjectInstanceModal";
import { applySort, applyPagination } from "features/tables/utils";
import { applyInteractiveFilter, parseTemplateId } from "features/maps/utils";
import SortSelect from "components/inputs/SortSelect";

const sortOptions = [
  {
    label: "Name (asc)",
    value: "name|asc",
  },
  {
    label: "Name (desc)",
    value: "name|desc",
  },
  {
    label: "Instances (asc)",
    value: "length|asc",
  },
  {
    label: "Instances (desc)",
    value: "length|desc",
  },
];

const filterOptions = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Interactive",
    value: "isInteractive",
  },
];

export interface ObjectGroupInfo {
  name: string;
  key: string;
  length: number;
}

export interface ObjectGroups {
  [id: string]: ObjectDB[];
}
interface Props {
  map?: AdminSpaceMap;
}

const ObjectsTab: FC<Props> = ({ map }) => {
  const [currentFilter, setCurrentFilter] = useState<string>("all");
  const [currentSort, setCurrentSort] = useState<string>("name|asc");
  const [currentPage, setCurrentPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState<string>("");
  const [objects, setObjects] = useState<ObjectDB[]>(Object.values(map?.objects ?? {}));
  const [groupInfo, setGroupInfo] = useState<ObjectGroupInfo[]>([]);
  const [objGroups, setObjGroups] = useState<ObjectGroups>({});
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [objToView, setObjToView] = useState<ObjectDB>();

  const applyQuery = (arr: ObjectDB[], query: string) =>
    arr.filter((obj) => {
      let matches = true;

      if (query) {
        const properties: (keyof ObjectDB)[] = ["id", "_name", "templateId", "previewMessage"];
        let containsQuery = false;

        properties.forEach((property) => {
          if (obj[property]?.toString().toLowerCase().includes(query.toLowerCase())) {
            containsQuery = true;
          }
        });

        /* \(? - match optional opening paren
         * ([0-9]+) - match any number (0-999999...) and capture in capture group 1
         * , - comma ;)
         *  ([0-9]+) - match second number into capture group 2
         * \)? - optional closing paren
         */
        const coordRegex = /\(?([0-9]+),\s?([0-9]+)\)?/;
        const hasCoord = coordRegex.test(query); // true false

        if (!containsQuery && hasCoord) {
          const coords = query.match(coordRegex); // number[] === [x, y]

          if (coords?.length === 3) {
            const x = coords[1];
            const y = coords[2];
            const matchX = x !== undefined && obj.x === parseInt(x);
            const matchY = y !== undefined && obj.y === parseInt(y);
            containsQuery = matchX && matchY;
          }
        }

        if (!containsQuery) {
          matches = false;
        }
      }

      return matches;
    });

  const handleModalOpen = useCallback(
    (obj: ObjectDB) => {
      setObjToView(obj);
      setModalOpen(true);
    },
    [setModalOpen, setObjToView],
  );

  const handleModalClose = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  const handlePageChange = useCallback(
    (_event: MouseEvent<HTMLButtonElement> | null, page: number) => {
      setCurrentPage(page);
    },
    [setCurrentPage],
  );

  const handleLimitChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setLimit(parseInt(event.target.value, 10));
      setCurrentPage(0);
    },
    [setLimit, setCurrentPage],
  );

  const handleFilterChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setCurrentFilter(event.target.value);
      setCurrentPage(0);
    },
    [setCurrentFilter],
  );

  const handleSortChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setCurrentSort(event.target.value);
      setCurrentPage(0);
    },
    [setCurrentSort],
  );

  const handleSearchQueryChange = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  useEffect(() => {
    const filtered = currentFilter === "all" ? objects : applyInteractiveFilter(objects);
    const queried = applyQuery(filtered, query);
    const objectGroups = _.groupBy(queried, (obj) => parseTemplateId(obj.templateId));
    const objectGroupInfo: ObjectGroupInfo[] = [];

    Object.keys(objectGroups).forEach((key: keyof typeof objectGroups) => {
      const group = objectGroups[key];
      const first = group?.[0];

      if (first) {
        objectGroupInfo.push({
          name: (first._name || "").toLowerCase(),
          key: key.toString(),
          length: group.length,
        });
      }
    });

    const sortedGroupInfo = applySort<ObjectGroupInfo>(objectGroupInfo, currentSort);
    const paginatedGroupInfo = applyPagination<ObjectGroupInfo>(
      sortedGroupInfo,
      currentPage,
      limit,
    );
    setGroupInfo(paginatedGroupInfo);
    setObjGroups(objectGroups);
  }, [
    objects,
    query,
    currentPage,
    currentFilter,
    currentSort,
    limit,
    setGroupInfo,
    setObjGroups,
    setObjects,
  ]);

  return (
    <Box
      sx={{
        minWidth: "100%",
        mt: 1,
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexWrap: "wrap",
          pl: 1,
          pr: 1,
          mb: 1,
          ml: -1,
          gap: 1,
        }}
      >
        <Box
          sx={{
            maxWidth: "100%",
            width: 500,
          }}
        >
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            onChange={handleSearchQueryChange}
            placeholder="Search by Name, ID, Preview Message, or Coordinates (x, y)"
            value={query}
            variant="outlined"
          />
        </Box>

        <Box
          sx={{
            maxWidth: "100%",
            width: 167,
          }}
        >
          <SortSelect onChange={handleSortChange} value={currentSort} options={sortOptions} />
        </Box>
        <Box
          sx={{
            maxWidth: "100%",
            width: 150,
          }}
        >
          <SortSelect
            onChange={handleFilterChange}
            value={currentFilter}
            options={filterOptions}
            label="Filter by"
          />
        </Box>
      </Box>

      <Card variant="outlined">
        <ObjectsTable
          objectGroups={objGroups}
          sortedGroupInfo={groupInfo}
          onModalOpen={handleModalOpen}
        />
        <TablePagination
          component="div"
          count={Object.keys(objGroups).length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={currentPage}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
        />
      </Card>

      <ObjectInstanceModal open={modalOpen} onClose={handleModalClose} obj={objToView} />
    </Box>
  );
};

export default ObjectsTab;
