import React, { FC, useState, useCallback, ChangeEvent, KeyboardEvent, useMemo } from "react";
import TabWrapper from "components/dashboard/TabWrapper";
import SearchIdInput from "../../../../components/inputs/SearchIdInput";
import { useSpace, useSpaceMaps } from "../../../../features/spaces/hooks";
import { extractSpaceIDFromUserInput } from "../../../../utils/handleSpaceID";
import AlertDialog from "../../../../components/dashboard/AlertDialog";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { toast } from "react-hot-toast";
import { copySpaceMaps } from "../../../../features/spaceTemplates/api";
import { guaranteedError } from "gather-common/dist/src/public/utils";

interface Props {
  spaceId?: string;
}
const CopySpaceTab: FC<Props> = ({ spaceId }) => {
  const { data: maps, isLoading: isLoadingMaps } = useSpaceMaps(spaceId);
  const { data: space, isLoading: isLoadingSpace } = useSpace(spaceId);
  const allMaps = useMemo(() => maps || [], [maps]);

  const [targetSpaceId, setTargetSpaceId] = useState<string>("");
  const [showCopyConfirmation, setShowCopyConfirmation] = useState(false);
  const [spaceIdInputValue, setSpaceIdInputValue] = useState<string>("");
  const [mapsAreCopying, setMapsAreCopying] = useState(false);

  const { data: targetSpace, isLoading: isLoadingTargetSpace } = useSpace(targetSpaceId);

  const { data: targetMaps, isLoading: isLoadingTargetMaps } = useSpaceMaps(
    !isLoadingTargetSpace ? targetSpace?.id : undefined,
  );
  const allTargetMaps = useMemo(() => targetMaps || [], [targetMaps]);

  const handleSpaceIdChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSpaceIdInputValue(event.target.value);
    },
    [setSpaceIdInputValue],
  );

  const handleSpaceIdKeyPress = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        const newSpaceId = extractSpaceIDFromUserInput(spaceIdInputValue);
        setTargetSpaceId(newSpaceId);
      }
    },
    [setTargetSpaceId, spaceIdInputValue],
  );

  const handleCopy = async (confirm: boolean) => {
    setShowCopyConfirmation(false);
    if (!spaceId || !targetSpaceId || !confirm) return;

    setMapsAreCopying(true);
    try {
      await copySpaceMaps(spaceId, targetSpaceId);
      setMapsAreCopying(false);
      toast.success("Space maps were successfully copied.");
    } catch (e) {
      setMapsAreCopying(false);
      const error = guaranteedError(e);
      toast.error(`Space maps failed to copy: ${error}`);
    }
  };

  return (
    <TabWrapper>
      <Box
        sx={{
          display: "flex",
          height: "100%",
          width: "100%",
        }}
      >
        <Box
          sx={{
            width: "50%",
          }}
        >
          {(isLoadingMaps || isLoadingSpace) && <Box sx={{ p: 2, width: "100%" }}>Loading...</Box>}
          {!isLoadingMaps && !isLoadingSpace && maps && space && (
            <>
              <p>This space</p>
              <ul>
                <li>{`Has ${allMaps.length} map(s)`}</li>
                {space.isTemplate && <li>Is a template space</li>}
              </ul>
            </>
          )}
        </Box>
        <Box>
          <SearchIdInput
            onKeyPress={handleSpaceIdKeyPress}
            onChange={handleSpaceIdChange}
            id={spaceIdInputValue}
            placeholder="Enter Space ID or URL here"
            label="Search for target space"
          />
          {isLoadingTargetMaps && <Box sx={{ p: 2 }}>Loading...</Box>}
          {!isLoadingTargetMaps && !isLoadingTargetSpace && targetMaps && targetSpace && (
            <>
              {targetSpaceId === spaceId ? (
                <p>Target space cannot be the same as source space.</p>
              ) : (
                <>
                  <p>The target space</p>
                  <ul>
                    <li>{`Has ${allTargetMaps.length} map(s)`}</li>
                    {targetSpace.isTemplate && <li>Is a template space</li>}
                  </ul>
                  <Button
                    color="primary"
                    onClick={() => setShowCopyConfirmation(true)}
                    variant="contained"
                    disabled={mapsAreCopying}
                  >
                    {mapsAreCopying ? "Copy in progress..." : "Overwrite target space's maps"}
                  </Button>
                </>
              )}
            </>
          )}
        </Box>
        <AlertDialog
          isOpen={showCopyConfirmation}
          onClose={handleCopy}
          title={`Confirm Space Maps overwrite`}
        >
          {`Are you sure you want to overwrite all maps inside of ${targetSpaceId} with the maps of
        ${spaceId}? This action is not reversible.`}
        </AlertDialog>
      </Box>
    </TabWrapper>
  );
};

export default CopySpaceTab;
