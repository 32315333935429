import React, { FC } from "react";
import { TableCell, Typography } from "@mui/material";

interface TableCellTitleProps {
  children: string | React.ReactNode;
}

const TableCellTitle: FC<TableCellTitleProps> = ({ children }) => (
  <TableCell
    sx={{
      whiteSpace: "nowrap",
      wordWrap: "break-word",
      verticalAlign: "top",
      width: {
        xs: 190,
        lg: "33%",
      },
    }}
  >
    <Typography color="textSecondary" variant="subtitle2">
      {children}
    </Typography>
  </TableCell>
);

export default TableCellTitle;
