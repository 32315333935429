/* External modules */
import React, { FC } from "react";

/* MUI Components */
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

/* Local modules */
import WearableVariantRow from "./WearableVariantRow";

import { Wearable } from "gather-common/dist/src/public/resources/users";

interface Props {
  variants: Wearable[];
  onDelete?: (wearable: Wearable) => void;
}

const WearableVariantsTable: FC<Props> = ({ variants, onDelete }) => (
  <Box>
    <Card>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Color</TableCell>
            <TableCell>Default</TableCell>
            <TableCell>Spritesheet IDs</TableCell>
            {onDelete && <TableCell>Actions</TableCell>}
          </TableRow>
        </TableHead>

        <TableBody>
          {variants.map((variant, index) => (
            <WearableVariantRow
              variant={variant}
              index={index}
              key={`var-${index}`}
              onDelete={onDelete}
            />
          ))}
        </TableBody>
      </Table>
    </Card>
  </Box>
);

export default WearableVariantsTable;
