import Big from "big.js";

import { PaletteKey } from "components/types";
import { DiscountType } from "gather-prisma-types/dist/src/public/client";
import { AdminDiscount } from "./types";

export enum DiscountState {
  Active = "Active",
  MaxUses = "Max Uses",
  NotStarted = "Not Started",
  Expired = "Expired",
}

interface StateLabel {
  state: DiscountState;
  stateLabelVariant: PaletteKey;
}

export const determineDiscountState = (discount: AdminDiscount): StateLabel => {
  const { numUses, maxUses, startTime, endTime } = discount;

  if (maxUses) {
    const useLimitReached = numUses >= maxUses;

    if (useLimitReached) {
      return {
        state: DiscountState.MaxUses,
        stateLabelVariant: "error",
      };
    }
  }

  if (startTime) {
    const startTimeMs = new Date(startTime).getTime();
    const isNotStarted = Date.now() < startTimeMs;

    if (isNotStarted) {
      return {
        state: DiscountState.NotStarted,
        stateLabelVariant: "warning",
      };
    }
  }

  if (endTime) {
    const endTimeMs = new Date(endTime).getTime();
    const isExpired = Date.now() > endTimeMs;

    if (isExpired) {
      return {
        state: DiscountState.Expired,
        stateLabelVariant: "error",
      };
    }
  }

  return {
    state: DiscountState.Active,
    stateLabelVariant: "primary",
  };
};

/**
 * Filters discounts by state
 * @param discounts - list of discounts to filter
 * @param targetState - discount state to filter for
 * @returns AdminDiscount[]
 */
export const applyDiscountStateFilter = (
  discounts: AdminDiscount[],
  targetState: DiscountState | string,
): AdminDiscount[] => {
  if (targetState === "all") return discounts;

  return discounts.filter((discount) => {
    const { state } = determineDiscountState(discount);
    return state === targetState;
  });
};

/**
 * Filters discounts by type
 * @param discounts - list of discounts to filter
 * @param targetType - discount type to filter for
 * @returns AdminDiscount[]
 */
export const applyDiscountTypeFilter = (
  discounts: AdminDiscount[],
  targetType: DiscountType | string,
): AdminDiscount[] => {
  switch (targetType) {
    case "subscription":
      return discounts.filter((d) => d.type === DiscountType.subscription);
    case "multiplier":
      return discounts.filter((d) => d.type === DiscountType.multiplier);
    default:
      return discounts.filter((d) => d.id !== undefined); // Filters out the legacy custom types map
  }
};

/**
 * Filters discounts by id
 * @param discounts - list of discounts to filter
 * @param query - string to query for in discountId
 * @returns AdminDiscount[]
 */
export const applyDiscountIdFilter = (discounts: AdminDiscount[], query: string) => {
  if (query.length === 0) return discounts;

  return discounts.filter((discount) => {
    const properties: (keyof AdminDiscount)[] = ["id", "description", "subscriptionCode"];

    let hasPropertyMatch = false;

    properties.forEach((property) => {
      if (discount[property]?.toString().toLowerCase().includes(query.toLowerCase())) {
        hasPropertyMatch = true;
      }
    });

    return hasPropertyMatch;
  });
};

/**
 * Calculate multiplier based off of intended discount, avoiding issues with JS precision
 * @param intendedDiscountPercent The discount (eg, 30)
 * @returns multiplier to be used in calculating final price (eg, 0.7 should be returned for a discount of 30)
 */
export const calculateMultiplierFromDiscount = (intendedDiscountPercent: number) => {
  const intendedDiscount = Big(intendedDiscountPercent).div(100);
  return Big(1).minus(intendedDiscount).toNumber();
};

/**
 * Calculate discount percentage based off of multiplier, avoiding issues with JS precision
 * @param multiplier The multiplier used to calculate final price (eg, 0.7)
 * @returns discount in percent (eg, 30 should be returned for a multiplier of 0.7)
 */
export const calculateDiscountFromMultiplier = (multiplier: number) => {
  const bigMultiplier = Big(multiplier);
  const discountDecimal = Big(1).minus(bigMultiplier);
  return discountDecimal.times(100).toNumber();
};
