/* Usage: 
  const myArr = [{myKey: obj1}, {myKey: obj2}]
  objFromArray(myArr, "myKey") // { key1: obj1, key2: obj2 }
*/
export function objFromArray<T extends { [key in U]: string }, U extends string = "id">(
  arr: T[],
  key: U,
): Record<string, T> {
  return arr.reduce((accumulator: Record<string, T>, current: T) => {
    const id = current[key];
    accumulator[id] = current;
    return accumulator;
  }, {});
}

/**
 * source: https://stackoverflow.com/questions/42136098/array-groupby-in-typescript
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Error autoignored while enabling eslint for gather-admin. If you're already touching this code, please clean this up while you're at it.
export const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
  list.reduce((previous, currentItem) => {
    const group = getKey(currentItem);
    if (!previous[group]) {
      previous[group] = [];
    }
    previous[group].push(currentItem);
    return previous;
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- Error autoignored while enabling eslint for gather-admin. If you're already touching this code, please clean this up while you're at it.
  }, {} as Record<K, T[]>);

export default objFromArray;
