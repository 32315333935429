import React, { FC, useState, useMemo, useCallback } from "react";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import { Button, Divider, Typography } from "@mui/material";
import { updateTutorialAreas } from "features/maps/api";
import { extractSpaceIDFromUserInput } from "utils/handleSpaceID";
import { toast } from "react-hot-toast";
import useRoles from "features/roles/useRoles";
import { AdminSpaceMap } from "gather-admin-common/dist/src/public/spaces/types";
import { DBAreas } from "gather-common/dist/src/public/resources/space";
import { AdminPermission as Can } from "gather-admin-common/dist/src/public/roles/types";

export interface ObjectGroupInfo {
  name: string;
  key: string;
  length: number;
}

interface Props {
  map?: AdminSpaceMap;
  spaceId?: string;
  mapId?: string;
}

type AreaPositionKey = "x1" | "x2" | "y1" | "y2";

const areaPositionKeyArray: AreaPositionKey[] = ["x1", "x2", "y1", "y2"];

const TutorialAreasTab: FC<Props> = ({ map, spaceId, mapId }) => {
  const [areas, setAreas] = useState<DBAreas>(map?.areas || {});
  const [newTutorialAreaId, setNewTutorialAreaId] = useState<string>("");
  const { permissions } = useRoles();

  const onChange = useCallback(
    (id: string, index: number, field: AreaPositionKey, value: number) => {
      const newAreas = { ...areas };
      const update = newAreas[id]?.coords[index];
      if (update) {
        update[field] = value;
      }
      setAreas(newAreas);
    },
    [areas, setAreas],
  );

  const addTutorialAreaSection = useCallback(
    (id: string) => {
      const newAreas = { ...areas };
      const coords = newAreas[id]?.coords;
      if (!coords) return;
      coords.push({ x1: 0, y1: 0, x2: 0, y2: 0 });
      setAreas(newAreas);
    },
    [areas, setAreas],
  );

  const createNewTutorialArea = useCallback(() => {
    if (!newTutorialAreaId) return;
    const newAreas = { ...areas };
    newAreas[newTutorialAreaId] = {
      coords: [
        {
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 0,
        },
      ],
    };
    setAreas(newAreas);
    setNewTutorialAreaId("");
  }, [areas, setAreas, setNewTutorialAreaId, newTutorialAreaId]);

  const onSubmit = useCallback(async () => {
    try {
      await updateTutorialAreas(
        extractSpaceIDFromUserInput(spaceId ?? ""),
        decodeURIComponent(mapId ?? ""),
        areas,
      );
      toast.success("Tutorial areas updated successfully");
    } catch (e) {
      toast.error("Tutorial areas failed to save");
    }
  }, [areas, mapId, spaceId]);

  const deleteTutorialArea = useCallback(
    (areaId: string) => {
      const newAreas = { ...areas };
      delete newAreas[areaId];
      setAreas(newAreas);
    },
    [areas, setAreas],
  );

  const validNewTutorialAreaId = useMemo(
    () => newTutorialAreaId && !areas[newTutorialAreaId],
    [areas, newTutorialAreaId],
  );

  const hasPermission = useMemo(() => permissions.includes(Can.UpdateTutorialAreas), [permissions]);

  if (!hasPermission) return <>You need permission to view/update tutorial areas</>;

  return (
    <>
      {Object.entries(areas).map(([areaId, area]) => (
        <>
          <Box sx={{ marginBottom: "16px", padding: "8px" }}>
            <Typography align="left" color="textPrimary" gutterBottom variant={"body1"}>
              areaId: {areaId}
            </Typography>
            {area.coords.map((section, i) => (
              <Box sx={{ display: "flex", flexDirection: "row", gap: "8px", marginBottom: "16px" }}>
                {areaPositionKeyArray.map((field) => (
                  <>
                    {field}
                    <TextField
                      type="number"
                      error={isNaN(section[field]) || section[field] < 0}
                      value={section[field]}
                      onChange={(e) => {
                        onChange(areaId, i, field, parseInt(e.target.value));
                      }}
                    />
                  </>
                ))}
              </Box>
            ))}
            <Button
              color="secondary"
              onClick={() => {
                addTutorialAreaSection(areaId);
              }}
            >
              Add New Box to Area
            </Button>
            <Button
              color="error"
              onClick={() => {
                deleteTutorialArea(areaId);
              }}
            >
              Delete Area
            </Button>
          </Box>
          <Divider />
        </>
      ))}
      <Box marginTop="16px" display="flex" flexDirection="column" gap="8px">
        <TextField
          value={newTutorialAreaId}
          onChange={(e) => setNewTutorialAreaId(e.target.value)}
          error={!!areas[newTutorialAreaId]}
          helperText={areas[newTutorialAreaId] ? "Area already exists" : ""}
        />
        <Button color="primary" onClick={createNewTutorialArea} disabled={!validNewTutorialAreaId}>
          Create New Tutorial Area
        </Button>
      </Box>
      <Divider />
      <Box marginTop="24px" justifyContent="center" display="flex">
        <Button color="primary" onClick={onSubmit} size="large">
          Save
        </Button>
      </Box>
    </>
  );
};

export default TutorialAreasTab;
