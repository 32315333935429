import { useQuery } from "react-query";

import * as WearablesAPI from "./api";

/**
 * Fetches all wearables
 * @returns ReactQuery object where data is type of Wearable | undefined
 */
export const useWearables = () =>
  useQuery("wearables", WearablesAPI.fetchWearables, {
    initialData: undefined,
    retry: false,
  });
