/* External modules */
import React, { ChangeEvent, FC, useRef, useState } from "react";
import { Buffer } from "buffer";

/* MUI Components */
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

/* Local modules */

import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronDown from "@mui/icons-material/KeyboardArrowDown";
import Upload from "@mui/icons-material/Upload";
import * as WearablesAPI from "../../../../features/wearables/api";
import useUpdateWearablePart from "../../../../features/wearables/mutations/useUpdateWearablePart";
import { Wearable } from "gather-common/dist/src/public/resources/users";

interface Props {
  variant: Wearable;
}

const VariantPart: FC<{ layerId: string; spritesheetId: string; spritesheetUrl?: string }> = ({
  layerId,
  spritesheetId,
  spritesheetUrl,
}) => {
  const { mutate } = useUpdateWearablePart();
  const inputRef = useRef<HTMLInputElement>(null);
  const [expanded, setExpanded] = useState(false);

  const cellStyle = expanded ? { border: 0 } : undefined;

  const handleUploadSpritesheet = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    const fileUrl = await WearablesAPI.uploadWearableImage(Buffer.from(await file.arrayBuffer()));

    mutate({ wearablePartId: spritesheetId, data: { spritesheetUrl: fileUrl } });
  };

  const uploadLabel = `upload-${spritesheetId}`;

  return (
    <React.Fragment>
      <TableRow>
        <TableCell style={cellStyle}>
          <Typography color="textPrimary" variant="body2">
            {layerId}
          </Typography>
        </TableCell>
        <TableCell style={cellStyle}>
          <Typography color="textPrimary" variant="body2">
            {spritesheetId}
          </Typography>
        </TableCell>
        <TableCell style={cellStyle}>
          <IconButton onClick={() => setExpanded(!expanded)} size="small">
            {expanded ? <ChevronDown /> : <ChevronLeft />}
          </IconButton>
        </TableCell>
      </TableRow>
      {expanded && (
        <TableRow style={{ border: 0 }}>
          <TableCell colSpan={2}>
            <img src={spritesheetUrl} style={{ maxWidth: 270 }} alt="spritesheet" />
          </TableCell>
          <TableCell>
            <input
              type="file"
              style={{ display: "none" }}
              id={uploadLabel}
              ref={inputRef}
              onChange={handleUploadSpritesheet}
            />
            <IconButton size="small" onClick={() => inputRef.current?.click()}>
              <Upload />
            </IconButton>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
};

const WearableVariantSpritesheetsTable: FC<Props> = ({ variant }) => (
  <TableCell>
    <Table stickyHeader aria-label="sticky table" size="small">
      <TableHead>
        <TableRow>
          <TableCell>Layer ID</TableCell>
          <TableCell>ID</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {variant.parts.map((part) => (
          <VariantPart {...part} />
        ))}
      </TableBody>
    </Table>
  </TableCell>
);

export default WearableVariantSpritesheetsTable;
