import { UserDevice } from "gather-prisma-types/dist/src/public/client";
import HttpV2AdminClient from "../../http-client/httpV2Client";
import { getErrorMessage } from "gather-common/dist/src/public/utils";
import { HttpV2Paths } from "gather-http-common/dist/src/public/httpAPI";

export const fetchUserDeviceTokens: (userId: string) => Promise<UserDevice[]> = async (
  userId: string,
) => {
  try {
    const devices = await HttpV2AdminClient.get<UserDevice[]>(HttpV2Paths.AdminAuthUserDevices, {
      auth: true,
      params: { path: { user: userId } },
    });

    return devices ?? [];
  } catch (error) {
    throw new Error(getErrorMessage(error, "Failed to fetch user devices"));
  }
};

type ArgBody = {
  title: string;
  body: string;
};

export const sendPushNotification: (userId: string, body: ArgBody) => Promise<void> = async (
  userId,
  { title, body },
) => {
  try {
    await HttpV2AdminClient.post(HttpV2Paths.AdminAuthUserSendPushNotifications, {
      auth: true,
      params: { path: { user: userId }, body: { title, body } },
    });
  } catch (error) {
    throw new Error(getErrorMessage(error, "Failed to fetch user devices"));
  }
};
