interface FirebaseClientConfig {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
}

interface AllFirebaseClientConfigs {
  prod: FirebaseClientConfig;
  staging: FirebaseClientConfig;
  dev: FirebaseClientConfig;
  test: FirebaseClientConfig;
  local: FirebaseClientConfig;
}

const prodConfig: FirebaseClientConfig = {
  apiKey:
    process.env.PROD_CLIENT_FIREBASE_API_KEY ||
    process.env.REACT_APP_PROD_CLIENT_FIREBASE_API_KEY ||
    "",
  appId:
    process.env.PROD_CLIENT_FIREBASE_APP_ID ||
    process.env.REACT_APP_PROD_CLIENT_FIREBASE_APP_ID ||
    "",
  authDomain: "google-auth.gather.town",
  databaseURL: "https://gather-town.firebaseio.com",
  messagingSenderId:
    process.env.PROD_CLIENT_FIREBASE_MESSAGING_SENDER_ID ||
    process.env.REACT_APP_PROD_CLIENT_FIREBASE_MESSAGING_SENDER_ID ||
    "",
  projectId: "gather-town",
  storageBucket: "gather-town.appspot.com",
};

// staging browser uses same Firebase client as prod
const stagingConfig: FirebaseClientConfig = prodConfig;

const devConfig: FirebaseClientConfig = {
  apiKey:
    process.env.DEV_CLIENT_FIREBASE_API_KEY ||
    process.env.REACT_APP_DEV_CLIENT_FIREBASE_API_KEY ||
    "",
  appId:
    process.env.DEV_CLIENT_FIREBASE_APP_ID ||
    process.env.REACT_APP_DEV_CLIENT_FIREBASE_APP_ID ||
    "",
  authDomain: "gather-town-dev.firebaseapp.com",
  databaseURL: "https://gather-town-dev.firebaseio.com",
  messagingSenderId:
    process.env.DEV_CLIENT_FIREBASE_MESSAGING_SENDER_ID ||
    process.env.REACT_APP_DEV_CLIENT_FIREBASE_MESSAGING_SENDER_ID ||
    "",
  projectId: "gather-town-dev",
  storageBucket: "gather-town-dev.appspot.com",
};

// local and dev environments use same config too
const localConfig: FirebaseClientConfig = devConfig;

const testConfig: FirebaseClientConfig = {
  apiKey: "test",
  appId: "test",
  authDomain: "test",
  databaseURL: "test",
  messagingSenderId: "test",
  projectId: "demo-gather-town-test",
  storageBucket: "test",
};

const allConfigs: AllFirebaseClientConfigs = {
  prod: prodConfig,
  staging: stagingConfig,
  dev: devConfig,
  test: testConfig,
  local: localConfig,
};

function isConfigKey(key?: string): key is keyof AllFirebaseClientConfigs {
  return key != null && key in allConfigs;
}

const env = process.env.ENVIRONMENT || process.env.NODE_ENV || "";
if (!isConfigKey(env)) throw new Error(`invalid ENVIRONMENT value: ${env}`);

export const config = allConfigs[env];
