import { FixedSizeArray, OmitParameters } from "./tsUtils";
import { always, memoizeWith } from "ramda";

/**
 * Allows you to partially apply arguments to a function and create a specialized function:
 *
 *     const add = (a, b) => a + b
 *     const add5 = partial<typeof add, 1>([5], add)
 *     add5(3) // => 8
 *
 * Very useful when you want to use the same function, but with some arguments already provided.
 * Here's a pretty decent writeup, if you're unfamiliar with partial application:
 * https://www.digitalocean.com/community/tutorials/javascript-functional-programming-explained-partial-application-and-currying
 *
 * We must use `any` (as opposed to `unknown` for example) for correct typing here. You should always
 * explicitly specify the `T` and `N` generics - relying on inference typically doesn't work, unfortunately.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const partial = <T extends (...args: any[]) => any, N extends number>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  staticArgs: FixedSizeArray<any, N>,
  f: T,
) =>
  // We *could* use Ramda's partial function here, but to provide stronger typing we basically
  // have to re-implement the function, so we don't gain much by delegating in the final return.
  function (...restArgs: Parameters<OmitParameters<T, N>>): ReturnType<T> {
    return f(...[...staticArgs, ...restArgs]);
  };

/**
 * Evaluates the function lazily, and then always returns that result. A specialized form
 * of memoization.
 */
export const lazily = <R, T extends () => R>(fn: T) => memoizeWith(always("1"), fn);
