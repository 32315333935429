import React, { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import { experimentalStyled, AppBar, Box, IconButton, Toolbar } from "@mui/material";
import MenuIcon from "@mui/icons-material/MenuRounded";

import Logo from "components/Logo";
import AccountPopover from "components/dashboard/AccountPopover";

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === "light" && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: "none",
    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === "dark" && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: "none",
  }),
  zIndex: theme.zIndex.drawer + 100,
}));

interface Props {
  onToggleMobileNav: () => void;
}

const DashboardNavbar: FC<Props> = ({ onToggleMobileNav, ...props }) => (
  <DashboardNavbarRoot {...props}>
    <Toolbar sx={{ minHeight: 64 }}>
      <IconButton
        color="inherit"
        onClick={onToggleMobileNav}
        sx={{
          display: {
            lg: "none",
          },
        }}
      >
        <MenuIcon fontSize="small" />
      </IconButton>
      <RouterLink to="/">
        <Logo
          sx={{
            display: {
              lg: "inline",
              xs: "none",
            },
            height: 40,
            width: 40,
          }}
        />
      </RouterLink>
      <Box
        sx={{
          flexGrow: 1,
          ml: 2,
        }}
      />
      <Box sx={{ ml: 2 }}>
        <AccountPopover />
      </Box>
    </Toolbar>
  </DashboardNavbarRoot>
);

export default DashboardNavbar;
