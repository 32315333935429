import { useQueryClient, useMutation } from "react-query";

import * as ObjectTemplatesAPI from "features/objectTemplates/api";
import QueryKeys from "features/queryKeys";
import {
  ObjectVariantDefaultVariables,
  ObjTempMutationCallbacks,
  ObjTempMutationContext,
} from "./types";
import { ObjectTemplate } from "gather-common/dist/src/public/resources/objectTemplates";

/**
 * Mutation that updates an object variant document that belongs to an object
 * template
 * @param callbacks Object containing onError and onSuccess callback methods
 * @returns ReactQuery useMutation hook
 */
const useUpdateDefaultVariant = (callbacks?: ObjTempMutationCallbacks) => {
  const queryClient = useQueryClient();

  return useMutation(
    (variables: ObjectVariantDefaultVariables) =>
      ObjectTemplatesAPI.updateDefaultVariant(
        variables.templateId,
        variables.currentDefault,
        variables.newDefault,
      ),
    {
      onMutate: async (variables) => {
        const { templateId, currentDefault, newDefault } = variables;

        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries([QueryKeys.ObjectTemplates]);
        await queryClient.cancelQueries([QueryKeys.ObjectTemplate, templateId]);

        // Snapshot the previous value of all templates currently cached
        const previousTemplates =
          queryClient.getQueryData<ObjectTemplate[]>([QueryKeys.ObjectTemplates]) ?? [];
        const previousTemplate = queryClient.getQueryData<ObjectTemplate>([
          QueryKeys.ObjectTemplate,
          templateId,
        ]);

        if (!previousTemplate) {
          throw new Error(
            "Previous template doesn't exist - please contact the Platform Tools team about how you received this error",
          );
        }

        // Update the variants
        const updatedVariants = [...previousTemplate.variants].map((variant) => {
          if (
            currentDefault &&
            variant.color === currentDefault.color &&
            variant.orientation === currentDefault.orientation
          ) {
            return {
              ...currentDefault,
              default: false,
            };
          } else if (
            variant.color === newDefault.color &&
            variant.orientation === newDefault.orientation
          ) {
            return {
              ...newDefault,
              default: true,
            };
          }
          return variant;
        });

        // Update the list
        const updatedTemplate = {
          ...previousTemplate,
          variants: updatedVariants,
        };

        // Find the old item in the list and replace it
        if (previousTemplates) {
          const index = previousTemplates.findIndex(
            (template) => template.id === previousTemplate.id,
          );
          const updatedList = [...previousTemplates];
          updatedList[index] = updatedTemplate;

          queryClient.setQueryData<ObjectTemplate[]>([QueryKeys.ObjectTemplates], updatedList);
        }

        queryClient.setQueryData<ObjectTemplate>(
          [QueryKeys.ObjectTemplate, templateId],
          updatedTemplate,
        );

        return {
          previousTemplates,
          previousTemplate,
        };
      },

      onError: async (_err, variables, context?: ObjTempMutationContext) => {
        const { previousTemplates, previousTemplate } = context || {};
        const { templateId } = variables;

        if (previousTemplates) {
          queryClient.setQueryData<ObjectTemplate[]>(
            [QueryKeys.ObjectTemplates],
            previousTemplates,
          );
        }

        if (previousTemplate) {
          queryClient.setQueryData<ObjectTemplate>(
            [QueryKeys.ObjectTemplate, templateId],
            previousTemplate,
          );
        }

        callbacks?.onError?.();
      },

      onSuccess: () => {
        callbacks?.onSuccess?.();
      },

      onSettled: async (_data, _err, variables) => {
        const { templateId } = variables;
        await queryClient.invalidateQueries([QueryKeys.ObjectTemplates]);
        await queryClient.invalidateQueries([QueryKeys.ObjectTemplate, templateId]);
      },
    },
  );
};

export default useUpdateDefaultVariant;
