import React, { useEffect, FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "features/authentication/useAuth";

interface Props {
  children: string | React.ReactNode;
}

const AuthGuard: FC<Props> = ({ children }) => {
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth.isAuthenticated) {
      navigate("/login");
    }
  }, [location, auth, navigate]);

  return <>{children}</>;
};

export default AuthGuard;
