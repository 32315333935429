/* External modules */
import React, { FC, useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

/* MUI Components */
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

/* Material Icons */
import DotsIcon from "@mui/icons-material/MoreHoriz";

/* Local modules */
import Label from "components/dashboard/Label";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Wearable } from "gather-common/dist/src/public/resources/users";

interface Props {
  wearableVariants: Wearable[];
  onDelete?: (wearables: Wearable[]) => void;
}

const WearableRow: FC<Props> = ({ wearableVariants, onDelete }) => {
  const moreRef = useRef(null);
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const firstWearable = wearableVariants[0];
  if (!firstWearable) return null;

  const { name, type, subType } = firstWearable;
  const defaultVariant = wearableVariants.find((variant) => variant.isDefault);

  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  const handleDelete = () => {
    setOpenMenu(false);

    if (onDelete) {
      onDelete(wearableVariants);
    }
  };

  return (
    <TableRow hover key={`wearableRow-${name}`}>
      <TableCell>
        <Avatar
          variant="square"
          src={defaultVariant ? defaultVariant.previewUrl : firstWearable.previewUrl}
        />
      </TableCell>

      <TableCell>
        <Box>
          <Link
            color="textPrimary"
            variant="body1"
            component={RouterLink}
            to={`/dashboard/wearables/${name}`}
          >
            {name}
          </Link>
        </Box>
      </TableCell>

      <TableCell sx={{ maxWidth: 250 }}>
        <Box component="span" sx={{ mr: 1, mt: 2 }}>
          <Label key={`type-${type}`} color="primary">
            {type}
          </Label>
        </Box>
        {subType && (
          <Box component="span" sx={{ mr: 1, mt: 2 }}>
            <Label key={`type-${subType}`} color="secondary">
              {subType}
            </Label>
          </Box>
        )}
      </TableCell>

      {onDelete && (
        <TableCell>
          <IconButton color="inherit" edge="end" onClick={handleMenuOpen} ref={moreRef}>
            <DotsIcon fontSize="small" />
          </IconButton>

          <Menu
            anchorEl={moreRef.current}
            anchorOrigin={{
              horizontal: "center",
              vertical: "bottom",
            }}
            keepMounted
            onClose={handleMenuClose}
            open={openMenu}
          >
            <MenuItem onClick={handleDelete}>Delete</MenuItem>
          </Menu>
        </TableCell>
      )}
    </TableRow>
  );
};

export default WearableRow;
