import { QueryClient } from "react-query";

import QueryKeys from "features/queryKeys";
import { UpdateReservationVariables } from "./types";
import { AdminReservation } from "gather-admin-common/dist/src/public/reservations/types";

/**
 * Update the cached data for the reservation details page
 * @param queryClient - QueryClient instance from react-query
 * @param variables - UpdateReservationVariables object for the mutation
 * @returns
 *   Object containing the previous reservation details and the
 *   updated reservation object.
 */
export const updateDetailsCache: (
  queryClient: QueryClient,
  variables: UpdateReservationVariables,
) => {
  previousReservation: AdminReservation;
  updatedReservation: AdminReservation;
} = (queryClient, { resId, fields }) => {
  // Cache old value of reservation in case api request fails
  const previousReservation = queryClient.getQueryData<AdminReservation>([
    QueryKeys.Reservation,
    resId,
  ]);

  if (previousReservation === undefined) {
    throw new Error(
      "Reservation doesn't exist in cache - please let Platform Tools team know how you got here.",
    );
  }

  const updatedReservation = {
    ...previousReservation,
    ...fields,
  };

  // Optimistically update to the new values
  queryClient.setQueryData<AdminReservation>([QueryKeys.Reservation, resId], updatedReservation);

  return {
    previousReservation,
    updatedReservation,
  };
};

/**
 * Update the cached data for the spaceReservations list
 * @param queryClient - QueryClient instance from react-query
 * @param variables - UpdateReservationVariables object for the mutation
 * @param prevRes - cached version of the reservation before changes are made
 * @param newRes - updated reservation
 * @returns old space reservation list
 */
export const updateSpaceCache: (
  queryClient: QueryClient,
  variables: UpdateReservationVariables,
  prevRes: AdminReservation,
  newRes: AdminReservation,
) => AdminReservation[] | undefined = (queryClient, { resId }, prevRes, newRes) => {
  const { roomId } = prevRes;

  const prevSpaceList = queryClient.getQueryData<AdminReservation[]>([
    QueryKeys.SpaceReservations,
    roomId,
  ]);

  // Update the reservation in the space list
  if (prevSpaceList) {
    const resIndex = prevSpaceList.findIndex((res) => res.id === resId);
    const updatedSpaceList = [...prevSpaceList];
    updatedSpaceList[resIndex] = newRes;

    queryClient.setQueryData<AdminReservation[]>(
      [QueryKeys.SpaceReservations, roomId],
      updatedSpaceList,
    );
  }

  return prevSpaceList;
};

/**
 * Update the cached data for the reserver and update the reserver
 * reservation lists if the `reserver` value is modified in the
 * updated reservation.
 * @param queryClient - QueryClient instance from react-query
 * @param variables - UpdateReservationVariables object for the mutation
 * @param prevRes - cached version of the reservation before changes are made
 * @param newRes - updated reservation
 * @returns object containing cached lists of the new and old reserver lists
 */
export const updateReserverCaches: (
  queryClient: QueryClient,
  variables: UpdateReservationVariables,
  prevRes: AdminReservation,
  newRes: AdminReservation,
) => {
  previousOldReserverList?: AdminReservation[];
  previousNewReserverList?: AdminReservation[];
} = (queryClient, { resId, fields }, prevRes, newRes) => {
  const { reserver: oldReserver } = prevRes;
  const { reserver: newReserver } = fields;
  const reserverChanged = newReserver !== oldReserver;

  // Snapshot the previous values for userReservations
  const prevReserverList = queryClient.getQueryData<AdminReservation[]>([
    QueryKeys.UserReservations,
    oldReserver,
  ]);

  // Snapshot the previous values for the new reserver's userReservations
  const newReserverList = queryClient.getQueryData<AdminReservation[]>([
    QueryKeys.UserReservations,
    newReserver,
  ]);

  // Update the reservation for the cached reserver (if it already exists)
  if (prevReserverList) {
    if (reserverChanged) {
      // Delete the reservation from the original reserver's list
      const listWithoutOldRes = prevReserverList.filter((res) => res.id !== resId);
      queryClient.setQueryData<AdminReservation[]>(
        [QueryKeys.UserReservations, oldReserver],
        listWithoutOldRes,
      );

      // Add the reservation to the new list (if it's already cached)
      if (newReserverList) {
        const listWithUpdatedRes = [...newReserverList];
        listWithUpdatedRes.push(newRes);

        queryClient.setQueryData<AdminReservation[]>(
          [QueryKeys.UserReservations, newReserver],
          listWithUpdatedRes,
        );
      }
    } else {
      // Simply replace the value in the original reserver's list of reservations
      const resIndex = prevReserverList.findIndex((res) => res.id === resId);
      const updatedList = [...prevReserverList];
      updatedList[resIndex] = newRes;

      queryClient.setQueryData<AdminReservation[]>(
        [QueryKeys.UserReservations, oldReserver],
        updatedList,
      );
    }
  }

  return {
    previousOldReserverList: prevReserverList,
    previousNewReserverList: newReserverList,
  };
};

/**
 * Update cache with new reservation for a space
 * @param queryClient - QueryClient instance from react-query
 * @param newRes - AdminReservation that simulates the new reservation document being added
 * @returns cached space reservations list
 */
export const addNewReservationToSpaceCache = (
  queryClient: QueryClient,
  newRes: AdminReservation,
): AdminReservation[] => {
  // Snapshot the previous value of all space reservations currently cached
  const previousSpaceList =
    queryClient.getQueryData<AdminReservation[]>([QueryKeys.SpaceReservations, newRes.roomId]) ??
    [];

  const updatedSpaceList = [...previousSpaceList];

  updatedSpaceList.push(newRes);

  // Update the cached data
  queryClient.setQueryData<AdminReservation[]>(
    [QueryKeys.SpaceReservations, newRes.roomId],
    updatedSpaceList,
  );

  // Return the old list
  return previousSpaceList;
};

/**
 * Update cache with new reservation for a user
 * @param queryClient - QueryClient instance from react-query
 * @param newRes - AdminReservation that simulates the new reservation document being added
 * @returns cached user reservations list
 */
export const addNewReservationToUserCache = (
  queryClient: QueryClient,
  newRes: AdminReservation,
): AdminReservation[] => {
  // Snapshot the previous value of all space reservations currently cached
  const previousUserList =
    queryClient.getQueryData<AdminReservation[]>([QueryKeys.UserReservations, newRes.reserver]) ??
    [];

  const updatedUserList = [...previousUserList];

  updatedUserList.push(newRes);

  // Update the cached data
  queryClient.setQueryData<AdminReservation[]>(
    [QueryKeys.UserReservations, newRes.reserver],
    updatedUserList,
  );

  // Return the old list
  return previousUserList;
};
