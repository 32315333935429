import { getAppBasePath } from "./basePath";

export class ThirdPartyURLError extends Error {
  constructor(msg?: string) {
    const basePath = getAppBasePath();
    const DEFAULT_MESSAGE = `URL must include ${basePath} and cannot be a third-party link. Try again or pass the space ID by itself instead.`;
    super(msg || DEFAULT_MESSAGE);
    Object.setPrototypeOf(this, ThirdPartyURLError.prototype);
  }
}

export class UndefinedBasePathError extends Error {
  constructor(msg?: string) {
    const DEFAULT_MESSAGE = "REACT_APP_API_BASE_PATH is undefined";
    super(msg || DEFAULT_MESSAGE);
    Object.setPrototypeOf(this, UndefinedBasePathError.prototype);
  }
}

export class InvalidRoomPathError extends Error {
  constructor(msg?: string) {
    const DEFAULT_MESSAGE =
      "Path for Gather Space is invalid. Double check URL is a valid RoomPath before trying again.";
    super(msg || DEFAULT_MESSAGE);
    Object.setPrototypeOf(this, InvalidRoomPathError.prototype);
  }
}

export class InvalidSpaceIDError extends Error {
  constructor(msg?: string) {
    const DEFAULT_MESSAGE = "Space ID (on left-hand side of the slash) must be 16 characters long.";
    super(msg || DEFAULT_MESSAGE);
    Object.setPrototypeOf(this, InvalidSpaceIDError.prototype);
  }
}

export const isGatherURL: (str: string) => boolean = (str) => {
  const basePath = getAppBasePath();

  if (!basePath) throw new UndefinedBasePathError();

  const gatherPath = new URL(basePath);
  const targetPath = new URL(str);
  const pathTest = new RegExp(gatherPath.host);
  return pathTest.test(targetPath.host);
};

export const isValidURL: (str: string) => boolean = (str) => {
  try {
    new URL(str); // if this line fails, it will throw an error and indicate it is not a valid URL
    return true;
  } catch (_) {
    return false;
  }
};

export const parseSpaceIDInput: (str: string) => string = (str) => {
  if (!isValidURL(str)) return str;

  if (!isGatherURL(str)) throw new ThirdPartyURLError();

  const url = new URL(str);
  /**
   * This approach of splitting up the pathname means that /app/,
   * /dashboard/, and other urls that have a spaceID appended to
   * them will be usable in the search input field.
   */
  const splitted = decodeURI(url.pathname).split("/");
  const enoughParams = splitted.length > 3;

  if (!enoughParams) throw new InvalidRoomPathError();

  return splitted.slice(-2).join("/");
};

export const extractSpaceIDFromUserInput: (userInput: string) => string = (userInput) => {
  const tempSpaceID = parseSpaceIDInput(userInput);
  const splitted = decodeURI(tempSpaceID).replace(/\\/g, "/").split("/");
  const validFormat = splitted[splitted.length - 2] !== undefined;

  if (!validFormat) throw new InvalidSpaceIDError("SpaceID is invalid. Please try again.");

  // @ts-expect-error Error auto-ignored when enabling TS noUncheckedIndexedAccess. If you're already touching this code, please clean this up while you're at it.
  // TODO: @ENG-4257 Clean these up! See the linear task for more context and advice for cleaning up.
  const correctLength = splitted[splitted.length - 2].length === 16;

  if (!correctLength) throw new InvalidSpaceIDError();

  return splitted.join("\\");
};
