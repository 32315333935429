import React, { createContext, useEffect, useReducer, useCallback, FC } from "react";
import { toast } from "react-hot-toast";
import { AdminRoleTypePrisma } from "gather-prisma-types/dist/src/public/client";
import { axios } from "gather-common-including-video/dist/src/public/axios";

import useAuth from "features/authentication/useAuth";
import SplashScreen from "components/SplashScreen";
import { AdminPermission } from "gather-admin-common/dist/src/public/roles/types";
import { getPermissionsFromRoles } from "gather-admin-common/dist/src/public/roles/permissions";
import HttpV2AdminClient from "../../http-client/httpV2Client";
import { HttpV2Paths } from "gather-http-common/dist/src/public/httpAPI";

interface RoleState {
  isInitialized: boolean;
  permissions: AdminPermission[];
  roles: AdminRoleTypePrisma[];
}

const initialState: RoleState = {
  isInitialized: false,
  permissions: [],
  roles: [],
};

// Lint warning auto-ignored when enabling the no-explicit-any rule. Fix this the next time this code is edited! TODO: @ENG-4294 Clean these up! See the linear task for guidance on how to do so.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = (state: any, action: any) => {
  if (action.type === "ROLE_STATE_CHANGED") {
    const { permissions, roles } = action.payload;

    return {
      ...state,
      isInitialized: true,
      permissions,
      roles,
    };
  }

  return state;
};

const RoleContext = createContext({
  ...initialState,
  fetchRoles: () => Promise.resolve(),
});

interface RoleProviderProps {
  children: string | React.ReactNode;
}

export const RoleProvider: FC<RoleProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { user } = useAuth();
  const origin = process.env.REACT_APP_API_BASE_PATH;

  const fetchRoles = useCallback(
    async (userId: string): Promise<AdminRoleTypePrisma[]> => {
      try {
        const roles =
          (await HttpV2AdminClient.get<AdminRoleTypePrisma[]>(HttpV2Paths.AdminUserRoles, {
            auth: true,
            params: { path: { user: userId } },
          })) ?? [];
        const permissions: AdminPermission[] = getPermissionsFromRoles(roles);

        dispatch({
          type: "ROLE_STATE_CHANGED",
          payload: {
            roles,
            permissions,
          },
        });

        return roles;
      } catch (error) {
        const message =
          (axios.isAxiosError(error) && error.response?.data?.message) ??
          "Failed to retrieve user roles.";
        toast.error(message);
        return Promise.reject(message);
      }
    },
    [dispatch, origin],
  );

  useEffect(() => {
    if (user?.id) {
      fetchRoles(user.id);
    }
  }, [user, fetchRoles]);

  return (
    <RoleContext.Provider
      value={{
        ...state,
        fetchRoles,
      }}
    >
      {state.isInitialized ? children : <SplashScreen />}
    </RoleContext.Provider>
  );
};

export default RoleContext;
