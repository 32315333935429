import { useQueryClient, useMutation } from "react-query";

import { AdminDiscount } from "features/discounts/types";
import * as DiscountsAPI from "features/discounts/api";
import QueryKeys from "features/queryKeys";
import {
  DiscountMutationCallbacks,
  DiscountUpdateVariables,
  DiscountMutationContext,
} from "./types";

/**
 * Mutation hook that updates a discount
 * @param callbacks - callback functions to be used in the later lifecycle steps of the mutation
 * @returns ReactQuery useMutation hook
 */
const useUpdateDiscount = (callbacks?: DiscountMutationCallbacks) => {
  const queryClient = useQueryClient();

  return useMutation(
    (variables: DiscountUpdateVariables) =>
      DiscountsAPI.updateDiscount(variables.discountId, variables.fields),
    {
      onMutate: async (variables) => {
        const { discountId, fields } = variables;
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        queryClient.cancelQueries([QueryKeys.Discounts]);

        // Snapshot the previous values of AdminDiscount
        const previousDiscounts =
          queryClient.getQueryData<AdminDiscount[]>([QueryKeys.Discounts]) || [];

        // Update the discount in the cache (if it exists)
        const discountIndex = previousDiscounts.findIndex((d) => d.id === discountId);
        const oldDiscount = previousDiscounts[discountIndex];

        // ...and update it
        if (oldDiscount) {
          const updatedDiscountsArray = [...previousDiscounts];
          updatedDiscountsArray[discountIndex] = {
            ...oldDiscount,
            ...fields,
          };

          queryClient.setQueryData<AdminDiscount[]>([QueryKeys.Discounts], updatedDiscountsArray);
        }

        return { previousDiscounts };
      },

      onError: (_err, _variables, context?: DiscountMutationContext) => {
        const { previousDiscounts = [] } = context || {};

        queryClient.setQueryData<AdminDiscount[]>([QueryKeys.Discounts], previousDiscounts);

        if (callbacks?.onError) {
          callbacks?.onError();
        }
      },

      onSuccess: () => {
        if (callbacks?.onSuccess) {
          callbacks?.onSuccess();
        }
      },

      onSettled: () => {
        queryClient.invalidateQueries([QueryKeys.Discounts]);
      },
    },
  );
};

export default useUpdateDiscount;
