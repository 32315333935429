import React, { useState } from "react";

import BooleanLabel from "components/dashboard/BooleanLabel";
import Box from "@mui/material/Box";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { toast } from "react-hot-toast";
import { displayDate } from "features/reservations/utils";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DateTime } from "luxon";
import useUpdateOrDeleteSpaceTrial from "features/spaces/mutations/useUpdateOrDeleteSpaceTrial";

type SpaceTrialExpiryProps = {
  spaceId: string;
  endsAt: DateTime | null;
};

const SpaceTrialExpiry: React.FC<SpaceTrialExpiryProps> = React.memo(function SpaceTrialExpiry({
  spaceId,
  endsAt,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [endsAtValue, setEndsAtValue] = useState<DateTime | null>(endsAt);

  const { mutate: updateSpaceTrial } = useUpdateOrDeleteSpaceTrial({
    onSuccess: () => toast.success("Space trial was updated."),
    onError: (error) => toast.error(`Space trial failed to update: ${error}`),
  });

  const handleSave = () => {
    updateSpaceTrial({ spaceId, endsAt: endsAtValue ? endsAtValue.toISO() : null });
    setIsEditing(false);
  };

  const handleClear = () => {
    updateSpaceTrial({ spaceId, endsAt: null });
  };

  const handleEnableEditForm = () => {
    setIsEditing(true);
  };

  return (
    <Box display="flex" flexDirection="row" gap={1} justifyContent="space-between">
      {isEditing ? (
        <>
          <DateTimePicker
            label="Ends At"
            value={endsAtValue}
            onChange={setEndsAtValue}
            renderInput={(inputProps: TextFieldProps) => (
              <TextField
                required
                type="datetime-local"
                name="startDate"
                {...inputProps}
                variant="outlined"
              />
            )}
          />
          <Box alignSelf="center">
            <Tooltip title="Save">
              <IconButton size="small" onClick={handleSave}>
                <DoneIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </>
      ) : endsAt ? (
        <>
          <Typography color="textPrimary" variant="body2">
            Expires on
            <br />
            {displayDate(endsAt.toISO())}
          </Typography>
          <Box display="flex">
            <Tooltip title="Change expiry date">
              <IconButton size="small" onClick={handleEnableEditForm}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Clear and reset (allows user to re-activate trial)">
              <IconButton size="small" onClick={handleClear}>
                <ClearIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </>
      ) : (
        <>
          <BooleanLabel condition={false} />
          <Box alignSelf="center">
            <Tooltip title="Add trial">
              <IconButton size="small" onClick={handleEnableEditForm}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </>
      )}
    </Box>
  );
});

export default SpaceTrialExpiry;
