import React, { FC } from "react";
import { Buffer } from "buffer";
import { capitalize } from "lodash";
import { Orientation } from "gather-common/dist/src/public/constants";
import { BulkUploadObject, bulkUploadObjects } from "../../../../features/objectTemplates/api";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { toast } from "react-hot-toast";
import { getErrorMessage } from "gather-common/dist/src/public/errors";
import { isNil } from "ramda";
import { BulkUploadResponse } from "gather-http-common/dist/src/public/resources/admin";
import { enumKeys } from "gather-common/dist/src/public/ts-utils";

const BulkUploadForm: FC = () => {
  const [files, setFiles] = React.useState<FileList | null>(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [logs, setLogs] = React.useState<BulkUploadResponse | null>(null);

  const handleSubmit = async () => {
    if (!files) return;
    if (isSubmitting) return;
    setIsSubmitting(true);
    setLogs(null);

    const objects: Map<string, BulkUploadObject> = new Map();

    for (const file of files) {
      if (file.webkitRelativePath?.split("/")?.splice(-1)?.[0]?.[0] === ".") {
        continue;
      }

      const [_, objectName, color, fileName] = file.webkitRelativePath.split("/");
      const orientationString = fileName && capitalize(fileName.split(".")[0]);
      const orientationKey = orientationString
        ? enumKeys(Orientation).find((orientation) => orientation === orientationString)
        : undefined;
      const orientation = orientationKey && Orientation[orientationKey];

      if (!objectName || !color || !fileName || isNil(orientation)) {
        console.log(file.webkitRelativePath, `Incorrect file path`);
        continue;
      }

      const fileData = await file.arrayBuffer();
      const fileType = file.type;
      const buffer = Buffer.from(fileData);

      const object = objects.get(objectName);
      const variantData = {
        color,
        orientation,
        normal: buffer,
        normalFileType: fileType,
      };
      if (object) {
        objects.set(objectName, {
          ...object,
          variants: [...(object.variants || []), variantData],
        });
      } else {
        objects.set(objectName, {
          name: objectName,
          variants: [variantData],
        });
      }
    }

    const objectTemplates = Array.from(objects.values());
    try {
      const data = await bulkUploadObjects(objectTemplates);
      setLogs(data);
    } catch (e) {
      toast.error(getErrorMessage(e));
    }
    setIsSubmitting(false);
  };

  return (
    <Box>
      <Box sx={{ mt: 3, display: "flex" }}>
        <Box>
          <Typography variant="subtitle1" sx={{ width: "100%" }}>
            Upload Objects Directory:
          </Typography>
        </Box>
        <Box sx={{ ml: 2 }}>
          <input
            type="file"
            name="fileList"
            id="mapImage"
            onChange={(e) => setFiles(e.target.files)}
            // @ts-expect-error
            webkitdirectory=""
          />
        </Box>
        <Box>
          <Button
            onClick={handleSubmit}
            color="primary"
            disabled={isSubmitting}
            sx={{ ml: 1 }}
            type="submit"
            variant="contained"
          >
            Add objects
          </Button>
        </Box>
      </Box>
      <Box sx={{ p: 2, display: "flex", justifyContent: "flex-end", flexDirection: "column" }}>
        <Typography variant="h6" sx={{ width: "100%" }}>
          Logs
        </Typography>
        {logs && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Object name</TableCell>
                <TableCell align="right">Status</TableCell>
                <TableCell align="right">Error reason</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {logs.map((log) => (
                <TableRow key={log.name}>
                  <TableCell component="th" scope="row">
                    {log.name}
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      color={log.status === "fulfilled" ? "green" : "red"}
                      variant="body2"
                    >
                      {log.status}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    {log.status === "fulfilled" ? "-" : log.reason}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Box>
    </Box>
  );
};

export default BulkUploadForm;
