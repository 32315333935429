export const baseConsole = console;

const DEFAULT_LOG_LEVEL = "dir";

const logLevelMap = {
  error: 0,
  warn: 1,
  log: 2,
  debug: 3,
  info: 4,
  dir: 5,
} as const;

type LogLevel = keyof typeof logLevelMap;

export const isAboveLogLevel = (level: LogLevel) => {
  const logLevelValue =
    // The type assertion here is added to simplify the code to prevent additional logic needed to
    // validate that the LOG_LEVEL env var was set to a valid value.
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    logLevelMap[process.env.LOG_LEVEL as LogLevel] ?? logLevelMap[DEFAULT_LOG_LEVEL];

  return logLevelMap[level] <= logLevelValue;
};

/**
 * Provides a wrapper around console to allow for mocking in tests.
 */
export const getConsoleFn =
  (method: LogLevel) =>
  (...params: unknown[]) => {
    // we have to implement our own log level filtering on the browser because we don't get that for
    // free like we do with Winston, and having log level is helpful to reduce noise in our tests.
    if (!isAboveLogLevel(method)) return;

    return baseConsole[method](...params);
  };
