import { axios } from "gather-common-including-video/dist/src/public/axios";
import { authTokenManager } from "gather-auth-client/dist/src/public/auth";

import { DBAreas } from "gather-common/dist/src/public/resources/space";

const ORIGIN = process.env.REACT_APP_API_BASE_PATH;

export const updateTutorialAreas = async (
  spaceId: string,
  mapId: string,
  areasData: DBAreas,
): Promise<void> => {
  const authToken = await authTokenManager.waitForToken();
  try {
    await axios.post(
      `${ORIGIN}/api/v2/admin/spaces/${encodeURIComponent(spaceId)}/maps/${mapId}/tutorial-areas`,
      {
        tutorialAreas: areasData,
      },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
  } catch (e) {
    let message = "Failed to update areas.";

    if (e instanceof Error) {
      message = e.message;
    }

    return Promise.reject(message);
  }
};
