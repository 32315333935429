import { nth, pipe, split } from "ramda";
import React, { FC, memo } from "react";

import { formatMultiArgumentPayloads, formatTimestamp, isConsoleMessage } from "./utils";
import { RenderRowProps, WithRestProps } from "./types";
import { Box } from "@mui/material";
import { just } from "gather-common/dist/src/public/fpHelpers";

const getConsoleLevel = pipe(split("/"), nth(1), just);

export const RenderConsoleRow: FC<WithRestProps<RenderRowProps>> = memo(
  ({ message, className = "", ...props }) => {
    if (!isConsoleMessage(message)) return null;
    const { payload, type, timestamp } = message;

    return (
      <div
        className={`row-message log-message log-message-${getConsoleLevel(type)} ${className}`}
        {...props}
      >
        <Box flexShrink={0}>{formatTimestamp(timestamp)}</Box>
        <div>{formatMultiArgumentPayloads(payload)}</div>
      </div>
    );
  },
);
