import { authTokenManager } from "gather-auth-client/dist/src/public/auth";
import {
  DEFAULT_PATH_PARAMS,
  WebWorkerClientWrapper,
} from "gather-browser-common/dist/src/public/http-client/httpV2Client";
import HttpV2Client from "gather-http-v2-client/dist/src/public/httpV2Client";

import { API_BASE_PATH } from "gather-browser-common/dist/src/public/basePaths";

const apiBasePath = process.env.REACT_APP_API_BASE_PATH || API_BASE_PATH;

const HttpV2AdminClient = new HttpV2Client({
  apiBasePath,
  authManager: authTokenManager,
  defaultPathParams: DEFAULT_PATH_PARAMS,
  webWorkerClient: WebWorkerClientWrapper,
});

export default HttpV2AdminClient;
