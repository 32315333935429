import * as Yup from "yup";
import { Interaction } from "gather-admin-common/dist/src/public/spaces/types";
import { isValidAudio, isValidImage } from "utils/uploads";

const AUDIO_SIZE = 5000000; // 5 MB
const IMAGE_SIZE = 1100000; // 1.1 MB

const IMG_VALIDATION = Yup.mixed()
  .nullable()
  .test("FILE_SIZE", "Uploaded file is larger than 1MB.", (fileBuffer?: Buffer) => {
    const file = fileBuffer ? new Blob([fileBuffer]) : undefined;
    return !file || file.size <= IMAGE_SIZE;
  });

const IMG_TYPE_VALIDATION = Yup.string().test(
  "FILE_FORMAT",
  "Uploaded file is unsupported format.",
  (fileType?: string) => !fileType || isValidImage(fileType),
);

const SOUND_VALIDATION = Yup.mixed()
  .nullable()
  .test("FILE_SIZE", "Uploaded file is larger than 1MB.", (fileBuffer) => {
    const file = fileBuffer ? new Blob([fileBuffer]) : undefined;
    return !file || (file && file.size <= AUDIO_SIZE);
  });

const SOUND_TYPE_VALIDATION = Yup.string().test(
  "FILE_FORMAT",
  "Uploaded file has unsupported format.",
  (fileBuffer) => {
    const file = fileBuffer ? new Blob([fileBuffer]) : undefined;
    return !file || (file && isValidAudio(file.type));
  },
);

export const SOUND_SCHEMA = Yup.object().shape({
  src: SOUND_VALIDATION,
  volume: Yup.number().min(0).max(100).required(),
  loop: Yup.boolean().required(),
  maxDistance: Yup.number().required(),
  isPositional: Yup.boolean().optional(),
});

export const NEW_SOUND_FIELDS = {
  src: SOUND_VALIDATION,
  srcType: SOUND_TYPE_VALIDATION,
  volume: Yup.number()
    .min(0)
    .max(100)
    .when("src", {
      is: undefined,
      then: (schema) => schema,
      otherwise: (schema) => schema.required(),
    }),
  audioLoop: Yup.boolean().when("src", {
    is: undefined,
    then: (schema) => schema,
    otherwise: (schema) => schema.required(),
  }),
  maxDistance: Yup.number().when("src", {
    is: undefined,
    then: (schema) => schema,
    otherwise: (schema) => schema.required(),
  }),
  isPositional: Yup.boolean().optional(),
};

export const OBJECT_VARIANT_SCHEMA = Yup.object().shape({
  color: Yup.string().required(),
  orientation: Yup.number().required(),
  normal: IMG_VALIDATION.required(),
  normalFileType: IMG_TYPE_VALIDATION,
  highlighted: IMG_VALIDATION,
  highlightedFileType: IMG_TYPE_VALIDATION,
  default: Yup.boolean().required(),
  spritesheetUrl: Yup.string().optional(),
  framingWidth: Yup.number().optional(),
  framingHeight: Yup.number().optional(),
  frameRate: Yup.number().optional(),
  startFrame: Yup.number().optional(),
  endFrame: Yup.number().optional(),
});

const COMMON_OBJ_TEMPLATE_FIELDS = {
  desc: Yup.string(),
  name: Yup.string().required(),
  tags: Yup.array(Yup.string()).min(1, "You need to add at least one tag to save this template."),
  type: Yup.mixed().oneOf(Object.values(Interaction)),
  distThreshold: Yup.number().when("type", {
    is: Interaction.NONE,
    then: (schema) => schema,
    otherwise: (schema) =>
      schema.required("Distance threshold must be set for interactive object template types."),
  }),
  previewMessage: Yup.string().when("type", {
    is: Interaction.NONE,
    then: (schema) => schema,
    otherwise: (schema) =>
      schema.required("Preview message must be set for interactive object template types."),
  }),
  preview: Yup.string().when("type", {
    is: Interaction.POSTER,
    then: (schema) => schema.required("Preview must be set for Poster templates."),
    otherwise: (schema) => schema,
  }),
  blurb: Yup.string().when("type", {
    is: Interaction.POSTER,
    then: (schema) => schema.required("Blurb must be set for Poster templates."),
    otherwise: (schema) => schema,
  }),
  image: IMG_VALIDATION.when("type", {
    is: Interaction.POSTER,
    then: (schema) => schema.required("Image must be uploaded for Poster templates."),
    otherwise: (schema) => schema,
  }),
  video: Yup.string().when("type", {
    is: Interaction.VIDEO,
    then: (schema) => schema.required("Video URL must be set for Video templates."),
    otherwise: (schema) => schema,
  }),
  zoomLink: Yup.string().when("type", {
    is: Interaction.EXTERNAL_CALL,
    then: (schema) => schema.required("Zoom Link must be set for External Call templates."),
    otherwise: (schema) => schema,
  }),
};

export const NEW_OBJECT_TEMPLATE_SCHEMA = Yup.object().shape({
  ...COMMON_OBJ_TEMPLATE_FIELDS,
  ...NEW_SOUND_FIELDS,
  variants: Yup.array(OBJECT_VARIANT_SCHEMA)
    .required()
    .min(1, "You need to add at least one variant to save this template.")
    .test(
      "HAS_DEFAULT",
      "You must set one variant to be the default.",
      (variant) => !variant || variant.filter((v) => v.default).length === 1,
    ),
});

export const UPDATE_OBJECT_TEMPLATE_SCHEMA = Yup.object().shape({
  ...COMMON_OBJ_TEMPLATE_FIELDS,
  ...NEW_SOUND_FIELDS,
});
