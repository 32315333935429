/* External modules */
import React, { FC, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";

/* MUI Components */
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";

/* Material Icons */
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/ModeEdit";

/* Local modules */
import PermittedComponent from "components/authentication/PermittedComponent";
import AlertDialog from "components/dashboard/AlertDialog";
import PageContainer from "components/layout/dashboard/PageContainer";
import ReservationDetails from "./partials/ReservationDetails";
import { BreadcrumbTrail } from "components/layout/dashboard/Breadcrumbs";
import { useReservationDetails } from "features/reservations/hooks";
import useDeleteReservation from "features/reservations/mutations/useDeleteReservation";
import { AdminPermission as Can } from "gather-admin-common/dist/src/public/roles/types";

const PAGE_TITLE = "Reservation Details";
const DELETE_CONFIRMATION =
  "Are you sure you want to delete this reservation? This action will NOT automatically issue a refund.";
const DOUBLE_CHECK_CONFIRMATION =
  "Are you really, REALLY sure? Remember: no refund & the reservation will be gone forever once you click 'OK'";

const ReservationsDetailsPage: FC = () => {
  const navigate = useNavigate();
  const { reservationId } = useParams();
  const resId = decodeURIComponent(reservationId ?? "");
  const { data: reservation, isLoading } = useReservationDetails(resId);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [showFirstDeleteAlert, setShowFirstDeleteAlert] = useState<boolean>(false);
  const [showSecondDeleteAlert, setShowSecondDeleteAlert] = useState<boolean>(false);
  const toggleEditMode = useCallback(() => setIsEditing((prev) => !prev), [setIsEditing]);

  const onSuccessfulDeletion = () => {
    toast.success("Reservation was successfully deleted.");
  };

  const onDeletionError = () => {
    toast.error(`Error: Reservation ${resId} could not be deleted.`);
  };

  const { mutate: deleteReservation } = useDeleteReservation({
    onSuccess: onSuccessfulDeletion,
    onError: onDeletionError,
  });

  const confirmDeletion = useCallback(() => {
    setShowFirstDeleteAlert(true);
  }, [setShowFirstDeleteAlert]);

  const doubleCheckDeletion = useCallback(
    (confirm: boolean) => {
      setShowFirstDeleteAlert(false);
      setShowSecondDeleteAlert(confirm);
    },
    [setShowFirstDeleteAlert, setShowSecondDeleteAlert],
  );

  const handleDelete = useCallback(
    (confirm: boolean) => {
      setShowSecondDeleteAlert(false);

      if (confirm) {
        const roomId = reservation?.roomId || "";
        deleteReservation(resId);
        navigate(`/dashboard/reservations?space=${encodeURIComponent(roomId)}`);
      }
    },
    [setShowSecondDeleteAlert, deleteReservation, resId, navigate, reservation?.roomId],
  );

  // Until reservation is loaded, link to the general reservations index. Otherwise, create a link
  // to a search for the space that this reservation belongs to in order to make it easy to navigate
  // back if the user clicks on the wrong reservation, etc.
  const breadcrumbs: BreadcrumbTrail[] = [
    {
      to: reservation
        ? `/dashboard/reservations?space=${encodeURIComponent(reservation?.roomId)}`
        : "/dashboard/reservations",
      title: "Manage Reservations",
    },
  ];

  return (
    <PageContainer
      pageTitle={PAGE_TITLE}
      breadcrumbs={breadcrumbs}
      buttons={
        !isLoading &&
        reservation && (
          <>
            <Box sx={{ mr: 2, display: "inline-block" }}>
              {isEditing ? (
                <Button
                  color="info"
                  onClick={toggleEditMode}
                  variant="contained"
                  startIcon={<ClearIcon />}
                >
                  Discard Changes
                </Button>
              ) : (
                <Button
                  color="primary"
                  onClick={toggleEditMode}
                  variant="contained"
                  startIcon={<EditIcon />}
                >
                  Edit
                </Button>
              )}
            </Box>

            <PermittedComponent permissions={[Can.DeleteReservations]} requiresAll={true}>
              <Tooltip title="NOTE: This does NOT process refunds" placement="top" arrow>
                <Button
                  color="error"
                  onClick={confirmDeletion}
                  variant="contained"
                  startIcon={<DeleteIcon />}
                >
                  Delete
                </Button>
              </Tooltip>
            </PermittedComponent>
          </>
        )
      }
    >
      {isLoading ? (
        <Box>Loading...</Box>
      ) : (
        reservation && (
          <ReservationDetails
            isEditing={isEditing}
            reservation={reservation}
            toggleEditMode={toggleEditMode}
          />
        )
      )}

      <AlertDialog
        isOpen={showFirstDeleteAlert}
        onClose={doubleCheckDeletion}
        title={"Confirm Deletion"}
      >
        {DELETE_CONFIRMATION}
      </AlertDialog>

      <AlertDialog
        isOpen={showSecondDeleteAlert}
        onClose={handleDelete}
        title={"Last Chance (no take-backsies)"}
        transition="slide"
      >
        {DOUBLE_CHECK_CONFIRMATION}
      </AlertDialog>
    </PageContainer>
  );
};

export default ReservationsDetailsPage;
