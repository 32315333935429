import { MapObject as ObjectDB } from "gather-admin-common/dist/src/public/spaces/types";

export const applyInteractiveFilter: (mapObjects: ObjectDB[]) => ObjectDB[] = (mapObjects) =>
  mapObjects.filter((obj) => obj.type > 0);

// Input: "Whiteboard - tOsOBPYRTh6x_dMv_tzFn"
// Output: "tOsOBPYRTh6x_dMv_tzFn"
export const parseTemplateId = (id?: string) => {
  const split = id?.split(" - ") || [id];

  if (split.length === 1) return id;

  return split[1];
};

export const getObjectNanoID: (obj: ObjectDB) => string = (obj) => {
  const templateId = parseTemplateId(obj.templateId) ?? "undefined";
  const id = (obj.id ?? "undefined").replace(`${templateId.toString()}_`, "").split(" - ");
  const tempId = ((id[1] ?? id[0]) || "").split("_");

  return tempId[tempId.length - 1] || "";
};
