import React, { FC } from "react";
import useRoles from "features/roles/useRoles";
import { AdminPermission } from "gather-admin-common/dist/src/public/roles/types";
import { checkIfPermitted } from "gather-admin-common/dist/src/public/roles/permissions";

interface Props {
  permissions?: AdminPermission[];
  requiresAll?: boolean;
  children: string | React.ReactNode;
}

const PermittedComponent: FC<Props> = ({ children, permissions, requiresAll = false }) => {
  const { permissions: currentPermissions } = useRoles();
  const isPermitted = checkIfPermitted(permissions, currentPermissions, requiresAll);

  return isPermitted ? <>{children}</> : null;
};

export default PermittedComponent;
