import cn from "classnames";
import { noop } from "lodash";
import React, { FC, PropsWithChildren, useCallback, useState } from "react";

type SectionProps = {
  area: string;
  disableMaximize?: boolean;
};

const Section: FC<PropsWithChildren<SectionProps>> = ({
  area,
  children,
  disableMaximize = false,
}) => {
  const [maximize, setMaximize] = useState(false);
  const handleDoubleClick = useCallback(() => {
    disableMaximize ? noop : setMaximize((m) => !m);
  }, [disableMaximize]);

  return (
    <div
      onDoubleClick={handleDoubleClick}
      className={cn("section", { "maximize-cell": maximize, [area]: !maximize })}
    >
      {children}
    </div>
  );
};

export default Section;
