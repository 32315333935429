import React, { FC, useState, useRef } from "react";

import DotsIcon from "@mui/icons-material/MoreHoriz";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import Label from "components/dashboard/Label";
import { displayDate } from "features/reservations/utils";
import { AdminDiscount } from "features/discounts/types";
import { calculateDiscountFromMultiplier, determineDiscountState } from "features/discounts/utils";
import { Box } from "@mui/system";
import { isNil } from "ramda";

interface Props {
  discount: AdminDiscount;
  displayType: string;
  canEdit: boolean;
  onEdit?: (discountId: string) => void;
  onDelete?: (discountId: string) => void;
}

const DiscountRow: FC<Props> = ({ discount, displayType, onDelete, onEdit, canEdit }) => {
  const moreRef = useRef(null);
  const { state, stateLabelVariant } = determineDiscountState(discount);
  const {
    id,
    description,
    numUses,
    maxUses,
    reservationLength,
    subscriptionCode,
    multiplier,
    startTime,
    endTime,
    type: discountType,
  } = discount;
  const showSubscriptionColumn = displayType === "subscription";
  const showMultiplierColumn = displayType === "multiplier";
  const showAllColumn = displayType === "all";
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  const handleEdit = () => {
    setOpenMenu(false);

    if (onEdit) {
      onEdit(discount.id);
    }
  };

  const handleDelete = () => {
    setOpenMenu(false);

    if (onDelete) {
      onDelete(discount.id);
    }
  };

  return (
    <TableRow hover key={discount.id}>
      <TableCell>
        <Typography color="textPrimary" variant="body1">
          {id}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {description}
        </Typography>
      </TableCell>

      {showAllColumn && (
        <TableCell>
          <Typography color="textPrimary" variant="body2">
            {discountType === "subscription" && (
              <Box>
                <Label color="success">Subscription</Label>
                <Typography variant="body2" color="textPrimary">
                  {subscriptionCode}
                </Typography>
              </Box>
            )}

            {discountType === "multiplier" && (
              <Box>
                <Label color="primary">Multiplier</Label>
                <Typography variant="body2" color="textPrimary">
                  {multiplier} (
                  {!isNil(multiplier) && calculateDiscountFromMultiplier(multiplier).toString()}%
                  off)
                </Typography>
              </Box>
            )}
          </Typography>
        </TableCell>
      )}

      {showSubscriptionColumn && (
        <TableCell>
          <Typography color="textPrimary" variant="body2">
            {subscriptionCode}
          </Typography>
        </TableCell>
      )}

      {showMultiplierColumn && (
        <TableCell>
          <Typography color="textPrimary" variant="body2">
            {multiplier}
          </Typography>
        </TableCell>
      )}

      <TableCell>
        <Typography color="textPrimary" variant="body2">
          {numUses} / {maxUses ? maxUses : "\u221e"}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography color="textPrimary" variant="body2">
          {reservationLength ? `${reservationLength} days` : ""}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography color="textPrimary" variant="body2">
          {startTime && displayDate(new Date(startTime).toISOString())}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography color="textPrimary" variant="body2">
          {endTime && displayDate(new Date(endTime).toISOString())}
        </Typography>
      </TableCell>

      <TableCell>
        <Label color={stateLabelVariant}>{state}</Label>
      </TableCell>

      {canEdit && (onEdit || onDelete) && (
        <TableCell>
          <IconButton color="inherit" edge="end" onClick={handleMenuOpen} ref={moreRef}>
            <DotsIcon fontSize="small" />
          </IconButton>

          <Menu
            anchorEl={moreRef.current}
            anchorOrigin={{
              horizontal: "center",
              vertical: "bottom",
            }}
            keepMounted
            onClose={handleMenuClose}
            open={openMenu}
          >
            {onEdit && <MenuItem onClick={handleEdit}>Edit</MenuItem>}
            {onDelete && <MenuItem onClick={handleDelete}>Delete</MenuItem>}
          </Menu>
        </TableCell>
      )}
    </TableRow>
  );
};

export default DiscountRow;
