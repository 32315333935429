export {
  AreaCategory,
  coordsMapToPositions,
  convertAreaCoordsToCoordsMap,
  convertDesksToDBDesks,
  convertNookInfoToOccupiedNookTilesMap,
  convertMapObjectToWireObject,
  convertStringToEnabledChat,
  convertStringToPresetTutorialGroupSetId,
  convertTutorialTasksToWireTutorialTasks,
  convertWireAreasToAreas,
  convertWireAreasToDBAreas,
  convertWireObjectToMapObjectPartial,
  convertWireObjectToMapObjectDBPartial,
  convertWireTutorialTasksToTutorialTasks,
  desksToNooks,
  EnabledChat,
  findMissingMapObjectFields,
  Interaction,
  interactionOpensModal,
  isMapObject,
  isMapObjectDB,
  isNook,
  MAP_MAX_DIMENSION,
  mergeNooks,
  nookToDeprecatedSpaces,
  objectsArrayToDict,
  deprecatedSpacesToNooks,
  PresetTutorialGroupSetIds,
  TileType,
  isTileType,
  WallFloorDB,
} from "../GameMap";

export type {
  Area,
  Areas,
  CoordsMap,
  BaseArea,
  DBArea,
  DBAreas,
  DBDesks,
  Desks,
  FloorBlock,
  SpawnTile,
  PortalTile,
  TileEffect,
  GameMapBaseDoNotUseDirectly,
  GameMapDB,
  GameMapHttpPublic,
  MapNameAndId,
  MapObject,
  MapObjectDB,
  MapObjectToAdd,
  ModalInteraction,
  Nook,
  NookWithId,
  NookInfo,
  NookTile,
  ReadonlyGameMapDB,
  ReadonlyMapObjectDB,
  ReadonlyNook,
  ReadonlyPortal,
  AreaTile,
  OccupiedNookTilesMap,
  Tile,
  TutorialTasks,
  WallBlock,
  WallFloorBlock,
  DeskPod,
  DeskPods,
  DeskPodsDiff,
  WireDeskPods,
  DeskToDeskPodMap,
} from "../GameMap";

export { getUnusedObjectKey, getUnusedObjectKeyCustomCheck } from "../objectUtils";

// We reexport some of the types from the protobuf `events.ts` because this is a more likely and logical place people
// would search for them. Also it makes it possible to auto-import with Intellisense, since it's not possible from
// public/events.ts.
export { Space, Portal, SpawnPoint, AreaPosition } from "../generated_DO_NOT_TOUCH/events";
