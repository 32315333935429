import React, {
  FC,
  useState,
  useEffect,
  useCallback,
  ChangeEvent,
  KeyboardEvent,
  SyntheticEvent,
} from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";

import useSearchParams from "features/useSearchParams";
import { useSpace } from "features/spaces/hooks";
import { extractSpaceIDFromUserInput } from "utils/handleSpaceID";
import SearchIdInput from "components/inputs/SearchIdInput";
import PageContainer from "components/layout/dashboard/PageContainer";
import { Tab as TabType } from "../../../components/types";
import MapsTab from "./MapsTab/MapsTab";
import CopySpaceTab from "./CopySpaceMapsTab/CopySpaceTab";
import TabWrapper from "../../../components/dashboard/TabWrapper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Divider from "@mui/material/Divider";

const PAGE_TITLE = "Map Inspector";
const tabs: TabType[] = [
  {
    label: "Maps",
    value: "maps",
  },
  {
    label: "Copy Space",
    value: "copySpace",
  },
];

const IndexPage: FC = () => {
  const navigate = useNavigate();
  const queryParam = useSearchParams();
  const initialSpace = decodeURIComponent(queryParam.get("maps") ?? "");
  const [spaceId, setSpaceId] = useState<string>(initialSpace);
  const [spaceIdInputValue, setSpaceIdInputValue] = useState<string>(initialSpace);
  const { data, isLoading } = useSpace(spaceId);
  const [currentTab, setCurrentTab] = useState("maps");

  const handleSpaceIdChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSpaceIdInputValue(event.target.value);
    },
    [setSpaceIdInputValue],
  );

  const handleSpaceIdKeyPress = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        const newSpaceId = extractSpaceIDFromUserInput(spaceIdInputValue);
        setSpaceId(newSpaceId);
      }
    },
    [setSpaceId, spaceIdInputValue],
  );

  const updateQueryParam = useCallback(() => {
    const params = new URLSearchParams({ space: encodeURIComponent(spaceId) });
    navigate(`${window.location.pathname}${spaceId.length ? `?${params.toString()}` : ""}`, {
      replace: true,
    });
  }, [spaceId]);

  useEffect(() => {
    if (data) {
      updateQueryParam();
      setCurrentTab("maps"); // Reset to the main space details tab
    }
  }, [updateQueryParam, data, setCurrentTab]);

  const getCurrentTabContents = () => {
    switch (currentTab) {
      case "maps":
        return <MapsTab spaceId={data?.id} />;
      case "copySpace":
        return <CopySpaceTab spaceId={data?.id} />;
      default:
        return <TabWrapper>Please let the Core App team know how you ended up here...</TabWrapper>;
    }
  };

  const handleTabsChange = useCallback(
    (_event: SyntheticEvent<Element>, value: string) => {
      setCurrentTab(value);
    },
    [setCurrentTab],
  );

  return (
    <PageContainer pageTitle={PAGE_TITLE}>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexWrap: "wrap",
            mb: 2,
            width: "100%",
          }}
        >
          <SearchIdInput
            onKeyPress={handleSpaceIdKeyPress}
            onChange={handleSpaceIdChange}
            id={spaceIdInputValue}
            placeholder="Enter Space ID or URL here"
            label="Search for Space"
          />
        </Box>

        {isLoading && <Box sx={{ p: 2, width: "100%" }}>Loading...</Box>}

        {!isLoading && data && (
          <Box sx={{ width: "100%" }}>
            <Tabs
              indicatorColor="primary"
              onChange={handleTabsChange}
              scrollButtons="auto"
              textColor="primary"
              value={currentTab}
              variant="scrollable"
            >
              {tabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </Tabs>
            <Divider />

            {getCurrentTabContents()}
          </Box>
        )}
      </Box>
    </PageContainer>
  );
};

export default IndexPage;
