import { useQueryClient, useMutation } from "react-query";

import * as WearablesAPI from "features/wearables/api";
import QueryKeys from "features/queryKeys";
import { toPairs, values } from "ramda";
import { EditableWearableFields } from "features/wearables/api";
import { indexById } from "gather-common/dist/src/public/fpHelpers";
import { Wearable } from "gather-common/dist/src/public/resources/users";

interface WearableMutationCallbacks {
  /** onError callback function for the mutation */
  onError?: () => void;
  /** onSuccess callback function for the mutation */
  onSuccess?: (updatedValues: Record<string, Wearable>) => void;
}

/**
 * Mutation that deletes a wearable document
 * @param callbacks Object containing onError and onSuccess callback methods
 * @returns ReactQuery useMutation hook
 */
const useUpdateWearables = (callbacks?: WearableMutationCallbacks) => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: Record<string, EditableWearableFields>) => WearablesAPI.patchWearables(data),
    {
      onError: (_err) => callbacks?.onError?.(),
      onSuccess: (updatedWearables) => {
        const previousWearables = queryClient.getQueryData<Wearable[]>([QueryKeys.Wearables]);
        if (!previousWearables || !updatedWearables) return;

        const wearablesRecord = indexById(previousWearables);

        toPairs(updatedWearables).forEach(([id, wearable]) => (wearablesRecord[id] = wearable));

        queryClient.setQueryData<Wearable[]>([QueryKeys.Wearables], values(wearablesRecord));

        callbacks?.onSuccess?.(updatedWearables);
      },
    },
  );
};

export default useUpdateWearables;
