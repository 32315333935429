/* External modules */
import React, { FC } from "react";

/* MUI Components */
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

/* Local modules */
import WearableRow from "./WearableRow";
import { Wearable } from "gather-common/dist/src/public/resources/users";

interface Props {
  wearables?: { [wearableName: string]: Wearable[] };
  onDelete?: (wearables: Wearable[]) => void;
}

const WearablesTable: FC<Props> = ({ wearables, onDelete }) => {
  if (!wearables) return null;

  return (
    <Box sx={{ minWidth: "100%" }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              Name
            </TableCell>
            <TableCell>Wearable Type</TableCell>

            {onDelete && <TableCell>Actions</TableCell>}
          </TableRow>
        </TableHead>

        <TableBody>
          {Object.values(wearables).map((wearableVariants, index: number) => (
            <WearableRow
              key={`wearable-${index}`}
              wearableVariants={wearableVariants}
              onDelete={onDelete}
            />
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default WearablesTable;
