import React, { Fragment, useMemo } from "react";
import { Pane, PaneContent, PaneHeader, ScrollPane } from "./ui";
import { useCurrentTimestamp, useSessionMessages } from "state/avClientStateViewerSlice";
import { DevicePayload, SelectedDeviceChangedMessage, SelectedDeviceChangedPayload } from "./types";
import { List, ListItem, ListItemText, ListSubheader, Typography } from "@mui/material";
import { getDeviceSnapshots, getSelectedDeviceSnapshot } from "./utils";
import { fromPairs, groupBy, is } from "ramda";
import startCase from "lodash/startCase";

const DevicesTab = () => {
  const timestamp = useCurrentTimestamp();
  const messages = useSessionMessages();

  const devicesChangedSnapshots = useMemo(
    () => fromPairs(getDeviceSnapshots(messages)),
    [messages],
  );
  const audioInputSnapshots = useMemo(
    () => fromPairs(getSelectedDeviceSnapshot(messages, "audioInput")),
    [messages],
  );
  const videoInputSnapshots = useMemo(
    () => fromPairs(getSelectedDeviceSnapshot(messages, "videoInput")),
    [messages],
  );
  const audioOutputSnapshots = useMemo(
    () => fromPairs(getSelectedDeviceSnapshot(messages, "audioOutput")),
    [messages],
  );

  const currentDevicesChanged = devicesChangedSnapshots[timestamp];
  const currentAudioInput = audioInputSnapshots[timestamp];
  const currentVideoInput = videoInputSnapshots[timestamp];
  const currentAudioOutput = audioOutputSnapshots[timestamp];

  const groupedDevicesChanged = useMemo(
    () =>
      groupBy<DevicePayload>((devicePayload) => devicePayload.kind)(
        currentDevicesChanged?.payload ?? [],
      ),
    [currentDevicesChanged],
  );

  const currentlySelected = [currentAudioInput, currentAudioOutput, currentVideoInput];

  const groupedSelectedDevices = useMemo(
    () => ({
      audioInput: currentAudioInput,
      videoInput: currentVideoInput,
      audioOutput: currentAudioOutput,
    }),
    [currentAudioInput, currentVideoInput, currentAudioOutput],
  );

  return (
    <Pane sx={{ height: "100%", overflow: "hidden" }}>
      <PaneHeader sx={{ padding: 1 }}>
        Devices ({`${currentDevicesChanged?.payload?.length ?? 0}`})
      </PaneHeader>
      <PaneContent sx={{ maxHeight: "100%" }}>
        <ScrollPane>
          <List sx={{ width: "100%" }}>
            <ListSubheader>Selected Devices</ListSubheader>
            {Object.entries(groupedSelectedDevices).map(([kind, device]) =>
              device?.payload ? (
                <ListItem key={kind} alignItems="flex-start">
                  <ListItemText
                    primaryTypographyProps={{ color: "text.primary" }}
                    primary={
                      getDeviceLabel(currentDevicesChanged?.payload ?? [], device?.payload) ??
                      "Unknown"
                    }
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.secondary"
                        >
                          {`${startCase(kind)} - ${
                            device?.payload?.deviceId?.deviceId ?? device?.payload?.deviceId
                          } `}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              ) : (
                <ListItem key={kind} alignItems="flex-start">
                  <ListItemText color="text.primary" primary={`No ${startCase(kind)} selected`} />
                </ListItem>
              ),
            )}

            {Object.entries(groupedDevicesChanged).map(([kind, devices]) => (
              <Fragment key={kind}>
                <ListSubheader>{startCase(kind)}</ListSubheader>
                {devices?.map((devicePayload) => (
                  <ListItem
                    key={`${devicePayload.kind}-${devicePayload.deviceId}`}
                    alignItems="flex-start"
                  >
                    <ListItemText
                      primaryTypographyProps={{
                        color: isCurrentlySelected(currentlySelected, devicePayload)
                          ? "text.primary"
                          : "inherit",
                      }}
                      primary={devicePayload.label}
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            variant="body2"
                            color="text.secondary"
                          >
                            {` id: ${devicePayload.deviceId} / gid: ${devicePayload.groupId}`}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                ))}
              </Fragment>
            ))}
          </List>
        </ScrollPane>
      </PaneContent>
    </Pane>
  );
};
export default DevicesTab;

function isCurrentlySelected(
  currentSelected?: (SelectedDeviceChangedMessage | undefined)[],
  devicePayload?: DevicePayload,
) {
  return currentSelected?.some(
    (selected) =>
      (selected?.payload?.deviceId?.deviceId ?? selected?.payload?.deviceId) ===
        devicePayload?.deviceId &&
      selected?.payload?.type.toLowerCase() === devicePayload?.kind.toLowerCase(),
  );
}

function getDeviceLabel(
  deviceChanges: DevicePayload[],
  devicePayload: SelectedDeviceChangedPayload,
) {
  return deviceChanges.find((device) =>
    devicePayload &&
    devicePayload?.type.toLowerCase() === device?.kind.toLowerCase() &&
    is(String, devicePayload.deviceId)
      ? devicePayload.deviceId === device?.deviceId
      : devicePayload.deviceId?.deviceId === device?.deviceId,
  )?.label;
}
