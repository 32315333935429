import React, {
  FC,
  useState,
  useCallback,
  ChangeEvent,
  MouseEvent,
  useMemo,
  useEffect,
} from "react";
import {
  Box,
  Button,
  Card,
  Dialog,
  InputAdornment,
  TablePagination,
  TextField,
  SelectChangeEvent,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import PlusIcon from "@mui/icons-material/Add";
import { toast } from "react-hot-toast";
import { AdminRoleTypePrisma as AdminRole } from "gather-prisma-types/dist/src/public/client";

import { applyPagination, applySort } from "features/tables/utils";
import NewUserForm from "./partials/NewUserForm";
import AdminUsersTable from "./partials/AdminUsersTable";
import PageContainer from "components/layout/dashboard/PageContainer";
import MultipleSelectChip from "components/inputs/MultipleSelectChip";
import * as AdminUsersAPI from "features/adminUsers/api";
import { guaranteedError } from "gather-common/dist/src/public/utils";
import { AdminUser } from "gather-admin-common/dist/src/public/auth/types";

const PAGE_TITLE = "Manage Admin Users";

const UsersIndexPage: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<AdminUser[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [selectedRoles, setSelectedRoles] = useState<AdminRole[]>([]);
  const [emailQuery, setEmailQuery] = useState<string>("");
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [sort, setSort] = useState("email|asc"); // Email (desc) will be the default option

  interface FindUserResult {
    user?: AdminUser;
    index: number;
  }

  const findUser = useCallback(
    (id: string): FindUserResult => {
      const userIndex = users.findIndex((u) => u.id === id);
      return {
        user: users[userIndex],
        index: userIndex,
      };
    },
    [users],
  );

  const toggleSortOrder = useCallback(() => {
    const currentSortAsc = sort === "email|asc";

    if (currentSortAsc) {
      setSort("email|desc");
    } else {
      setSort("email|asc");
    }
  }, [setSort, sort]);

  const handleSelectedRolesChange = (event: SelectChangeEvent<AdminRole[]>) => {
    const {
      target: { value },
    } = event;
    const result =
      typeof value === "string"
        ? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          (value.split(",") as AdminRole[]) // autofill returns a string but we know the string array only has AdminRoleTypes in it
        : value;
    setSelectedRoles(result);
  };

  const handleEmailQueryChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmailQuery(event.target.value);
  };

  const handleQuery = async () => {
    let response: AdminUser[] = [];
    setLoading(true);

    // Fetch new batch of AdminUsers and update page
    try {
      response = await AdminUsersAPI.fetchAdminUsers();
    } catch (e) {
      const error = guaranteedError(e);
      console.warn(error);
      toast.error(error.message);
    }

    setCurrentPage(0);
    setLoading(false);
    setUsers(response);
  };

  const handleModalOpen = useCallback(() => {
    setModalOpen(true);
  }, [setModalOpen]);

  const handleModalClose = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  const handlePageChange = useCallback(
    (_event: MouseEvent<HTMLButtonElement> | null, page: number) => {
      setCurrentPage(page);
    },
    [setCurrentPage],
  );

  const handleLimitChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setLimit(parseInt(event.target.value, 10));
      setCurrentPage(0);
    },
    [setLimit, setCurrentPage],
  );

  const handleNewUser = useCallback(
    (newUser: AdminUser) => {
      setUsers([...users, newUser]);
    },
    [setUsers, users],
  );

  const handleUserDeletion = useCallback(
    async (userId: string) => {
      const { index } = findUser(userId);

      if (!index) {
        toast.error("User not found.");
        return;
      }

      // Tentatively remove user from the list (optimistic update)
      const usersList = [...users];
      const user = usersList.splice(index, 1)[0];
      setUsers([...usersList]);

      try {
        await AdminUsersAPI.deleteUser(userId);
        toast.success(`${user?.email} was successfully removed from Gather Admin`);
      } catch (e) {
        const error = guaranteedError(e);
        // Re-add user after failing to delete
        if (user) {
          setUsers([...usersList, user]);
        }

        console.warn(error);
        toast.error(error.message);
      }
    },
    [users, setUsers, findUser],
  );

  const handleUserEdit = useCallback(
    async (updatedUserId: string, roles: AdminRole[]) => {
      const { user: userToUpdate, index } = findUser(updatedUserId);

      if (!userToUpdate) {
        toast.error("User you are trying to edit cannot be found.");
        return;
      }

      const originalRoles = userToUpdate.roles;

      // Tentatively update the user's roles (optimistic update)
      userToUpdate.roles = roles;
      const newUsersList = [...users]; // Cloning users array so that we're not mutating it directly
      newUsersList[index] = userToUpdate;
      setUsers([...newUsersList]);

      try {
        await AdminUsersAPI.updateUser(userToUpdate.id, roles);
        toast.success(`${userToUpdate.email} was successfully updated.`);
      } catch (e) {
        const error = guaranteedError(e);
        // Re-add user after failing to update
        userToUpdate.roles = originalRoles;
        newUsersList[index] = userToUpdate;
        setUsers([...newUsersList]);

        console.warn(error);

        toast.error(error.message);
      }
    },
    [users, setUsers, findUser],
  );

  const applyFilters = useCallback(
    (users: AdminUser[], query: string) =>
      users.filter((user: AdminUser) => {
        let matches = true;

        if (query) {
          const properties: (keyof AdminUser)[] = ["email", "id"];
          let containsQuery = false;

          properties.forEach((property) => {
            if (user[property]?.toString().toLowerCase().includes(query.toLowerCase())) {
              containsQuery = true;
            }
          });

          if (!containsQuery) {
            matches = false;
          }
        }

        if (selectedRoles.length > 0 && user.roles) {
          matches = matches && selectedRoles.every((r) => user.roles?.includes(r));
        }

        return matches;
      }),
    [selectedRoles],
  );

  useEffect(() => {
    handleQuery();
  }, []);

  const filteredUsers: AdminUser[] = useMemo(
    () => applyFilters(users, emailQuery),
    [users, emailQuery, applyFilters],
  );

  const usersToDisplay: AdminUser[] = useMemo(() => {
    const sortedUsers = applySort<AdminUser>(filteredUsers, sort);
    return applyPagination<AdminUser>(sortedUsers, currentPage, limit);
  }, [filteredUsers, currentPage, limit, sort]);

  return (
    <PageContainer
      pageTitle={PAGE_TITLE}
      buttons={
        <Button
          color="primary"
          startIcon={<PlusIcon fontSize="small" />}
          variant="contained"
          onClick={handleModalOpen}
        >
          Add User
        </Button>
      }
    >
      <Box sx={{ minWidth: 1100 }}>
        <Card>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexWrap: "wrap",
              m: -1,
              p: 2,
            }}
          >
            <Box
              sx={{
                m: 1,
                maxWidth: "100%",
                width: 500,
              }}
            >
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  ),
                }}
                onChange={handleEmailQueryChange}
                placeholder="Search by Email or ID"
                value={emailQuery}
                variant="outlined"
              />
            </Box>

            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <MultipleSelectChip
                options={Object.keys(AdminRole).sort()}
                value={selectedRoles}
                onChange={handleSelectedRolesChange}
                label="Filter by Roles"
              />
            </Box>
          </Box>

          {loading && <Box sx={{ p: 2 }}>Loading...</Box>}

          {!loading && usersToDisplay.length > 0 && (
            <>
              <AdminUsersTable
                users={usersToDisplay}
                onToggleSort={toggleSortOrder}
                currentSortAsc={sort === "email|asc"}
                onDelete={handleUserDeletion}
                onUpdate={handleUserEdit}
              />
              <TablePagination
                component="div"
                count={filteredUsers.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={currentPage}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
              />
            </>
          )}
        </Card>
      </Box>

      <Dialog fullWidth maxWidth="sm" onClose={handleModalClose} open={modalOpen}>
        {/* Dialog renders its body even if not open */}
        {modalOpen && (
          <NewUserForm
            onAddComplete={handleModalClose}
            onCancel={handleModalClose}
            onCreate={handleNewUser}
          />
        )}
      </Dialog>
    </PageContainer>
  );
};

export default UsersIndexPage;
