import React, { FC } from "react";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// eslint-disable-next-line @gathertown/cross-module-import-paths -- Error autoignored while enabling eslint for gather-admin. If you're already touching this code, please clean this up while you're at it.
import { AdminSpaceMap } from "gather-admin-common/dist/src/spaces/types";
import { displayDate } from "features/reservations/utils";

interface Props {
  map: AdminSpaceMap;
}

const MapItem: FC<Props> = ({ map }) => {
  const delay = (time: number) => new Promise((resolve) => setTimeout(resolve, time));

  const openTabToMap = async () => {
    await delay(300);
    const path = `/dashboard/mapmaker/inspector/${encodeURIComponent(
      map.spaceId,
    )}/maps/${encodeURIComponent(map.id)}`;
    const win = window.open(path, "_blank");
    win?.focus();
  };

  return (
    <Grid item key={map.id} md={4} sm={6} xs={12}>
      <Card variant="outlined">
        <CardActionArea onClick={openTabToMap}>
          <CardMedia
            image={map.backgroundImagePath}
            sx={{
              backgroundColor: "background.default",
              height: 200,
            }}
          />
          <CardContent>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                mb: 1,
              }}
            >
              <Typography color="textPrimary" variant="h6">
                {map.name ?? map.id}
              </Typography>
            </Box>

            <Grid alignItems="center" container justifyContent="space-between" spacing={3}>
              <Grid item>
                <Typography color="textPrimary" variant="subtitle2">
                  {Object.keys(map.objects ?? {}).length}
                </Typography>
                <Typography color="textSecondary" variant="body2">
                  Objects
                </Typography>
              </Grid>

              <Grid item>
                <Typography color="textPrimary" variant="subtitle2">
                  {map.dimensions[0]} x {map.dimensions[1]}
                </Typography>
                <Typography color="textSecondary" variant="body2">
                  Dimensions
                </Typography>
              </Grid>

              <Grid item>
                <Typography color="textPrimary" variant="subtitle2">
                  {displayDate(map.updatedAt, "MMM d, y") || "Never"}
                </Typography>
                <Typography color="textSecondary" variant="body2">
                  Last Update
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default MapItem;
