import { useAvClientStateUserFeedbackBySessionId } from "features/avClientState/hooks";
import { evolve, is, mapObjIndexed } from "ramda";
import React, { useMemo } from "react";
import { JSONTree } from "react-json-tree";
import { useParams } from "react-router-dom";
import { ScrollPane } from "./ui";
import { attempJSONParse } from "./utils";

// Expand all nodes at level 1+ except agoraReport branch for performance.
const expand = (key: (string | number)[], __: unknown, level: number) =>
  level > 1 && !key.includes("agoraReport");

const UserFeedback = () => {
  const { sessionId } = useParams();
  const { data: feedback, isLoading } = useAvClientStateUserFeedbackBySessionId(sessionId);

  const parsedFeedback = useMemo(
    () =>
      evolve(
        {
          feedback: (value: string) => {
            const parsedValue = attempJSONParse(value);

            return is(String, parsedValue)
              ? parsedValue
              : mapObjIndexed(
                  (value) => (is(String, value) ? attempJSONParse(value) : value),
                  parsedValue,
                );
          },
        },
        feedback ?? {},
      ),
    [feedback],
  );

  return (
    <ScrollPane>
      {!isLoading && (
        <JSONTree
          hideRoot
          shouldExpandNode={expand}
          theme={{
            value: {
              whiteSpace: "pre-wrap",
            },
          }}
          data={parsedFeedback}
        />
      )}
    </ScrollPane>
  );
};

export default UserFeedback;
