/* External modules */
import React, { FC } from "react";
import { useParams } from "react-router-dom";

/* MUI Components */

/* Local modules */
import PageContainer from "components/layout/dashboard/PageContainer";
import { BreadcrumbTrail } from "components/layout/dashboard/Breadcrumbs";
import EditObjectTemplateForm from "./partials/EditObjectTemplateForm";
import { useObjectTemplate } from "../../../features/objectTemplates/hooks";

const PAGE_TITLE = "Edit Object Template";

const ObjectTemplateEditFormPage: FC = () => {
  const { objectId } = useParams();
  const templateId = decodeURIComponent(objectId ?? "");
  const { data: objectTemplate, isLoading } = useObjectTemplate(templateId);

  const breadcrumbs: BreadcrumbTrail[] = [
    {
      to: "/dashboard/mapmaker/objects",
      title: "Manage Object Templates",
    },
    {
      to: `/dashboard/mapmaker/objects/${templateId || ""}`,
      title: "Object Template Details",
    },
  ];

  return (
    <PageContainer pageTitle={PAGE_TITLE} breadcrumbs={breadcrumbs}>
      {isLoading && <div>Loading...</div>}

      {!isLoading && objectTemplate && (
        <EditObjectTemplateForm objectTemplate={objectTemplate} templateId={templateId} />
      )}
    </PageContainer>
  );
};

export default ObjectTemplateEditFormPage;
