export {
  isCI,
  isDev,
  isDevOrLocal,
  isDevOrLocalOrTest,
  isGatherScripts,
  isLocal,
  isLocalOrTest,
  isProd,
  isStaging,
  isTest,
  isUnitTest,
} from "../envShared";
