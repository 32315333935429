import { useQueryClient, useMutation } from "react-query";

import * as DiscountsAPI from "features/discounts/api";
import { AdminDiscount } from "features/discounts/types";
import QueryKeys from "features/queryKeys";
import { DiscountMutationCallbacks } from "./types";

/**
 * Mutation that deletes a discount document
 * @param callbacks Object containing onError and onSuccess callback methods
 * @returns ReactQuery useMutation hook
 */
const useDeleteDiscount = (callbacks?: DiscountMutationCallbacks) => {
  const queryClient = useQueryClient();

  return useMutation((discountId: string) => DiscountsAPI.deleteDiscount(discountId), {
    onMutate: async (discountId: string) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      queryClient.cancelQueries([QueryKeys.Discounts, discountId]);

      // Snapshot the previous values of AdminDiscount
      const previousDiscounts = queryClient.getQueryData<AdminDiscount[]>([QueryKeys.Discounts]);

      // Remove the discount from the current list
      if (previousDiscounts) {
        const updatedDiscountArray = previousDiscounts.filter(
          (current) => current.id !== discountId,
        );

        queryClient.setQueryData<AdminDiscount[]>([QueryKeys.Discounts], updatedDiscountArray);
      }

      return { previousDiscounts };
    },
    onError: (_err, _variables, context) => {
      if (context?.previousDiscounts) {
        const { previousDiscounts } = context;

        if (previousDiscounts) {
          queryClient.setQueryData<AdminDiscount[]>([QueryKeys.Discounts], previousDiscounts);
        }
      }

      if (callbacks?.onError) {
        callbacks?.onError();
      }
    },

    onSuccess: () => {
      if (callbacks?.onSuccess) {
        callbacks?.onSuccess();
      }
    },

    onSettled: () => {
      queryClient.invalidateQueries([QueryKeys.Discounts]);
    },
  });
};

export default useDeleteDiscount;
