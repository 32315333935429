import { AxiosRequestConfig } from "gather-common-including-video/dist/src/public/axios";
import { authTokenManager } from "gather-auth-client/dist/src/public/auth";
import { API_BASE_PATH } from "../basePaths";
import { getSpaceIdFromPath } from "../utils";
import WebWorkerClient from "../worker/WebWorkerClient";
import { PromiseHttpClient } from "gather-http-v2-client/dist/src/public/constants";
import HttpV2Client from "gather-http-v2-client/dist/src/public/httpV2Client";
import { switchEnv } from "gather-env-config/dist/src/public/env";

export const DEFAULT_PATH_PARAMS = {
  // Use the current space
  space: () => getSpaceIdFromPath() || "",
};

// WebWorkerClient doesn't really work with generics unfortunately, so we have to manually
// cast its return type appropriately. See the implementation of WebWorkerClient for more.
export const WebWorkerClientWrapper: PromiseHttpClient = {
  get: <TResponse>(finalPath: string, axiosConfig: AxiosRequestConfig): Promise<TResponse> =>
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    WebWorkerClient.get(finalPath, axiosConfig) as Promise<TResponse>,
  post: <TResponse, D>(
    finalPath: string,
    body: D,
    axiosConfig?: AxiosRequestConfig<D>,
  ): Promise<TResponse> =>
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    WebWorkerClient.post(finalPath, body, axiosConfig) as Promise<TResponse>,
  patch: <TResponse, D>(
    finalPath: string,
    body: D,
    axiosConfig?: AxiosRequestConfig<D>,
  ): Promise<TResponse> =>
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    WebWorkerClient.patch(finalPath, body, axiosConfig) as Promise<TResponse>,
  put: <TResponse, D>(
    finalPath: string,
    body: D,
    axiosConfig?: AxiosRequestConfig<D>,
  ): Promise<TResponse> =>
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    WebWorkerClient.put(finalPath, body, axiosConfig) as Promise<TResponse>,
  delete: <TResponse>(finalPath: string, axiosConfig: AxiosRequestConfig): Promise<TResponse> =>
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    WebWorkerClient.delete(finalPath, axiosConfig) as Promise<TResponse>,
};

const HttpV2ClientBrowser = new HttpV2Client({
  apiBasePath: API_BASE_PATH,
  authManager: authTokenManager,
  defaultPathParams: DEFAULT_PATH_PARAMS,
  webWorkerClient: WebWorkerClientWrapper,
  customHeaders: switchEnv({
    prod: () => ({}),
    staging: () => ({}),
    dev: () => ({}),
    local: () =>
      // ignore the warning from ngrok to connect to tunneled API backend when needed
      API_BASE_PATH.includes(".ngrok-free.app") ? { "ngrok-skip-browser-warning": true } : {},
    test: () => ({}),
  }),
});

export default HttpV2ClientBrowser;
