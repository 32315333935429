import { useQuery } from "react-query";
import QueryKeys from "features/queryKeys";

import * as ObjectTemplateCategoryGroupAPI from "./api";

const useObjectTemplateCategories = () =>
  useQuery(QueryKeys.ObjectTemplateCategories, ObjectTemplateCategoryGroupAPI.fetch, {
    initialData: [],
  });

export default useObjectTemplateCategories;
