import QueryKeys from "features/queryKeys";
import { useQuery } from "react-query";

import * as ObjectTemplatesAPI from "./api";

/**
 * Fetches all object templates
 * @returns ReactQuery object where data is type of ObjectTemplate[] | undefined
 */
export const useObjectTemplates = () =>
  useQuery([QueryKeys.ObjectTemplates], ObjectTemplatesAPI.fetchObjectTemplates, {
    initialData: undefined,
    retry: false,
  });

/**
 * Fetches a single object template and its variants
 * @returns ReactQuery object where data is type of ObjectTemplate | undefined
 */
export const useObjectTemplate = (id: string) =>
  useQuery([QueryKeys.ObjectTemplate, id], () => ObjectTemplatesAPI.fetchObjectTemplate(id), {
    initialData: undefined,
    retry: false,
  });
