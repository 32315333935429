/* External modules */
import React, { FC } from "react";
import { toast } from "react-hot-toast";
import * as Yup from "yup";
import { Formik } from "formik";

/* Material UI Components */
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

/* Material Icons */
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import PercentIcon from "@mui/icons-material/Percent";

/* Local modules */
import useCreateDiscount from "features/discounts/mutations/useCreateDiscount";
import { calculateMultiplierFromDiscount } from "features/discounts/utils";
import { DiscountType } from "gather-prisma-types/dist/src/public/client";

import { Discount } from "gather-http-common/dist/src/public/resources/discounts";

interface Props {
  onClose: () => void;
}

type FormDiscount = Omit<Discount, "authUsers" | "numUses" | "createdAt" | "updatedAt"> & {
  authUsers: string | null;
};

const NewDiscountForm: FC<Props> = ({ onClose }) => {
  const onSuccessfulCreation = () => {
    toast.success("Discount was successfully created.");
  };

  const onCreationError = () => {
    toast.error(`Error: Discount could not be created.`);
  };

  const { mutate: createDiscount } = useCreateDiscount({
    onSuccess: onSuccessfulCreation,
    onError: onCreationError,
  });

  return (
    <Formik<FormDiscount>
      initialValues={{
        id: "",
        description: "",
        type: DiscountType.multiplier,
        reservationLength: null,
        subscriptionCode: null,
        authUsers: null,
        maxUses: null,
        multiplier: null,
        startTime: null,
        endTime: null,
      }}
      validationSchema={Yup.object().shape({
        id: Yup.string().required("Discount code is required."),
        description: Yup.string().required(),
        type: Yup.mixed<DiscountType>().oneOf(Object.values(DiscountType)),
        startTime: Yup.date().nullable(true).optional(),
        endTime: Yup.date().nullable(true).optional(),
        reservationLength: Yup.number().positive().integer().nullable(),
        subscriptionCode: Yup.string()
          .when("type", {
            is: DiscountType.subscription,
            then: Yup.string().required(
              "Subscription code is required when discount is a Subscription type.",
            ),
          })
          .nullable(),
        authUsers: Yup.string().optional().nullable(),
        maxUses: Yup.number().positive().integer().nullable(),
        multiplier: Yup.number()
          .positive()
          .min(0)
          .max(100)
          .nullable()
          .when("type", {
            is: DiscountType.multiplier,
            then: Yup.number()
              .positive()
              .min(0)
              .max(100)
              .required("Percent Off is required when discount is a Multiplier type."),
          }),
      })}
      onSubmit={async (values, { resetForm, setStatus, setSubmitting }) => {
        console.log("submit");
        try {
          createDiscount({
            ...values,
            multiplier:
              values.type === DiscountType.multiplier && values.multiplier
                ? calculateMultiplierFromDiscount(values.multiplier)
                : null,
            // Convert authUsers to string array
            authUsers:
              (values?.authUsers?.length ?? 0) > 0 ? (values.authUsers ?? "").split(",") : [],
            startTime: values.startTime !== null ? values.startTime : null,
            endTime: values.endTime !== null ? values.endTime : null,
            subscriptionCode:
              (values.subscriptionCode?.length ?? 0) > 0 ? values.subscriptionCode : null,
            numUses: 0,
          });
          onClose();
          resetForm();
          setStatus({ success: true });
          setSubmitting(false);
        } catch (error) {
          let msg = "";

          if (error instanceof Error) {
            msg = error.message;
          }
          console.warn(msg);
          toast.error(msg);

          setStatus({ success: false });
          setSubmitting(false);
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box sx={{ p: 3 }}>
            <Typography align="center" color="textPrimary" gutterBottom variant="h5">
              Add Discount
            </Typography>
          </Box>

          <Box sx={{ pl: 3, pr: 3, mb: 3 }}>
            <TextField
              error={Boolean(touched.id && errors.id)}
              helperText={touched.id && errors.id}
              label="Discount Code"
              name="id"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.id}
              variant="outlined"
            />

            <Box sx={{ mt: 3 }}>
              <TextField
                fullWidth
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
                label="Description"
                name="description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                variant="outlined"
              />
            </Box>

            <Box sx={{ display: "flex", mt: 3 }}>
              <Box sx={{ maxWidth: 200 }}>
                <TextField
                  fullWidth
                  select
                  label="Discount Type"
                  name="type"
                  value={values.type}
                  onChange={handleChange}
                  error={Boolean(touched.type && errors.type)}
                  helperText={touched.type && errors.type}
                >
                  <MenuItem value={DiscountType.multiplier}>
                    <Typography sx={{ textTransform: "capitalize" }}>
                      {DiscountType.multiplier}
                    </Typography>
                  </MenuItem>

                  <MenuItem value={DiscountType.subscription}>
                    <Typography sx={{ textTransform: "capitalize" }}>
                      {DiscountType.subscription}
                    </Typography>
                  </MenuItem>
                </TextField>
              </Box>

              <Box sx={{ ml: 2 }}>
                {values.type === DiscountType.multiplier && (
                  <Box sx={{ maxWidth: 120 }}>
                    <TextField
                      fullWidth
                      type="number"
                      error={Boolean(touched.multiplier && errors.multiplier)}
                      helperText={touched.multiplier && errors.multiplier}
                      label="Percent Off"
                      name="multiplier"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.multiplier}
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <PercentIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                )}

                {values.type === DiscountType.subscription && (
                  <TextField
                    error={Boolean(touched.subscriptionCode && errors.subscriptionCode)}
                    helperText={touched.subscriptionCode && errors.subscriptionCode}
                    label="Subscription Code"
                    name="subscriptionCode"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.subscriptionCode}
                    variant="outlined"
                  />
                )}
              </Box>
            </Box>
          </Box>

          <Divider />

          <Accordion square elevation={0} disableGutters sx={{ mb: 2 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="adv-newDiscount-panel"
              id="adv-newDiscount-header"
            >
              <Typography>Advanced Details</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ pl: 3, pr: 3 }}>
              <Box sx={{ display: "flex" }}>
                <TextField
                  type="number"
                  error={Boolean(touched.reservationLength && errors.reservationLength)}
                  helperText={touched.reservationLength && errors.reservationLength}
                  label="Reservation Length"
                  name="reservationLength"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.reservationLength}
                  variant="outlined"
                />

                <Box sx={{ ml: 2 }}>
                  <TextField
                    type="number"
                    error={Boolean(touched.maxUses && errors.maxUses)}
                    helperText={touched.maxUses && errors.maxUses}
                    label="Max Uses"
                    name="maxUses"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.maxUses}
                    variant="outlined"
                  />
                </Box>
              </Box>

              <Box sx={{ mt: 3, display: "flex" }}>
                <DateTimePicker
                  label="Start Time"
                  value={values.startTime}
                  onChange={(value: unknown) => setFieldValue("startTime", value)}
                  renderInput={(inputProps: TextFieldProps) => (
                    <TextField
                      type="datetime-local"
                      name="startTime"
                      {...inputProps}
                      error={Boolean(errors.startTime)}
                      helperText={errors.startTime}
                      onBlur={handleBlur}
                      variant="outlined"
                    />
                  )}
                />

                <Box sx={{ ml: 2 }}>
                  <DateTimePicker
                    label="End Time"
                    value={values.endTime}
                    onChange={(value: unknown) => setFieldValue("endTime", value)}
                    renderInput={(inputProps: TextFieldProps) => (
                      <TextField
                        type="datetime-local"
                        name="endTime"
                        {...inputProps}
                        error={Boolean(errors.endTime)}
                        helperText={errors.endTime}
                        onBlur={handleBlur}
                        variant="outlined"
                      />
                    )}
                  />
                </Box>
              </Box>

              <Box sx={{ mt: 3 }}>
                <TextField
                  fullWidth
                  name="authUsers"
                  label="Authorized Users (or domains)"
                  onChange={handleChange}
                  value={values.authUsers}
                  helperText={touched.authUsers && errors.authUsers}
                  error={Boolean(touched.authUsers && errors.authUsers)}
                  multiline
                />
                <FormHelperText>
                  Comma-separated list of users and domains allowed to use this discount code.
                </FormHelperText>
              </Box>
            </AccordionDetails>
          </Accordion>

          <Divider />

          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              p: 2,
            }}
          >
            <Box sx={{ flexGrow: 1 }} />
            <Button color="primary" onClick={onClose} variant="text">
              Cancel
            </Button>

            <Button
              color="primary"
              disabled={isSubmitting}
              sx={{ ml: 1 }}
              type="submit"
              variant="contained"
            >
              Create
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default NewDiscountForm;
