import { AVCLIENTSTATE_DEFAULT_PAGE_SIZE } from "gather-common/dist/src/public/av";
import { useQuery } from "react-query";

import * as AvClientStateSessionsAPI from "./api";

/**
 * Fetches all A/V client state sessions
 * @returns ReactQuery object where data is type of BrowserVideoClientStateSession[]
 */
export const useAvClientStateSessions = (
  page = 1,
  pageSize = AVCLIENTSTATE_DEFAULT_PAGE_SIZE,
  params: AvClientStateSessionsAPI.AvClientSessionParams = {},
) =>
  useQuery(
    ["avClientStateSessions", { page, pageSize, params }],
    () => AvClientStateSessionsAPI.fetchAvClientStateSessions(page, pageSize, params),
    {
      initialData: undefined,
      retry: false,
    },
  );

/**
 * Fetches an A/V client state session by id
 * @returns ReactQuery object where data is type of BrowserVideoClientStateSession | undefined
 */
export const useAvClientStateSessionById = (sessionId?: string) =>
  useQuery(
    ["avClientStateSession", sessionId],
    () => AvClientStateSessionsAPI.fetchAvClientStateSessionById(sessionId),
    {
      initialData: undefined,
      enabled: !!sessionId,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

export const useAvClientStateUserFeedbackBySessionId = (sessionId?: string) =>
  useQuery(
    ["avClientStateUserFeedback", sessionId],
    () => AvClientStateSessionsAPI.fetchAvClientStateUserFeedbackBySessionId(sessionId),
    {
      initialData: undefined,
      enabled: !!sessionId,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );
