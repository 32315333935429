import { SUPPORTED_IMAGE_MIME_TYPES } from "gather-common/dist/src/public/resources/files";

export const SUPPORTED_AUDIO_FORMATS: { [key: string]: string[] } = {
  "audio/mpeg": ["audio/mpeg", "audio/MPA"],
  "audio/wav": ["audio/vnd.wave", "audio/wav", "audio/wave", "audio/x-wav", "audio/x-pn-wav"],
  "audio/ogg": ["audio/ogg"],
};

export const isValidAudio = (fileType: string) =>
  !!Object.keys(SUPPORTED_AUDIO_FORMATS).find((mimeType) =>
    SUPPORTED_AUDIO_FORMATS[mimeType]?.includes(fileType),
  );

export const isValidAudioUrl = (url: string) => /\.(mp3|wav|ogg)$/.test(url);

export const isValidImage = (fileType: string) => SUPPORTED_IMAGE_MIME_TYPES.includes(fileType);

export const isValidImageUrl = (url: string) => /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
