import {
  AddPageActionPriority,
  newRelicManager,
} from "gather-browser-newrelic-manager/dist/src/public/newRelicManager";
import { getConsoleFn } from "./consoleWrapper";

export enum ErrorClass {
  Unexpected = "unexpected",
  Expected = "expected",
  ErrorThatShouldNeverHappen = "errorThatShouldNeverHappen",
}

/**
 * This Logger is a wrapper of window.console (with added custom functionality) to allow developers to
 * silence logs in unit test files by mocking Logger.
 */
export const Logger = {
  log: getConsoleFn("log"),
  warn: getConsoleFn("warn"),
  error: getConsoleFn("error"),
  debug: getConsoleFn("debug"),
  info: getConsoleFn("info"),
  dir: getConsoleFn("dir"),
  /**
   * Log an error.
   *
   * This will show up in our error dashboard and will trigger low-priority alerts in Slack. For
   * errors that we expect to happen that we don't want alerts for, use `expectedError` instead.
   */
  unexpectedError: (message: string, ...data: unknown[]) => {
    Logger.error(message, ...data, { errorClass: ErrorClass.Unexpected });
    newRelicManager?.addPageActionReliably(
      "unexpected-error",
      { message },
      AddPageActionPriority.Low,
    );
  },
  /**
   *  Log an error that is expected to happen.
   *
   *  Use this to keep track of errors that we expect to happen and don't want to get alerts for.
   */
  expectedError: (message: string, ...data: unknown[]) => {
    Logger.error(`${message}`, ...data, { errorClass: ErrorClass.Expected });
  },
  /**
   * Log an error that should NEVER happen - print the error to console but also log data to track this.
   *
   * BE VERY CAREFUL WHEN USING THIS! Adding new callsites where this gets invoked will very likely
   * page the Platform oncall. Triple check that your code _actually_ should never get called!
   * @param message
   * @param data
   */
  errorThatShouldNeverHappen: (message: string, ...data: unknown[]) => {
    // We log both a console error (which goes to the JavascriptError NR table) and a PageAction
    // to reap the benefits of both tables. Alerts / metrics / investigation can happen on both!
    // This is the same approach we take in Fatal Error logging.
    Logger.error(`[ERROR THAT SHOULD NEVER HAPPEN] ${message}`, ...data, {
      errorClass: ErrorClass.ErrorThatShouldNeverHappen,
    });
    newRelicManager?.addPageActionReliably(
      "error-should-never-happen",
      { message },
      AddPageActionPriority.Urgent,
    );
  },
};
