import { AdminPermission } from "./types";
import { AdminRole } from "./index";
import { just } from "gather-common/dist/src/public/fpHelpers";
import { uniq } from "ramda";
import { AdminRoleTypePrisma } from "gather-prisma-types/dist/src/public/client";

// From a list of roles, retrieve all of the (unique) permissions
export const getPermissionsFromRoles = (roles: AdminRoleTypePrisma[]) =>
  uniq(roles.map((role) => just(AdminRole[role]).permissions).flat());

type PermittedType = (
  required: AdminPermission[] | undefined,
  current: AdminPermission[],
  requiresAll?: boolean,
) => boolean;

export const checkIfPermitted: PermittedType = (required = [], current, requiresAll = false) => {
  let isPermitted = false;

  if (required && required.length > 0) {
    if (requiresAll) {
      isPermitted = required.every((r) => current.includes(r));
    } else {
      isPermitted = required.some((r) => current.includes(r));
    }
  } else {
    isPermitted = true;
  }

  return isPermitted;
};
