import { useQueryClient, useMutation } from "react-query";

import * as ReservationsAPI from "features/reservations/api";
import QueryKeys from "features/queryKeys";
import {
  CreateReservationMutationContext,
  CreateReservationMutationVariables,
  ReservationMutationCallbacks,
} from "./types";
import { addNewReservationToSpaceCache, addNewReservationToUserCache } from "./utils";
import { AdminReservation } from "gather-admin-common/dist/src/public/reservations/types";

/**
 * Mutation that creates a reservation document
 * @param callbacks Object containing onError and onSuccess callback methods
 * @returns ReactQuery useMutation hook
 */
const useCreateReservation = (callbacks?: ReservationMutationCallbacks) => {
  const queryClient = useQueryClient();

  return useMutation(
    (variables: CreateReservationMutationVariables) =>
      ReservationsAPI.submitNewReservation(variables.fields),
    {
      onMutate: async (variables) => {
        const { fields, userId = "" } = variables;
        // Convert NewAdminReservationFields to a plain ol' AdminReservation
        const newRes: AdminReservation = {
          roomId: fields.roomId,
          capacity: fields.capacity,
          price: fields.price,
          startDate: fields.startDate,
          endDate: fields.endDate,
          reserver: fields.disableInvoice ? userId : fields.customerEmailOrId ?? "",
          id: "Assigning ID...", // reservation ID will show as "Assigning ID..." until react-query refetches
          paid: fields.disableInvoice,
          paymentIntentIds: [],
          hasUnpaidInvoice: false,
          isInvoicedSubscription: false,
          plan: fields.plan,
          canceled: false,
          otherReservationType: null,
          paymentFailedAt: null,
          paymentFailureContext: null,
          reservationType: fields.reservationType,
        };

        const previousSpaceList = addNewReservationToSpaceCache(queryClient, newRes);
        const previousUserList = addNewReservationToUserCache(queryClient, newRes);

        const context: CreateReservationMutationContext = {
          previousSpaceList,
          previousUserList,
          newReservation: newRes,
        };
        return context;
      },
      onError: (_err, { fields }, context?: CreateReservationMutationContext) => {
        const { previousSpaceList, previousUserList, newReservation } = context || {};

        // Restore old cached values if api request fails
        if (previousSpaceList) {
          queryClient.setQueryData<AdminReservation[]>(
            [QueryKeys.SpaceReservations, fields.roomId],
            previousSpaceList,
          );
        }

        if (previousUserList && newReservation) {
          queryClient.setQueryData<AdminReservation[]>(
            [QueryKeys.UserReservations, newReservation?.reserver],
            previousUserList,
          );
        }

        if (callbacks?.onError) {
          callbacks?.onError();
        }
      },
      onSuccess: () => {
        if (callbacks?.onSuccess) {
          callbacks?.onSuccess();
        }
      },
      onSettled: (_data, _err, { fields }, context) => {
        const { newReservation, previousSpaceList, previousUserList } = context || {};

        if (previousSpaceList) {
          const { roomId } = fields;
          queryClient.invalidateQueries([QueryKeys.SpaceReservations, roomId]);
        }

        if (previousUserList && newReservation) {
          const { reserver } = newReservation;
          queryClient.invalidateQueries([QueryKeys.UserReservations, reserver]);
        }
      },
    },
  );
};

export default useCreateReservation;
