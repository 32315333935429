import { useQueryClient, useMutation } from "react-query";

import * as DiscountsAPI from "features/discounts/api";
import { AdminDiscount } from "features/discounts/types";
import QueryKeys from "features/queryKeys";
import { DiscountMutationCallbacks, DiscountMutationContext } from "./types";

/**
 * Mutation that creates a discount document
 * @param callbacks Object containing onError and onSuccess callback methods
 * @returns ReactQuery useMutation hook
 */
const useCreateDiscount = (callbacks?: DiscountMutationCallbacks) => {
  const queryClient = useQueryClient();

  return useMutation((newDiscount: AdminDiscount) => DiscountsAPI.submitNewDiscount(newDiscount), {
    onMutate: async (newDiscount) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      queryClient.cancelQueries([QueryKeys.Discounts]);

      // Snapshot the previous value of all discounts currently cached
      const previousDiscounts =
        queryClient.getQueryData<AdminDiscount[]>([QueryKeys.Discounts]) ?? [];

      // Update the cached data
      queryClient.setQueryData<AdminDiscount[]>(
        QueryKeys.Discounts,
        previousDiscounts.concat([newDiscount]),
      );

      const context: DiscountMutationContext = { previousDiscounts };

      return context;
    },
    onError: async (_err, _data, context?: DiscountMutationContext) => {
      const { previousDiscounts = [] } = context || {};

      queryClient.setQueryData<AdminDiscount[]>([QueryKeys.Discounts], previousDiscounts);

      if (callbacks?.onError) {
        callbacks?.onError();
      }
    },
    onSuccess: () => {
      if (callbacks?.onSuccess) {
        callbacks?.onSuccess();
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries([QueryKeys.Discounts]);
    },
  });
};

export default useCreateDiscount;
