import React, { FC } from "react";
import { Link as RouterLink } from "react-router-dom";

import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import Label from "components/dashboard/Label";
import { displayDate } from "features/reservations/utils";
import { AdminReservation } from "gather-admin-common/dist/src/public/reservations/types";

interface Props {
  reservation: AdminReservation;
  showReserver: boolean;
}

const ReservationRow: FC<Props> = ({ reservation, showReserver = true }) => {
  const paid = reservation.paid && !reservation.hasUnpaidInvoice;

  return (
    <TableRow hover key={reservation.id}>
      <TableCell>
        {reservation.id === "Assigning ID..." ? (
          reservation.id
        ) : (
          <Link
            color="textPrimary"
            component={RouterLink}
            to={`/dashboard/reservations/${reservation.id}`}
            underline="always"
            variant="subtitle2"
          >
            {reservation.id}
          </Link>
        )}
      </TableCell>

      <TableCell>
        {reservation.startDate && (
          <Typography color="textSecondary" variant="body2">
            {displayDate(reservation.startDate)}
          </Typography>
        )}
      </TableCell>

      <TableCell>
        {reservation.endDate ? (
          <Typography color="textSecondary" variant="body2">
            {displayDate(reservation.endDate)}
          </Typography>
        ) : (
          <Label color="primary">Ongoing Subscription</Label>
        )}
      </TableCell>

      <TableCell>
        <Label color={paid ? "success" : "error"}>{paid ? "Paid" : "Unpaid"}</Label>
        {reservation.canceled && (
          <Box sx={{ ml: 1, display: "inline-block" }}>
            <Label color="warning">Cancelled</Label>
          </Box>
        )}
      </TableCell>

      <TableCell>
        <Typography color="textSecondary" variant="body2">
          {reservation.capacity}
        </Typography>
      </TableCell>

      {showReserver && (
        <TableCell>
          <Typography color="textSecondary" variant="body2">
            {reservation.reserver}
          </Typography>
        </TableCell>
      )}
    </TableRow>
  );
};

export default ReservationRow;
